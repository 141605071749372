import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../Styles/Navbar.css';
import Logo from "../../../Assets/Images/logo.svg"

import ArrowDown from "../../../Assets/Images/arrow-down-menu.svg"

import { Dropdown } from "react-bootstrap";
const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  return (
    <nav className="td-home-navbar">
          <div className="nav-logo">
        <Link to="/" onClick={toggleMenu}>
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <Link
          to="/"
          className={location.pathname === "/" ? "active" : ""}
          onClick={toggleMenu}
        >
          Home
        </Link>
        <Dropdown
          show={showDropdown}
          onToggle={toggleDropdownMenu}
          className="main-nav-dropdown"
        >
          <Dropdown.Toggle
            variant="link"
            className={location.pathname.includes("/service") ? "active" : ""}
            id="dropdown-basic"
          >
            Services <img src={ArrowDown} alt="ArrowDown" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/service-cafe-startup"
              onClick={toggleMenu}
            >
             Cafe Entrepreneurship
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/service-food" onClick={toggleMenu}>
            Foodservice
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/service-trade" onClick={toggleMenu}>
            Trades & Services
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Link
          to="/public-cafe-directory"
          className={
            location.pathname === "/public-cafe-directory" ? "active" : ""
          }
          onClick={toggleMenu}
        >
          Public Cafe Directory 
        </Link>
        <Link
          to="/about-us"
          className={location.pathname === "/about-us" ? "active" : ""}
          onClick={toggleMenu}
        >
          About Us
        </Link>
        <Link
          to="/contact-us"
          className={location.pathname === "/contact-us" ? "active" : ""}
          onClick={toggleMenu}
        >
          Contact
        </Link>
        <Link to="/common-signup">
          <button type="button" className="btn primary-button sign-up-btn">
            Sign Up
          </button>
        </Link>
        <Link to="/cafe-login">
          <button type="button" className="btn primary-button">
            Log In
          </button>
        </Link>
      </div>
      <div className="nav-toggle" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
  );
};
export default Navbar;
