import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_COMMUNITY_DEV_URL;

export const communityapi = createApi({
  reducerPath: 'communityapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');
    
    
      const noAuthEndpoints = ['submitForm', 'submitInviteFriend', 'verifyRegisterOTP', 'resendRegisterOTP'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
       // Community
getNoticeCategory: builder.query({
    query: () => `api/get-notice-categories`,
  }),
  storeNotices:builder.mutation({
    query: (postData) => ({
      url: `api/notices`,
      method: 'POST',
      body: postData,
    }),
  }),
  getNotices: builder.query({
    query: ({userId,categoryId,directoryListingId,subscriptionType}) => `api/get_notices?user_id=${userId}&category_id=${categoryId}&directory_listing_id=${directoryListingId}&subscription_type=${subscriptionType}`,
  }),
  getMyNoticeBoard : builder.query({
    query: ({userId,directoryListingId,subscriptionType}) => `api/get_my_notices?user_id=${userId}&directory_listing_id=${directoryListingId}&subscription_type=${subscriptionType}`,
  }),
  showhideNotice: builder.mutation({
    query: (id) => ({
      url: `api/notices/${id}/toggle-visibility`,
      method: 'POST', // Change to PUT
    }),
  }),
  deleteNotice: builder.mutation({ // Change from query to mutation
    query: (id) => ({
      url: `api/delete-user-notices/${id}`,
      method: 'DELETE', // Change to DELETE
    }),
  }),
  // getChatCategories: builder.query({
  //   query: () => 'api/chat_categories'
  // }),
  getchatpostbycategory: builder.query({
    query: ({chat_category_id,directory_listing_id,subscription_type}) => `api/post_by_category?chat_category_id=${chat_category_id}&directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
  }),

getChatCategories: builder.query({
  query: () => 'api/get-chat-categories'
}),

saveChatTopic:builder.mutation({
  query: (postData) => ({
    url: `api/add-chat-posts`,
    method: 'POST',
    body: postData,
  }),
}),

getAllChatTopic: builder.query({
 query: ({directory_listing_id,subscription_type}) => `api/chat_categories?directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
}),

getChatTopicbyId: builder.query({
  query: ({chat_category_id,directory_listing_id,subscription_type}) => `api/post_by_category?chat_category_id=${chat_category_id}&directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
 }),

 getMyChatTopic: builder.query({
  query: ({directory_listing_id,subscription_type,user_id}) => `api/user_chat_categories?user_id=${user_id}&directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
 }),
 
 getMyChatTopicbyId: builder.query({
   query: ({user_id,chat_category_id,directory_listing_id,subscription_type}) => `api/user-posts-by-category?user_id=${user_id}&chat_category_id=${chat_category_id}&directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
  }),
 
getPostById :builder.query({
  query: ({id,directory_listing_id,subscription_type}) => `api/posts/${id}?directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
 }),

postChatReply:builder.mutation({
  query: (postData) => ({
    url: `api/replay_post`,
    method: 'POST',
    body: postData,
  }),
}),
submitEcosystemRecommendation: builder.mutation({
  query: (postdata) => ({
    url: 'api/invite-friend',
    method: 'POST',
    body: postdata,
  }),
}),

submitInviteFriend: builder.mutation({
  query: (postdata) => ({
    url: 'api/invite-friend-common',
    method: 'POST',
    body: postdata,
  }),
}),

deleteUserbyNoticeId: builder.mutation({
  query: (user_id) => ({
    url: `api/delete-notices/${user_id}`,
    method: 'DELETE',
  }),
}),
deleteBranchbyNoticeId: builder.mutation({
  query: ({user_id,deleteBranchId}) => ({
    url: `api/delete-by-listing_notices/${user_id}/${deleteBranchId}`,
    method: 'DELETE',
  }),
}),

  }),
});

export const {
    useGetNoticeCategoryQuery,
    useGetNoticesQuery,
    useStoreNoticesMutation,
    useGetchatpostbycategoryQuery,
    useGetChatCategoriesQuery,
    useSaveChatTopicMutation,
    useGetAllChatTopicQuery,
    useGetChatTopicbyIdQuery,
    useGetMyChatTopicQuery,
    useGetMyChatTopicbyIdQuery,
    useGetPostByIdQuery,
    usePostChatReplyMutation,
    useSubmitEcosystemRecommendationMutation,
    useSubmitInviteFriendMutation,
    useGetMyNoticeBoardQuery,
    useDeleteNoticeMutation,
    useShowhideNoticeMutation,
    useDeleteUserbyNoticeIdMutation,
  useDeleteBranchbyNoticeIdMutation,
} = communityapi;
