import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tabs, Tab, Table } from "react-bootstrap";
import SearchIcon from "../Assets/Images/search-icon.svg";
import "../Styles/Chat.css";
import AddNewTopic from "../Components/AddNewTopicModal";
import { Link, useLocation } from "react-router-dom";
import BackIcon from "../Assets/Images/Back-icon.svg";
import ActionIcon from "../Assets/Images/actions-img.svg";
import defaultprofileImg from "../Assets/Images/default-profile-img.png";
import { useParams } from "react-router-dom";
import {
  useGetChatTopicbyIdQuery,
  useGetMyChatTopicQuery,
} from "../Services/CommunityAPI";
import moment from "moment";

function formatDate(dateString) {
  return moment(dateString).format("DD MMMM YYYY HH:mm");
}

function Chat() {
  const { id } = useParams();
  const branchId = localStorage.getItem("branchId_current_selected");
  const accountHolderId = localStorage.getItem("accountHolderId");
  const subscription_type = localStorage.getItem("subscription_type_current_selected");

  const { data: allmessages } = useGetChatTopicbyIdQuery({
    chat_category_id: id,
    directory_listing_id: branchId,
    subscription_type: subscription_type,
  });
  const [key, setKey] = useState("allTopics");
  const [showAddNewTopicModal, setshowAddNewTopicModal] = useState(false);
  const handleAddNewTopic = () => {
    setshowAddNewTopicModal(true);
  };
  const handleclodeAddnewTopicModal = () => {
    setshowAddNewTopicModal(false);
  };
  const { data: mytopic, isLoading: mytopicloding } = useGetMyChatTopicQuery({
    user_id: accountHolderId,
    directory_listing_id: branchId,
    subscription_type: subscription_type,
  });

  return (
    <div className="chat-list chat-list-view">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Chat</h4>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button className="add-another-location" onClick={handleAddNewTopic}>
            Add Your topic
          </button>
        </Col>
      </Row>
      <div className="chat-list-tab">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="allTopics" title="All topics">
            <Row className="category-search">
              <Col xs={12} sm={12} g={6} md={6}>
                <h5>
                  <Link to="/chat-list">
                    <img src={BackIcon} alt="BackIcon" />
                  </Link>
                  {allmessages?.category_name}
                </h5>
              </Col>
              <Col xs={12} sm={12} g={4} md={4} className="text-end">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      name="search"
                      className="serach-box"
                    />
                  </div>
                </form>
              </Col>
            </Row>
            {mytopicloding && <div class="loader"></div>}
            {allmessages?.posts?.map((posts) => (
              <div key={posts.id} className="card">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex">
                      <div>
                        <h5>{posts.post_title}</h5>
                      </div>
                      <div>
                        <img src={ActionIcon} alt="ActionIcon" />
                      </div>
                    </div>
                    <p className="description">{posts.post_content}</p>

                    <hr />
                    <div className="d-flex">
                      <div className="d-flex">
                        <div>
                          <img
                            src={
                              posts.author_profile_image
                                ? posts.author_profile_image
                                : defaultprofileImg
                            }
                            alt="profile"
                            className="default-chat-profile-img"
                          />
                        </div>
                        <div className="profile">
                          <p className="person-name"> {posts.author_name} </p>

                          <p className="date">
                            {" "}
                            {formatDate(posts.post_created_at)}{" "}
                          </p>
                        </div>
                      </div>

                      <Link
                        className="reply-btn"
                        to={`/chat-list-reply/${posts.post_id}`}
                      >
                        <p>Reply</p>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </Tab>
          <Tab eventKey="mytopics" title="Manage my topics">
            <Row className="category-search">
              <Col xs={12} sm={12} g={6} md={6}>
                <h5>Categories</h5>
              </Col>
              <Col xs={12} sm={12} g={4} md={4} className="text-end">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      name="search"
                      className="serach-box"
                    />
                  </div>
                </form>
              </Col>
            </Row>
            <div>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>No of posts</th>
                    <th>Last posted</th>
                    <th>Author</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {mytopic?.data?.map((user) => (
                    <tr key={user.id}>
                      <td>{user.category_name}</td>
                      <td>{user.number_of_comments_on_user_posts}</td>
                      <td>{formatDate(user.last_post_date ?? "")}</td>
                      <td>{user.last_comment_author}</td>
                      <td>
                        <Link to={`/chat-list-view-my-topic/${user.category_id}`}>
                          <button className="table-view-btn" type="button">
                            View
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>
          {mytopicloding && <div class="loader"></div>}
        </Tabs>
      </div>
      <AddNewTopic
        show={showAddNewTopicModal}
        handlecloseModal={handleclodeAddnewTopicModal}
      />
    </div>
  );
}

export default Chat;
