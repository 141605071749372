import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/UpgradeModal.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
 
function UpgradeModal({ show, handleCloseUpgradeModal, selectedLocations }) {
  const handleCloseModal = () => {
    handleCloseUpgradeModal();
  };
  const navigate = useNavigate();

  const allselectedLocations = selectedLocations;
  const handleClickUpgrade = () => {
    navigate("/upgrade-to-pro", {
      state: { allselectedLocations },
    });
  };
  return (
    <Modal
      show={show}
      backdrop="true"
      onHide={handleCloseUpgradeModal}
      dialogClassName="modal-dialog-centered upgrade-modal"
    >
      <Modal.Header closeButton>
                {/* Optionally, you can include a title or leave it blank */}
            </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <div className="left-sec">
              <h4>All Lite Member Features Plus:</h4>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Ecosystem</span>
              </h5>
              <p>
                Engage with the entire community for communication, matching,
                and shared knowledge.
              </p>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>50% off Access to 1:1 Mentorship & Roadmapping</span>
              </h5>
              <p>
                Personalised guidance, shadowing, and support to stay
                accountable and on your A-game.
              </p>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Included Specialist Course Framework</span>
              </h5>
              <p>
                Structured courses, collaborative sessions with panelists, and
                insights from expert speakers.
              </p>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Development Materials</span>
              </h5>
              <p>
                Access interactive online cafe business materials, covering
                essential areas of cafe business management
              </p>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Included Regular Group Huddle Sessions</span>
              </h5>
              <p>Collaborative sessions for shared learning and support.</p>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span> Priority Fast Support</span>
              </h5>
              <p>Quick assistance for platform-related queries.</p>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Preferred Rates for Products & Services</span>
              </h5>
              <p>Discounts on introduced products and services.</p>
            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7}>
            <div className="right-sec">
              <h4>Keen to go Pro?</h4>
              <p>
              Unlock the full potential of your cafe with our Pro plan. Gain access to 
              exclusive resources and dedicated assistance. The most cost-effective way to get 
              the support your cafe needs to thrive.{" "}
              </p>
              <div className="upgrade-to-pro" onClick={handleClickUpgrade}>
                <h4>Upgrade to Pro </h4>
                <p>Get full access to all features and exclusive benefits. </p>
              </div>
              <div className="upgrade-to-pro" onClick={handleCloseModal}>
                <h4>Continue with Lite </h4>
                <p>Stick with the basic plan and access limited features. </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default UpgradeModal;
