import React, { useState, useCallback, useRef, useEffect } from "react";
import "../Styles/EcoSystemMatches.css";
import { Row, Col } from "react-bootstrap";
import FilterIcon from "../Assets/Images/filter-icon.svg";
import ActionIcon from "../Assets/Images/actions-img.svg";
import ComapnyLogo from "../Assets/Images/company-logo.png";
import InactiveBUtton from "../Assets/Images/Inactive Button.svg";
import ActiveButton from "../Assets/Images/cafe-match-active.svg";
import heartImg from "../Assets/Images/heart-cafe-match.svg";
import heartImgFill from "../Assets/Images/heart-cafe-fill.svg";
import { Link, useLocation } from "react-router-dom";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import LockIcon from "../Assets/Images/lock-page.svg";
import ArrowdownIcon from "../Assets/Images/Dropdown.svg";
import {
  useGetEcosystemFoodSericeQuery,
  useGetEcosystemTradeSericeQuery,
  useGetStatesQuery,
  useGetServiceTypeQuery,
  useGetProductTypeQuery,
  useGetfoodDeietryServiceQuery,
  useGetTradeServiceCategoryQuery,
  useEcosystematchFoodserviceLikeMutation,
  useFoodserviceLikeMutation,
  useTradeserviceLikeMutation,
  useCafeacceptfdMutation,
  useCaferejectfdMutation,
  useTdacceptcafeMutation,
  useTdrejectcafeMutation,
  useCafeDeclinedFoodListQuery,
  useCafeDeclinedTradeListQuery
} from "../Services/CcownerAPI";

const SortDropdown = ({ handleClickOrderingFood }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const onSelectFoodService = (item) => {
    
    setActiveItem(item);
   
    setIsOpen(false);
    handleClickOrderingFood(item);
  };

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();
  const { data: tradeServicesType } = useGetTradeServiceCategoryQuery();
  return (
    <div className="custom-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Sort
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button
            className={`dropdown-item ${
              activeItem === "latest" ? "active" : ""
            }`}
            onClick={() => onSelectFoodService("latest")}
          >
            Latest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "oldest" ? "active" : ""
            }`}
            onClick={() => onSelectFoodService("oldest")}
          >
            Oldest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "recommendation" ? "active" : ""
            }`}
            onClick={() => onSelectFoodService("recommendation")}
          >
            Recommendation
          </button>
          <button
            className={`dropdown-item ${activeItem === "" ? "active" : ""}`}
            onClick={() => onSelectFoodService("bestmatches")}
          >
            Best matches
          </button>
        </div>
      )}
    </div>
  );
};

const SortDropdownTrade = ({ handleClickOrderingTrade }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const onSelectTradeService = (item) => {

    setActiveItem(item);

    setIsOpen(false);
    handleClickOrderingTrade(item);
  };

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();
  const { data: tradeServicesType } = useGetTradeServiceCategoryQuery();
  return (
    <div className="custom-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Sort
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button
            className={`dropdown-item ${
              activeItem === "latest" ? "active" : ""
            }`}
            onClick={() => onSelectTradeService("latest")}
          >
            Latest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "oldest" ? "active" : ""
            }`}
            onClick={() => onSelectTradeService("oldest")}
          >
            Oldest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "recommendation" ? "active" : ""
            }`}
            onClick={() => onSelectTradeService("recommendation")}
          >
            Recommendation
          </button>
          <button
            className={`dropdown-item ${activeItem === "" ? "active" : ""}`}
            onClick={() => onSelectTradeService("bestmatches")}
          >
            Best matches
          </button>
        </div>
      )}
    </div>
  );
};

const FilterDropdownFoodservice = ({
  handleStateButtonClick,
  handleProductTypeButtonclick,
  handleFoodCategoryButtonclick,
  serviceTypes,
  handleFoodServiceApplyClick,
  handleDietraryTypeButtonclick,
  states,
  productTypes,
  dietryServices,
  foodserviceCategories,
  productType,
  dietaryType,
  stateData,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className="custom-dropdown filter-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Filter
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="apply-btns">
            <button className="reset-button">Reset</button>
            <button className="apply-btn" onClick={handleFoodServiceApplyClick}>
              Apply
            </button>
          </div>
          <div>
            <div className="d-flex align-space-between category-head">
              <p>Service Category</p>
              <img src={ArrowdownIcon} alt="ArrowdownIcon" />
            </div>
            <div className="menu-items">
              {serviceTypes &&
                serviceTypes.map((service) => (
                  <button
                    key={service.id}
                    onClick={() => handleFoodCategoryButtonclick(service.id)}
                    className={
                      foodserviceCategories?.includes(service.id)
                        ? "active"
                        : ""
                    }
                  >
                    {service.name}
                  </button>
                ))}
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>Product type</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {productTypes &&
                  productTypes.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleProductTypeButtonclick(service.id)}
                      className={
                        productType?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>Specialist dietary services</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {dietryServices &&
                  dietryServices.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleDietraryTypeButtonclick(service.id)}
                      className={
                        dietaryType?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>States</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {states &&
                  states.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleStateButtonClick(service.id)}
                      className={
                        stateData?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const FilterDropdownTradeservice = ({
  handleTradeStateButtonClick,
  states,
  handleTradeCategoryButtonclick,
  tradeServiceTypes,
  handleTradeServiceApplyClick,
  tradestateData,
  tradeserviceCategories,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  return (
    <div className="custom-dropdown filter-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Filter
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="apply-btns">
            <button className="reset-button">Reset</button>
            <button
              className="apply-btn"
              onClick={handleTradeServiceApplyClick}
            >
              Apply
            </button>
          </div>
          <div>
            <div className="d-flex align-space-between category-head">
              <p>Service Category</p>
              <img src={ArrowdownIcon} alt="ArrowdownIcon" />
            </div>
            <div className="menu-items">
              {tradeServiceTypes &&
                tradeServiceTypes.map((service) => (
                  <button
                    key={service.id}
                    onClick={() => handleTradeCategoryButtonclick(service.id)}
                    className={
                      tradeserviceCategories?.includes(service.id)
                        ? "active"
                        : ""
                    }
                  >
                    {service.name}
                  </button>
                ))}
            </div>

            <div>
              <div className="d-flex align-space-between category-head">
                <p>States</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {states &&
                  states.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleTradeStateButtonClick(service.id)}
                      className={
                        tradestateData?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Card = ({
  companyLogo,
  businessName,
  location,
  address,
  dietrayTypes,
  productTypes,
  serviceTypes,
  onActionClick,
  handleClikFoodserviceLike,
  fsbranchid,
  liked,
  decision,
  refetchfoodserviceData
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const [cstofsaccept, {}] = useCafeacceptfdMutation();
  const [cstofsdeclined, {}] = useCaferejectfdMutation();
  const currentbranchbranchId = localStorage.getItem(
    "branchId_current_selected"
  );

  const handleActiveClick = async () => {
    const postdata = {
      fs_branch_id: fsbranchid,
      status: "accepted",
    };
    try {
      const response = await cstofsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);
        refetchfoodserviceData();
      }
    } catch (err) {
      alert("Failed to Accept");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  const handleInactiveClick = async () => {
    const postdata = {
      fs_branch_id: fsbranchid,
      status: "pending",
    };
    try {
      const response = await cstofsdeclined({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        // setIsActive(true);
        // setIsHidden(false);
        alert("Succesfully unblocked");
        refetchfoodserviceData();
      }
    } catch (err) {
      alert("Failed to unblock");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  if (isHidden) {
    return null;
  }

  return (
    <div className="card">
      <div className="d-flex justify-space-between">
        <div className="d-flex">
          <div>
            <img src={companyLogo || ComapnyLogo} alt="CompanyLogo" />
          </div>
          <div className="mlr-20">
            <h4>{businessName}</h4>
            <p>{location}</p>
          </div>
        </div>
        <div className="d-flex align-items-start">
          {/* <img
            src={liked ? heartImgFill : heartImg}
            alt="heart-img"
            className="img-fluid"
            width={24}
            onClick={handleClikFoodserviceLike}
          /> */}
          {/* <img
            src={ActionIcon}
            alt="ActionIcon"
            className="img-fluid"
            width={30}
            onClick={onActionClick}
          /> */}
        </div>
      </div>
      <div className="d-flex justify-space-between flex-end">
        <div>
          <p className="address">{address}</p>
        </div>
        <button type="button" className="unblock-btn" onClick={handleInactiveClick}>
        Rematch
              </button>
      </div>
      <hr />
      <div className="service-type types">
        <h5>Service Type</h5>
        {serviceTypes &&
          serviceTypes?.map((type, index) => (
            <button key={index}>{type.name}</button>
          ))}
      </div>
      <div className="dietary-type types">
        <h5>Dietary services</h5>
        {dietrayTypes &&
          dietrayTypes?.map((type, index) => (
            <button key={index}>{type.name}</button>
          ))}
      </div>
      <div className="product-type types">
        <h5>Product type</h5>
        {/* {productTypes && productTypes?.map((type, index) => (
          <button key={index}>{type}</button>
        ))} */}
      </div>
    </div>
  );
};

const TradeCard = ({
  companyLogo,
  businessName,
  location,
  address,
  serviceTypes,
  onActionClick,
  tsbranchid,
  handleClikTradeserviceLike,
  liked,
  decision,
  refetchTradeserviceData
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const [cstotsaccept, {}] = useTdacceptcafeMutation();
  const [cstotsdeclined, {}] = useTdrejectcafeMutation();
  const currentbranchbranchId = localStorage.getItem(
    "branchId_current_selected"
  );

  const handleActiveClick = async () => {
    const postdata = {
      ts_branch_id: tsbranchid,
      status: "accepted",
    };
    try {
      const response = await cstotsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);
        refetchTradeserviceData();
      }
    } catch (err) {
      alert("Failed to Accept");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  const handleInactiveClick = async () => {
    const postdata = {
      ts_branch_id: tsbranchid,
      status: "pending",
    };
    try {
      const response = await cstotsdeclined({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        // setIsActive(true);
        // setIsHidden(false);
        alert("Succesfully unblocked");
        refetchTradeserviceData();
      }
    } catch (err) {
      alert("Failed unblock");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };
  if (isHidden) {
    return null;
  }

  return (
    <div className="card">
      <div className="d-flex justify-space-between">
        <div className="d-flex">
          <div>
          <img src={companyLogo || ComapnyLogo} alt="CompanyLogo" />
          </div>
          <div className="mlr-20">
            <h4>{businessName}</h4>
            <p>{location}</p>
          </div>
        </div>
        <div className="d-flex align-items-start">
          {/* <img
            src={liked ? heartImgFill : heartImg}
            alt="heart-img"
            className="img-fluid"
            width={24}
            onClick={handleClikTradeserviceLike}
          /> */}

          {/* <img
            src={ActionIcon}
            alt="ActionIcon"
            className="img-fluid"
            width={30}
            onClick={onActionClick}
          /> */}
        </div>
      </div>
      <div className="d-flex justify-space-between flex-end">
        <div>
          <p className="address">{address}</p>
        </div>

              <button type="button" className="unblock-btn" onClick={handleInactiveClick}>
                Unblock
              </button>
          

      </div>
      <hr />
      <div className="service-type types">
        <h5>Service Type</h5>
        {serviceTypes &&
          serviceTypes?.map((type, index) => (
            <button key={index}>{type.name}</button>
          ))}
      </div>
    </div>
  );
};

function EcoSystemMatches() {
  const [activeTab, setActiveTab] = useState("tab1");
  const branchId = localStorage.getItem("branchId_current_selected");

  const [foodServiceParams, setfoodServiceParams] = useState({
    id: branchId,
    service_categories: [],
    states: [],
    product_type: [],
    specialist_dietary_services: [],
    recommendation: "",
    ordering: "",
    delivery_range: "",
  });

  const [tradeServiceParams, settradeServiceParams] = useState({
    id: branchId,
    service_categories: [],
    states: [],
    recommendation: "",
    ordering: "",
    delivery_range: "",
  });

  const [foodserviceCategories, setfoodserviceCategories] = useState([]);
  const [productType, setproductTypes] = useState([]);
  const [dietaryType, setdietaryType] = useState([]);
  const [stateData, setstates] = useState([]);
  const [tradestateData, setstatesTrade] = useState([]);
  const [tradeserviceCategories, settradeserviceCategories] = useState([]);
  const [orderingValue, setOrdering] = useState("");

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();

  const { data: foodServiceData, isLoading: ecosystemfoodServiceIsloding, refetch:refetchfoodserviceData } =
  useCafeDeclinedFoodListQuery(branchId);

  const { data: tradeServiceData, isLoading: ecosystemTradeServiceIsloding, refetch:refetchTradeserviceData } =
  useCafeDeclinedTradeListQuery(branchId);

  useEffect(()=>{
    refetchfoodserviceData();
    refetchTradeserviceData();
  },[])

  useEffect(() => {

  }, [foodServiceData]);

  useEffect(() => {

  }, [tradeServiceData]);

  const handleFoodCategoryButtonclick = (serviceTypeId) => {
    setfoodserviceCategories((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };
  const handleStateButtonClick = (serviceTypeId) => {
    setstates((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleProductTypeButtonclick = (serviceTypeId) => {
    setproductTypes((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleDietraryTypeButtonclick = (serviceTypeId) => {
    setdietaryType((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleTradeCategoryButtonclick = (serviceTypeId) => {
    settradeserviceCategories((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };
  const handleTradeStateButtonClick = (serviceTypeId) => {
    setstatesTrade((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [progress, setProgress] = useState(0);
  const [deliveryRange, setDeliveryRange] = useState("100km");
  const barRef = useRef(null);

  useEffect(() => {
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      delivery_range: 100,
    }));
    setProgress(100);
    setDeliveryRange("100km");
  }, []); // Empty dependency array to run only once on mount

  const updateProgress = useCallback((clientX) => {
    if (!barRef.current) return;
    const barRect = barRef.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100);
    const roundedvalue = Math.round(
      Math.min(Math.max((offsetX / barWidth) * 100, 0), 100)
    );
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      delivery_range: roundedvalue,
    }));

    setProgress(newProgress);
    setDeliveryRange(`${Math.round(newProgress)}km`);
  }, []);

  const handleMouseDown = (e) => {
    updateProgress(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMove);
      },
      { once: true }
    );
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.buttons > 0) {
        updateProgress(e.clientX);
      }
    },
    [updateProgress]
  );

  const [progresstrade, setProgresstrade] = useState(0);
  const [deliveryRangetrade, setDeliveryRangetrade] = useState("100km");
  const barReftrade = useRef(null);

  useEffect(() => {
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      deliveryRangetrade: 100,
    }));
    setProgress(100);
    setDeliveryRange("100km");
  }, []); // Empty dependency array to run only once on mount

  const updateProgressTrade = useCallback((clientX) => {
    if (!barReftrade.current) return;
    const barRect = barReftrade.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100);
    const roundedvalue = Math.round(
      Math.min(Math.max((offsetX / barWidth) * 100, 0), 100)
    );
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      delivery_range: roundedvalue,
    }));

    setProgresstrade(newProgress);
    setDeliveryRangetrade(`${Math.round(newProgress)}km`);
  }, []);

  const handleMouseDownTrade = (e) => {
    updateProgressTrade(e.clientX);
    document.addEventListener("mousemove", handleMouseMoveTrade);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMoveTrade);
      },
      { once: true }
    );
  };

  const handleMouseMoveTrade = useCallback(
    (e) => {
      if (e.buttons > 0) {
        updateProgressTrade(e.clientX);
      }
    },
    [updateProgressTrade]
  );


  const [upgradeModal, setupgradeModal] = useState(false);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const showupgradeModal = () => {
    setupgradeModal(true);
  };
  const subscription_type = localStorage.getItem(
    "subscription_type_current_selected"
  );
const subscription_status  = localStorage.getItem(
  "subscription_status_current_selected"
);
  useEffect(() => {
    if((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))){
      setupgradeModal(false);
    }
   else {
      setupgradeModal(true);
    }
  }, [subscription_type,subscription_status]);

  const handleFoodServiceApplyClick = () => {
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      service_categories: foodserviceCategories,
      product_type: productType,
      specialist_dietary_services: dietaryType,
      states: stateData,
    }));
  };

  const handleClickOrderingFood = (orderingValue) => {
    if (orderingValue !== "bestmatches") {
      if (orderingValue !== "recommendation") {
        // Pass only the ordering value and clear recommendation
        setfoodServiceParams((prevfoodServiceParams) => ({
          ...prevfoodServiceParams,
          ordering: orderingValue,
          recommendation: null, // Clear the recommendation if ordering is set
        }));
      } else {
        // Pass only recommendation and clear ordering
        setfoodServiceParams((prevfoodServiceParams) => ({
          ...prevfoodServiceParams,
          recommendation: "most-liked",
          ordering: null, // Clear the ordering if recommendation is set
        }));
      }
    } else {
      // Clear both ordering and recommendation, pass only the id
      setfoodServiceParams({ id: branchId });
    }
  };

  const handleClickOrderingTrade = (orderingValue) => {
    if (orderingValue !== "bestmatches") {
      if (orderingValue !== "recommendation") {
        // Pass only the ordering value and clear recommendation
        settradeServiceParams((prevtradeServiceParams) => ({
          ...prevtradeServiceParams,
          ordering: orderingValue,
          recommendation: null, // Clear the recommendation if ordering is set
        }));
      } else {
        // Pass only recommendation and clear ordering
        settradeServiceParams((prevtradeServiceParams) => ({
          ...prevtradeServiceParams,
          recommendation: "most-liked",
          ordering: null, // Clear the ordering if recommendation is set
        }));
      }
    } else {
      settradeServiceParams({ id: branchId });
    }
  };

  const handleTradeServiceApplyClick = () => {
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      service_categories: tradeserviceCategories,
      states: tradestateData,
    }));
  };
  const username = localStorage.getItem("name");

  const [submitFoodLike, {}] = useFoodserviceLikeMutation();

  const [submitTradeLike, {}] = useTradeserviceLikeMutation();

  const handleClikFoodserviceLike = async (id) => {
    const postdata = {
      fs_branch_id: id,
    };
    try {
      const response = await submitFoodLike({ postdata, branchId }).unwrap();
      if (response) {
        refetchfoodserviceData();
        // alert(response.status);
      }
    } catch (err) {
      alert("Failed to like");
    }
  };

  const handleClikTradeserviceLike = async (id) => {
    const postdata = {
      ts_branch_id: id,
    };
    try {
      const response = await submitTradeLike({ postdata, branchId }).unwrap();
      if (response) {
        refetchTradeserviceData();
        // alert(response.status);
      }
    } catch (err) {
      alert("Failed to like");
    }
  };

  return (
    // <div
    //   className={`ecosystem-matches ${
    //     subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial")) 
    //         ? ""
    //         : "chat-disable-for-liteuser"
    //   }`}
    // >
    <div
    className="ecosystem-matches"
  >
      {" "}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4>Decline list</h4>
          {/* <br /> */}
          <p>You’ve decided these don’t suit your business at the moment, but they will remain available for future consideration.</p>
        </Col>
      </Row>
      <div className="match-tabs-container">
        <div className="match-tabs-header">
          <button
            className={`match-tab-button ${
              activeTab === "tab1" ? "active" : ""
            }`}
            onClick={() => setActiveTab("tab1")}
          >
            Foodservice
          </button>
          <button
            className={`match-tab-button ${
              activeTab === "tab2" ? "active" : ""
            }`}
            onClick={() => setActiveTab("tab2")}
          >
            Trades & Services
          </button>
        </div>
        <div className="match-tabs-content">
          {activeTab === "tab1" && (
            <div className="match-tab-content">
              <Row>   
                {foodServiceData ?(
                  foodServiceData.length > 0 ? (
                    foodServiceData.map((data, index) => (
                      <Col sm={12} xs={12} md={4} lg={4} key={index}>
                        <Card
                          companyLogo={data.companyLogo}
                          fsbranchid={data.id}
                          businessName={data.business_name || "N/A"}
                          location={data.location || "N/A"}
                          address={data.address || "N/A"}
                          status={data.status || "N/A"}
                          liked={data.liked}
                          decision={data.decision}
                          productTypes={data.productTypes}
                          dietrayTypes={data.dietrayTypes}
                          serviceTypes={
                            data.additional_detail?.service_categories || []
                          }
                          onActionClick={data?.onActionClick}
                          handleClikFoodserviceLike={() =>
                            handleClikFoodserviceLike(data.id)
                          }
                          refetchfoodserviceData={refetchfoodserviceData}
                        />
                      </Col>
                    ))
                  ) : (
                    <div>No data available</div>
                  )
                ) : (
                  <div>No data available</div>
                )}

                {ecosystemfoodServiceIsloding && <div class="loader"></div>}
              </Row>
            </div>
          )}
          {activeTab === "tab2" && (
            <div className="match-tab-content">
              <Row>
                {tradeServiceData ? (
                  tradeServiceData.length > 0 ? (
                    tradeServiceData.map((data, index) => (
                      <Col sm={12} xs={12} md={4} lg={4} key={index}>
                        <TradeCard
                          companyLogo={data.companyLogo}
                          tsbranchid={data.id}
                          decision={data.decision}
                          businessName={data.business_name || "N/A"}
                          location={data.location || "N/A"}
                          address={data.address || "N/A"}
                          status={data.status || "N/A"}
                          liked={data.liked}
                          serviceTypes={
                            data.additional_detail?.service_categories || []
                          }
                          onActionClick={data?.onActionClick}
                          handleClikTradeserviceLike={() =>
                            handleClikTradeserviceLike(data.id)
                          }
                          refetchTradeserviceData={refetchTradeserviceData}
                        />
                      </Col>
                    ))
                  ) : (
                    <div>No data available</div>
                  )
                ) : (
                  <div>No data available</div>
                )}
                {ecosystemTradeServiceIsloding && <div class="loader"></div>}
              </Row>
            </div>
          )}
        </div>
        {/* <UpgradeModal
          show={upgradeModal}
          handleCloseUpgradeModal={handleCloseUpgradModal}
        /> */}
      </div>
    </div>
  );
}

export default EcoSystemMatches;