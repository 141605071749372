import React, { useState, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/AllMaterialCafeStartup.css";
import { Tab, Tabs, Nav } from "react-bootstrap";
import CafestartImg from "../Assets/Images/all-matrial-cafe-startup.png";
import LeaseImg from "../Assets/Images/all-matrials-leasing.png";
import EditYellowImg from "../Assets/Images/edit-2-yellow.png";
import EdirBlackImg from "../Assets/Images/edit-black.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import BookmarkIcon from "../Assets/Images/bookmark.svg";
import BookmarkFilled from "../Assets/Images/bookmark-filled.svg";
import FilterIcon from "../Assets/Images/filter-icon.svg";
import { useParams, useNavigate } from 'react-router-dom';
import { useGetSubCategorybyMaterialIdQuery, useListUnSavedMaterialRecordsMutation, useListSavedMaterialRecordsQuery, useGetAllRecordsbyMaterialIdQuery, useGetMaterialTypeQuery, useGetMaterialTypebyCategoryIdQuery, useGetRecordsByIdQuery, useSaveRecordsMutation } from "../Services/SecondaryAPI"
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import { PDFDocument, rgb } from 'pdf-lib';
import DefaultPdfImg from "../Assets/Images/defaultpdf-img.png"
import PageLoader from "../Assets/Images/page-loader.gif"

function AllMaterialCafeStratup() {
  const { id } = useParams();
  const [mainKey, setMainKey] = useState(1);
  const [subKey, setSubKey] = useState("Saved");
  const [isOpen, setIsOpen] = useState(false);
  const [activeItem, setActiveItem] = useState(1);
  const dropdownRef = useRef(null);

  const [upgradeModal, setupgradeModal] = useState(false);



  const accountHolderId = localStorage.getItem("accountHolderId");
  const branchId = localStorage.getItem("branchId_current_selected");
  const subscription_type = localStorage.getItem("subscription_type_current_selected");
  // const subscriptionType = localStorage.getItem("subscription_type_current_selected");
  const subscriptionType = subscription_type;

  const { data: subCategories, isLoading } = useGetSubCategorybyMaterialIdQuery({ id });
  const { data: SavedRecords, error, refetch: refetchSavedRecords, isLoading: isloadingSacedRecords } = useListSavedMaterialRecordsQuery({
    userId: accountHolderId,
    businessmaterialid: id,
    subcategory_id: mainKey,
    directoryListingId: branchId,
    subcsriptionType: subscriptionType,
    material_type_id:activeItem,
    status: 1
  });

  const { data: editedRecords } = useListSavedMaterialRecordsQuery({
    userId: accountHolderId,
    businessmaterialid: id,
    subcategory_id: mainKey,
    directoryListingId: branchId,
    subcsriptionType: subscriptionType,
    status: 2
  });
  const { data: materialTypes, isLoading: isloadingmatrialtypeRecords } =
    useGetMaterialTypebyCategoryIdQuery({
      businessmaterialid: id,
      subcategory_id: mainKey,
    });

  const toggleDropdown = () => {
    setIsOpen((prevState) => {

      return !prevState;
    });
  };

  const handleTabSelect = (k) => {
    setMainKey(k);
    refetchSavedRecords();
    setActiveItem(k)
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const handleSelect = (item) => {
    console.log("item", item)
    setActiveItem(item);
    refetchSavedRecords();
    setIsOpen(false); // Close dropdown after selection
  };
  const subscription_status = localStorage.getItem(
    "subscription_status_current_selected"
  );
  useEffect(() => {
    if ((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))) {
      setupgradeModal(false);
    }
    else {
      setupgradeModal(true);
    }
  }, [subscription_type, subscription_status]);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  }
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  if (isLoading) {
    // Render a loader if the data is still loading
    return (
      <div className="page-loader">
        <img src={PageLoader} alt="PageLoader" width={100} />
      </div>
    );
  }

  return (
    <div className="my-material-cafestartup ">
      <h4>{SavedRecords?.data[0]?.business_material_name}</h4>
      <div className="filter-dtpdn">
        <div className="custom-dropdown" ref={dropdownRef}>
          <button className="dropdown-toggle" onClick={toggleDropdown}>
            <img src={FilterIcon} alt="FilterIcon" />
            Filter
          </button>
          {isOpen && (
            <div className="dropdown-menu">
              {materialTypes?.data?.map((subCategory) => (
                <button
                  className={`dropdown-item ${activeItem === "All" ? "active" : ""
                    }`}
                  onClick={() => handleSelect(subCategory.id)}
                >
                  {subCategory.name}
                </button>
              ))}
              {/* <button
                className={`dropdown-item ${
                  activeItem === "Category1" ? "active" : ""
                }`}
                onClick={() => handleSelect("Templates & workbooks")}
              >
                Templates & workbooks
              </button>
              <button
                className={`dropdown-item ${
                  activeItem === "Category2" ? "active" : ""
                }`}
                onClick={() => handleSelect(" Workbooks")}
              >
                Workbooks
              </button>
              <button
                className={`dropdown-item ${
                  activeItem === "Category3" ? "active" : ""
                }`}
                onClick={() => handleSelect("Resources")}
              >
                Resources
              </button>
              <button
                className={`dropdown-item ${
                  activeItem === "Category4" ? "active" : ""
                }`}
                onClick={() => handleSelect("Checklist")}
              >
                Checklist
              </button> */}
            </div>
          )}
        </div>
      </div>
      <div className="vertical-tabs-container">
        {isLoading && <div class="loader"></div>}
        <Tabs
          id="vertical-tabs"
          activeKey={mainKey}
          onSelect={handleTabSelect}
          className="mb-3"
          variant="pills"
        >
          {subCategories &&
            subCategories?.data?.subcategories?.map((subCategory) => (
              <Tab
                key={subCategory.id}
                eventKey={subCategory.id}
                title={subCategory.subcategory_title}
                onSelect={()=>handleTabSelect(subCategory.id)}
              >
              {isloadingSacedRecords ? (
                <div class="loader"></div>
              ):(<HorizontalTabs activeKey={subKey} onSelect={(k) => setSubKey(k)} error={error} mainKey={mainKey} isloadingSacedRecords={isloadingSacedRecords} editedRecords={editedRecords} SavedRecords={SavedRecords} />)}
              </Tab>
            ))}
        </Tabs>
        <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} />
      </div>
    </div>
  );
}

function HorizontalTabs({ activeKey, isloadingSacedRecords, onSelect, SavedRecords, mainKey, editedRecords, error }) {
  const [bookmarks, setBookmarks] = useState({
    cafestart: true,
    lease: true,
    // Add more items as needed
  });
  const { id } = useParams();

  const [itemId, setItemId] = useState(null)
  const accountHolderId = localStorage.getItem("accountHolderId");
  const branchId = localStorage.getItem("branchId_current_selected");
  const subscriptionType = localStorage.getItem("subscription_type_current_selected");


  // const { data, error } = useListUnSavedMaterialRecordsQuery({
  //   userId: accountHolderId,
  //   record_id: itemId,
  //   directory_listing_id: branchId,
  //   subscription_type: "pro",
  // });

  // const handleBookmarkClick = async (itemKey, item) => {
  //   setItemId(item.id)
  //   const newBookmarks = { ...bookmarks, [itemKey]: !bookmarks[itemKey] };
  //   setBookmarks(newBookmarks);
  // };
  const [triggerUnsave, { isLoading }] = useListUnSavedMaterialRecordsMutation();

  const handleBookmarkClick = async (itemKey, item) => {
    const accountHolderId = localStorage.getItem("accountHolderId");
    const branchId = localStorage.getItem("branchId_current_selected");
    const subscriptionType = localStorage.getItem("subscription_type_current_selected");

    const newBookmarks = { ...bookmarks, [itemKey]: !bookmarks[itemKey] };
    setBookmarks(newBookmarks);

    if (!newBookmarks[itemKey]) {
      try {
        const response = await triggerUnsave({
          userId: accountHolderId,
          record_id: item.id,
          directory_listing_id: branchId,
          subscription_type: subscriptionType,
        }).unwrap();

        if (response.success) {

          alert("Record successfully unsaved")
          window.location.reload();
        }
      } catch (error) {
        console.error("Failed to unsave record:", error);
        alert("failed")
      }
    }
  };
  const [upgradeModal, setupgradeModal] = useState(false);

  const subscription_type = localStorage.getItem("subscription_type_current_selected");
  const subscription_status = localStorage.getItem(
    "subscription_status_current_selected"
  );

  useEffect(() => {
    if ((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))) {
      setupgradeModal(false);
    }
    else {
      setupgradeModal(true);
    }
  }, [subscription_type, subscription_status]);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  }
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  const editPDF = async (pdfUrl) => {
    const existingPdfBytes = await fetch(pdfUrl).then(res => res.arrayBuffer());

    // Load the PDF document
    const pdfDoc = await PDFDocument.load(existingPdfBytes);

    // Get the first page
    const pages = pdfDoc.getPages();
    const firstPage = pages[0];

    // Draw some text on the page
    firstPage.drawText('Hello World!', {
      x: 50,
      y: 500,
      size: 30,
      color: rgb(0, 0.53, 0.71),
    });

    // Serialize the PDFDocument to bytes (a Uint8Array)
    const pdfBytes = await pdfDoc.save();

    return pdfBytes;
  };

  const navigate = useNavigate();
  const handleEditAndUpload = (record) => {
    navigate(`/pdf-edit`, { state: { record } });
  }


  return (
    <div className={`mb-3 horizontal-tabs my-material-cafestartup ${subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))
      ? ""
      : "chat-disable-for-liteuser"}`}>
      {/* {isloadingSacedRecords && <div class="loader"></div>} */}

      {isloadingSacedRecords ? (
                <div class="loader"></div>
              ):( <Tabs
        id="horizontal-tabs"
        activeKey={activeKey}
        onSelect={(k) => onSelect(k)}
        className="mb-3"
        variant="pills"
      >
        <Tab eventKey="Saved" title="Saved">
          <div className="tab-content">
            <Row>
              {error?.status === 404 || SavedRecords?.data?.length === 0 ? (
                <Col xs={12}>
                  <div className="no-data-message">No data available</div>
                </Col>
              ) : (
                SavedRecords?.data?.map((record) => (
                  <Col xs={12} sm={12} lg={4} md={4} key={record.id}>
                    <div className="card">
                      <div className="card-img">
                        <img
                          src={record.record.thumbnail_path || DefaultPdfImg}
                          alt="CafestartImg"
                        />
                      </div>
                      <div className="card-body">
                        <h4>{record.title}</h4>
                        <p className="category">{record.subcategory_id}</p>
                        <div className="btns">
                          <a
                            href={record.record.file_path}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <button type="button" className="view-btn">
                              View
                            </button>
                          </a>
                        </div>
                        <img
                          src={bookmarks.cafestart ? BookmarkFilled : BookmarkIcon}
                          alt={
                            bookmarks.cafestart ? "BookmarkFilled" : "BookmarkIcon"
                          }
                          className="bookmark-icon"
                          onClick={() => handleBookmarkClick(record.id, record)}
                        />
                      </div>
                    </div>
                  </Col>
                ))
              )}
            </Row>
          </div>
        </Tab>

        {/* <Tab eventKey="edited" title="Edited">
          <div className="tab-content">
           <Row>
           {editedRecords?.data?.map((record)=>(
              <Col xs={12} sm={12} lg={4} md={4}>
                <div className="card">
                  <div className="card-img">
                    <img src={record.record.thumbnail_path || DefaultPdfImg} alt="CafestartImg" />
                  </div>
                  <div className="card-body">
                    <h4>
                      {record.title}
                    </h4>
                    <p className="category">{record.subcategory_id}</p>
                    <div className="btns">
                   
                      <a href={record.record.file_path} target="_blank" ><button type="button" className="view-btn">
                        View
                      </button></a>  
                    </div>
                    <img
                      src={bookmarks.cafestart ? BookmarkFilled : BookmarkIcon}
                      alt={
                        bookmarks.cafestart ? "BookmarkFilled" : "BookmarkIcon"
                      }
                      className="bookmark-icon"
                      onClick={() => handleBookmarkClick("cafestart")}
                    />
                  </div>
                </div>
              </Col>
            ))}  
           </Row>
          </div>
        </Tab> */}
      </Tabs>)}
      <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} />
    </div>
  );
}

export default AllMaterialCafeStratup;
