import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/CcOwnerPayment.css";
import ElipseImg from "../Assets/Images/enterprenurship-elipse1.svg";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from "../Components/PaymentForm"
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetceAmountQuery } from "../Services/CommonAPI"
import AlertImg from "../Assets/Images/contact-alert.svg";
import {
  useGetRefreshTokenMutation
} from "../Services/CommonAPI"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function CcOwnerPayment() {
  const location = useLocation();
  const { id, email, subscription_type, regBranchId } = location.state || {};
  const { data: proamount } = useGetceAmountQuery();
  const navigate = useNavigate();
  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
    useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")
  const [gstAmount, setGstAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);

  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {

        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {

    if (isErrorRefreshToken) {
      navigate("/");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  useEffect(() => {
    if (proamount) {
      const gstRate = 0.10; // 10%
      const amount = proamount?.amount || 0; // Default to 0 if proamount or amount is not defined
      const calculatedGstAmount = amount * gstRate; // Calculate GST
      const calculatedSubtotal = amount + calculatedGstAmount; // Calculate subtotal
      setGstAmount(calculatedGstAmount); // Set GST amount
      setSubtotal(calculatedSubtotal); // Set subtotal
    }
  }, [proamount]);



  return (
    <div className="Ccowner-payment mglr-24">
      <Row>
        <Col xs={12} sm={12} lg={6} md={6}>
          <div className="left-sec">
            <div className="d-flex align-space-btwn main-head">
              <h3>
                <img src={ElipseImg} alt="ElipseImg" className="elipseimg" />
                <span>Cafe Entrepreneurship</span>
              </h3>
              <p className="pay-rupee"><span>${proamount?.amount} </span>/monthly</p>
            </div>
            <h5>Pro Membership  <div class="tooltip-common">
              <img src={AlertImg} alt="AlertImg" />
              <span class="tooltip-commontext payment-pro-member-tooltip">
                All-in-one - best for smooth operations and steady growth.
              </span>
            </div></h5>
            <h5>${proamount?.amount}  /month</h5>
            <p className="sub-text">
              Full access to all features and exclusive benefits, including free
              advertising.
            </p>
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${proamount?.amount.toFixed(2)}</p>
            </div>
            <div className="d-flex align-space-btwn">
              <p className="head">GST (10%)</p>
              <p className="value">${gstAmount?.toFixed(2)}</p>
            </div>
            <hr />
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${subtotal?.toFixed(2)}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} md={6}>
          <Elements stripe={stripePromise}>
            <PaymentForm regBranchId={regBranchId} />
          </Elements>
        </Col>
      </Row>
    </div>
  );
}

export default CcOwnerPayment;
