import React from "react";
import "../Styles/ProductTypes.css";
import CloseImg from "../../../Assets/Images/close.svg";

function ProductTypes({ handleCloseQuickPitchModal }) {
    const handleClose = () => {
        handleCloseQuickPitchModal();
    };


    return (
        <div className="trades-product-types quickPitchModal">
            <img
                src={CloseImg}
                alt="CloseImg"
                className="close-img"
                onClick={handleClose}
            />
            <div className="quickpath-guide-div">
                <h4>Quick Pitch Guide</h4>
                <p>We recommend recording and uploading a short video to introduce your business. Use the guide below to highlight what cafe operators can quickly learn about you. Aim to keep your recording under 3 minutes to ensure your message is clear and concise.</p>
                <p>If you'd prefer to be interviewed, with the recording edited and sent back to you, this service is free. Please book a session using the link provided.</p>
                <h4>Recording Guide:</h4>
                <h6>1. Introduction (20 seconds):</h6>
                <p>Share your name, your business name, and your role.</p>
                <p>Provide a brief overview of what your business does and why you started it.</p>
                <h6>2. How You Support Cafes (45 seconds):</h6>
                <p>Explain how your services help cafes improve daily operations and enhance the customer experience.</p>
                <h6>3. Key Offerings & Business Stages (45 seconds):</h6>
                <p>Highlight your main offerings and how they support cafes at different stages—whether new, established, or scaling.</p>
                <h6>4. Future Plans (20 seconds):</h6>
                <p>Share any upcoming initiatives or goals that could benefit cafe growth and operations.</p>
                <h6>5. Advice for Cafe Operators (15 seconds):</h6>
                <p>Provide one piece of practical advice for cafe operators looking to improve their business.</p>
                <h6>6. Overcoming Challenges (25 seconds):</h6>
                <p>Share a success story where your solution helped a cafe overcome a major challenge.</p>
                <h6>7. Next Steps (10 seconds):</h6>
                <p>Invite viewers to explore your profile for more details.</p>
                <p>Encourage them to message you through the platform for inquiries or collaboration.</p>
            </div>
        </div>
    );
}

export default ProductTypes;
