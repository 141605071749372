import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import BackIcon from "../../../Assets/Images/Back-icon.svg";
import { useNavigate } from "react-router-dom";
import Person3 from "../../../Assets/Images/default-profile-img.png";
import { useParams } from 'react-router-dom';
import { useFetchTicketbyIdQuery, useSubmitReplyMutation } from "../../../Services/SupportAPI"
import moment from 'moment';
function formatDate(dateString) {
    return moment(dateString).format('DD MMMM YYYY');
}

function SupportViweTicket() {
    const navigate = useNavigate();
    const goBack = () => {
        navigate(-1);
    };
    const { id } = useParams();
    const { data: ticket, isLoading, refetch } = useFetchTicketbyIdQuery(id);
    const [replyVisible, setReplyVisible] = useState({ ticketId: null, commentId: null });
    const [replyText, setReplyText] = useState('');

    const handleReplyClick = (ticketId, commentId) => {
        setReplyVisible((prev) =>
            prev.ticketId === ticketId && prev.commentId === commentId
                ? { ticketId: null, commentId: null }
                : { ticketId, commentId }
        );
    };


    const [submitForm, { isLoading: submitrplyLoading, isError, isSuccess, error }] =
        useSubmitReplyMutation();

    const handleReplyChange = (e) => {
        setReplyText(e.target.value);
    };
    const handleSubmit = async (commentId) => {
     
        let postData = {
            reply: replyText
        }
        try {
            const response = await submitForm({ ticketId: id, commentid: commentId, postData }).unwrap();
            if (response) {
                window.location.reload();
            }


        } catch (err) {
            console.error("Failed to submit form:", err);
        }
    };
    return (
        <div className="support-reply">
            {" "}
            <Row>
                <Col xs={12} sm={12} md={8} lg={8}>
                    <h4>Support</h4>
                    <p className="sub-head">
                        Pro members receive priority support. <br />
                        We recognise that all issues are important and aim to resolve all
                        tickets by the next business day or sooner.
                    </p>
                </Col>
            </Row>
            <Row className="category-search">
                <Col xs={12} sm={12} g={12} md={12}>
                    <h5>
                        <Link onClick={goBack}>
                            <img src={BackIcon} alt="BackIcon" />
                        </Link>
                        {ticket?.ticket?.ticket_code}
                    </h5>
                </Col>
            </Row>
            <div className="support-reply-div">
                <p className="category">{ticket?.ticket?.category_name || ""}</p>
                <p className="subcategory">{ticket?.ticket?.subcategory?.subcategory_name || ""}</p>
                <p className="para">
                    {ticket?.ticket?.description}
                </p>

                <div className="already-added-comments">
                    {ticket?.ticket?.comments?.map((comment, index) => (
                        <div key={index} className="comments">
                            <div className="br-l">
                                <div className="d-flex">
                                    <div className="d-flex">
                                        <div>
                                            <img
                                                src={comment.posted_by.profile_image || Person3}
                                                alt={comment.posted_by.name}
                                                className="default-dp"
                                            />
                                        </div>
                                        <div className="profile">
                                            <p className="person-name">{comment.posted_by.name}</p>
                                            <p className="date">{comment.comment_date}</p>
                                        </div>
                                    </div>
                                </div>
                                <p className="para pt-10">{comment.comment}</p>
                                <hr />
                                <div className="d-flex align-space-between">
                                    <p className="posted-on">Posted on {ticket.posted_date}</p>
                                    <p className="reply-btn-food" onClick={() => handleReplyClick(ticket.id)}>Reply</p>
                                </div>

                                {/* Reply Form for the current comment */}
                                {/* <Row>
                                    {replyVisible === ticket.id && (
                                        <div className="reply-form">
                                            <textarea
                                                value={replyText}
                                                onChange={handleReplyChange}
                                                placeholder="Type your reply here..."
                                            />
                                            <div className="text-end">
                                                <button onClick={() => handleSubmit(comment.comment_id)} className="primary-btn">Submit</button>
                                            </div>
                                        </div>
                                    )}
                                </Row> */}

<Row>
                  {replyVisible?.ticketId === ticket.id && replyVisible?.commentId === comment.comment_id && (
                    <div className="reply-form">
                      <textarea
                        value={replyText}
                        onChange={handleReplyChange}
                        placeholder="Type your reply here..."
                      />
                      <div className="text-end">
                        <button onClick={() => handleSubmit(comment.comment_id)} className="primary-btn-food"  disabled={submitrplyLoading}> {submitrplyLoading ? "Submitting..." : "Submit"}</button>
                      </div>
                    </div>
                  )}
                </Row>

                                {/* Display replies for the current comment */}
                                <div className="already-added-replies">
                                    {comment.replies?.map((reply, index) => (
                                        <div key={index} className="comments">
                                            <div className="br-l">
                                                <div className="d-flex">
                                                    <div className="d-flex">
                                                        <div>
                                                            <img
                                                                src={reply.replay_profile_image_url || Person3}
                                                                alt={reply.reply_author}
                                                                className="default-dp"
                                                            />
                                                        </div>
                                                        <div className="profile">
                                                            <p className="person-name">{reply.reply_author}</p>
                                                            <p className="date">{formatDate(reply.reply_created_at)} </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <p className="para pt-10">{reply.reply_content}</p>
                                                <hr />

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                {isLoading && <div class="loader-food"></div>}
                {/* {isError && (
                    <p style={{ color: "red" }}>
                        Error: {error?.data?.error || "Failed to submit form"}
                    </p>
                )}
                {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                        Form submitted successfully!
                    </p>
                )}
                {submitrplyLoading && <div class="loader-food"></div>} */}
            </div>
        </div>
    );
}

export default SupportViweTicket;
