import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Cafelogin.css";
import { useChangePasswordMutation } from "../Services/CcownerAPI";
import { Link, useNavigate , useLocation} from "react-router-dom";
import logo from "../Assets/Images/logo.svg";
import VectorImg from "../Assets/Images/login-cafe-bg-vector.svg";

function Login() {
    const navigate =  useNavigate();
    const location = useLocation();
    const { email, otp } =
      location.state || {};
  
      
  const [formData, setFormData] = useState({
    confirmpassword: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    const numberRegex = /\d/;
    
    if (!formData.password) {
      formErrors.password = "Password is required.";
    } else if (formData.password.length < 8) {
      formErrors.password = "Password must be at least 8 characters long.";
    } else if (!specialCharRegex.test(formData.password)) {
      formErrors.password = "Password must include at least one special character.";
    } else if (!numberRegex.test(formData.password)) {
      formErrors.password = "Password must include at least one number.";
    }
    
    if (!formData.confirmpassword) {
      formErrors.confirmpassword = "Confirm Password is required.";
    } else if (formData.password !== formData.confirmpassword) {
      formErrors.confirmpassword = "Passwords do not match.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm) {
  //   }
  // };

  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useChangePasswordMutation();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
        let postData = {
          email: email,
          otp:otp,
          password:formData.confirmpassword
        };
        try {
          const response = await submitForm(postData).unwrap();
          setFormData({
            confirmpassword: "",
            password: "",
          });
    
          const timer = setTimeout(() => {
            navigate("/change-password-success");
            // navigate("/forgot-password-otp"), {state:{responseEmail}};
          }, 1000);
        } catch (err) {
          console.error("Failed to submit form:", err);
        }
      }
  };

  return (
    <div className="login change-password">
      <Row>
        <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg">
            <div className="logo-div">
            <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>  
            </div>
            <div className="login-bg-text">
              <h2>
                Cafe <br />
                Entrepreneurship
              </h2>
              <p>
                Discover the Breakthrough You've Been Looking For with
                Australia's Premier Cafe Support Platform
              </p>
            </div>
            <img src={VectorImg} alt="VectorImg" className="vector-img-login"/>
          </div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
          <div className="login-form">
            <h5>
            Enter your password
            </h5>
         
            <form onSubmit={handleSubmit}>

            <div className="form-control">
                <label>New password</label>
                <br />

                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="*********"
                />
                {errors.password && <p className="error">{errors.password}</p>}
              </div>
         

              <div className="form-control">
                <label>Confirm password</label>
                <br />

                <input
                 type="password"
                  name="confirmpassword"
                  value={formData.confirmpassword}
                  onChange={handleChange}
                  placeholder="*********"
                />
                {errors.confirmpassword && <p className="error">{errors.confirmpassword}</p>}
              </div>
         
              
              <div className="form-control">
                <button type="submit" className="login-submit-btn">
                Confirm
                </button>
              </div>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.message || error?.data?.error || "Login Failed"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                 Form submitted successfully
                </p>
              )}
              {isLoading && <div class="loader"></div>}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
