import React, { useState, useEffect } from "react";
import CheckCircle from "../../../Assets/Images/trade-account-activation.svg";
import "../Styles/SignUpAccountActivation.css";
import { useNavigate } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";


import {
  useGetRefreshTokenMutation
} from "../../../Services/CommonAPI"

function CcOwnerAccountActivation() {
  const navigate = useNavigate();
  const location = useLocation();
  const {  regBranchId } = location.state || {};

  // useEffect(() => {
  //   navigate("/trade-service-Accountdetails", {
  //     state: { regBranchId},
  //   });
  // }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
     navigate("/trade-service-Accountdetails", {
        state: { regBranchId},
      });
    }, 2000); 
  }, []);

  const [submitRefreshToken, { isSuccess:isSuccessRefreshToken, isError:isErrorRefreshToken }] =
  useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
 const [refreshtokenNew, setRefreshTokenNew] = useState("")

  const handleSubmitRefreshToken = async () => {
  const  RefreshToken =localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {
    
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(()=>{
   
    if(isErrorRefreshToken){
      navigate("/trade-login");
      localStorage.clear();
    }
    }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  return (
    <div>
      <section className="tdowner-account-activation">
        <img src={CheckCircle} n alt="CheckCircle" className="check-circle"/>
        <h3>Your account has been created</h3>
        <p>
          Welcome to our community. Now, it's time to start building your
          profile. If you <span className="disply-blk">need any assistance, click <span className="support">"Support"</span> and we will
          contact you as soon as possible </span>to keep you moving forward.
        </p>
      </section>
    </div>
  );
}

export default CcOwnerAccountActivation;
