import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/AccountSuspended.css";
import BlockIcon from "../Assets/Images/account-suspended-img.svg"
import { Link } from "react-router-dom";

function AccountSuspended({ show, handleCloseAccountsuspededModal }) {
  return (
    <div>
      <div className="overlay"></div>
      <Modal
        show={show}
        backdrop="true"
        dialogClassName="modal-dialog-centered add-account-supended-modal"
      >
        <Modal.Body>
          <h4><img src={BlockIcon} alt={BlockIcon} /> Your account has been currently suspended</h4>
          <p>
            Lorem ipsum dolor sit amet consectetur. Condimentum habitasse enim
            quis amet consectetur amet. Turpis id sed amet turpis ac et tortor
            tellus. Tincidunt nisl pretium volutpat in viverra sit facilisi.
          </p>
         <Link to="/support-listing"> <button className="btn-primary">Contact supporting team</button></Link>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AccountSuspended;
