import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Cafelogin.css";
import { useSubmitLoginMutation } from "../Services/CcownerAPI";
import logo from "../Assets/Images/logo.svg";
import VectorImg from "../Assets/Images/login-cafe-bg-vector.svg";
import OtpInput from "react-otp-input";
import CheckCircle from "../Assets/Images/check-circle.svg";
import { Link, useNavigate, useLocation } from "react-router-dom";

function Login() {
 const navigate =  useNavigate();
  const location = useLocation();
  const { email, otp } =
    location.state || {};
  
  useEffect(() => {
    const timer = setTimeout(() => {
  //  navigate("/change-password")

  navigate("/change-password", {
    state: { email, otp},
  });

  }, 1000);
  }, []);

  return (
    <div className="login forgot-psw-otp">
      <Row>
        <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg">
            <div className="logo-div">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid" />
              </Link>
            </div>
            <div className="login-bg-text">
              <h2>
                Cafe <br />
                Entrepreneurship
              </h2>
              <p>
                Discover the Breakthrough You've Been Looking For with
                Australia's Premier Cafe Support Platform
              </p>
            </div>
            <img src={VectorImg} alt="VectorImg" className="vector-img-login" />
          </div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
        <img src={CheckCircle} alt="CheckCircle" />
        <p className="verification-complete-txt">Verification completed successfully</p>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
