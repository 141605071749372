import React from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/DirectoryPaymentList.css";
import ElipseImg from "../../../Assets/Images/enterprenurship-elipse1.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../Components/DirectoryPaymentForm";
import { useLocation } from "react-router-dom";
import { useGetfsAmountQuery, } from "../../../Services/CommonAPI"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function CcOwnerPayment() {
  const location = useLocation();
  const {  locations,total, subtotal, gst} = location.state || {};
  
  const locationIds = locations.map((loc) => loc.id);
  const {data:proamount} = useGetfsAmountQuery();
  return (
    <div className="Ccowner-payment fd-directory-payment-list">
      <Row>
        <Col xs={12} sm={12} lg={6} md={6}>
          <div className="left-sec">
            <div className="d-flex align-space-btwn main-head">
              <h4>Payment Location List</h4>
            </div>
            {locations?.map((loc) => (  <div className="d-flex align-space-btwn location-details">
              <div>
                <h5>{loc.location}</h5>
                {/* <p className="type">Open For Business</p> */}
                <p className="address">
                  {" "}
                  {loc.address}
                </p>
              </div>
              <div>
                {" "}
                <p className="payment-value">${proamount?.amount.toFixed(0)}</p>
              </div>
            </div>))}
          
            <div className="d-flex align-space-btwn mt-60">
              <p className="head">Total</p>
              <p className="value">${total?.toFixed(2)}</p>
            </div>
            <div className="d-flex align-space-btwn">
              <p className="head">GST (10%)</p>
              <p className="value"> ${gst.toFixed(2)}</p>
            </div>
            <hr />
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${subtotal?.toFixed(2)}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} md={6}>
          <Elements stripe={stripePromise}>
            <PaymentForm locationIds={locationIds}/>
          </Elements>
        </Col>
      </Row>
    </div>
  );
}

export default CcOwnerPayment;
