import React from "react";
import "../Styles/HomeBanner.css";
import { Link } from "react-router-dom";
import videoSource from "../Assets/video/home-banner-video.mp4"; // Add your video path

function HomeBanner() {
  return (
    <section className="section home-banner">
      <div className="banner-overlay"></div>
      {/* Background Video */}
      {/* <video autoPlay loop muted className="background-video">
        <source src={videoSource} type="video/mp4" />
        Your browser does not support the video tag.
      </video> */}

      <div className="banner-content">
        <h2>
          Join the Dedicated Cafe Industry Hub Delivering Eye-Opening Results
        </h2>
        <p>
          Clever Cafe is an online platform designed exclusively for cafe owners
          and their ecosystem, providing support, mentorship, networking, 
          and essential tools - all in one place. We make it easier to plan, open, 
          and manage a profitable cafe with proven strategies for growth.
        </p>
        <Link to="/free-trial-common-signup">
          <button className="free-trial-btn">Free Trial</button>
        </Link>
      </div>
    </section>
  );
}

export default HomeBanner;
