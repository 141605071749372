import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/SignUp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ElipseIMg from "../../../Assets/Images/trade-service-elispse.svg";
import TickIcon from "../../../Assets/Images/tick-icon-green.svg";
import CheckCircle from "../../../Assets/Images/trade-verification-complate.png"
import WhiteAlertImg from "../../../Assets/Images/alert-white.svg";
import BackIcon from "../../../Assets/Images/Back-icon.svg";
import ProcuctTypes from "./ProductTypes";
import {
  useGetRefreshTokenMutation
} from "../../../Services/CommonAPI"
import {useCreateonBoardingAssistanceMutation} from "../../../Services/SupportAPI"

function CcOwnerSignUp() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id, email, subscription_type, regBranchId , onboarding_option} = location.state || {};
  const [activeTab, setActiveTab] = useState(0);
  const [showProductTypes, setshowProductTypes] = useState(false);
  const handleMouseEnter = () => {
    setshowProductTypes(true);
  };
  const handlecloseProductModal=()=>{
    setshowProductTypes(false);
      }

      const [submitRefreshToken, { isSuccess:isSuccessRefreshToken, isError:isErrorRefreshToken }] =
  useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
 const [refreshtokenNew, setRefreshTokenNew] = useState("")

  const handleSubmitRefreshToken = async () => {
  const  RefreshToken =localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {
      
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(()=>{

    if(isErrorRefreshToken){
      navigate("/trade-login");
      localStorage.clear();
    }
    }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);
  
  const [createTicket, { }] =
  useCreateonBoardingAssistanceMutation();


  const fetchData = async () => {
    try {
      let postData = {
        directory_listing_id : regBranchId
      }
      const response = await createTicket(postData).unwrap();
     
    } catch (error) {
     
    }
  };
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onboarding_option) {
        fetchData();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [onboarding_option]);

  const tabs = [
    {
      label: "",
      heading: "Free Marketing & Exposure",
      content: (
        <ul>
          <li>Premium Directory Listings & Profiles</li>
          <li>Strategic Branding & Positioning</li>
          <li>Targeted Exposure for Established Cafes</li>
          <li>Real-Time Startup Exposure</li>
          <li>Complimentary Marketing Support</li>
          <li>Increased Customer Visibility</li>
          <li>Effective Event Promotion</li>
          <li>Positive Review Enhancement</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Automated Industry Networking ",
      content: (
        <ul>
          <li>24/7 Automated Ecosystem Development</li>
          <li>Enhanced Industry Education & Exposure</li>
          <li>Foodservice / Trades & Services Partners</li>
          <li>Direct Communication & Account Applications</li>
          <li>Detailed Profiles & Comprehensive Support</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Expansion & Multisite ",
      content: (
        <ul>
          <li>Financial Planning & Budgeting</li>
          <li>Scalability & Risk Management</li>
          <li>Supply Chain Optimisation</li>
          <li>Advanced Technology Integration</li>
          <li>Operational Efficiency Assessment</li>
          <li>Evaluating Adaptability</li>
          <li>Advanced Training & Development</li>
          <li>Market Research & Analysis</li>
          <li>Marketing Strategy Development</li>
          <li>Competitive Benchmarking</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Start-ups & Incubation",
      content: (
        <ul>
          <li>Early Industry & Public Visibility</li>
          <li>Preliminary Budgeting & Business Planning</li>
          <li>Initial Branding, Marketing & Menu Drafting</li>
          <li>Action Planning & Financial Modelling</li>
          <li>Expert Guidance & Industry Tools</li>
          <li>Accessible Products & Services</li>
          <li>Build Your Own Supportive Ecosystem</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Mentorship",
      content: (
        <ul>
          <li>Accredited Business Coaching</li>
          <li>Leading Hospitality Experts</li>
          <li>Vital Business Management</li>
          <li>80+ Areas of Mentorship</li>
          <li>Creative & Analytical Thinking</li>
          <li>Financial Literacy Coaching</li>
          <li>Crisis Management & Adaptability</li>
          <li>Effective Leadership Strategies</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Menu Development",
      content: (
        <ul>
          <li>Pricing, Profitability & Productivity</li>
          <li>Data-Driven Decision Alignment</li>
          <li>COGS Reduction</li>
          <li>Menu Development & Engineering</li>
          <li>Size, Variety, Seasonality & Simplicity</li>
          <li>Branding, Positioning & Crafting Specials</li>
          <li>Creating Catering Menus</li>
          <li>Executive Chef Mentorship</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Development Materials",
      content: (
        <ul>
          <li>Visual Learning Support</li>
          <li>Member Only Resources</li>
          <li>Operational Templates</li>
          <li>Editable Workbooks</li>
          <li>Editable Checklists</li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length); 
    }, 3000); // 3000 milliseconds = 3 seconds
// Clean up interval on component unmount
  }, []);

  const handleNavigateContinue =()=>{
    navigate("/trade-service-signupaccountactivation", {
       state: { regBranchId},
     });
    }

    const token= localStorage.getItem("accessToken");
 

  return (
    <div className="td-owner-signup">
      <section className="section">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="image-div">
              <h3>
                <img src={ElipseIMg} alt="ElipseIMg" />
                <span> Trades & services</span>
                <img
                  src={WhiteAlertImg}
                  alt={WhiteAlertImg}
                  className="services-indication-icon"
                  onMouseEnter={handleMouseEnter} 
                />
              </h3>
              <p className="sub-text">
                {/* <span>$15</span>/monthly */}
              </p>
              <div className="features-box">
                <h5>Features</h5>
                <p><img src={TickIcon} alt="TickIcon"/>Professional directory listing</p>
                <p><img src={TickIcon} alt="TickIcon"/>Ecosystem integration</p>
                <p><img src={TickIcon} alt="TickIcon"/>24/7 relevant connections</p>
                <p><img src={TickIcon} alt="TickIcon"/>Automated networking</p>
                <p><img src={TickIcon} alt="TickIcon"/>Local & national market reach</p>
                <p><img src={TickIcon} alt="TickIcon"/>Strengthen online presence</p>
                <p><img src={TickIcon} alt="TickIcon"/>Review boosting</p>
                <p><img src={TickIcon} alt="TickIcon"/>Media uploads</p>
                <p><img src={TickIcon} alt="TickIcon"/>Story sharing</p>
                <p><img src={TickIcon} alt="TickIcon"/>Brand awareness</p>
                <p><img src={TickIcon} alt="TickIcon"/>Service showcase</p>
                <p><img src={TickIcon} alt="TickIcon"/>Event promotion</p>
                <p><img src={TickIcon} alt="TickIcon"/>Direct account links</p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
          <div className="form-div text-center">
             <div className="text-center">
                <img src={CheckCircle} alt="CheckCircle"/>
                <p className="mt-20 verification-complete-txt">Verification completed successfully</p>
             </div>
             <button className="proceed-to-payment-btn mt-20" onClick={handleNavigateContinue}>Continue</button>
            </div>
          </Col>
        </Row>
        {showProductTypes && <ProcuctTypes handlecloseProductTypeModal={handlecloseProductModal}/>}
      </section>
    </div>
  );
}

export default CcOwnerSignUp;
