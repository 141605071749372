// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/AddAnotherLocation.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/AddNewNotice.css";
import ArrowDownImg from "../../../Assets/Images/arrow-down-icon.svg";
import FileUpload from "../../../Assets/Images/file-upload.svg";
import Select from "react-select";

import "../Styles/Support.css";
import {
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useCreateTicketMutation,
  useFetchTicketsQuery
} from "../../../Services/SupportAPI";

import { useGetBranchListQuery } from "../../../Services/TradeserviceAPI";

const ModalComponent = ({ show, handleCloseAddNewModal }) => {
  const [selectedFile, setselectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({
    category: 4,
    subcategory: 10,
    directory_listing: "",
    description: "",
    file: "",
  });
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleMultiSelectChange = (options) => {
    setSelectedOptions(options);
  
    // Check if options are selected and remove the validation error
    if (options && options.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        directory_listing: "", // Clear the specific validation error
      }));
    }
  };

  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useCreateTicketMutation();

  const handlecloseModal = () => {
    handleCloseAddNewModal();
  };

  const handlefileChange = (e) => {
    const file = e.target.files[0];
    setselectedFile(file);
  };

  const { data: categories } = useGetCategoriesQuery();
  const { data: subcategories } = useGetSubCategoriesQuery(4);
  const { data: listedLocations } = useGetBranchListQuery();
  const { data: ticketList } = useFetchTicketsQuery();



  const validateForm = () => {
    const formErrors = {};
    if (!selectedOptions || selectedOptions.length === 0) {
      formErrors.directory_listing = "Directory listing is required.";
    }
    if (!formData.category) formErrors.category = "Category is required.";
    if (!formData.subcategory)
      formErrors.subcategory = "Sub Category is required.";
    if (!formData.description) {
      formErrors.description = "Description is required.";
    } else {
      // Split the description into words and count them
      const wordCount = formData.description.trim().split(/\s+/).length;

      if (wordCount > 200) {
        formErrors.description = "Maximum allowed length is 200 words.";
      }
    
    }
    // }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const transformedLocations = listedLocations?.map((location) => ({
    label: location.location || location.business_name || "Unknown location", // Fallback in case location is null
    value: location.id,
  }));

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const postData = new FormData();
      if (selectedFile) postData.append("file", selectedFile);
      postData.append("category_id", formData.category || "");
      postData.append("subcategory_id", formData.subcategory || "");
      postData.append("description", formData.description || "");
    
      const directoryListingIds = selectedOptions.map((item) => item.value);
      directoryListingIds.forEach((item) => postData.append('directory_listing_ids[]', item));
      try {
        const response = await submitForm(postData).unwrap();
        if(response){
          setFormData({
            category_id: "",
            subcategory_id: "",
            description: "",
          });
          const timer = setTimeout(() => {
            handleCloseAddNewModal();
          }, 3000);
        }
      
      } catch (err) {
        console.error("Failed to submit form:", err);
      }

    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseAddNewModal}
        backdrop="true"
        dialogClassName="add-new-modal add-new-ticket add-new-ticket-trade"
      >
        <Modal.Body>
          <h2>Add Your Ticket</h2>
          <p className="sub-head">
            All submissions are reviewed and approved before being published.
          </p>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Category <span className="red-star">*</span>
                  </label>
                  <br />
                  <img
                    src={ArrowDownImg}
                    alt="ArrowDownImg"
                    className="arrow-down"
                  />
                  <select
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                    disabled
                  >
                    <option>Select your category</option>
                    {categories?.categories?.map((category) => (
                      <option value={category.id}>
                        {category.category_name}
                      </option>
                    ))}
                  </select>
                  {errors.category && (
                    <p className="error">{errors.category}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Subcategory<span className="red-star">*</span>
                  </label>
                  <br />
                  <img
                    src={ArrowDownImg}
                    alt="ArrowDownImg"
                    className="arrow-down"
                  />
                  <select
                    name="subcategory"
                    value={formData.subcategory}
                    onChange={handleChange}
                    disabled
                  >
                    <option> Select Your Subcategory</option>
                    {subcategories?.subcategories?.map((category) => (
                      <option value={category.id}>
                        {category.subcategory_name}
                      </option>
                    ))}
                  </select>
                  {errors.subcategory && (
                    <p className="error">{errors.subcategory}</p>
                  )}
                </div>
              </Col>
              {/* <Col xs={12} sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>
                Select your listings<span className="red-star">*</span>
                </label><br />
                <img src={ArrowDownImg} alt="ArrowDownImg" className="arrow-down"/>
                <select name="directory_listing" value={formData.directory_listing} onChange={handleChange}>
                    <option> Select your listings</option>
                    {listedLocations?.map((loc) => (  <option value={loc.id}>{loc.location}</option>  ))}
                </select>
                {errors.directory_listing && (
                  <p className="error">{errors.directory_listing}</p>
                )}
                </div>
              </Col> */}
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Select your listings<span className="red-star">*</span>
                  </label>
                  <br />
                  <img
                    src={ArrowDownImg}
                    alt="ArrowDownImg"
                    className="arrow-down"
                  />
                  <Select
                    isMulti
                    value={selectedOptions}
                    onChange={handleMultiSelectChange}
                    options={transformedLocations}
                    placeholder="Select your listings"
                    name="directory_listing"
                  />

                  {errors.directory_listing && (
                    <p className="error">{errors.directory_listing}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                    Description <span className="red-star">*</span>
                  </label>
                  <br />
                  <textarea
                    onChange={handleChange}
                    rows={4}
                    placeholder="Add your description"
                    name="description"
                    value={formData.description}
                  ></textarea>
                  <p className="max-word">Maximum of 200 words</p>
                  {errors.description && (
                    <p className="error">{errors.description}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} lg={12} md={12}>
                <label>Attach File</label>
                <div className="file-upload-container d-flex">
                  <label className="file-upload-label">
                    <input
                      type="file"
                      className="file-upload-input"
                      onChange={handlefileChange}
                      name="file"
                    />
                    <div>
                      <img src={FileUpload} alt="FilecUpload" />
                    </div>
                    <div>
                      <span className="file-upload-text">
                        {selectedFile
                          ? selectedFile.name
                          : "Please select a file"}
                      </span>
                      <span className="format-text">
                        (Only supports .jpg, .png and .svg files)
                      </span>
                    </div>
                  </label>
                </div>
              </Col>
            </Row>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handlecloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-save-primary">
                  Add
                </button>
              </Col>
              {isError && (
                    <p style={{ color: "red" }}>
                      Error: {error?.data?.error || "Failed to submit form"}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                      Form submitted successfully!
                    </p>
                  )}
                  {isLoading && <div class="loader-trade"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
