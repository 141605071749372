import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/AllMaterialCafeStartup.css";
import { Tab, Tabs, Nav } from "react-bootstrap";
import CafestartImg from "../Assets/Images/all-matrial-cafe-startup.png";
import LeaseImg from "../Assets/Images/all-matrials-leasing.png";
import EditYellowImg from "../Assets/Images/edit-2-yellow.png";
import EdirBlackImg from "../Assets/Images/edit-black.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import BookmarkIcon from "../Assets/Images/bookmark.svg";
import BookmarkFilled from "../Assets/Images/bookmark-filled.svg";
import { useParams } from "react-router-dom";
import {
  useGetSubCategorybyMaterialIdQuery,
  useGetAllRecordsbyMaterialIdQuery,
  useGetMaterialTypeQuery,
  useGetMaterialTypebyCategoryIdQuery,
  useGetRecordsByIdQuery,
  useSaveRecordsMutation,
} from "../Services/SecondaryAPI";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import DefaultPdfImg from "../Assets/Images/defaultpdf-img.png"
import PageLoader from "../Assets/Images/page-loader.gif"

function AllMaterialCafeStratup() {
  const { id } = useParams();
  const [mainKey, setMainKey] = useState(1);
  const [subKey, setSubKey] = useState("all");

  const { data: subCategories, isLoading } = useGetSubCategorybyMaterialIdQuery(
    { id }
  );
  const { data: allRecords, isLoading: isloadingRecords, refetch } =
    useGetAllRecordsbyMaterialIdQuery({
      businessmaterialid: id,
      subcategory_id: mainKey,
    });
  const { data: recordsByMaerialTypeId, isLoading: recordsloading, refetch: refetchRecords } =
    useGetRecordsByIdQuery({
      businessmaterialid: id,
      subcategory_id: mainKey,
      materialtypeid: subKey,
    });


  const handleTabSelect = (k) => {
    setMainKey(k);
    refetch();
  };


  const [upgradeModal, setupgradeModal] = useState(false);

  const subscription_type = localStorage.getItem("subscription_type_current_selected");

  const subscription_status = localStorage.getItem(
    "subscription_status_current_selected"
  );
  useEffect(() => {
    if ((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))) {
      setupgradeModal(false);
    }
    else {
      setupgradeModal(true);
    }
  }, [subscription_type, subscription_status]);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  }
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  useEffect(() => {
    // Fetch data based on updated mainKey and subKey
    refetchRecords();
  }, [mainKey, subKey]);

  const handleChangeVerticalTab = () => {
    refetch();
  }
  if (isLoading) {
    return (
      <div className="page-loader">
        <img src={PageLoader} alt="PageLoader" width={100} />
      </div>
    );
  }


  return (
    <div>
      <h4>{subCategories?.data?.category_name}</h4>
      <div className={`vertical-tabs-container ${subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))
        ? ""
        : "chat-disable-for-liteuser"}`}>
        {isLoading && <div class="loader"></div>}
        <Tabs
          id="vertical-tabs"
          activeKey={mainKey}
          onSelect={handleTabSelect}
          className="mb-3"
          variant="pills"
        >
          {subCategories &&
            subCategories?.data?.subcategories?.map((subCategory) => (
              <Tab
                key={subCategory.id}
                eventKey={subCategory.id}
                title={subCategory.subcategory_title}
                onSelect={handleChangeVerticalTab}

              >
                {recordsloading || isloadingRecords ? (<div class="loader"></div>) : (
                  <HorizontalTabs
                    activeKey={subKey}
                    onSelect={(k) => setSubKey(k)}
                    allRecords={allRecords}
                    recordsByMaerialTypeId={recordsByMaerialTypeId}
                    mainKey={mainKey}
                    isloadingRecords={isloadingRecords}
                    recordsloading={recordsloading}
                    refetchRecords={refetchRecords}
                    refetch={refetch}
                  />)}
              </Tab>
            ))}
        </Tabs>
      </div>
      <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} />
    </div>
  );
}

function HorizontalTabs({ activeKey, onSelect, allRecords, refetch, refetchRecords, recordsloading, mainKey, isloadingRecords, recordsByMaerialTypeId }) {
  const { id } = useParams();
  const [bookmarks, setBookmarks] = useState({
    cafestart: false,
    lease: false,
    // Add more items as needed
  });

  const [submitForm, { isLoading: submitform, isError, isSuccess, error }] =
    useSaveRecordsMutation();

  const handleBookmarkClick = async (itemKey, item) => {
    const newBookmarks = { ...bookmarks, [itemKey]: !bookmarks[itemKey] };
    setBookmarks(newBookmarks);

    const accountHolderId = localStorage.getItem("accountHolderId");
    const branchId = localStorage.getItem("branchId_current_selected");
    const subscriptionType = localStorage.getItem("subscription_type_current_selected");

    const postData = {
      record_id: item.id,
      material_type_id: activeKey,
      business_material_id: id,
      subcategory_id: mainKey,
      title: item.title,
      status: 1,
      user_id: accountHolderId,
      directory_listing_id: branchId,
      subscription_type: subscriptionType,
    };

    try {
      const response = await submitForm(postData).unwrap();
      alert("Saved Successfully")
    } catch (err) {
      console.error("Failed to save bookmark:", err);
    }
  };

  const handleAllBookmarkClick = async (itemKey, item) => {
    const newBookmarks = { ...bookmarks, [itemKey]: !bookmarks[itemKey] };
    setBookmarks(newBookmarks);

    const accountHolderId = localStorage.getItem("accountHolderId");
    const branchId = localStorage.getItem("branchId_current_selected");
    const subscriptionType = localStorage.getItem("subscription_type_current_selected");

    const postData = {
      record_id: item.id,
      material_type_id: item.material_type_id,
      business_material_id: id,
      subcategory_id: mainKey,
      title: item.title,
      status: 1,
      user_id: accountHolderId,
      directory_listing_id: branchId,
      subscription_type: subscriptionType,
    };

    try {
      const response = await submitForm(postData).unwrap();
      alert("Saved Successfully")
    } catch (err) {
      console.error("Failed to save bookmark:", err);
    }
  };

  // const {data:materialTypes, isLoading} = useGetMaterialTypeQuery();

  const { data: materialTypes, isLoading: isloadingmatrialtypeRecords } =
    useGetMaterialTypebyCategoryIdQuery({
      businessmaterialid: id,
      subcategory_id: mainKey,
    });
  const filteredRecords = allRecords?.data?.filter(
    (record) =>
      (activeKey === "all" || record.material_type_id === activeKey) &&
      record.subcategory_id === mainKey
  );


  const handleTabChange = () => {
    refetchRecords();
  };

  const handleTabChangeAll = () => {
    refetch();
  }
  return (
    <div className="mb-3 horizontal-tabs">
      <Tabs
        id="horizontal-tabs"
        activeKey={activeKey}
        onSelect={(k) => onSelect(k)}
        className="mb-3"
        variant="pills"
      >
        {materialTypes?.data
          ?.filter((materialType) => materialType.id == null)
          .map((materialType) => (
            <Tab
              eventKey="all"
              onSelect={handleTabChangeAll}
              title={<span>All ({materialType.records_count || 0})</span>}
            >
              <div className="tab-content">
                {recordsloading || isloadingRecords ? (
                  <div className="loader"></div>
                ) : materialType.records_count > 0 ? (
                  <Row>
                    {allRecords?.data?.map((record) => (
                      <Col xs={12} sm={12} lg={4} md={4} key={record.id}>
                        <div className="card">
                          <div className="card-img">
                            <img src={record.thumbnail_path || DefaultPdfImg} alt={record.title} />
                          </div>
                          <img
                            src={bookmarks[record.id] ? BookmarkFilled : BookmarkIcon}
                            alt={bookmarks[record.id] ? "BookmarkFilled" : "BookmarkIcon"}
                            className="bookmark-icon"
                            onClick={() => handleAllBookmarkClick(record.id, record)}
                          />
                          <div className="card-body">
                            <a href={record.file_path} target="_blank" rel="noopener noreferrer">
                              <h4>{record.title}</h4>
                              <p className="category">{record.material_type_name}</p>
                            </a>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ) : (
                  <p>No records available</p>
                )}
              </div>

            </Tab>))}
        {materialTypes?.data
          ?.filter((materialType) => materialType.id !== null)
          .map((materialType) => (
            <Tab
              eventKey={materialType.id}
              title={
                <span>
                  {materialType.name} ({materialType.records_count || 0})
                </span>
              }
              key={materialType.id}
              onSelect={handleTabChange}
            >
              <div className="tab-content">

                <Row>
                  {recordsloading || isloadingRecords ? (
                    <div className="loader"></div>
                  ) : materialType.records_count > 0 ? (
                    recordsByMaerialTypeId?.data?.map((record) => (
                      <Col xs={12} sm={12} lg={4} md={4} key={record.id}>
                        <div className="card">
                          <div className="card-img">
                            <img src={record.thumbnail_path || DefaultPdfImg} alt={record.title} />
                          </div>
                          <img
                            src={bookmarks[record.id] ? BookmarkFilled : BookmarkIcon}
                            alt={bookmarks[record.id] ? "BookmarkFilled" : "BookmarkIcon"}
                            className="bookmark-icon"
                            onClick={() => handleBookmarkClick(record.id, record)}
                          />
                          <div className="card-body">
                            <a href={record.file_path} target="_blank" rel="noopener noreferrer">
                              <h4>{record.title}</h4>
                              <p className="category">{record.subcategory_name}</p>
                            </a>
                          </div>
                        </div>
                      </Col>
                    ))
                  ) : (
                    <Col xs={12} sm={12} lg={4} md={4}>
                      <p>No data available</p>
                    </Col>
                  )}
                </Row>

              </div>
            </Tab>
          ))}

      </Tabs>
    </div>
  );
}

export default AllMaterialCafeStratup;
