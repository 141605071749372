import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import "../Styles/DirectoryPayment.css";

import { useGetceAmountQuery } from "../Services/CommonAPI"

function DashboardDirectoryListing( ) {
  const location = useLocation();
  const { allselectedLocations } = location.state || {};
  const [proprice,setProprice] = useState(0);
 
  const navigate = useNavigate();

  const {data:proamount} = useGetceAmountQuery();
  useEffect(()=>{
    if(proamount){
      setProprice(proamount?.amount)
     
    }

  },[proamount])
  const [selectedLocations, setSelectedLocations] = useState(
    allselectedLocations?.map(location => ({ ...location, payment: proprice }))
  );

  const handleCheckboxChange = (id) => {
    setSelectedLocations((prevSelected) => {
      // Check if the location is already selected
      const isSelected = prevSelected.some((loc) => loc.id === id);
  
      // If the location is selected, remove it (i.e., unselect it)
      if (isSelected) {
        return [];
      } else {
        // Find the location to add to the selected list
        const locationToAdd = allselectedLocations?.find((loc) => loc.id === id);
        return [{ ...locationToAdd, payment: proprice }];
      }
    });
  };
  
  const handlePaymentOptionChange = (id, isPro) => {
    setSelectedLocations((prevSelected) => {
      return prevSelected.map((loc) =>
        loc.id === id ? { ...loc, payment: isPro ? proprice : 0 } : loc
      );
    });
  };

  const calculateTotal = () => {
    return selectedLocations?.reduce((sum, loc) => sum + loc.payment, 0);
  };

  const total = calculateTotal();
  const gst = total * 0.10;
  const subtotal = total + gst;

  const handleCompletePayment = () => {
 
    navigate("/directory-payment-list", {
        state: {
          liteLocations:{},
          proLocations: selectedLocations,
          total: total,
          subtotal: subtotal,
          isUpgratoPro:true
        },
      });
  };

  return (
    <div className="dashboard-listing directory-payment">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Payments & Invoicing</h4>
          <p className="sub-head">
            Complete directory listings amplify engagement. Ensure your
            business shines by filling in all criteria.
          </p>
        </Col>
      </Row>

      <div className="list-card">
  {allselectedLocations?.map((location) => (
    <div className="card" key={location.id}>
      <Row>
        <Col xs={12} sm={12} md={2} lg={2}>
          <div className="d-flex">
            <div className="mr-15">
              <input
                type="radio"
                onChange={() => handleCheckboxChange(location.id)}
                checked={selectedLocations.length === 1 && selectedLocations[0].id === location.id}
              />
            </div>
            <div>
              <p className="location-head">{location.location ?? "Location"}</p>
              <div className="d-flex upgrade-payment-list-status-btn">
                {location.operational_status.length > 0 ? (
                  location.operational_status.map((status) => (
                    <button key={status.id} className="open-for-business-btn">
                      {status.name}
                    </button>
                  ))
                ) : (
                  <button className="open-for-business-btn">Unknown status</button>
                )}
              </div>
              <p className="address">{location.address ?? "Address"}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={4} md={4} lg={4} className="text-end">
          <label className="switch-payment-box">
            <span className="lite-text">Lite</span>
            <input
              type="checkbox"
              onChange={() =>
                handlePaymentOptionChange(
                  location.id,
                  !selectedLocations.some(
                    (loc) => loc.id === location.id && loc.payment === proprice
                  )
                )
              }
              checked={selectedLocations.some(
                (loc) => loc.id === location.id && loc.payment === proprice
              )}
            />
            <span className="slider round"></span>
            <span className="pro-text-payment">Pro</span>
          </label>
          <p className="payment-method">
            <b>
              ${selectedLocations.some(
                (loc) => loc.id === location.id && loc.payment === proprice
              )
                ? proprice
                : "0"}{" "}
              / month
            </b>
          </p>
        </Col>
      </Row>
    </div>
  ))}
</div>


      <div className="payment-complete-box">
        <div>
          <p className="selected-loc">
            Selected <button>{selectedLocations?.length} location</button>
          </p>
        </div>
        <div className="d-flex align-space-btwn">
          <p className="head">Total</p>
          <p className="value">${total?.toFixed(2)}</p>
        </div>
        <div className="d-flex align-space-btwn">
          <p className="head">GST (10%)</p>
          <p className="value">${gst?.toFixed(2)}</p>
        </div>
        <hr />
        <div className="d-flex align-space-btwn">
          <p className="head">Subtotal</p>
          <p className="value">${subtotal?.toFixed(2)}</p>
        </div>
        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button type="button" className="prev-btn">
              Cancel
            </button>
            <button
              type="submit"
              className="btn-save-primary"
              onClick={handleCompletePayment}
            >
              Complete Payment
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default DashboardDirectoryListing;
