import React from 'react'
import { Modal, Button } from "react-bootstrap";
import "../Styles/AlertBoxModal.css"

function AlertBoxAccountDeleted({show, handleCloseAlertModal}) {
    return (
        <div>
            <Modal
                show={show}
                backdrop="true"
                dialogClassName="alert-account-modal"
            >

                <Modal.Body>
               <p>Your account does not exist. Please log out to continue.</p>
               <button type='button' onClick={handleCloseAlertModal}>Logout</button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AlertBoxAccountDeleted