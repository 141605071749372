// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/VerifyOtpModal.css";
import OtpInput from "react-otp-input";
import { Link, useLocation } from "react-router-dom";
import VerifyComplteModal from "../Components/VerifyCompleteModal";
import CheckCircle from "../Assets/Images/check-circle.svg";
import {useVerifysmsotpMutation,useVerifyContactNumberMutation} from "../Services/CcownerAPI"

const maskEmail = (email) => {
  const [localPart, domain] = email.split("@");

  // Handle case where localPart is less than 2 characters
  const visibleLength = Math.min(localPart.length, 2);
  const maskedLocalPart =
    localPart.slice(0, visibleLength) + "*".repeat(Math.max(localPart.length - 2, 0));

  return `${maskedLocalPart}@${domain}`;
};


const ModalComponent = ({ show, handleClose, contactnum}) => {
  const [code, setCode] = useState("");
  const [showVeifyCompleteModal, setshowVeifyCompleteModal] = useState(false);
  const maskedEmail = contactnum ? maskEmail(contactnum) : "";
  const [submitContactNum] = useVerifyContactNumberMutation();
  const [error, setError] = useState("");
  const handleShowVerifyCompleteModal = () => {
    setshowVeifyCompleteModal(true);
    // handleClose();
  };
  const [formValid, setFormValid] = useState(false);
  const [submitForm, { isLoading, isError, isSuccess, submitRrror }] =
  useVerifysmsotpMutation();
  const handleChange = (otp) => {
    setCode(otp);
    setError("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code.length < 4) {
      setError("Please enter a 4-digit OTP.");
      return;
    }

    let Postata = {
      otp: code,
    };

    try {
      const response = await submitForm(Postata).unwrap();
    if (response){
      // alert("Phone number verified successfully");
      setshowVeifyCompleteModal(true);
      const timer = setTimeout(() => {
        window.location.reload();
      }, 3000);
    
    }
     
    } catch (err) {
      console.error("Failed to submit form:", err);
      alert("Failed to verify Contact number");
    }
  };

 useEffect(() => {
  if (code.length == 4) {
    setFormValid(true);
  }
  else{
    setFormValid(false);
  }
}, [code]);


const handleVerify = async () => {
  let postData = {
    contact_number: '+61' + contactnum
  };
  try {
    const response = await submitContactNum(postData).unwrap();
  } catch (err) {
    alert(error?.data?.error);
  }
};

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="true"
        dialogClassName="modal-dialog-centered verify-otp-modal"
      >
        {!showVeifyCompleteModal ? (
          <Modal.Body className="otp-modal">
            <h2>Verify your phone number using OTP</h2>
            <p>We have sent an OTP phone number to +XX XXXXXXXXXX</p>
            <p>
              Didn’t receive OTP? <span onClick={handleVerify}>[Resend]</span>
            </p>
            <form onSubmit={handleSubmit}>
            <OtpInput
              value={code}
              onChange={handleChange}
              numInputs={4}
              separator={<span style={{ width: "8px" }}></span>}
              isInputNum={true}
              shouldAutoFocus={true}
              renderInput={(props) => <input {...props} />}
              className="otp-input"
              inputStyle={{
                border: "1px solid transparent",
                borderRadius: "8px",
                width: "54px",
                height: "54px",
                fontSize: "12px",
                color: "#000",
                fontWeight: "400",
                caretColor: "blue",
                marginRight: "10px",
                marginTop: "20px",
                backgroundColor: "#F1F1F1",
              }}
              focusStyle={{
                border: "1px solid #CFD3DB",
                outline: "none",
              }}
            />
            <button
             className={`verify-btn mt-40 ${
                formValid ? "btn-primary-valid mt-40" : ""
              }`}
              type="submit"
            >
              Verify
            </button>
            {isError && (
                  <p style={{ color: "red" }} className="error-message">
                    Error: {error?.data?.error || "Failed to submit form"}
                  </p>
                )}
                {isSuccess && (
                  <p style={{ color: "green" }} className="isSuceesMsg">
                    Form submitted successfully!
                  </p>
                )}
                {isLoading && <div class="loader"></div>}
            </form>
          </Modal.Body>
        ) : (
          <Modal.Body className="verification-modal">
            <img src={CheckCircle} alt="TickIcon" />
            <h2>Verification completed successfully</h2>
            <p>Thank You</p>
          </Modal.Body>
        )}
      </Modal>
      {/* { showVeifyCompleteModal && <VerifyComplteModal showVeifyCompleteModal={showVeifyCompleteModal} />} */}
    </>
  );
};

export default ModalComponent;
