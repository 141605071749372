
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_SUPPORT_URL;

export const supportapi = createApi({
  reducerPath: 'websiteapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');
   
      
      const noAuthEndpoints = ['submitForm', 'verifyRegisterOTP', 'resendRegisterOTP','getStates'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    createTicket: builder.mutation({
        query: (newPost) => ({
          url: 'api/create-tickets',
          method: 'POST',
          body: newPost,
        }),
      }),

      getCategories :builder.query({
        query: () => `api/list-ticket-categories`
      }),

      getSubCategories :builder.query({
        query: (id) => `api/list-ticket-subcategories/${id}`
      }),

      fetchTickets :builder.query({
        query: () => `api/list-user-tickets`
      }),
      fetchTicketbyId: builder.query({
        query: (id) => `api/ticket/${id}`
      }),
      submitReply: builder.mutation({
        query: ({ticketId,commentid,postData}) => ({
          url: `api/comment-replay/tickets/${ticketId}/comments/${commentid}/reply`,
          method: 'POST',
          body: postData,
        }),
      }),
      deleteUserbyTicketId: builder.mutation({
        query: (user_id) => ({
          url: `api/delete-ticket/${user_id}`,
          method: 'DELETE',
        }),
      }),

      deleteBranchyTicketId: builder.mutation({
        query: ({user_id,deleteBranchId}) => ({
          url: `api/delete-by_listing_ticket/${user_id}/${deleteBranchId}`,
          method: 'DELETE',
        }),
      }),
     createonBoardingAssistance : builder.mutation({
      query: (postData) => ({
        url: `api/create-onboarding-tickets`,
        method: 'POST',
        body: postData,
      }),
    }),
  }),
});

export const {
useCreateTicketMutation,
useGetCategoriesQuery,
useGetSubCategoriesQuery,
useFetchTicketsQuery,
useFetchTicketbyIdQuery,
useSubmitReplyMutation,
useDeleteUserbyTicketIdMutation,
useDeleteBranchyTicketIdMutation,
useCreateonBoardingAssistanceMutation
} = supportapi;
