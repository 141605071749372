import React, { useRef } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Card } from "react-bootstrap";
import Testmonial1 from "../Assets/Images/testimonial/testimonial-1.png";
import Testmonial2 from "../Assets/Images/testimonial/testimonial-2.png";
import Testmonial3 from "../Assets/Images/testimonial/testimonial-3.png";
import Testmonial4 from "../Assets/Images/testimonial/testimonial-4.png";
import Testmonial5 from "../Assets/Images/testimonial/testimonial-5.png";
import Testmonial6 from "../Assets/Images/testimonial/testimonial-6.png";
import Testmonial7 from "../Assets/Images/testimonial/testimonial-7.jpg";
import Testmonial8 from "../Assets/Images/testimonial/testimonial-8.png";
import Testmonial9 from "../Assets/Images/testimonial/testimonila-9.png";
import Testmonial10 from "../Assets/Images/testimonial/testimonial-10.png";
import Testmonial11 from "../Assets/Images/testimonial/testimonial-11.png";
import Testmonial12 from "../Assets/Images/testimonial/testimonial-12.png";
import Testmonial13 from "../Assets/Images/testimonial/testimonial-13.png";
import Testmonial14 from "../Assets/Images/testimonial/testimonial-14.png";
import Testmonial15 from "../Assets/Images/testimonial/testimonial-15.png";
import Testmonial16 from "../Assets/Images/testimonial/testimonial-16.png";

import Elipse1 from "../Assets/Images/testimonial-elipse1.svg";
import Elipse2 from "../Assets/Images/testimonial-elipse2.svg";
import "../Styles/HomeTestimonal.css";

const SlickCarousel = () => {
  const sliderRef = useRef(null);
  const settings = {
    speed: 1000,  // Adjust to 1000ms to match or be slightly less than autoplaySpeed
  autoplay: true,
  autoplaySpeed: 1500, // Ensure this is slightly longer than the transition speed
  cssEase: "linear",
  slidesToShow: 5,
  slidesToScroll: 1,
  infinite: true,
  swipeToSlide: false,
  centerMode: true,
  focusOnSelect: true,
  arrows: false,
  variableWidth: true,
  pauseOnHover: false, // Disable built-in pause
    responsive: [
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
        },
      },
    ],
  };

  const handleMouseEnter = () => {
    sliderRef.current.slickPause();
  };

  const handleMouseLeave = () => {
    sliderRef.current.slickPlay();
  };

  return (
    <div className="testimonial-carousel">
      <Slider {...settings} ref={sliderRef}>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial1} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  Adding our cafe to the free directory was really easy, and
                  it’s great to know we’re getting extra exposure without
                  spending any additional money, time, or effort!
                </p>
                <p className="name">Emily M | Owner</p>
                <p className="designation">Mr Cuppa | Parramatta, NSW</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial2} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                "This was the best thing I could have done for my business in its early stages! The perfect place to plan a cafe startup."
                </p>
                <p className="name">Ross B | Cafe Startup</p>
                <p className="designation">Chocolate Cafe, Penrith, NSW</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial3} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "With Clever Cafe, we achieved a 26% increase in kitchen
                  efficiency and a 5% decrease in COGS."
                </p>
                <p className="name">Irene Z | Owner</p>
                <p className="designation">
                  Little Shop of Waffles, Manly, NSW
                </p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial4} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "Tim’s impact on our business has been undeniably positive. He
                  shows a genuine dedication to our success, displaying
                  unwavering commitment to achieving our objectives."
                </p>
                <p className="name">Rouby S | Managing Director</p>
                <p className="designation">LifeGrain Cafe Group, Sydney, NSW</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial5} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "I learned more about cafe business planning and the numbers
                  involved in one day than I had in months of searching online.
                  Great experience."
                </p>
                <p className="name">Fraser | Cafe Startup</p>
                <p className="designation">Rotorua, NZ</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial6} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "Top-notch coaching, excellent value, and service. THANK YOU!"
                </p>
                <p className="name">Luca & Jenni</p>
                <p className="designation">Cafe Startup, Toowoomba, QLD </p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial7} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  “Working with this team has significantly streamlined our menu
                  and business model, leading to better growth and
                  sustainability. Their insights and tailored strategies have
                  made a noticeable impact on our efficiency and overall
                  success.”
                </p>
                <p className="name">Aniko K | Owner</p>
                <p className="designation">Wicked Nutri Cookie | NSW</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial8} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "Clever Cafe supports new and emerging cafes to get off the
                  ground and thrive. I've seen the positive impact on business
                  owners in achieving their growth goals."
                </p>
                <p className="name">Ella B | Founding Director</p>
                <p className="designation">Employii, Perth, WA</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial9} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "We are always looking to improve and innovate, and Clever
                  Cafe has been an invaluable resource."
                </p>
                <p className="name">Rick H | Manager</p>
                <p className="designation">Monkey Bean, Toorak, VIC</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial10} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "My daughter has been running cafes for years, and as her
                  father, I'm grateful that this level of support is available
                  to help her succeed."
                </p>
                <p className="name">Pete U | Proud Dad</p>
                <p className="designation">Palm Beach, NSW</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        {/* foodtestimonial */}
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial11} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "A great way to advertise an Australian Cafe Foodservice
                  Business."
                </p>
                <p className="name">Danny H | Owner </p>
                <p className="designation">Imperial Gluten Free Bakery</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial12} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "Tim is really a great source of knowledge for all things food
                  service. Our session with him was really eye-opening,
                  informative, and inspiring!"
                </p>
                <p className="name">Alex | Founder </p>
                <p className="designation">Drink Nimbus</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial13} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  "As a small business trying to get established in the cafe
                  arena, Clever Cafe has already had an impact and has increased
                  our customer base."
                </p>
                <p className="name">Fatema K | Owner </p>
                <p className="designation">The Chai Room, Sydney, NSW</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        {/* tradeservice */}

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial14} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  “Clever Cafe is making an impactful presence in the trades and
                  services sector within the hospitality industry, providing
                  small businesses with incredible opportunities to network and
                  grow.”
                </p>
                <p className="name">John | Director </p>
                <p className="designation">Good Time Clean Pty Ltd.</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial15} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  “I am excited about the new connections Clever Cafe will
                  create for more synergy and a positive impact that supports
                  small hospitality businesses.”
                </p>
                <p className="name">Linda Kim | Founder</p>
                <p className="designation">
                  Spazii | Space & Catering Platform | Sydney, NSW
                </p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>

        <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <Card>
            <Card.Img variant="top" src={Testmonial16} />
            <Card.Body>
              <Card.Text>
                <p className="sub-head">
                  “For years, I have been waiting for an innovative ecosystem
                  that can ethically help cafe owners build profitability and an
                  entrepreneurial mindset. Finally, someone has done it! I am
                  confident that Clever Cafe will make a tangible difference to
                  a part of the business community that truly needs help.”
                </p>
                <p className="name">Emmanuel Martin, CPA</p>
                <p className="designation">General Manager</p>
                <p className="designation">BREED Australia</p>
                <img src={Elipse1} alt="elispse" className="elipse" />
                <img src={Elipse2} alt="elispse" className="elipse2" />
              </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Slider>
    </div>
  );
};

export default SlickCarousel;