import { useEffect } from "react";
import "../Styles/ArticleDetail.css";
import ArticleDetailImg from "../Assets/Images/article-detail.png";
import CafeStartup from "../Assets/Images/cafe-starup.png";
import BusinessStrategy from "../Assets/Images/business-planning-strategy.png";
import Operations from "../Assets/Images/operations.png";
import Team from "../Assets/Images/team.png";
import Leadeship from "../Assets/Images/leadership.png";
import Profilibilty from "../Assets/Images/profitibility.png";
import Creativity from "../Assets/Images/creativity.png";
import ExitStrategy from "../Assets/Images/exit-strategy.png";
import WingPNG from "../Assets/Images/pngwing.png";
import { Row, Col } from "react-bootstrap";
import ArticleReadMore1 from "../Assets/Images/ariticle-read-more-1.png";
import ArticleReadMore2 from "../Assets/Images/article-read-more-2.png";
import ArticleReadMore3 from "../Assets/Images/article-read-more-3.png";
import RightArrow from "../Assets/Images/right-arrow.svg";
import { Link } from "react-router-dom";
import ReadMoreArticleBg from "../Assets/Images/readmore-article-bg.png";
import Rocket from "../Assets/Images/rocket_svgrepo.com.svg";
import Lock from "../Assets/Images/lock_svgrepo.com.svg";
import MessageBox from "../Assets/Images/message-text-1_svgrepo.com.svg";
import Linkdin from "../Assets/Images/linkedin-black.svg";
import Facebook from "../Assets/Images/facebook-black.svg";
import Instagram from "../Assets/Images/instagram-black.svg";
import Youtube from "../Assets/Images/youtube-black.svg";
import GoogleMyBusiness from "../Assets/Images/google-my-business.svg";
import EllispeYellow from "../Assets/Images/Ellipse-ylw.svg";
import EllipseRed from "../Assets/Images/Ellipse-red.svg";
import EllipseBlue from "../Assets/Images/elipse-blue.svg"

function ArticleDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="article-detail-page">
      <div className="article-detail">
        <section className="article-detail-banner">
          <h3>
            Effective Cafe Business Coaching{" "}
            <span className="disply-blk">
              & Mentorship by Hospitality Experts
            </span>
          </h3>
          <p className="paragraph">
            At Clever Cafe, we specialise in providing comprehensive cafe
            business coaching and mentorship across 70 areas covering 10 vital
            aspects of cafe management. Our approach combines creativity with
            analytical education, supporting both startup cafes and established
            cafe entrepreneurs in their pursuit of long-term success. We cover
            essential aspects including vision and goals, market research,
            concept and offerings, finance, team building, legal requirements,
            menu development, profitability, and much more. We are dedicated to
            inclusivity and accessibility, offering group booster sessions as
            well as 1:1 strategy sessions. This allows our members the freedom
            to choose what is best for them at all stages of their cafe business
            journey.{" "}
          </p>
          <p className="paragraph">
            Our mentorship program is carefully designed to cover essential
            areas of business management, ensuring strong foundations,
            streamlined operations, and growth. Here are a few of the main areas
            our cafe entrepreneurs can explore when they sign up and take
            advantage of our mentorship services.
          </p>
        </section>
        <section className="cafe-startup">
          <h4>Cafe Startup</h4>
          <img
            src={CafeStartup}
            alt="ArticleDetailImg"
            className="banner-img"
          />
          <p className="mt-27 paragraph">
            Starting a cafe requires careful planning and clear goals. We ensure
            that you set realistic and achievable goals aligned with your vision
            for success. We guide you through effective market research to help
            your cafe stand out in a competitive marketplace.
          </p>
          <ul>
            <li>
              <b>Vision & Goals:</b> Ensure realistic and achievable goals align
              with your cafe's vision for success.
            </li>
            <li>
              <b>Market Research: </b>Conduct effective market research to set
              your cafe apart in a competitive marketplace.
            </li>
            <li>
              <b>Concept & Offerings:</b> Align your offerings with your target
              demographic and location for a successful concept.
            </li>
            <li>
              <b>Finance & Funding: </b>Manage finances and secure funding for
              your cafe.
            </li>
            <li>
              <b>Core Team Members: </b>Determine staffing needs early on to
              ensure sustainable cafe management.
            </li>
          </ul>
        </section>

        <section className="cafe-startup">
          <h4>Business Planning & Strategy</h4>
          <img
            src={BusinessStrategy}
            alt="ArticleDetailImg"
            className="banner-img"
          />
          <p className="mt-27 paragraph">
            Developing a comprehensive business plan is crucial for long-term
            success. Our mentorship helps you create a detailed plan using
            market insights and refine your concept and offerings based on
            customer preferences. We also provide tools for conducting a SWOT
            analysis and managing finances effectively.
          </p>
          <ul>
            <li>
              Complete Business Plan Development: Plan development using
              available tools and resources, leveraging market insights.
            </li>
            <li>
              Concept & Offering Refinement: Fine-tune your cafe's scope and
              menu to align with customer preferences for consistent foot
              traffic.
            </li>
            <li>
              SWOT Analysis: Assess internal strengths and weaknesses, growth
              opportunities, and potential threats for clear direction.
            </li>
            <li>
              Financial Management & Forecasting: Master budgeting, target
              setting, and precise forecasting for effective business
              navigation.
            </li>
            <li>
              Marketing & Branding Strategy: Develop a well-defined marketing
              strategy to increase foot traffic and enhance social presence.
            </li>
          </ul>
        </section>
        <section className="cafe-startup">
          <h4>Operations</h4>
          <img src={Operations} alt="ArticleDetailImg" className="banner-img" />
          <p className="mt-27 paragraph">
            Smooth operations are key to a successful cafe. We provide guidance
            on establishing standard operating procedures, optimising workflows,
            and maintaining quality control. Our support ensures that you build
            strong supplier relationships and enhance customer experience.
          </p>
          <ul>
            <li>
              Standard Operating Procedures (SOPs): Ensure seamless operations
              through documented procedures and training.
            </li>
            <li>
              Workflow Optimisation: Define clear workflows for each team member
              to enhance efficiency and productivity.
            </li>

            <li>
              Quality Control & Consistency: Establish systems for monitoring
              and maintaining consistency in customer satisfaction and team
              performance.
            </li>
            <li>
              Supplier Relationships & Tendering: Strengthen supplier
              connections and negotiate competitive pricing for cost-effective
              management.
            </li>
            <li>
              Customer Experience Enhancement: Enhance customer satisfaction
              through systematic assessment and improvement of the overall
              experience.
            </li>
          </ul>
        </section>
        <section className="cafe-startup">
          <h4>Team</h4>
          <img src={Team} alt="ArticleDetailImg" className="banner-img" />
          <p className="mt-27 paragraph">
            Building a strong team is essential for a thriving cafe. We assist
            in recruiting the right staff, providing effective onboarding and
            training, and ensuring employee engagement and motivation. Our focus
            on performance management and compliance helps you maintain a
            productive and satisfied workforce.
          </p>
          <ul>
            <li>
              Staff Recruitment & Hiring: Ensure you have the right people on
              board with effective recruitment and hiring processes.
            </li>
            <li>
              Onboarding & Training: Implement a comprehensive training approach
              to empower your team.
            </li>

            <li>
              Employee Engagement & Motivation: Keep your team passionate and
              driven with a customised plan to align their goals with those of
              your cafe.
            </li>
            <li>
              Performance Management & KPIs: Strengthen your business model and
              boost team morale with personalised KPIs.
            </li>
            <li>
              Workforce Scheduling & Time Management: Optimise labour operations
              to save costs without compromising quality.
            </li>
          </ul>
        </section>
        <section className="cafe-startup">
          <h4>Leadership</h4>
          <img src={Leadeship} alt="ArticleDetailImg" className="banner-img" />
          <p className="mt-27 paragraph">
            Effective leadership is crucial for guiding your team and achieving
            your business goals. We provide insights into different leadership
            styles, help you communicate your vision, and support you in
            building a cohesive team. Our guidance on decision-making and
            conflict resolution ensures you manage your cafe with confidence.
          </p>
          <ul>
            <li>
              Leadership Styles & Approaches: Tailor leadership styles to align
              seamlessly with your team for retention and productivity.
            </li>
            <li>
              Sharing Vision & Goals: Regain clarity on goals and implement team
              improvements for unified direction.
            </li>

            <li>
              Building a Team: Create a cohesive, high-morale team with a
              sustainable blend of qualities and skills.
            </li>
            <li>
              Communication & Feedback: Develop a structured approach to gather
              and incorporate effective feedback.
            </li>
            <li>
              Decision Making & Problem Solving: Minimise necessary decisions
              for effective cafe management based on experience.
            </li>
          </ul>
        </section>
        <section className="cafe-startup">
          <h4>Profitability</h4>
          <img
            src={Profilibilty}
            alt="ArticleDetailImg"
            className="banner-img"
          />
          <p className="mt-27 paragraph">
            Maintaining profitability is a continuous challenge in the cafe
            business. We help you conduct financial health checks, develop
            effective pricing strategies, and manage costs. Our approach ensures
            that you optimise your revenue and maintain a healthy profit margin.
          </p>
          <ul>
            <li>
              Financial Health Check: Assess sustainability, revenue-to-profit
              balance, lease-to-revenue ratio, and expenses to guide improvement
              plans.
            </li>
            <li>
              Pricing Strategy: Evaluate sustainability, psychology of pricing,
              menu layout, and competitor analysis for effective pricing
              strategies.
            </li>

            <li>
              Cost Control & Expense Management: Streamline expenses, payment
              timing, and supplier arrangements for enhanced efficiency.
            </li>
            <li>
              Current Revenue Split Analysis: Optimise revenue distribution by
              evaluating labour costs, overheads, and month-end balances.
            </li>
            <li>
              Operational Efficiency & Productivity: Identify growth
              opportunities and optimise output-to-revenue ratio.
            </li>
          </ul>
        </section>
        <section className="cafe-startup">
          <h4>Creativity & Menu</h4>
          <img src={Creativity} alt="ArticleDetailImg" className="banner-img" />
          <p className="mt-27 paragraph">
            A costed and well-designed menu is at the heart of any sustainable,
            successful cafe operation. We assist you in refining your menu,
            incorporating seasonal ingredients, and balancing practicality with
            variety. Our focus on flavour profiling and productivity ensures
            your menu delights customers and drives profitability. Simplicity:
            Refine creativity, optimise menu,
          </p>
          <ul>
            <li>
              Simplicity: Refine creativity, optimise menu, tailor feedback for
              expansion, considering location, audience, and demographics.
            </li>
            <li>
              Seasonality: Maximise profit by adapting to seasonal changes,
              understanding buyer behaviour, and supplier communication.
            </li>

            <li>
              Kitchen Practicalities: Balance menu with size, equipment, and
              functionality for control and profitability.
            </li>
            <li>
              Menu Size & Variety: Craft a balanced menu for dietary needs and
              preferences.
            </li>
            <li>
              Flavour Profiling: Align menu with customer preferences for
              balance and coherence.
            </li>
          </ul>
        </section>
        <section className="cafe-startup">
          <h4>Exit Strategy</h4>
          <img
            src={ExitStrategy}
            alt="ArticleDetailImg"
            className="banner-img"
          />
          <p className="mt-27 paragraph">
            Planning your exit strategy is just as important as starting your
            cafe. We help you set clear exit objectives, value your business
            accurately, and navigate the legal and financial considerations. Our
            support ensures a smooth transition and maximises your profits when
            the time comes to sell.
          </p>
          <ul>
            <li>
              Defining Exit Objectives: Establish clear goals for a smooth exit.
            </li>
            <li>
              Business Valuation: Assess your cafe's value and prepare it for
              sale.
            </li>

            <li>
              Legal & Financial Considerations: Simplify navigating complex
              regulations, leases, contracts, and taxes.
            </li>
            <li>
              Succession Planning: Develop an exit strategy and transition plan
              for a seamless handover.
            </li>
            <li>
              Transition & Handover Process: Refine the handover process with
              defined responsibilities and deadlines.
            </li>
          </ul>
          <div className="share-icon-btm">
            <img src={Linkdin} alt="share-icon" width={20} />
            <img src={Facebook} alt="share-icon" width={20}/>
            <img src={Instagram} alt="share-icon"width={20} />
            <img src={Youtube} alt="share-icon" width={20}/>
            <img src={Linkdin} alt="share-icon" width={20}/>
            <img src={GoogleMyBusiness} alt="share-icon" width={20}/>
          </div>
        </section>
      </div>
      <section className="our-news-letter">
<div className="news-letter-cnt">
  <h3>Our Newsletter</h3>
  <p>Subscribe to our monthly newsletter, We'll serve up all the latest from <span className="disply-blk">Clever Cafe and the industry.</span></p>
  <form>
    <input  type="email" placeholder="Email" name="email" />
    <button  type="submit">Submit</button>
  </form>
  </div>
  <img src={EllipseBlue}  alt="elipse" className="ellipse-blue"/>
  <img src={EllipseRed}  alt="elipse" className="ellipse-red"/>
  <img src={EllispeYellow}  alt="elipse" className="ellipse-ylw"/>

      </section>
      <section className="article-readmore">
        <h3>Read More Articles</h3>
        <img src={WingPNG} alt="pngwing" className="png-wing" />
        <Row className="read-more-article-card">
          <Col xs={12} sm={12} lg={4} md={4}>
            <div className="card-image">
              <img src={ArticleReadMore1} alt="card-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8}>
            <div className="card-body">
              <p className="publish-date">Alec Whitten • 17 Jan 2022</p>
              <Link to="/article-detail">
                {" "}
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h3>
                Effective Cafe Business Coaching & Mentorship by Hospitality
                Experts
              </h3>
              <p>
                At Clever Cafe, we specialise in providing comprehensive cafe
                business coaching and mentorship across 70 areas covering 10
                vital aspects of cafe management.
              </p>
              <div className="d-flex">
                {" "}
                <button type="button" className="researchbtn-yellow">
                  Research
                </button>{" "}
                <button type="button" className="researchbtn-blue">
                  Research
                </button>{" "}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="read-more-article-card">
          <Col xs={12} sm={12} lg={4} md={4}>
            <div className="card-image">
              <img src={ArticleReadMore2} alt="card-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8}>
            <div className="card-body">
              <p className="publish-date">Alec Whitten • 17 Jan 2022</p>
              <Link to="/article-detail">
                {" "}
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h3>
                Effective Cafe Business Coaching & Mentorship by Hospitality
                Experts
              </h3>
              <p>
                At Clever Cafe, we specialise in providing comprehensive cafe
                business coaching and mentorship across 70 areas covering 10
                vital aspects of cafe management.
              </p>
              <div className="d-flex">
                {" "}
                <button type="button" className="researchbtn-yellow">
                  Research
                </button>{" "}
                <button type="button" className="researchbtn-blue">
                  Research
                </button>{" "}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="read-more-article-card">
          <Col xs={12} sm={12} lg={4} md={4}>
            <div className="card-image">
              <img src={ArticleReadMore3} alt="card-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8}>
            <div className="card-body">
              <p className="publish-date">Alec Whitten • 17 Jan 2022</p>
              <Link to="/article-detail">
                {" "}
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h3>
                Effective Cafe Business Coaching & Mentorship by Hospitality
                Experts
              </h3>
              <p>
                At Clever Cafe, we specialise in providing comprehensive cafe
                business coaching and mentorship across 70 areas covering 10
                vital aspects of cafe management.
              </p>
              <div className="d-flex">
                {" "}
                <button type="button" className="researchbtn-yellow">
                  Research
                </button>{" "}
                <button type="button" className="researchbtn-blue">
                  Research
                </button>{" "}
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="ready-to-start">
        <h4>Ready to get started?</h4>
        <h3>Join Australia's Premier Cafe</h3>
        <h3>Ecosystem</h3>
        <button className="btn get-ready-outline-btn" type="button">
        Foodservice
        </button>
        <button className="btn get-ready-btn" type="button">
          Get a membership
        </button>
        <button className="btn get-ready-outline-btn" type="button">
        Trades & Services
        </button>
        <Row className="mt-80 border-top">
          <Col xs={12} sm={12} lg={4} md={4}>
            <p>
              <img src={Rocket} alt="help-img" className="help-img" />
              99% uptime in last 12 months
            </p>
          </Col>
          <Col xs={12} sm={12} lg={4} md={4}>
            <p>
              <img src={Lock} alt="help-img" className="help-img" />
              Safe and secure always backed up
            </p>
          </Col>
          <Col xs={12} sm={12} lg={4} md={4}>
            <p>
              <img src={MessageBox} alt="help-img" className="help-img" />
              Dedicated support here for you at any time
            </p>
          </Col>
        </Row>
      </section>
      <div className="share-icon-div">
        <ul>
          <li>
            <img src={Linkdin} alt="share-icon" />
          </li>
          <li>
            <img src={Facebook} alt="share-icon" />
          </li>
          <li>
            <img src={Instagram} alt="share-icon" />
          </li>
          <li>
            <img src={Youtube} alt="share-icon" />
          </li>
          <li>
            <img src={Linkdin} alt="share-icon" />
          </li>
           <li>
            <img src={GoogleMyBusiness} alt="share-icon" />
          </li>
        </ul>
      </div>
      <img
        src={ReadMoreArticleBg}
        alt="ReadMoreArticleBg"
        className="read-more-articlebg"
      />
    </div>
  );
}

export default ArticleDetail;
