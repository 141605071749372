// Services/TradeserviceAPI.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_ARTICLE_DEV_URL;

export const articleapi = createApi({
  reducerPath: 'articleapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');
   
      
      const noAuthEndpoints = ['getArticleData','mailchimp'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getArticleData:builder.query({
        query: () => "api/list-articles",
      }),
      getRecentArticleData:builder.query({
        query: () => "api/articles/recent",
      }),
      getArticleDetails: builder.query({
        query: ({id}) => `api/articles/${id}`,
      }),
      mailchimp: builder.mutation({
        query: (postdata) => ({
          url: 'api/mailchimp',
          method: 'POST',
          body: postdata,
        }),
      }),
  }),
});

export const {
    useGetArticleDataQuery,
    useGetRecentArticleDataQuery,
    useGetArticleDetailsQuery,useMailchimpMutation
} = articleapi;
