import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tabs, Tab } from "react-bootstrap";
import "../Styles/SessionDetailsBookNow.css";
import BackIcon from "../Assets/Images/Back-icon.svg";
import SessionMentorship from "../Components/SessionMentorDetails"
import SessionsessionDetails from "./SessionsessionDetails";
import SessionPayments from "./SessionPayments";
import { Link, useLocation, useNavigate } from "react-router-dom";

function TabTitle({ number, text }) {
  return (
    <div className="tab-title">
      <span className="circle-number">{number}</span>
      <span className="tab-text">{text}</span>
    </div>
  );
}

function SessionDetailsBookNow() {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("mentordetails");
  const { session,price } = location.state || {};


  const handleNext = () => {
    // Define the tab order
    const tabsOrder = ["mentordetails", "sessiondetails", "payments"];
    const currentIndex = tabsOrder.indexOf(activeTab);
    const nextIndex = (currentIndex + 1) % tabsOrder.length;
    setActiveTab(tabsOrder[nextIndex]);
  };
  const handleSkip = () => {
    const tabsOrder = ["mentordetails", "sessiondetails", "payments"];
    const currentIndex = tabsOrder.indexOf(activeTab);
   
    if(currentIndex == 0){
      const nextIndex = (currentIndex + 2) % tabsOrder.length;
      setActiveTab(tabsOrder[nextIndex]);
    }
    else if (currentIndex == 1){
      const nextIndex = (currentIndex + 1) % tabsOrder.length;
      setActiveTab(tabsOrder[nextIndex]);
    }
    
    
  };
  const subscriptiontype = localStorage.getItem("subscription_type_current_selected");

  return (
    <div className="session-details-book-now">
      <Link to="/session-details">
        <img src={BackIcon} alt="BackIcon" className="mgb-10" />
      </Link>
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>{session?.title}</h4>
          <p className="sub-head">
            Starting a new business demands a shift, from employee to business
            owner mindset; enthusiasm is vital, but knowledge is key.
          </p>
        </Col>
        {activeTab !== "payments" && (<Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <div className="next-buttons">
              <button onClick={handleSkip} className="skip-btn">Skip</button>
            <button className="next-btn" onClick={handleNext}>Next</button>
          </div>
        </Col>)}
      </Row>
      <div className="book-now-tab">
        <Tabs
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          id="custom-tab-example"
          className="mb-3"
        >
          <Tab eventKey="mentordetails" title={<TabTitle number={1} text="Mentor Details" />}>
            <div>
             <SessionMentorship />
            </div>
          </Tab>
          <Tab eventKey="sessiondetails" title={<TabTitle number={2} text="Session Details" />}>
            <div>
             <SessionsessionDetails sessionData={session} price={price} />
            </div>
          </Tab>
          <Tab eventKey="payments" title={<TabTitle number={3} text="Payments" />}>
            <div>
              {/* <SessionPayments sessionData={session} price={price}/> */}
              {/* <iframe width='100%' height='750px' src='https://motivation.zohobookings.in/portal-embed#/243828000000035054' frameborder='0' allowfullscreen='' > </iframe> */}
              {/* <iframe width='100%' height='750px' src='https://motivation.zohobookings.in/portal-embed#/243828000000053104' frameborder='0' allowfullscreen='' > </iframe> */}
              {/* <iframe width='100%' height='585px' src='https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/clevercafecompany' frameborder='0' allowfullscreen='' > </iframe> */}
              {subscriptiontype === 'lite' && (
              <iframe width='100%' height='750px' src={session?.meet_lite} frameborder='0' allowfullscreen='' > </iframe>
            )}
            {subscriptiontype === 'pro' && (
               <iframe width='100%' height='750px' src={session?.meet_pro} frameborder='0' allowfullscreen='' > </iframe>
               )}
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  ); 
}
  
export default SessionDetailsBookNow;
