import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../Styles/Dashboard.css";
import Logo from "../Assets/Images/logo.svg";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DirectoryListingImg from "../Assets/Images/directory-listing-icon.svg";
import BusinessMentorship from "../Assets/Images/business-mentorship.svg";
import Community from "../Assets/Images/community.svg";
import EcosystemImg from "../Assets/Images/ecosystem-menu-icon.svg";
import Settings from "../Assets/Images/settings.svg";
import Support from "../Assets/Images/support-img.svg";
import { Link } from "react-router-dom";
import MenuToogleIcon from "../Assets/Images/menu-toogle-icon.svg";

function SidebarComponent({ handleChange }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  
  const handleSubMenuClick = (menuName) => {
    setActiveSubMenu((prev) => (prev === menuName ? null : menuName));
  };


  return (
    <>
      <button className="toggle-btn" onClick={toggleSidebar}>
        <img src={MenuToogleIcon} alt="MenuToogleIcon"width={30}/>
      </button>
      <Sidebar className={isSidebarOpen ? "Sidebar active" : "Sidebar"}>
        <button className="close-btn" onClick={closeSidebar}>
          ×
        </button>
        <Link to={process.env.REACT_APP_BASEURL_CLIENT}>
          {" "}
          <img src={Logo} className="img sidebar-logo-mob" alt="Logo" />
        </Link>
        <Menu>
          <SubMenu
            label="Directory Listing"
            icon={
              <img
                src={DirectoryListingImg}
                alt="Community"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "DirectoryListing"}
            onClick={() => handleSubMenuClick("DirectoryListing")}
          >
            <Link to="/directory-listing">
              <MenuItem
                className={
                  window.location.pathname === "/directory-listing"
                    ? "sub-menu-item-active"
                    : ""
                }
                onClick={closeSidebar}
              >
                Manage
              </MenuItem>
            </Link>
            <Link to="/directory-payment">
              <MenuItem
                className={
                  window.location.pathname === "/directory-payment"
                    ? "sub-menu-item-active"
                    : ""
                }
                onClick={closeSidebar}
              >
                Payments & Invoicing
              </MenuItem>
            </Link>
          </SubMenu>
          <SubMenu
            label="Ecosystem"
            icon={
              <img
                src={EcosystemImg}
                alt="EcosystemImg"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "Ecosystem"}
            onClick={() => handleSubMenuClick("Ecosystem")}
          >
            <Link
              to="/ecosystem-matches"
              className={
                window.location.pathname === "/ecosystem-matches"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Matches</MenuItem>
            </Link>
            <Link
              to="/eco-enquiry"
              className={
                window.location.pathname === "/eco-enquiry"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Enquiries</MenuItem>
            </Link>
            <Link
              to="/ecosystem-recommendation"
              className={
                window.location.pathname === "/ecosystem-recommendation"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              {" "}
              <MenuItem>Recommendations</MenuItem>
            </Link>

            <Link
              to="/cafe-decline-list"
              className={
                window.location.pathname === "/cafe-decline-list"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              {" "}
              <MenuItem>Decline List</MenuItem>
            </Link>
          </SubMenu>
          <SubMenu
            label="Business Mentorship"
            icon={
              <img
                src={BusinessMentorship}
                alt="BusinessMentorship"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "BusinessMentorship"}
            onClick={() => handleSubMenuClick("BusinessMentorship")}
          >
            <Link
              to="/session-details"
              className={
                window.location.pathname === "/session-details"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>1:1 Session</MenuItem>
            </Link>
            <Link
              to="/huddlesession"
              className={
                window.location.pathname === "/huddlesession"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Huddle Sessions</MenuItem>
            </Link>
            <MenuItem>Development Materials</MenuItem>
            <Link
              to="/all-materials"
              className={
                window.location.pathname === "/all-materials"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem className="pl-20">All Materials</MenuItem>
            </Link>
            <Link
              to="/my-materials"
              className={
                window.location.pathname === "/my-materials"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem className="pl-20">My Materials</MenuItem>
            </Link>
          </SubMenu>
          <SubMenu
            label="Community"
            icon={<img src={Community} alt="Community" className="menu-icon" />}
            open={activeSubMenu === "Community"}
            onClick={() => handleSubMenuClick("Community")}
          >
            <Link to="/notice-board">
              {" "}
              <MenuItem
                className={
                  window.location.pathname === "/notice-board"
                    ? "sub-menu-item-active"
                    : ""
                }
                onClick={closeSidebar}
              >
                Notice Board
              </MenuItem>
            </Link>
            <Link
              to="/chat-list"
              className={
                window.location.pathname === "/chat-list"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Chat</MenuItem>
            </Link>
          </SubMenu>
          <SubMenu
            label=" Account Settings"
            icon={<img src={Settings} alt="Settings" className="menu-icon" />}
            open={activeSubMenu === "AccontSettings"}
            onClick={() => handleSubMenuClick("AccontSettings")}
          >
            {/* <MenuItem>Subscription</MenuItem> */}

            <Link
              to="/ccowner-subscription"
              className={
                window.location.pathname === "/ccowner-subscription"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Subscription</MenuItem>
            </Link>
            <Link
              to="/preference"
              className={
                window.location.pathname === "/preference"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              {" "}
              <MenuItem>Preferences</MenuItem>
            </Link>
          </SubMenu>
          <Link
            to="/support-listing"
            className={
              window.location.pathname === "/support-listing"
                ? "sub-menu-item-active"
                : ""
            }
            onClick={closeSidebar}
          >
            {" "}
            <MenuItem className="pl-5">
              <img src={Support} alt="Support" className="menu-icon" />
              Support
            </MenuItem>
          </Link>
        </Menu>
      </Sidebar>
    </>
  );
}

export default SidebarComponent;
