import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/CcOwnerSignUp.css";
import "../Styles/VerifyOtp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ElipseIMg from "../Assets/Images/enterprenurship-elipse1.svg";
import CrossIcon from "../Assets/Images/cross-icon.svg";
import TickIcon from "../Assets/Images/tick-icon.svg";
import ExploreMoreBtmImg from "../Assets/Images/explore-more-bottom-img.png";
import CrownStar from "../Assets/Images/crown-star-lite.svg";
import AlertImg from "../Assets/Images/contact-alert.svg";
import OtpInput from "react-otp-input";
import BackIcon from "../Assets/Images/Back-icon.svg";

import WhiteAlertImg from "../Assets/Images/alert-white.svg";
import ProcuctTypes from "./ProductTypes";
import { useGethuddlesessionPriceforWebQuery } from "../Services/SecondaryAPI";

import {
  useVerifyRegisterOTPMutation,
  useResendRegisterOTPMutation,
} from "../Services/CcownerAPI";

import { useGetceAmountQuery } from "../Services/CommonAPI";

const maskEmail = (email) => {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart =
    localPart.slice(0, 2) + "*".repeat(localPart.length - 2);
  return `${maskedLocalPart}@${domain}`;
};

function CcOwnerSignUp() {
  const location = useLocation();
  const { id, email, subscription_type, branchId, operational_status, onboarding_option } =
    location.state || {};
  const maskedEmail = email ? maskEmail(email) : "";
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [submitForm, { isLoading, isError, isSuccess, error:submiterror }] =
    useVerifyRegisterOTPMutation();
  const [submitResendOtp, {}] = useResendRegisterOTPMutation();
  const [formValid, setFormValid] = useState(false);
  const { data: propricece } = useGetceAmountQuery();
  const {data:huddlePrice} = useGethuddlesessionPriceforWebQuery ();
 

  const handleChange = (otp) => {
    setCode(otp);
    setError("");
  };

  useEffect(() => {
    if (code.length == 4) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [code]);


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code.length < 4) {
      setError("Please enter a 4-digit OTP.");
      return;
    }

    let Postata = {
      otp: code,
      operational_status: operational_status,
      subscription_type: subscription_type,
    };

    try {
      const response = await submitForm({ Postata, id }).unwrap();
      localStorage.setItem("accessToken", response.access);
      localStorage.setItem("refreshToken", response.refresh);

      const regBranchId = response?.branch?.id;
      const token = response.access;
      const payload = token.split(".")[1];
      const decodedPayload = JSON.parse(atob(payload));
      const userId = decodedPayload.user_id;

      localStorage.setItem("accountHolderId", userId);
      const timer = setTimeout(() => {
        navigate("/ccowner-verification-complete", {
          state: { id, email, subscription_type, regBranchId, onboarding_option  },
        });
      }, 3000);
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  };



  const handleResenOTP = async (e) => {
    e.preventDefault();

    try {
      const response = await submitResendOtp({ id }).unwrap(); // Await the response
      if (response) {
        alert("The New OTP is send to yor mail id");
      }
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  };
  const [showProductTypes, setshowProductTypes] = useState(false);
  const handleMouseEnter = () => {
    setshowProductTypes(true);
  };
  const handlecloseProductModal = () => {
    setshowProductTypes(false);
  };

  

  const tabs = [
    {
      label: "",
      heading: "Free Marketing & Exposure",
      content: (
        <ul>
          <li>Premium Directory Listings & Profiles</li>
          <li>Strategic Branding & Positioning</li>
          <li>Targeted Exposure for Established Cafes</li>
          <li>Real-Time Startup Exposure</li>
          <li>Complimentary Marketing Support</li>
          <li>Increased Customer Visibility</li>
          <li>Effective Event Promotion</li>
          <li>Positive Review Enhancement</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Automated Industry Networking ",
      content: (
        <ul>
          <li>24/7 Automated Ecosystem Development</li>
          <li>Enhanced Industry Education & Exposure</li>
          <li>Foodservice / Trades & Services Partners</li>
          <li>Direct Communication & Account Applications</li>
          <li>Detailed Profiles & Comprehensive Support</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Expansion & Multisite ",
      content: (
        <ul>
          <li>Financial Planning & Budgeting</li>
          <li>Scalability & Risk Management</li>
          <li>Supply Chain Optimisation</li>
          <li>Advanced Technology Integration</li>
          <li>Operational Efficiency Assessment</li>
          <li>Evaluating Adaptability</li>
          <li>Advanced Training & Development</li>
          <li>Market Research & Analysis</li>
          <li>Marketing Strategy Development</li>
          <li>Competitive Benchmarking</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Start-ups & Incubation",
      content: (
        <ul>
          <li>Early Industry & Public Visibility</li>
          <li>Preliminary Budgeting & Business Planning</li>
          <li>Initial Branding, Marketing & Menu Drafting</li>
          <li>Action Planning & Financial Modelling</li>
          <li>Expert Guidance & Industry Tools</li>
          <li>Accessible Products & Services</li>
          <li>Build Your Own Supportive Ecosystem</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Mentorship",
      content: (
        <ul>
          <li>Accredited Business Coaching</li>
          <li>Leading Hospitality Experts</li>
          <li>Vital Business Management</li>
          <li>80+ Areas of Mentorship</li>
          <li>Creative & Analytical Thinking</li>
          <li>Financial Literacy Coaching</li>
          <li>Crisis Management & Adaptability</li>
          <li>Effective Leadership Strategies</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Menu Development",
      content: (
        <ul>
          <li>Pricing, Profitability & Productivity</li>
          <li>Data-Driven Decision Alignment</li>
          <li>COGS Reduction</li>
          <li>Menu Development & Engineering</li>
          <li>Size, Variety, Seasonality & Simplicity</li>
          <li>Branding, Positioning & Crafting Specials</li>
          <li>Creating Catering Menus</li>
          <li>Executive Chef Mentorship</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Development Materials",
      content: (
        <ul>
          <li>Visual Learning Support</li>
          <li>Member Only Resources</li>
          <li>Operational Templates</li>
          <li>Editable Workbooks</li>
          <li>Editable Checklists</li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length); // Change to the next tab
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <div className="cc-owner-signup verify-otp">
      <section className="section">
        <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
            <div className="image-div">
              <h3>
                <img src={ElipseIMg} alt="ElipseIMg" />
                Cafe Entrepreneurship
                {/* <img
                  src={WhiteAlertImg}
                  alt={WhiteAlertImg}
                  className="services-indication-icon"
                  onMouseEnter={handleMouseEnter}
                /> */}
              </h3>
              <p className="sub-text">
                Discover the Breakthrough You've Been Looking For with
                Australia's Premier Cafe Support Platform
              </p>
              <p className="sub-text">(Tax Deductible!)</p>
              <p>Enjoy 1 month of free access to all PRO-level features. After your trial, choose between 'LITE' (pay-as-you-go) or 'PRO' (all-inclusive). No commitment until you decide!
              </p>

              <div className="signup-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="align-middle">
                        Features
                      </th>
                      <th scope="col">
                        <p className="mrl_-60">
                          <b>Lite </b>Membership{" "}
                          <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" />
                            <span class="tooltip-commontext">
                            Pay-as-you-go - great for trying new ideas or staying flexible.
                            </span>
                          </div>
                          <br />
                          <span>
                            <b>$0</b>/ month
                          </span>{" "}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="mrl_20">
                          <b>Pro </b> Membership{" "}
                          <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" />
                            <span class="tooltip-commontext">
                            All-in-one - best for smooth operations and steady growth.
                            </span>
                          </div>
                          <br />
                          <span>
                            <b>${propricece?.amount}</b>/ month
                          </span>{" "}
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Real-Time Professional Directory
                          Listing
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Marketing & Customer Growth
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Tailored Business Connections
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Communication with Industry Experts
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Practical Tools & Guides
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Unlimited Access to Specialist Courses
                        </p>
                      </td>
                      <td className="bold-txt text-center">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Unlimited Group Mentorship Sessions
                        </p>
                      </td>
                      <td className="bold-txt text-center">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> 1:1 Mentorship & Roadmapping
                        </p>
                      </td>
                      <td className="bold-txt text-center">$169.99</td>
                      <td className="bold-txt text-center">$79.99</td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Exclusive Discounts on Products & Services
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Priority Support When You Need It
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Industry Updates & Compliance Support
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                        <td >
                          <p className="d-flex"> <span>*</span> Unlimited Access to Online Expos & Events</p>
                         
                        </td>
                        <td className="text-center">
                          <img src={CrossIcon} />
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                      </tr>
                    <tr>
                        <td >
                        <p className="d-flex">  <span>*</span> Live Chat & Community Noticeboard </p>
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="explore-more">
                <h4>Explore more features for you</h4>
                <div className="tabs-container">
                  <div className="tabs">
                    {tabs.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? "active" : ""}`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.label}
                      </div>
                    ))}
                  </div>
                  <div className="tab-content">
                    <h3 className="tab-heading">{tabs[activeTab].heading}</h3>
                    {tabs[activeTab].content}
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="form-div">
              <Link to="/ccowner-signup">
                {" "}
                <img src={BackIcon} alt="BackIcon" />
              </Link>
              <h3 className="mt-20">Verify your email using OTP</h3>
              <p>
                We have sent an OTP to <span>{maskedEmail}</span>
              </p>
              <p>
                Didn't receive the email? Check your spam or promotions folder
                or{" "}
                <span onClick={handleResenOTP} className="resend-otp">
                  [Resend]
                </span>
              </p>
              <form onSubmit={handleSubmit}>
                <OtpInput
                  value={code}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  renderInput={(props) => <input {...props} />}
                  className="otp-input"
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "blue",
                    marginRight: "10px",
                    backgroundColor: "#F1F1F1",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                />
                {error && <p className="error">{error}</p>}
                <button
                  className={`verify-btn mt-40 ${
                    formValid ? "btn-primary-valid" : ""
                  }`}
                  type="submit"
                >
                  Verify
                </button>
                {isError && (
                  <p style={{ color: "red" }} className="error-message">
                    {submiterror?.data?.error || "Failed to submit form"}
                  </p>
                )}
                {isSuccess && (
                  <p style={{ color: "green" }} className="isSuceesMsg">
                    Form submitted successfully!
                  </p>
                )}
                {isLoading && <div class="loader"></div>}
              </form>
            </div>
          </Col>
        </Row>
        {showProductTypes && (
          <ProcuctTypes handlecloseProductTypeModal={handlecloseProductModal} />
        )}
      </section>
    </div>
  );
}

export default CcOwnerSignUp;
