import React, { useEffect, useState } from 'react'
import Banner from "./HomeBanner"
import About from "./HomeAbout"
import HomeExploreCollabrator from './HomeExploreCollabrator'
import HomeEntrepreneship from './HomeEntrepreneship'
import HomeTestimonal from "./HomeTestimonal"
import HomeQuestioner from "./HomeQuestioner"
import HomeNewsLetter from './HomeNewsLetter'
import HomeWhatWeDo from "./HomeWhatwedo"
import HomeJoinus from "./HomeJoinus"
import { Helmet } from 'react-helmet';
import LandingPagePopup from './LandingPagePopup'


function Home() {
  const [showLandingModal, setShowLandingModal] = useState(false);
  const handleCloseLandingModal = () => {
    setShowLandingModal(false);
  }

  useEffect(()=>{
    setTimeout(() => {
      setShowLandingModal(true);
    }, 4000);
  }, [])

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    zIndex: 999, // Ensures the overlay is on top
  };
  

  return (
    <>
      <Helmet>
        <title>Clever Cafe | The Team Behind the Scenes of Thriving Cafes</title>
        <meta name="description" content="Supporting new and existing cafe operators with 24/7 guidance, incubators, and accelerators. Connect with hospitality experts to refine concepts, develop menus, manage costs, and achieve sustainable growth." />
        <meta name="keywords" content="australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe, opening a cafe business plan, smart cafe business plans australia" />
      </Helmet>
      <Banner />
      <About />
      <HomeWhatWeDo />
      {/* <HomeEntrepreneship /> */}
      <HomeJoinus />
      <HomeTestimonal />
      <HomeExploreCollabrator />
      <HomeQuestioner />
      <HomeNewsLetter />
      {showLandingModal && (
        <div style={overlayStyle}></div>
      )}

      <LandingPagePopup showLandingModal={showLandingModal} handleCloseLandingModal={handleCloseLandingModal} />
    </>
  )
}

export default Home