import React from "react";
import { Row, Col } from "react-bootstrap";
import MentorImg from "../Assets/Images/mentor.png";
function SessionMentorDetails() {
  return (
    <div className="mentor-details">
      <div className="d-flex mentor-profile">
        <div>
          <img src={MentorImg} alt="MentorImg" className="mentor-img" />
        </div>
        <div>
          <p className="mentor-name">Tim Noye</p>
          <ul>
            <li>Leading Hospitality Mentor</li>
            <li>Accredited Business Coach</li>
            <li>Multi Award-Winning Chef</li>
          </ul>
        </div>
      </div>
      <h4>A Note from Our Founder & Your Mentor</h4>




      <p className="para">
        Dear valued members,
        <br />
        <br />I have been dedicated to the hospitality industry since I started
        my career in 1996. Over the years, I have built, owned, and operated
        cafes and QSR businesses, and advised well-known cafe chains, gaining
        invaluable firsthand experience in what it takes to thrive. Clever Cafe
        was created out of a genuine commitment to the industry and the people
        who power it, with the goal of building an ecosystem that sparks fresh,
        innovative growth and ensures accessibility.
        <br />
        <br />
        <h6>Unleashing Your Cafe Superpowers</h6>
        As your mentor, I will help you master
        both creative and analytical thinking to propel your business forward.
        With more than 7,000 hours of coaching, I am an ICF-recognised business
        coach, a certified performance coach, and a classically trained
        professional chef. Having worked in nearly every position in the
        industry, I understand the challenges you face and can offer practical,
        real-world advice. We'll get results, but we'll have fun too.
        <br />
        <br />
        <h6>VIP Perks for Clever Cafe Members</h6>
        My sessions, available exclusively to
        Clever Cafe members, are designed to provide valuable insights and
        actionable advice. Whether you are just starting out or managing
        multiple cafe locations, my approach is tailored and supportive,
        ensuring you feel confident and empowered.You will gain access to years
        of industry experience and personalised guidance that can transform your
        journey and your business. The strategies we’ll discuss are based on
        proven success and a deep understanding of the unique challenges you
        face. My team and I are dedicated to seeing you thrive.
        <br />
        <br />I look forward to working with you and helping your business reach
        new heights. Let's get started!
        <br />
        <br />
        See you soon,
        <br /> Tim
      </p>
      <h4 className="mt-30">Testimonials</h4>

      <p className="para m-0">
        "Tim’s impact on our business has been undeniably positive. He shows a
        genuine dedication to our success, displaying unwavering commitment to
        achieving our objectives. He excels in dealing with practical aspects
        and ensures that his guidance is grounded in reality rather than theory.
        His results-driven approach has allowed us to meticulously monitor the
        return on our investments, down to the last dollar."
      </p>
      <p className="testimonial-person">Nora & Rouby</p>
      <p className="testimonial-person">Managing Directors</p>
      <p className="testimonial-person">LifeGrain Cafe Group</p><br />

      <p className="para m-0">
        "Adding our cafe to the free directory was really easy, and it’s great to know we’re getting 
        extra exposure without spending any additional money, time, or effort!"
      </p>
      <p className="testimonial-person">Emily M </p>
      <p className="testimonial-person">Owner</p>
      <p className="testimonial-person">Mr Cuppa | Parramatta, NSW</p><br />
      <p className="para m-0">
        "This was the best thing I could have done for my business in its early stages! 
        The perfect place to plan a cafe startup."
      </p>
      <p className="testimonial-person">Ross B</p>
      <p className="testimonial-person">Cafe Startup</p>
      <p className="testimonial-person">Chocolate Cafe, Penrith, NSW</p><br />

      <p className="para m-0">
        "With Clever Cafe, we achieved a 26% increase in kitchen efficiency and a 5% decrease in COGS."
      </p>
      <p className="testimonial-person">Irene Z</p>
      <p className="testimonial-person"> Owner</p>
      <p className="testimonial-person">Little Shop of Waffles, Manly, NSW</p><br />

      <p className="para m-0">
        "Tim’s impact on our business has been undeniably positive. He shows a genuine 
        dedication to our success, displaying unwavering commitment to achieving our objectives."
      </p>
      <p className="testimonial-person">Rouby S</p>
      <p className="testimonial-person"> Managing Director</p>
      <p className="testimonial-person">LifeGrain Cafe Group, Sydney, NSW</p><br />

      <p className="para m-0">
        "I learned more about cafe business planning and the numbers involved 
        in one day than I had in months of searching online. Great experience"
      </p>
      <p className="testimonial-person">Fraser</p>
      <p className="testimonial-person">Cafe Startup</p>
      <p className="testimonial-person">Rotorua, NZ</p><br />

      <p className="para m-0">
        "Top-notch coaching, excellent value, and service. THANK YOU!"
      </p>
      <p className="testimonial-person">Luca & Jenni</p>
      <p className="testimonial-person">Cafe Startup</p>
      <p className="testimonial-person">Toowoomba, QLD</p><br />

      <p className="para m-0">
        "Working with this team has significantly streamlined our menu and business model, 
        leading to better growth and sustainability. Their insights and tailored strategies 
        have made a noticeable impact on our efficiency and overall success"
      </p>
      <p className="testimonial-person">Aniko K</p>
      <p className="testimonial-person">Owner</p>
      <p className="testimonial-person">Wicked Nutri Cookie | NSW</p><br />

      <p className="para m-0">
        "Clever Cafe supports new and emerging cafes to get off the ground and thrive. 
        I've seen the positive impact on business owners in achieving their growth goals."
      </p>
      <p className="testimonial-person">Ella B</p>
      <p className="testimonial-person">Founding Director</p>
      <p className="testimonial-person">Employii, Perth, WA</p><br />

      <p className="para m-0">
        "We are always looking to improve and innovate, and Clever Cafe has been an invaluable resource."
      </p>
      <p className="testimonial-person">Rick H</p>
      <p className="testimonial-person">Manager</p>
      <p className="testimonial-person">Monkey Bean, Toorak, VIC</p><br />

      <p className="para m-0">
        "My daughter has been running cafes for years, and as her father, 
        I'm grateful that this level of support is available to help her succeed."
      </p>
      <p className="testimonial-person">Pete U | Proud Dad</p>
      {/* <p className="testimonial-person">Proud Dad</p> */}
      <p className="testimonial-person">Palm Beach, NSW</p>

    </div>
  );
}

export default SessionMentorDetails;
