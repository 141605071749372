import React, { useState , useEffect} from "react";
import ProStar from "../../../Assets/Images/location-fs-managelisting.svg";
import ManageListing from "../../../Assets/Images/manage-listing.svg";
import CreateListing from "../../../Assets/Images/create-listing.svg";
import ActionIMg from "../../../Assets/Images/actions-img.svg";
import LocationIcon from "../../../Assets/Images/location-icon.svg";
import { Row, Col } from "react-bootstrap";
import AddLocationMOdal from "../../../Components/AddAnotheLocation";
import "../Styles/DirectoryPayment.css";
import { Link, useLocation } from "react-router-dom";
import "../Styles/Subscription.css";
import DownloadInvoice from "../../../Assets/Images/subscription-download-food.svg";
import moment from 'moment';

import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useGetFsInvoiceQuery, 
useGetFsSubscriptionListQuery
} from "../../../Services/FoodserviceAPI";

function Subscription() {
  const [showAddLocationpModal, setshowAddLocationpModal] = useState(false);
  const [recordId, setRecordId] = useState("")
  const handleshowAdLocationModal = () => {
    setshowAddLocationpModal(true);
  };
  const handleCloseModal = () => {
    setshowAddLocationpModal(false);
  };
  

  const { data: unlistedLocations, isLoading , refetch:refetchinCompletedLOcationData} =
  useGetInCompletedBranchesQuery();

const { data: completedbranch, isLoading: isLoadingCompletedData, refetch:refetchCompletedLOcationData } =
  useGetCompletedBranchesQuery();

  const proLocations = completedbranch?.filter(
    (loc) => loc?.subscription_type === "pro"
  );
  const liteLocations = completedbranch?.filter(
    (loc) => loc?.subscription_type === "lite"
  );

  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  const {data:SubscriptionList} = useGetFsSubscriptionListQuery();
  const {data:SubscriptionInvoice, refetch:refetchInvoice} = useGetFsInvoiceQuery(recordId);
  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  const handleGenerateInvoice = (recordId) => {

    setRecordId(recordId);  // First, set the recordId
  };
  
  // Call refetch when recordId changes
  useEffect(() => {
    if (recordId) {
      refetchInvoice();
    }
  }, [recordId]);

  useEffect(()=>{
if(SubscriptionInvoice){

  const url = SubscriptionInvoice;
  window.open(url, '_blank'); 
}
  }, [SubscriptionInvoice])



  return (
    <div className="dashboard-listing food-subscription">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Subscription</h4>
        </Col>
      </Row>

      {/* Pro Locations */}
      {SubscriptionList?.length>0 ? (
        <div className="list-card">
            {SubscriptionList
                ?.filter((branch) => branch.headquarter === true)
                .map((branch) => (
         <Row className="flagship-div">
            <Col xs={12} sm={12} md={12} lg={12}>
          
                  <div className="d-flex align-items-center">
                    <p>
                      <img src={ProStar} alt="Star" />
                    </p>
                    <div className="headquatterlocation">
                      <p className="headquarters">Headquarters</p>
                      <p>{branch.location}</p>
                    </div>
                  </div>
              
            </Col>
          </Row>
            ))}
          {SubscriptionList?.map((location) => (
            <div className="card" key={location.id}>
              <Row className="brdr-btm align-bottom">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <p className="location-head">

                  {location.branch.location}
                   
                  </p>
               
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} className="text-end">
                  <p className="subscription-invoice" onClick={()=>handleGenerateInvoice(location?.record_data?.id)}>
                    <img src={DownloadInvoice} alt="DownloadInvoice" />
                    Subscription invoice
                  </p>
                </Col>
              </Row>
              <Row>
                <div className="review-date d-flex align-space-between">
                <p>Added on {formatDate(location?.record_data?.subscription_start_date)}</p>
                <p>Renews on {formatDate(location?.record_data?.subscription_current_period_end)}</p>
                </div>
              </Row>
            </div>
          ))}
        </div>
      ):(<p className="subscription-no-data">No listings found</p>)}

      {/* Lite Locations */}
    

      <AddLocationMOdal
        show={showAddLocationpModal}
        handleCloseLocationModal={handleCloseModal}
      />
    </div>
  );
}

export default Subscription;
