import React, { useState, useCallback, useRef, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import FileUpload from "../Assets/Images/file-upload.svg";
import DropdownImg from "../Assets/Images/Dropdown.svg";
import MUltiSelectAdd from "../Assets/Images/multiselct-Add.svg";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import {
  useAdditionalDetailsEditWithAccountHolderIdMutation,
  useGetadditionalDetailswithAccountHolderIdQuery,
  useGetAmbienceQuery,
  useGetFeaturesQuery,
  useGetTechStackQuery,
  useGetMenuHighlihtsQuery,
  useGetSpecialistDietryServiceQuery,
  useAdditionalDetailsAddWithAccountHolderIdMutation,
  useGetFlagshipBranchesQuery
} from "../Services/CcownerAPI";
import TrustpioltRemoveImg from "../Assets/Images/removetrustpiolit-cafe.svg";
import GoogleReviewsImg from "../Assets/Images/googlereviewremove-cafe.svg";
import DeleteList from "../Assets/Images/delete-list.svg";
import FacebookImg from "../Assets/Images/facebook_review_cafe.svg"

function Additionaldetails({  onNext,BranchId, handlePrevious}) {
  const [selectedLogo, setSelectedLogo] = useState(null);
  const [selectedMenu, setselectedMenu] = useState(null);
  const [selectedImage, setselectedImage] = useState(null);
  const [selectedCoverImage, setselectedCoverImage] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [checked, setChecked] = useState({ yes: false, no: false });
  const [events, setEvents] = useState([{ title: "", link: "" }]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [value, setValue] = useState("10:00");
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  // const [additionalData, setadditionalData] = useState(data || {});
  const [additionalData, setAdditionalData] = useState({
    logo: "",
    menu: "",
    minibio: "",
    cover_image: "",
    delivery_available: false,
    delivery_range: 0,
    features: [],
    menu_highlights: [],
    specialist_dietary_services: [],
  });

  // const [dayAvailability, setdayAvailability] = useState(data || {});
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [reviews, setReviews] = useState([]);

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);

  const accountHolderId = localStorage.getItem("accountHolderId");
  const branchId =  BranchId;
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useAdditionalDetailsEditWithAccountHolderIdMutation();

  const [submitAddForm, { isAddLoading, isAddError, isAddSuccess, Adderror }] =
    useAdditionalDetailsAddWithAccountHolderIdMutation();

  const { data: additionalDataFetch, refetch } =
    useGetadditionalDetailswithAccountHolderIdQuery(branchId);
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (additionalDataFetch) {
      setAdditionalData({
        logo: additionalDataFetch.logo || "",
        menu: additionalDataFetch.menu || "",
        minibio: additionalDataFetch.min_bio || "",
        cover_image: additionalDataFetch.cover_image || "",
        delivery_range: additionalDataFetch.delivery_range || 0,
        features: additionalDataFetch.features || [],
        menu_highlights: additionalDataFetch.menu_highlights || [],
        specialist_dietary_services:
          additionalDataFetch.specialist_dietary_services || [],
      });
      setChecked({
        yes: additionalDataFetch.delivery_available === true,
        no: additionalDataFetch.delivery_available === false 
      });
      setSelectedFeatures(additionalDataFetch.features || []);
      setSelectedMenuHighlights(additionalDataFetch.menu_highlights || []);
      setSelectedDietaryServices(
        additionalDataFetch.specialist_dietary_services || []
      );
      setSelectedAmbience(additionalDataFetch.ambiences || []);
      setSelectedTechStack(additionalDataFetch.techstacks || []);
      setDeliveryRange(additionalDataFetch.delivery_range || 0);
      setEvents(additionalDataFetch.events.length ? additionalDataFetch.events : [{ title: "", link: "" }]);
      setReviews(additionalDataFetch.reviews || [ { platform_name: "", review_link: "" }]);
      setProgress(additionalDataFetch.delivery_range || 0);
      const fetchedData = days.map((day) => {
        const availability = additionalDataFetch[`${day.name.toLowerCase()}_availability`];
        const openingTime = additionalDataFetch[`${day.name.toLowerCase()}_opening_time`];
        const closingTime = additionalDataFetch[`${day.name.toLowerCase()}_closing_time`];

        return availability
          ? {
              day,
              openingTime: openingTime || "",
              closingTime: closingTime || "",
              availability,
            }
          : null;
      }).filter(Boolean);

      setFormData(fetchedData.length ? fetchedData : [{ day: null, openingTime: "", closingTime: "", availability: false }]);
    }
    
  }, [additionalDataFetch]);

  const { data: featuresData } = useGetFeaturesQuery();

  const { data: ambienceData } = useGetAmbienceQuery();

  const { data: techstackData } = useGetTechStackQuery();

  const { data: menuhighlitsData } = useGetMenuHighlihtsQuery();

  const { data: specialdietryServiceData } =
    useGetSpecialistDietryServiceQuery();

    const { data: flashshipbranch } =
    useGetFlagshipBranchesQuery();

  const [isOpentime, setIsOpentime] = useState(false);

  const handleClickTime = () => {
    setIsOpentime(!isOpen);
  };
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setAdditionalData({
      ...additionalData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  const [progress, setProgress] = useState(0); // Initialize progress to 0%
  const [deliveryRange, setDeliveryRange] = useState("0km"); // Initialize delivery range to 0km
  const barRef = useRef(null); // Reference to the progress bar container

  const updateProgress = useCallback((clientX) => {
    if (!barRef.current) return;

    // Calculate new progress based on mouse position
    const barRect = barRef.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100); // Range from 0 to 100 km
    setProgress(newProgress);
    setDeliveryRange(`${Math.round(newProgress)}km`); // Update delivery range
  }, []);

  const handleMouseDown = (e) => {
    updateProgress(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMove);
      },
      { once: true }
    );
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.buttons > 0) {
        // Check if mouse is pressed
        updateProgress(e.clientX);
      }
    },
    [updateProgress]
  );

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleBack = (e) => {
    handlePrevious();
  };

  const handleChangefield = (index, field, value) => {
    const newEvents = [...events];
    newEvents[index][field] = value;
    setEvents(newEvents);
  };

  const addEvent = () => {
    setEvents([...events, { title: "", link: "" }]);
  };

  const handleDeleteEvent = (index) => {
    const newEvents = events.filter((_, i) => i !== index);
    setEvents(newEvents);
  };

  const handleChangedelevery = (value) => {
    if (value === "no") {
      setChecked({ yes: false, no: true });
    } else if (value === "yes") {
      setChecked({ yes: true, no: false });
    }
  };
  const [formData, setFormData] = useState([
    { day: "", openingTime: "", closingTime: "" },
  ]);

  const days = [
    { id: 1, name: "Sunday" },
    { id: 2, name: "Monday" },
    { id: 3, name: "Tuesday" },
    { id: 4, name: "Wednesday" },
    { id: 5, name: "Thursday" },
    { id: 6, name: "Friday" },
    { id: 7, name: "Saturday" },
  ];
  const getSelectedDays = () => {
    return formData.map((data) => data.day?.id).filter(Boolean);
  };
  
  const toggleDropdown = (index) => {
    const newFormData = [...formData];
    newFormData[index].dropdownOpen = !newFormData[index].dropdownOpen;
    setFormData(newFormData);
  };

  const handleDayChange = (index, selectedDay) => {
    const newFormData = [...formData];
    newFormData[index] = {
      ...newFormData[index],
      day: selectedDay,
      dropdownOpen: false,
    };
    setFormData(newFormData);
  };

  const handleChange = (index, field, value) => {
    const newFormData = [...formData];
    newFormData[index] = { ...newFormData[index], [field]: value };
    setFormData(newFormData);
  };

  const addRow = () => {
    setFormData([...formData, { day: "", openingTime: "", closingTime: "" }]);
  };

  const removeTimeRow = (index) => {
    const newFormData = formData.filter((_, i) => i !== index);
    setFormData(newFormData);
  };
  const handleFileValidation = (file, allowedTypes) => {
    const fileType = file?.type?.split("/")[1].toLowerCase();
    return allowedTypes.includes(fileType);
  };
  const validateImageDimensions = (file, width, height, callback) => {
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = () => {
      if (img.width === width && img.height === height) {
        callback(true);
      } else {
        callback(false);
      }
    };
  };

  const handlelogoChange = (e) => {
    const file = e.target.files[0];
    const maxSizeInBytes = 0.3 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert("File size should not exceed 300kb.");
      setSelectedLogo("");
      return;
    }
    if (handleFileValidation(file, ["jpg", "png", "svg", "jpeg"])) {
      setSelectedLogo(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, .svg, and .jpeg files are allowed.");
      setSelectedLogo("");
    }
  };

  
  const handlemenuChange = (e) => {
    const file = e.target.files[0];
    setselectedMenu(file);
    const maxSizeInBytes = 0.7 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert("File size should not exceed 700kb.");
      setselectedMenu("");
      return;
    }
    if (handleFileValidation(file, ["jpg", "png", "svg", "jpeg", "pdf"])) {
      setselectedMenu(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, and .svg .pdf files are allowed.");
      setselectedMenu("");
    }
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
  
    if (files.length < 4) {
      alert("Please select at least 4 images.");
      return;
    }
  
    if (files.length > 10) {
      alert("You can only upload a maximum of 10 images.");
      return;
    }
  
    const validFiles = files.filter((file) =>
      ["image/jpeg", "image/jpg", "image/png", "image/svg+xml"].includes(file.type)
    );
  
    if (validFiles.length !== files.length) {
      alert("Only .jpg, .png, and .svg files are allowed.");
      return;
    }
  
    const sizeValidFiles = validFiles.filter((file) => file.size <= 600 * 1024); // 600KB in bytes
  
    if (sizeValidFiles.length !== validFiles.length) {
      alert("Each image must be less than 600kb.");
      return;
    }
  
    setselectedImage(sizeValidFiles);
  };
  

  const handleCoverImageChange = (e) => {
    const file = e.target.files[0];
    setselectedCoverImage(file);
    const maxSizeInBytes = 0.5 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      alert("File size should not exceed 500kb.");
      setselectedCoverImage("");
      return;
    }
    if (handleFileValidation(file, ["jpg", "jpeg", "png", "svg"])) {
      setselectedCoverImage(file);
    } else {
      alert("Invalid file type. Only .jpg, .png, and .svg files are allowed.");
      setselectedCoverImage("");
    }
  };
  const validateForm = () => {
    const newErrors = {};
    if (!additionalData.minibio) newErrors.minibio = "Minibio is required.";

    return newErrors;
  };
  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle Menu Highlights Change
  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };
  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      const postData = new FormData();

      // Append files
      if (selectedLogo) postData.append("logo", selectedLogo);
      if (selectedMenu) postData.append("menu", selectedMenu);
      if (selectedCoverImage)
        postData.append("cover_image", selectedCoverImage);
      // postData.append("images", JSON.stringify(selectedImage));

      // if (selectedImage && selectedImage.length > 0) {
      //   selectedImage.forEach((file, index) => {
      //     postData.append(`images[${index}]`, file);
      //   });
      // }
      if (selectedImage) {
        selectedImage.forEach((item) => postData.append("images", item));
      }

      // Append other fields
      if (additionalData.minibio) {
        postData.append("min_bio", additionalData.minibio || "");
      }

      postData.append("delivery_available", checked.yes ? "true" : "false");
      if (progress) {
        postData.append("delivery_range", Math.round(progress));
      }

      if (events && events.length > 0) {
        const validEvents = events.filter(
          (item) => item.title.trim() !== "" && item.link.trim() !== ""
        );
        if (validEvents.length > 0) {
          validEvents.forEach((item) =>
            postData.append("events", JSON.stringify(item))
          );
        }
      }

      if (reviews) {
        reviews.forEach((item) =>
          postData.append("reviews", JSON.stringify(item))
        );
      }

      selectedFeatures.forEach((item) => postData.append("features", item));
      selectedMenuHighlights.forEach((item) =>
        postData.append("menu_highlights", item)
      );
      selectedDietaryServices.forEach((item) =>
        postData.append("specialist_dietary_services", item)
      );
      selectedAmbience.forEach((item) => postData.append("ambiences", item));
      selectedTechStack.forEach((item) => postData.append("techstacks", item));

      // Handle availability
      const days = [
        "sunday",
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
      ];

      days.forEach((day) => {
        const data = formData?.find(
          (item) => item?.day?.name?.toLowerCase() === day
        );

        const formatTime = (time) => {
          if (!time) return null;

          const [hours, minutes, seconds = "00"] = time.split(":");
          return `${hours.padStart(2, "0")}:${minutes.padStart(
            2,
            "0"
          )}:${seconds.padEnd(2, "0")}`;
        };

        // Append availability
        if (data) {
          const isAvailable = !!(data.openingTime && data.closingTime);
          postData.append(`${day}_availability`, isAvailable);

          // Append opening and closing times only if they exist
          if (isAvailable) {
            if (data.openingTime) {
              postData.append(
                `${day}_opening_time`,
                formatTime(data.openingTime)
              );
            }
            if (data.closingTime) {
              postData.append(
                `${day}_closing_time`,
                formatTime(data.closingTime)
              );
            }
          }
        }
      });

      
      try {
        const response = await submitForm({ postData, branchId }).unwrap();
        if (response) {
          onNext(response?.branch);
        }
      } catch (err) {
        console.error("Failed to submit form:", err.status);
        // onNext(postData);
        if (err.status == 404) {
          try {
            const response = await submitAddForm({
              postData,
              branchId,
            }).unwrap();
            if (response) {
              onNext(response?.branch);
            }
          } catch (err) {}
        }
      }
    }
  };

  const handlePlatformSelect = (event) => {
    const selectedPlatform = event.target.value;
    if(selectedPlatform){
      if (!selectedPlatforms.includes(selectedPlatform)) {
        setSelectedPlatforms([...selectedPlatforms, selectedPlatform]);
        setReviews([
          ...reviews,
          { platform_name: selectedPlatform, review_link: "" },
        ]);
      }
    }
 
  };

  const handleLinkChange = (index, value) => {
    const updatedReviews = [...reviews];
    updatedReviews[index].review_link = value;
    setReviews(updatedReviews);
  };

  const handleDeleteReview = (index) => {
    const updatedReviews = reviews.filter((_, i) => i !== index);
    setReviews(updatedReviews);

    const platformToRemove = reviews[index].platform_name;
    const updatedPlatforms = selectedPlatforms.filter(
      (platform) => platform !== platformToRemove
    );
    setSelectedPlatforms(updatedPlatforms);
  };

  const getImageForPlatform = (platform) => {
    if (platform === "Trustpilot") {
      return TrustpioltRemoveImg;
    } else if (platform === "Google Reviews") {
      return GoogleReviewsImg;
    }
    else if (platform === "Facebook Reviews") {
      return FacebookImg;
    }
    return null;
  };
  return (
    <div className="add-details add-additional-details">
      <h4>Additional Details</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Logo</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handlelogoChange}
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                    {selectedLogo ? (
                      selectedLogo.name
                    ) : additionalDataFetch?.logo ? (
                      <img
                        src={additionalDataFetch.logo}
                        width="50px"
                        height="20px"
                        alt="Logo"
                      />
                    ) : (
                      "Please select a file"
                    )}
                  </span>
                  <span className="format-text">
                    (Only supports .jpg, .png and .svg files & File size should be less than 300kb)
                  </span>
                </div>
              </label>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Menu</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handlemenuChange}
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                    {selectedMenu ? (
                      selectedMenu.name
                    ) : additionalDataFetch?.menu ? (
                      <img
                        src={additionalDataFetch.menu}
                        width="50px"
                        height="20px"
                        alt="Logo"
                      />
                    ) : (
                      "Please select a file"
                    )}
                  </span>

                  <span className="format-text">
                    (Only supports .jpg, .png and .svg .pdf files & File size should be less than 700kb)
                  </span>
                 
                </div>
              </label>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Images</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handleImageChange}
                  multiple
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">
                    {selectedImage?.length > 0
                      ? `${selectedImage?.length} files selected`
                      : "Please select files"}
                  </span>
                  <span className="format-text">
                    Max of 10 images and min of 4
                  </span>
                  <br />
                  <span className="format-text">
                  File size should be less than 600kb
                  </span>
                </div>
              </label>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <label>Cover image</label>
            <div className="file-upload-container d-flex">
              <label className="file-upload-label">
                <input
                  type="file"
                  className="file-upload-input"
                  onChange={handleCoverImageChange}
                />
                <div>
                  <img src={FileUpload} alt="FilecUpload" />
                </div>
                <div>
                  <span className="file-upload-text">

                    {selectedCoverImage ? (
                      selectedCoverImage.name
                    ) : additionalDataFetch?.cover_image ? (
                      <img
                        src={additionalDataFetch.cover_image}
                        width="50px"
                        height="20px"
                        alt="Logo"
                      />
                    ) : (
                      "Please select a file"
                    )}
                  </span>

                  <span className="format-text">
                  Image length should be 1080x960
                  </span><br />
                  <span className="format-text">File size should be less than 300kb</span>
                </div>
              </label>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={12} md={12}>
            <div className="form-control">
              <label>
                Mini Bio<span className="red-star">*</span>
              </label>
              <br />
              <textarea
                rows={4}
                placeholder="About your company"
                name="minibio"
                maxLength="200"
                value={additionalData.minibio || ""}
                onChange={handleInputChange}
              ></textarea>
              {errors.minibio && (
                <div className="error-message">{errors.minibio}</div>
              )}
              <br />
              <span className="max-letter">Maximum of 200 words</span>
            </div>
          </Col>
        </Row>
        {formData.map((data, index) => (
      <Row key={index}>
        <Col xs={12} sm={12} lg={2} md={2}>
          <div className="form-control">
            <label>Day</label>
            <br />
            <div className="custom-dropdown">
              <div
                className="selected-day"
                onClick={() => toggleDropdown(index)}
              >
                {data.day ? data.day.name : "Select a day"}
                <img
                  src={DropdownImg}
                  alt="Dropdown"
                  className="arrow-dropdown-img"
                />
              </div>
              {data.dropdownOpen && (
                <ul className="dropdown-list">
                  {days.map((day) => (
                    <li
                      key={day.id}
                      onClick={() => handleDayChange(index, day)}
                      className={getSelectedDays().includes(day.id) ? 'disabled' : ''}
                      style={{
                        pointerEvents: getSelectedDays().includes(day.id)
                          ? "none"
                          : "auto",
                        opacity: getSelectedDays().includes(day.id) ? 0.5 : 1,
                      }}
                    >
                      {day.name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </Col>

        {/* Opening and Closing Time Inputs */}
        <Col xs={12} sm={12} lg={2} md={2}>
          <div className="form-control">
            <label>Opening Time</label>
            <br />
            <input
              type="time"
              value={data.openingTime}
              onChange={(e) =>
                handleChange(index, "openingTime", e.target.value)
              }
            />
          </div>
        </Col>
        <Col xs={12} sm={12} lg={2} md={2}>
          <div className="form-control">
            <label>Closing Time</label>
            <br />
            <input
              type="time"
              value={data.closingTime}
              onChange={(e) =>
                handleChange(index, "closingTime", e.target.value)
              }
            />
          </div>
        </Col>

        {/* Delete and Add Buttons */}
        <Col xs={12} sm={12} lg={4} md={4} className="d-flex">
          <div className="d-flex align-items-center">
            <img
              src={DeleteList}
              alt={DeleteList}
              onClick={() => removeTimeRow(index)}
            />
            {index === formData.length - 1 && (
              <button
                type="button"
                onClick={addRow}
                className="add-new-btn"
              >
                Add
              </button>
            )}
          </div>
        </Col>
      </Row>
    ))}

        <Row>
          <Col xs={12} sm={12} lg={12} md={12}>
            <div className="">
              <label>Delivery</label>
              <br />
              <div className="checkbox-group">
                <label className="deleivery-yes">
                  <input
                    type="checkbox"
                    checked={checked.yes}
                    onChange={() => handleChangedelevery("yes")}
                  />
                  <span className="check-delivery-text">Yes</span>
                </label>
                <label>
                  <input
                    type="checkbox"
                    checked={checked.no}
                    onChange={() => handleChangedelevery("no")}
                  />
                  <span className="check-delivery-text">No</span>
                </label>
              </div>
            </div>
          </Col>

          {checked.yes && (
            <Col xs={4} sm={4} lg={4} md={4}>
              <div className="form-control">
                <div className="d-flex align-flex-end">
                  <label>
                    Delivery Range<span className="red-star">*</span>
                  </label>
                  <span className="delivery-range"> {deliveryRange}</span>
                </div>
                <div
                  className="progress-bar-container"
                  ref={barRef}
                  onMouseDown={handleMouseDown}
                >
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                  ></div>
                </div>
              </div>
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
            <div className="form-control">
              <label className="choose-label">
                Features (Amenities and Products)
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {featuresData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedFeatures.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleFeatureChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedFeatures.includes(option.id)}
                        onChange={() => handleFeatureChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          <Col xs={12} sm={12} lg={6} md={6} className="mrt-20">
            <div className="form-control">
              <label className="choose-label">
                Menu Highlights
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {menuhighlitsData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedMenuHighlights.includes(option.id)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleMenuHighlightChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="products"
                        value={option.id}
                        checked={selectedMenuHighlights.includes(option.id)}
                        onChange={() => handleMenuHighlightChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
            <div className="form-control">
              <label className="choose-label">
                Specialist Dietary Services
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {specialdietryServiceData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedDietaryServices.includes(option.id)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleDietaryServiceChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`dietaryOption${option.id}`}
                        name="dietaryServices"
                        value={option.id}
                        checked={selectedDietaryServices.includes(option.id)}
                        onChange={() => handleDietaryServiceChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`dietaryOption${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>

          <Col xs={12} sm={12} lg={6} md={6} className="mrt-20">
            <div className="form-control">
              <label className="choose-label">
                Ambience
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {ambienceData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedAmbience.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleAmbienceChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`ambienceOption${option.id}`}
                        name="ambience"
                        value={option.id}
                        checked={selectedAmbience.includes(option.id)}
                        onChange={() => handleAmbienceChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`ambienceOption${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>

          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                Tech Stack
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {techstackData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedTechStack.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleTechStackChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`techStackOption${option.id}`}
                        name="techStack"
                        value={option.id}
                        checked={selectedTechStack.includes(option.id)}
                        onChange={() => handleTechStackChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`techStackOption${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
        </Row>

        <h4>Events</h4>
        <hr />
        {events.map((event, index) => (
          <Row key={index}>
            <Col xs={12} sm={12} lg={5} md={5}>
              <div className="form-control">
                <label>Event Title</label>
                <br />
                <input
                  type="text"
                  placeholder="Enter event title"
                  value={event.title}
                  onChange={(e) =>
                    handleChangefield(index, "title", e.target.value)
                  }
                />
              </div>
            </Col>
            <Col xs={12} sm={12} lg={5} md={5}>
              <div className="form-control">
                <label>Event Link</label>
                <br />
                <input
                  type="text"
                  placeholder="Add link"
                  value={event.link}
                  onChange={(e) =>
                    handleChangefield(index, "link", e.target.value)
                  }
                />
              </div>
            </Col>

            <Col xs={12} sm={12} lg={2} md={2} className="d-flex">
              <div className="d-flex align-items-center">
                {events.length > 1 && index !== events.length - 1 && (
                  <img
                    src={DeleteList}
                    alt={DeleteList}
                    onClick={() => handleDeleteEvent(index)}
                  />
                )}
                {index === events.length - 1 && (
                  <button
                    type="button"
                    onClick={addEvent}
                    className="add-new-btn"
                  >
                    Add
                  </button>
                )}
              </div>
            </Col>
          </Row>
        ))}
        <h4>Reviews</h4>
        <hr />
        <Row>
          <Col xs={12} sm={12} lg={5} md={5}>
            <div className="form-control">
              <label>Review Platform</label>
              <br />
              <select onChange={handlePlatformSelect}>
                <option value="">Select your review platform</option>
                <option value="Google Reviews">Google Reviews</option>
                <option value="Trustpilot">Trustpilot</option>
                <option value="Facebook Reviews">Facebook Reviews</option>
              </select>
            </div>
          </Col>
        </Row>

        <Row>
          {reviews.map((review, index) => (
            <Col xs={12} sm={12} lg={4} md={4} key={index}>
              <div className="form-control">
                {/* <label>{review.platform_name} Link</label> */}
                <label onClick={() => handleDeleteReview(index)}>
                  {" "}
                  {getImageForPlatform(review.platform_name) && (
                    <img
                      src={getImageForPlatform(review.platform_name)}
                      alt={`${review.platform_name} logo`}
                    />
                  )}
                </label>
                <br />
                <input
                  type="text"
                  placeholder={`Enter your ${review.platform_name} link`}
                  value={review.review_link}
                  onChange={(e) => handleLinkChange(index, e.target.value)}
                />
              </div>
            </Col>
          ))}
        </Row>

        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button
              type="button"
              className="prev-btn btn-64t35ne"
              onClick={handleBack}
            >
              Back
            </button>
            <button type="submit" className="btn-save-primary">
              Save and continue
            </button>
          </Col>
          {/* {isError && (
            <p style={{ color: "red" }}>
              Error: {error?.data?.error || "Failed to submit form"}
            </p>
          )} */}
          {isSuccess && (
            <p style={{ color: "green" }} className="isSuceesMsg">
              Form submitted successfully!
            </p>
          )}
          {isLoading && <div class="loader"></div>}
          {isAddError && (
            <p style={{ color: "red" }}>
              Error: {Adderror?.error || "Failed to submit form"}
            </p>
          )}
          {isAddSuccess && (
            <p style={{ color: "green" }} className="isSuceesMsg">
              Form submitted successfully!
            </p>
          )}
          {isAddLoading && <div class="loader"></div>}
        </Row>
      </form>
    </div>
  );
}

export default Additionaldetails;
