import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import MUltiSelectAdd from "../../../Assets/Images/trades-add-btn.svg";
import { useNavigate, Link, useLocation } from "react-router-dom";
import AlertImg from "../../../Assets/Images/trade-alert-img.svg";
import {
  useGetAmbienceQuery,
  useGetFeaturesQuery,
  useGetTechStackQuery,
  useGetMenuHighlihtsQuery,
  useGetSpecialistDietryServiceQuery,
  useGetStatesQuery,
} from "../../../Services/CcownerAPI";
import {
  useSubmitEcosystemCrietirabyBranchidMutation,
  useGetEcosystemDetailswithAccountHolderIdQuery,
  useSubmitEditEcosystemCrietirabyBranchidMutation,
} from "../../../Services/TradeserviceAPI";

function EcosystemCriteria({ onNext, handlePrevious, BranchId, activeTab }) {
  const location = useLocation();
  const { CBranchId } = location.state || {};
  const navigate = useNavigate();
  const handleBack = (e) => {
    handlePrevious();
  };

  const [
    submitEditForm,
    {
      isLoading: editisloading,
      isError: editIserror,
      isSuccess: editisSuccess,
      error: editError,
    },
  ] = useSubmitEditEcosystemCrietirabyBranchidMutation();
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubmitEcosystemCrietirabyBranchidMutation();

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedMenuHighlights, setSelectedMenuHighlights] = useState([]);
  const [selectedDietaryServices, setSelectedDietaryServices] = useState([]);
  const [selectedAmbience, setSelectedAmbience] = useState([]);
  const [selectedTechStack, setSelectedTechStack] = useState([]);
  const [selectedFSStates, setSelectedFsStates] = useState([]);
  const [branchId, setBranchId] = useState("");
  const { data: featuresData } = useGetFeaturesQuery();
  useEffect(() => {
    if (featuresData) {
    
      const features = featuresData;
    }
  }, [featuresData]);

  const { data: ambienceData } = useGetAmbienceQuery();

  const { data: techstackData } = useGetTechStackQuery();

  const { data: menuhighlitsData } = useGetMenuHighlihtsQuery();

  const { data: specialdietryServiceData } =
    useGetSpecialistDietryServiceQuery();
  const { data: states } = useGetStatesQuery();

  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  // Handle Menu Highlights Change
  const handleMenuHighlightChange = (id) => {
    setSelectedMenuHighlights((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((highlightId) => highlightId !== id)
        : [...prevSelected, id]
    );
  };
  const handleDietaryServiceChange = (id) => {
    setSelectedDietaryServices((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleAmbienceChange = (id) => {
    setSelectedAmbience((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };

  const handleTechStackChange = (id) => {
    setSelectedTechStack((prev) =>
      prev.includes(id) ? prev.filter((item) => item !== id) : [...prev, id]
    );
  };
  const handleFsStateChange = (id) => {
    setSelectedFsStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const { data: additionalDataFetch, refetch } =
    useGetEcosystemDetailswithAccountHolderIdQuery(branchId);
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (additionalDataFetch) {
    
      setSelectedFeatures(additionalDataFetch[0]?.ce_features || []);
      setSelectedMenuHighlights(
        additionalDataFetch[0]?.ce_menu_highlights || []
      );
      setSelectedFsStates(additionalDataFetch[0]?.ce_state || []);
      const operationalStatus =
        additionalDataFetch[0]?.ce_operational_status || [];

      // Map operational status IDs to checkbox states
      const statusMapping = {
        planning: operationalStatus.includes(1), // ID 1 is for planning
        open: operationalStatus.includes(2), // ID 2 is for open
        exiting: operationalStatus.includes(3), // ID 3 is for exiting
      };

      setSelectedOptions(statusMapping); // Update checkbox states based on the mapped values
    }
  }, [additionalDataFetch]);

  const [selectedOptions, setSelectedOptions] = useState({});

  const [operationalStatus, setOperationalStatus] = useState([]); // Initialize as an array

  const handleChangeStatus = (e) => {
    const { value } = e.target;
    
    setSelectedOptions((prevState) => {
      const updatedOptions = {
        ...prevState,

        [value]: !prevState[value],
      };

      const updatedStatus = [];
      if (updatedOptions.planning) updatedStatus.push(1);
      if (updatedOptions.open) updatedStatus.push(2);
      if (updatedOptions.exiting) updatedStatus.push(3);

      setOperationalStatus(updatedStatus);

      return updatedOptions;
    });
  };

  const handleSubmit = async (e) => {
    const branchId = BranchId;
    e.preventDefault();
  
    let postData = {
      ce_features: selectedFeatures,
      ce_menu_highlights: selectedMenuHighlights,
    };
  
    // Check if operationalStatus is not empty and assign it directly
    if (operationalStatus.length > 0) {
      postData.ce_operational_status = operationalStatus; // Assign instead of append
    }
  
    try {
      // Submit the edit form
      const response = await submitEditForm({ postData, branchId }).unwrap();
      if (response) {
        navigate("/trade-service-directorypagelisting");
        window.location.reload();
      }
    } catch (err) {
      // Check if the error is 404 (not found) and try submitting the form again
      if (err.status === 404) {
        try {
          const response = await submitForm({ postData, branchId }).unwrap();
          if (response) {
            navigate("/trade-service-directorypagelisting");
            window.location.reload();
          }
        } catch (err) {
          console.error("Error during form submission:", err);
        }
      } else {
        console.error("Failed to submit edit form:", err);
      }
    }
  };
  

  const goToPreview = async (e) => {
    e.preventDefault();
    let postData = {
      ce_features: selectedFeatures,
      ce_menu_highlights: selectedMenuHighlights,
    };
  
    // Check if operationalStatus is not empty and assign it directly
    if (operationalStatus.length > 0) {
      postData.ce_operational_status = operationalStatus; // Assign instead of append
    }
  
    try {
      const response = await submitEditForm({ postData, branchId }).unwrap();
      if (response) {
        navigate(`/trades-service`, { state: { activeTab, BranchId } });
      }
    } catch (err) {
      if (err.status == 404) {
        try {
          const response = await submitForm({ postData, branchId }).unwrap();
          if (response) {
            navigate(`/trades-service`, { state: { activeTab, BranchId } });
          }
        } catch (err) {}
      }
    }
  };

  useEffect(() => {
    if (BranchId) {
      setBranchId(BranchId);
    } else {
      setBranchId(CBranchId);
    }
  }, [BranchId, CBranchId]);

  return (
    <div className="add-details add-ecosystem-criteria">
      <h4 className="mb-20">
        Preferred operational status <span className="red-star">*</span>{" "}
        {/* <img src={AlertImg} alt="AlertImg" /> */}
        <div class="tooltip-common">
          <img src={AlertImg} alt="AlertImg" />
          <span class="tooltip-commontext">
            Select the type of business you would like to work with
          </span>
        </div>
      </h4>
      <form onSubmit={handleSubmit} className="m-0">
        <Row>
          <Col xs={12} sm={12} lg={12} md={12}>
            <div className="">
              <div className="checkbox-group">
                <label className="deleivery-yes">
                  <input
                    type="checkbox"
                    id="planning"
                    name="confirmation_status"
                    value="planning"
                    onChange={handleChangeStatus}
                    checked={selectedOptions.planning}
                  />
                  <span className="check-delivery-text">Planning to open</span>
                </label>
                <label className="deleivery-yes">
                  <input
                    type="checkbox"
                    id="open"
                    name="confirmation_status"
                    value="open"
                    onChange={handleChangeStatus}
                    checked={selectedOptions.open}
                  />
                  <span className="check-delivery-text">Open for business</span>
                </label>
                <label className="deleivery-yes">
                  <input
                    type="checkbox"
                    id="exiting"
                    name="confirmation_status"
                    value="exiting"
                    onChange={handleChangeStatus}
                    checked={selectedOptions.exiting}
                  />
                  <span className="check-delivery-text">Looking to exit</span>
                </label>
              </div>
            </div>
          </Col>
        </Row>
        <h4>Cafes (Extras)</h4>
        <p>Feel free to add any relevant interests or alignments outside your main trade and services, so cafe members can see any parallels. If none, leave blank.</p>
        <hr />

        <Row>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                Features
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {featuresData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedFeatures.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleFeatureChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedFeatures.includes(option.id)}
                        onChange={() => handleFeatureChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label className="choose-label">
                Menu Highlights
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {menuhighlitsData?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedMenuHighlights.includes(option.id)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleMenuHighlightChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="products"
                        value={option.id}
                        checked={selectedMenuHighlights.includes(option.id)}
                        onChange={() => handleMenuHighlightChange(option.id)}
                        style={{ display: "none" }} // Hide the checkbox
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>

          {/* <Col xs={12} sm={12} lg={6} md={6} className="pdr-40 mrt-20">
            <div className="form-control">
              <label className="choose-label">
                States
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {states?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedFSStates.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleFsStateChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedFSStates.includes(option.id)}
                        onChange={() => handleFsStateChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                        {option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>

        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button
              type="button"
              onClick={goToPreview}
              className="prev-btn btn-64t35ne"
            >
              Preview
            </button>
            <button
              type="button"
              className="prev-btn btn-64t35ne"
              onClick={handleBack}
            >
              Back
            </button>
            <button type="submit" className="btn-save-primary">
              Save and continue
            </button>
          </Col>
        </Row>
      </form>
    </div>
  );
}

export default EcosystemCriteria;
