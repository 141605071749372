// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/AddAnotherLocation.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/AddNewNotice.css";
import ArrowDownImg from "../../../Assets/Images/arrow-down-icon.svg";
import FileUpload from "../../../Assets/Images/file-upload.svg";
import Select from "react-select";

import "../Styles/Support.css";
import { useAccountDeleteMutation } from "../../../Services/TradeserviceAPI";
import { useDeleteUserbyTicketIdMutation } from "../../../Services/SupportAPI";
import {
  useGetAccountHolderDataQuery,
} from "../../../Services/TradeserviceAPI";


const ModalComponent = ({
  showAccountDeleteMOdal,
  handleCloseAccountDeleteModal,
}) => {
 
  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useAccountDeleteMutation();

  const navigate = useNavigate();
  
  const handlecloseModal = ()=>{
    handleCloseAccountDeleteModal();
  }
  const [isOtherChecked, setIsOtherChecked] = useState(false);
  const [otherReason, setOtherReason] = useState("");

  const [isswitchingChecked, setIsSwitchingChecked] = useState(false);
  const [switchingotherReason, setSwitchingOtherReason] = useState("");

  const {
    data: accouldHolderData,
    isLoading: accountholderdataloading,
    refetch,
  } = useGetAccountHolderDataQuery();
  const [deleteUserbyTicketId] = useDeleteUserbyTicketIdMutation();

  const handleCheckboxChange = (e) => {
    setIsOtherChecked(e.target.checked);
    if (!e.target.checked) {
      setOtherReason(""); // Clear the input if unchecked
    }
  };

  const handleSwitchingCheckboxChange = (e) => {
    setIsSwitchingChecked(e.target.checked);
    if (!e.target.checked) {
      setSwitchingOtherReason(""); // Clear the input if unchecked
    }
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
   
      try {
        const response = await submitForm().unwrap();
        alert("Account deleted successfully")
      
      } catch (err) {
        console.error("Failed to submit form:", err);
        alert("Failed to delete account")
      }
    
  };

  useEffect(() => {
    const deleteRelatedRecords = async () => {
      try {
        await deleteUserbyTicketId(accouldHolderData?.user_id).unwrap();
      } catch (err) {
        console.error("Failed to delete related records:", err);
      }
    };
  
    if (isSuccess) {
      deleteRelatedRecords();
      navigate("/trade-login");
    }
  }, [isSuccess, navigate]);



  return (
    <>
      <Modal
        show={showAccountDeleteMOdal}
        onHide={handleCloseAccountDeleteModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h2>Delete Account</h2>
          <p>
            Delete all listings under your account and deactivate your account.
          </p>
          <p className="small-text">
            <span>Please note:</span> When you delete listings or your entire account, this
            action is final and cannot be undone. If you choose to rejoin later,
            you will need to start from scratch.
          </p>
          <h2>Reason for Deleting Your Account</h2>
          <form onSubmit={handleSubmit} className="deleteaccont-modal">
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
              
                  <input type="checkbox" /><label>Business No Longer Active</label>
            
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                
                  <input type="checkbox" /><label>Service Fulfilled</label>
                
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                
                  <input type="checkbox"  onChange={handleSwitchingCheckboxChange}
                  checked={isswitchingChecked} /><label>Switching to Alternative Solutions (Please Specify)</label>
                {isswitchingChecked && (
                  <input
                    type="text"
                    className="mbt-20"
                    placeholder="Please specify your reason"
                    value={switchingotherReason}
                    onChange={(e) => setSwitchingOtherReason(e.target.value)}
                  />
                )}
               
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                
                  <input type="checkbox" /><label>Platform Not Meeting Expectations</label>
                
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                
              <input type="checkbox" onChange={handleCheckboxChange}
                  checked={isOtherChecked} /><label>Other (Please Specify)</label>
                {isOtherChecked && (
                  <input
                    type="text"
                    placeholder="Please specify your reason"
                    value={otherReason}
                    onChange={(e) => setOtherReason(e.target.value)}
                  />
                )}
               
              </Col>
            </Row>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handlecloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="primary-btn-trade">
                 Delete
                </button>
              </Col>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Failed to submit form"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                  Form submitted successfully!
                </p>
              )}
              {isLoading && <div class="loader-trade"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
