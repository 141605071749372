import React, { useState } from "react";
import "../Styles/CommonSignUp.css";
import { Row, Col } from "react-bootstrap";
import FoodServiceImg from "../Assets/Images/food-service-defaultimg.png";
import TradeServiceImg from "../Assets/Images/tradeservice-default-img.png";
import CafeDefaultImg from "../Assets/Images/cafe-defaultimg.png";
import Elipse from "../Assets/Images/enterprenurship-elipse1.svg";
import ElipseRed from "../Assets/Images/elipse-red.svg";
import ElipseBlue from "../Assets/Images/traders-elipse.svg";
import TickIcon from "../Assets/Images/tick-icon-black.svg";
import CrossIcon from "../Assets/Images/cross-icon-black.svg";
import TickPrimaryIcon from "../Assets/Images/tick-icon.svg";
import { Link } from "react-router-dom";
import Protext from "../Assets/Images/Pro.svg";
import liteIconDown from "../Assets/Images/lite-icon-down.svg";
import proIconDown from "../Assets/Images/po-icon-down.svg"
import AlertImg from "../Assets/Images/contact-alert.svg";
import {
  useGetceAmountQuery, useGetfsAmountQuery,
  useGettsAmountQuery
} from "../Services/CommonAPI"
import { Helmet } from 'react-helmet';
import { useGethuddlesessionPriceforWebQuery } from "../Services/SecondaryAPI";

function CommonSignUp() {
  const [activeCard, setActiveCard] = useState("cafe");
  const [isFoodservice, setIsFoodservice] = useState(false);
  const [isTradeservice, setIsTradeservice] = useState(false);
  const [isCafe, setIsCafe] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const { data: propricece } = useGetceAmountQuery();
  const { data: propricefs } = useGetfsAmountQuery();
  const { data: propricets } = useGettsAmountQuery();
const {data:huddlePrice} = useGethuddlesessionPriceforWebQuery ();
  const handleCardClick = (cardName) => {
    setActiveCard(cardName);
    if (cardName == "food") {
      setIsFoodservice(true);
      setIsTradeservice(false);
      setIsCafe(false);
    }
    if (cardName == "cafe") {
      setIsFoodservice(false);
      setIsTradeservice(false);
      setIsCafe(true);
    }
    if (cardName == "trade") {
      setIsFoodservice(false);
      setIsTradeservice(true);
      setIsCafe(false);
    }
  };
  const handleToggle = () => {
    setIsChecked(!isChecked);
  };

  <Helmet>
    <title>Clever Cafe | Sign Up - Where Every Day Is an Expo for Australia’s Cafe Industry</title>
    <meta name="description" content="Join Clever Cafe today and gain full access to a wealth of resources, industry connections, and daily opportunities designed to grow your cafe business." />
    <meta name="keywords" content="cafe business plans australia, opening a cafe checklist, australian cafe entrepreneurship, cafe planning and modelling in australia" />
  </Helmet>

  return (
    <div className="common-signup">
      <section className="common-signup-banner">
        <div className="common-signup-banner-content">
          {/* <h2>
            Choose your solution and access your
            <span className="disply-blk">cafe ecosystem membership</span>
          </h2> */}
          <h2>Choose your solution and access your <span className="disply-blk"></span>
          cafe ecosystem membership</h2>
          {/* <h3>Join now and experience a 1-month free trial.</h3> */}
        </div>
      </section>
      <section className="resgister-section">
        <Row className="align-items-center">
          <Col
            sm={12}
            lg={4}
            md={4}
            className={activeCard === "food" ? "center-card" : ""}
          >
            <div
              className={`card card-food ${activeCard === "food" ? "active" : ""
                }`}
            >
              <Link to="/common-signup-food"> <div className="card-image">
                <h4>
                  <img src={ElipseRed} className="elipse-img-red" />
                  Foodservice
                </h4>
                <p>
                  <div className="red-circle"></div>
                  <span className="mr-20">Suppliers</span>
                  <div className="red-circle"></div>
                  <span className="mr-20">Distributors
                  </span>
                  <div className="red-circle"></div>
                  <span className="mr-20">Brands</span><br />
                  <div className="red-circle"></div>
                  <span className="mr-20">Manufacturers</span>
                  <div className="red-circle"></div>
                  <span className="mr-20">Producers</span>
                  <div className="red-circle"></div>
                  <span className="mr-20">Wholesalers</span>
                  <div className="red-circle"></div>
                  <span className="mr-20">Importers</span>
                  <div className="red-circle"></div>
                  <span className="mr-20">Exporters
                  </span>
                </p>
                <h5>
                  <span><span className="price-symbol">$</span>{propricefs?.amount}</span>/state monthly
                </h5>
              </div></Link>
              <div className="card-body">
                <Link to="/common-signup-food">
                  <button className="register-btn">Register</button>
                </Link>
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            lg={4}
            md={4}
            className={activeCard === "cafe" ? "center-card" : ""}
          >
            <div
              className={`card card-cafe ${activeCard === "cafe" ? "active" : ""
                }`}
              onClick={() => handleCardClick("cafe")}
            >
            <Link to="/ccowner-signup"> <div className="card-image">
                <h4>
                  <img src={Elipse} className="elipse-img-ylw" />
                  Cafe
                  <br />
                  Entrepreneurship
                </h4>
                <p>
                  <div className="yellow-circle"></div>
                  <span className="mr-20">
                    Planning to Open
                  </span>
                  <div className="yellow-circle"></div>
                  <span className="mr-20">
                    Open for Business

                  </span><br />
                  <div className="yellow-circle"></div>
                  <span className="mr-20">
                    Looking to Exit


                  </span>
                </p>
                {isChecked ? (
                  <h5>
                    <span><span className="price-symbol">$</span>{propricece?.amount}</span> /monthly
                  </h5>
                ) : (
                  <h5>
                    <span><span className="price-symbol">$</span>0</span>
                  </h5>
                )}
              </div></Link>
              <div className="card-body">
                <div className="swich-box">
                  <span className="lite-text">Lite</span>
                  <label className="switch">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleToggle}
                    />
                    <span className="slider round"></span>
                  </label>
                  {/* <img src={Protext} className="pro-text" /> */}
                  <img
                    src={Protext}
                    className={`pro-text ${isChecked ? "zoom-in-out" : ""}`}
                    alt="PRO"
                  />
                </div>
                {isChecked ? (
                  <Link to="/ccowner-signup-pro">
                    <button className="register-btn">Register</button>
                  </Link>
                ) : (
                  <Link to="/ccowner-signup">
                    <button className="register-btn">Register</button>
                  </Link>
                )}
              </div>
            </div>
          </Col>
          <Col
            sm={12}
            lg={4}
            md={4}
            className={activeCard === "trade" ? "center-card" : ""}
          >
            <div
              className={`card card-trade ${activeCard === "trade" ? "active" : ""
                }`}

            >
              <Link to="/common-signup-trade"> <div className="card-image">
                <h4>
                  <img src={ElipseBlue} className="elipse-img" />
                  Trades & Services
                </h4>
                <p>
                  <div className="blue-circle"></div>
                  <span className="mr-20">Covering 30+ Sectors of Operational Support
                  </span>
                </p>
                <h5>
                  <span><span className="price-symbol">$</span>{propricets?.amount}</span> /state monthly
                </h5>
              </div></Link>
              <div className="card-body">
                <Link to="/common-signup-trade">
                  {" "}
                  <button className="register-btn">Register</button>
                </Link>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section>
        <p className="breif-text-para">Cafe operators can scroll down for a full breakdown of what’s available, then click Register to sign up. Food and trade service providers, click Register to view exclusive features.</p>
      </section>
      {isCafe && (
        <section className="explore-features">
          <Row>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="explore-all-features">
                <div className="explore-all-table">

                  <h5>Explore all features</h5>
                  <p className="tax-deductiblle-cs">(Tax Deductible!)</p>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="feature-head">
                          Features
                        </th>
                        <th scope="col" className="lite-head">
                          <div>
                            <b>Lite</b> Membership

                            <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" className="alert-img"/>
                            <span class="tooltip-commontext">
                              Pay-as-you-go - great for trying new ideas or staying flexible.
                            </span>
                          </div>
                            {/* <span class="tooltip-commontext">
                              Pay-as-you-go - great for trying new ideas or staying flexible.
                            </span> */}

                            <br />
                            <b>$0</b>/ month
                            <img src={liteIconDown} alt="liteIconDown" />
                          </div>
                        </th>
                        <th scope="col" className="pro-head">
                          <div>
                            <b>Pro </b> Membership


                            <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" className="alert-img"/>
                            <span class="tooltip-commontext">
                              All-in-one - best for smooth operations and steady growth.
                            </span>
                          </div>

                            <br />
                            <b>${propricece?.amount}</b>/ month

                            <img src={proIconDown} alt="proIconDown" />
                          </div>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Real-Time Professional Directory
                          Listing
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Marketing & Customer Growth
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Tailored Business Connections
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Communication with Industry Experts
                        </td>
                        <td>
                          <img src={CrossIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Practical Tools & Guides
                        </td>
                        <td>
                          <img src={CrossIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Unlimited Access to Specialist Courses
                        </td>
                        <td className="bold-txt">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Unlimited Group Mentorship Sessions
                        </td>
                        <td className="bold-txt">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> 1:1 Mentorship & Roadmapping
                        </td>
                        <td className="bold-txt">$169.99</td>
                        <td className="bold-txt">$79.99</td>
                      </tr>
                      <tr>
                        <td className="first-col">
                        <span>*</span> Exclusive Discounts on Products & Services
                        </td>
                        <td>
                          <img src={CrossIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Priority Support When You Need It
                        </td>
                        <td>
                          <img src={CrossIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Industry Updates & Compliance Support
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Unlimited Access to Online Expos & Events
                        </td>
                        <td>
                          <img src={CrossIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>*</span> Live Chat & Community Noticeboard
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                        <td>
                          <img src={TickIcon} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Row>
                    <Col md={6} lg={6} sm={12}>
                      <Link to="/ccowner-signup">  <button className="register-btn-btm">
                        Register for Lite
                      </button></Link>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                      {" "}
                      <Link to="/ccowner-signup-pro"> <button className="register-btn-btm">
                        Register for Pro
                      </button></Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="more-for-you">
                <h4>Business Plans. Menu Development. Networking. Mentorship. Systems.</h4>
                <Row>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Start-ups & Incubation</h5>
                    <ul>
                      <li>Early Industry & Public Visibility</li>
                      <li>Preliminary Budgeting & Business Planning</li>
                      <li>Initial Branding, Marketing & Menu Drafting</li>
                      <li>Action Planning & Financial Modelling</li>
                      <li>Expert Guidance & Industry Tools</li>
                      <li>Accessible Products & Services</li>
                      <li>Build Your Own Supportive Ecosystem</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Marketing & Exposure </h5>
                    <ul>
                      <li>Premium Directory Listings & Profiles</li>
                      <li>Strategic Branding & Positioning</li>
                      <li>Targeted Exposure for Established Cafes</li>
                      <li>Real-Time Startup Exposure</li>
                      <li>Complimentary Marketing Support</li>
                      <li>Increased Customer Visibility</li>
                      <li>Effective Event Promotion</li>
                      <li>Positive Review Enhancement</li>
                    </ul>
                  </Col>

                  <Col md={6} lg={6} sm={12}>
                    <h5>Mentorship </h5>
                    <ul>
                      <li>Accredited Business Coaching</li>
                      <li>Leading Hospitality Experts</li>
                      <li>Vital Business Management</li>
                      <li>80+ Areas of Mentorship</li>
                      <li>Creative & Analytical Thinking</li>
                      <li>Financial Literacy Coaching</li>
                      <li>Crisis Management & Adaptability</li>
                      <li>Effective Leadership Strategies</li>
                    </ul>
                  </Col>

                  <Col md={6} lg={6} sm={12}>
                    <h5>Expansion & Multisite </h5>
                    <ul>
                      <li>Financial Planning & Budgeting</li>
                      <li>Scalability & Risk Management</li>
                      <li>Supply Chain Optimisation</li>
                      <li>Advanced Technology Integration</li>
                      <li>Operational Efficiency Assessment</li>
                      <li>Evaluating Adaptability</li>
                      <li>Advanced Training & Development</li>
                      <li>Market Research & Analysis</li>
                      <li>Marketing Strategy Development</li>
                      <li>Competitive Benchmarking</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Menu Development</h5>
                    <ul>
                      <li>Pricing, Profitability & Productivity</li>
                      <li>Data-Driven Decision Alignment</li>
                      <li>COGS Reduction</li>
                      <li>Menu Development & Engineering</li>
                      <li>Size, Variety, Seasonality & Simplicity</li>
                      <li>Branding, Positioning & Crafting Specials</li>
                      <li>Creating Catering Menus</li>
                      <li>Executive Chef Mentorship</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Automated Industry Networking </h5>
                    <ul>
                      <li>24/7 Automated Ecosystem Development</li>
                      <li>Enhanced Industry Education & Exposure</li>
                      <li>Foodservice / Trades & Services Partners</li>
                      <li>Direct Communication & Account Applications</li>
                      <li>Detailed Profiles & Comprehensive Support</li>

                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Development Materials</h5>
                    <ul>
                      <li>Visual Learning Support</li>
                      <li>Member Only Resources</li>
                      <li>Operational Templates</li>
                      <li>Editable Workbooks</li>
                      <li>Editable Checklists</li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </section>
      )}

      {isFoodservice && (
        <section className="explore-features explore-features-trade">
          <Row>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="explore-all-features">
                <div className="explore-all-table">
                  <h5>Explore all features</h5>
                  <p className="tax-deductiblle-cs">(Tax Deductible!)</p>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="feature-head">
                          Features
                        </th>

                        <th scope="col" className="pro-head">
                          <b>$15</b>/ month
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Real-Time Professional Directory
                          Listing
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Ecosystem integration
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          24/7 relevant connections
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Automated networking
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Local & national market reach
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Strengthen online presence
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Review boosting
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Media uploads
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Story sharing
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Brand awareness
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Service showcase
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Event promotion
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Direct account links
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <Row>
                    <Col md={12} lg={12} sm={12}>
                      <Link to="/food-service-signup">
                        {" "}
                        <button className="register-btn-btm">
                          Register Now
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="more-for-you">
                <h4>Business Plans. Menu Development. Networking. Mentorship. </h4><h4>Systems.</h4>
                <Row>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Cafe Account Application </h5>
                    <ul>
                      <li>direct links for account applications</li>
                      <li>effective hospitality mentorship</li>
                      <li>enhanced financial literacy and sustainability</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Review Boosting</h5>
                    <ul>
                      <li>ecosystem recognition feature</li>
                      <li>direct links to review platforms</li>
                      <li>strengthened online presence</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Directory & Ecosystem</h5>
                    <ul>
                      <li>make a powerful introduction</li>
                      <li>professional business listings</li>
                      <li>showcase your brand and tell your story</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Customer Education</h5>
                    <ul>
                      <li>upload various content types</li>
                      <li>educate cafe members</li>
                      <li>showcase services and promotions</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Easy Networking</h5>
                    <ul>
                      <li>seamless ecosystem integration</li>
                      <li>24/7 relevant connections</li>
                      <li>automated criteria-based networking</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Event Promotion </h5>
                    <ul>
                      <li>online and in-person event sharing</li>
                      <li>streamlined event promotion</li>
                      <li>targeted audience engagement</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Marketing & Exposure</h5>
                    <ul>
                      <li>
                        boost your visibility across both local and national
                        markets
                      </li>
                      <li>engage with cafe entrepreneurs at crucial times</li>
                      <li>
                        enhance your online presence and build industry
                        connections
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </section>
      )}

      {isTradeservice && (
        <section className="explore-features explore-features-trade">
          <Row>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="explore-all-features">
                <div className="explore-all-table">
                  <h5>Explore all features</h5>
                  <p className="tax-deductiblle-cs">(Tax Deductible!)</p>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col" className="feature-head">
                          Features
                        </th>

                        <th scope="col" className="pro-head">
                          <b>$15</b>/ month
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Professional directory listing
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Ecosystem integration
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          24/7 relevant connections
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Automated networking
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Local & national market reach
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Strengthen online presence
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Review boosting
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Media uploads
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Story sharing
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Brand awareness
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>{" "}
                          Service showcase
                        </td>
                        <td className="second-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Event promotion
                        </td>
                      </tr>
                      <tr>
                        <td className="first-col">
                          <span>
                            <img src={TickPrimaryIcon} alt="TickPrimaryIcon" />{" "}
                          </span>
                          Direct account links
                        </td>
                        <td></td>
                      </tr>
                    </tbody>
                  </table>
                  <Row>
                    <Col md={12} lg={12} sm={12}>
                      <Link to="/trade-service-signup">
                        {" "}
                        <button className="register-btn-btm">
                          Register Now
                        </button>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col md={6} lg={6} sm={12} className="m-0 p-0">
              <div className="more-for-you">
                <h4>Business Plans. Menu Development. Networking. Mentorship. Systems.</h4>
                <Row>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Service Account Application </h5>
                    <ul>
                      <li>direct links for account applications</li>
                      <li>effective hospitality mentorship</li>
                      <li>enhanced financial literacy and sustainability</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Review Boosting</h5>
                    <ul>
                      <li>ecosystem recognition feature</li>
                      <li>direct links to review platforms</li>
                      <li>strengthened online presence</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Directory & Ecosystem</h5>
                    <ul>
                      <li>make a powerful introduction</li>
                      <li>professional business listings</li>
                      <li>showcase your brand and tell your story</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Customer Education</h5>
                    <ul>
                      <li>upload various content types</li>
                      <li>educate cafe members</li>
                      <li>showcase services and promotions</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Easy Networking</h5>
                    <ul>
                      <li>seamless ecosystem integration</li>
                      <li>24/7 relevant connections</li>
                      <li>automated criteria-based networking</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Event Promotion </h5>
                    <ul>
                      <li>online and in-person event sharing</li>
                      <li>streamlined event promotion</li>
                      <li>targeted audience engagement</li>
                    </ul>
                  </Col>
                  <Col md={6} lg={6} sm={12}>
                    <h5>Marketing & Exposure</h5>
                    <ul>
                      <li>
                        boost your visibility across both local and national
                        markets
                      </li>
                      <li>engage with cafe entrepreneurs at crucial times</li>
                      <li>
                        enhance your online presence and build industry
                        connections
                      </li>
                    </ul>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </section>
      )}
    </div>
  );
}

export default CommonSignUp;
