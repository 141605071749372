import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/Home.css";
import LeftImg from "../Assets/Images/landing_page_popup_img.jpg";
import { Link, useNavigate } from "react-router-dom";
import PartnerShipLogo from "../Assets/Images/partnership-logo.png"
import AssociationwithLogo from "../Assets/Images/square_img.png"
import HosposureLogo from "../Assets/Images/hosposure_logo.png"


function LandingPagePopup({ showLandingModal, handleCloseLandingModal }) {
    const [fromLandingPopup, setfromLandingPOpup] = useState(true)
    const navigate = useNavigate();
    const gotoContactPage = () => {
        navigate("/contact-us", { state: { fromLandingPopup } });
    };
    return (
        <Modal
            show={showLandingModal}
            onHide={handleCloseLandingModal}
            backdrop="true"
            dialogClassName="landing-page-modal"
        >
            <Modal.Header closeButton>
                {/* Optionally, you can include a title or leave it blank */}
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Col sm={12} md={6} lg={6}>
                        <div className="landing-popup-leftbg">
                            <img src={LeftImg} className="img-fluid" />
                        </div>
                    </Col>
                    <Col sm={12} md={6} lg={6} className="landing-page-right-sec">
                        <div>
                            <h3>Specialist Programs for Cafe Members & Their Ecosystem</h3>
                            <p className="font-wght-semibold">Starting January 2025!</p>
                            <p>Whether you're launching or growing your cafe, our exclusive members-only programs are always on, delivering strategies that drive real results.</p>
                            <h6><b>Who’s it for?</b></h6>
                            <ul>
                                <li><b>For Startups:</b> Learn what it takes to launch successfully, avoid common pitfalls, and reduce risks.</li>
                                <li><b>For Established Cafes:</b> Discover proven methods to boost profitability, work smarter, and streamline your menu and operations.</li>
                                <li><b>For Trades & Foodservice Providers:</b> Join relevant sessions to collaborate with cafe operators and showcase your products, services, and expertise.</li>
                            </ul>
                            <p>All sessions are open to attendees across the ecosystem, providing valuable insights and connections for everyone.</p>
                            <p><b>Submit your details via our contact page today to receive the full lineup of sessions and secure your spot. Spaces are limited</b></p>
                            <button className="btn primary-button" onClick={gotoContactPage}>Take Me There!</button>
                        </div>

                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <div className="d-flex partner-logos">
                    <a href="https://www.rca.asn.au/" target="_blank">   <div className="d-flex logo-items">
                        <h6 className="p-0"> Preferred Payments & POS Partner</h6>
                        <img src={AssociationwithLogo} width={60} height={60} className="mob-mb-30" />
                    </div></a>
                    <a href="https://squareup.com/au/en/point-of-sale/coffee-shop" target="_blank"> <div className="d-flex logo-items">
                        <h6>In Partnership With</h6>
                        <img src={PartnerShipLogo} width={70} height={70} className="mob-mb-30" />
                    </div></a>
                    <a href="https://hosposure.com.au/" target="_blank"><div className="d-flex logo-items">
                        <h6>Business Modelling Partner </h6>
                        <img src={HosposureLogo} width={70} height={70} />
                    </div></a>
                </div>

            </Modal.Footer>
        </Modal>
    )
}

export default LandingPagePopup