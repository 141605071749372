import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import CreditCard from "../../../Assets/Images/credit-card.png";
import CSVIcon from "../../../Assets/Images/csv-icon.svg";
import CreditCardBands from "../../../Assets/Images/creditCardBrands.png";
import { useRegisterPaymentSubmitMutation } from "../../../Services/FoodserviceAPI";

import {
  useGetRefreshTokenMutation
} from "../../../Services/CommonAPI"

function PaymentForm({ regBranchId }) {
  const navigate = useNavigate();
  const stripe = useStripe();
  const elements = useElements();
 
  // 
  const [errorMessage, setErrorMessage] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useRegisterPaymentSubmitMutation();
  const [formValid, setFormValid] = useState(false);
  // 
  const [disableButton, setDisableButton] = useState(false);
  const handleSubmit = async (event) => {
    setDisableButton(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Handle payment submission
    if (cardNumberError || expiryError || cvcError) {
      setErrorMessage("Please fix the errors in the form before submitting.");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {
  

      const branchid = localStorage.getItem('fdservicebranchId');
   
      const postData = {
        branch_id: regBranchId,
        payment_method_id: paymentMethod.id,
      }
   
      try {
        const response = await submitForm(postData).unwrap();
     
        if (response.requires_action) {
          // Step 4: Confirm the PaymentIntent with 3D Secure
          const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
            response.payment_intent_client_secret
          );
    
          if (confirmError) {
            setDisableButton(false);
            return;
          }
    
          const timer = setTimeout(() => {
            navigate("/food-service-signupaccountactivation", {
              state: {regBranchId },
            });
          }, 3000);
        } else {
          
          const timer = setTimeout(() => {
            navigate("/food-service-signupaccountactivation", {
              state: {regBranchId },
            });
          }, 3000);
        }

      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  const handleCardNumberChange = (event) => {
    if (event.error) {
      setCardNumberError(event.error.message);
    } else {
      setCardNumberError("");
    }
  };

  const handleExpiryChange = (event) => {
    if (event.error) {
      setExpiryError(event.error.message);
    } else {
      setExpiryError("");
    }
  };

  const handleCvcChange = (event) => {
    if (event.error) {
      setCvcError(event.error.message);
    } else {
      setCvcError("");
      setFormValid(true);
    }
  };

  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
    useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")

  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {
       
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {
 
    if (isErrorRefreshToken) {
      navigate("/food-login");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  return (
    <form onSubmit={handleSubmit} className="payment-form">
      <h4>Payment method</h4>
      <img src={CreditCard} alt="CreditCard" />
      <div className="stripe-form-field">
        <label style={{ display: "block", marginBottom: "0.5rem" }}>
          Card Number
        </label>
        <img
          src={CreditCardBands}
          alt="CreditCardBands"
          className="creditcard-bands"
        />
        <CardNumberElement
          className="stripe-card-element"
          onChange={handleCardNumberChange}
          options={{
            style: {
              base: {
                fontSize: "20px",
                color: "#424770",
                border: "1px solid #ccc",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        {cardNumberError && <p className="error">{cardNumberError}</p>}
      </div>
      <div className="d-flex">
        <div className="stripe-form-field w-50 mr-10">
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            Expiry Date
          </label>
          <CardExpiryElement
            className="stripe-card-element"
            onChange={handleExpiryChange}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  border: "1px solid #ccc",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {expiryError && <p className="error">{expiryError}</p>}
        </div>

        <div className="stripe-form-field w-50 ml-10">
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            CVC
          </label>
          <img src={CSVIcon} alt="csv-icon" className="csv-icon" />
          <CardCvcElement
            className="stripe-card-element"
            onChange={handleCvcChange}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  border: "1px solid #ccc",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {cvcError && <p className="error">{cvcError}</p>}
        </div>
      </div>

      {/* <Link to="/food-service-signupaccountactivation">  */}
      {/* <button type="submit" disabled={!stripe} className="payment-submit-btn">
          Proceed to payment
        </button> */}
      {/* </Link> */}
      <button type="submit" disabled={disableButton} className={`payment-submit-btn ${formValid ? "btn-primary-valid-food" : ""
        }`}>
        Proceed to payment
      </button>
      <p>
        SSL Encrypted payment <br />
        Your information is protected by 256-bit SSL encryption.
      </p>

      {isError && (
        <p style={{ color: "red" }}>
          Error: {error?.data?.message || "Failed to submit form"}
        </p>
      )}
      {/* {isSuccess &&  (
        <p style={{ color: "green" }} className="isSuceesMsg">
          Payment Successful!
        </p>
      )} */}
      {isLoading && <div class="loader-food"></div>}
    </form>
  );
}

export default PaymentForm;
