import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/SignUp.css";
import "../Styles/VerifyOtp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ElipseIMg from "../../../Assets/Images/trade-service-elispse.svg";
import CrossIcon from "../../../Assets/Images/cross-icon.svg";
import TickIcon from "../../../Assets/Images/tick-icon-green.svg";
import ExploreMoreBtmImg from "../../../Assets/Images/explore-more-bottom-img.png";
import CrownStar from "../../../Assets/Images/crown-star-lite.svg";
import AlertImg from "../../../Assets/Images/contact-alert.svg";
import WhiteAlertImg from "../../../Assets/Images/alert-white.svg";
import BackIcon from "../../../Assets/Images/Back-icon.svg";
import ProcuctTypes from "./ProductTypes";
import OtpInput from "react-otp-input";
import {
  useVerifyRegisterOTPMutation,
  useResendRegisterOTPMutation,
} from "../../../Services/TradeserviceAPI";

import { useGettsAmountQuery } from "../../../Services/CommonAPI"

const maskEmail = (email) => {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart =
    localPart.slice(0, 2) + "*".repeat(localPart.length - 2);
  return `${maskedLocalPart}@${domain}`;
};

function CcOwnerSignUp() {
  const [activeTab, setActiveTab] = useState(0);
  const [showProductTypes, setshowProductTypes] = useState(false);
  const [code, setCode] = useState("");

  const { data: propricets } = useGettsAmountQuery();

  const handleChange = (code) => {
   
    setCode(code);
  };
  const handleMouseEnter = () => {
    setshowProductTypes(true);
  };
  const handlecloseProductModal = () => {
    setshowProductTypes(false);
  };
  const location = useLocation();
  const { id, email, subscription_type, branchId, onboarding_option } = location.state || {};
  const maskedEmail = email ? maskEmail(email) : "";
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [submitForm, { isLoading, isError, isSuccess, error:submitError }] =
    useVerifyRegisterOTPMutation();
  const [submitResendOtp, {}] = useResendRegisterOTPMutation();
  const [formValid, setFormValid] = useState(false);

  useEffect(() => {
    if (code.length == 4) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [code]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (code.length < 4) {
      setError("Please enter a 4-digit OTP.");
      return;
    }

    let Postata = {
      otp: code,
    };

    try {
   
      const response = await submitForm({ Postata, id }).unwrap();

      localStorage.setItem("accessToken", response.access);
      localStorage.setItem("refreshToken", response.refresh);

      const token = response.access;
      const payload = token.split(".")[1];
      const decodedPayload = JSON.parse(atob(payload));
      const userId = decodedPayload.user_id;

      localStorage.setItem("accountHolderId", userId);
  
      const regBranchId = response?.branch?.id;

      const timer = setTimeout(() => {
        navigate("/trade-service-verificationcomplete", {
          state: { id, email, subscription_type, regBranchId, onboarding_option },
        });
      }, 3000);
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  };

  const handleResenOTP = async (e) => {
    e.preventDefault();

    try {
      const response = await submitResendOtp({ id }).unwrap(); // Await the response
      if (response) {
        alert("The New OTP is send to yor mail id");
      }
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  };

  const tabs = [
    {
      label: "",
      heading: "Free Marketing & Exposure",
      content: (
        <ul>
          <li>Premium Directory Listings & Profiles</li>
          <li>Strategic Branding & Positioning</li>
          <li>Targeted Exposure for Established Cafes</li>
          <li>Real-Time Startup Exposure</li>
          <li>Complimentary Marketing Support</li>
          <li>Increased Customer Visibility</li>
          <li>Effective Event Promotion</li>
          <li>Positive Review Enhancement</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Automated Industry Networking ",
      content: (
        <ul>
          <li>24/7 Automated Ecosystem Development</li>
          <li>Enhanced Industry Education & Exposure</li>
          <li>Foodservice / Trades & Services Partners</li>
          <li>Direct Communication & Account Applications</li>
          <li>Detailed Profiles & Comprehensive Support</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Expansion & Multisite ",
      content: (
        <ul>
          <li>Financial Planning & Budgeting</li>
          <li>Scalability & Risk Management</li>
          <li>Supply Chain Optimisation</li>
          <li>Advanced Technology Integration</li>
          <li>Operational Efficiency Assessment</li>
          <li>Evaluating Adaptability</li>
          <li>Advanced Training & Development</li>
          <li>Market Research & Analysis</li>
          <li>Marketing Strategy Development</li>
          <li>Competitive Benchmarking</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Start-ups & Incubation",
      content: (
        <ul>
          <li>Early Industry & Public Visibility</li>
          <li>Preliminary Budgeting & Business Planning</li>
          <li>Initial Branding, Marketing & Menu Drafting</li>
          <li>Action Planning & Financial Modelling</li>
          <li>Expert Guidance & Industry Tools</li>
          <li>Accessible Products & Services</li>
          <li>Build Your Own Supportive Ecosystem</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Mentorship",
      content: (
        <ul>
          <li>Accredited Business Coaching</li>
          <li>Leading Hospitality Experts</li>
          <li>Vital Business Management</li>
          <li>80+ Areas of Mentorship</li>
          <li>Creative & Analytical Thinking</li>
          <li>Financial Literacy Coaching</li>
          <li>Crisis Management & Adaptability</li>
          <li>Effective Leadership Strategies</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Menu Development",
      content: (
        <ul>
          <li>Pricing, Profitability & Productivity</li>
          <li>Data-Driven Decision Alignment</li>
          <li>COGS Reduction</li>
          <li>Menu Development & Engineering</li>
          <li>Size, Variety, Seasonality & Simplicity</li>
          <li>Branding, Positioning & Crafting Specials</li>
          <li>Creating Catering Menus</li>
          <li>Executive Chef Mentorship</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Development Materials",
      content: (
        <ul>
          <li>Visual Learning Support</li>
          <li>Member Only Resources</li>
          <li>Operational Templates</li>
          <li>Editable Workbooks</li>
          <li>Editable Checklists</li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length); // Change to the next tab
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  return (
    <div className="td-owner-signup td-verify-otp">
      <section className="section">
        <Row>
          <Col lg={4} md={4} sm={12} xs={12}>
            <div className="image-div">
              <h3>
                <img src={ElipseIMg} alt="ElipseIMg" />
                <span> Trades & services</span>
                <img
                  src={WhiteAlertImg}
                  alt={WhiteAlertImg}
                  className="services-indication-icon"
                  onMouseEnter={handleMouseEnter}
                />
              </h3>
              <p className="sub-text">
                <span>${propricets?.amount} </span>/state monthly
              </p>
              <div className="features-box">
                <h5>Features</h5>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Professional directory listing
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Ecosystem integration
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  24/7 relevant connections
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Automated networking
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Local & national market reach
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Strengthen online presence
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Review boosting
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Media uploads
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Story sharing
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Brand awareness
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Service showcase
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Event promotion
                </p>
                <p>
                  <img src={TickIcon} alt="TickIcon" />
                  Direct account links
                </p>
              </div>
            </div>
          </Col>
          <Col lg={8} md={8} sm={12} xs={12}>
            <div className="form-div">
              <h3>Verify your email using OTP</h3>
              <p>
                We have sent an OTP to <span>{maskedEmail || email}</span>
              </p>
              <p>
                Didn't receive the email? Check your spam or promotions folder
                or <span onClick={handleResenOTP} className="resend-otp">[Resend]</span>
              </p>
              <form onSubmit={handleSubmit}>
                <OtpInput
                  value={code}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  renderInput={(props) => <input {...props} />}
                  className="otp-input"
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "12px",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "blue",
                    marginRight: "10px",
                    backgroundColor: "#F1F1F1",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                />
                <button
                  className={`verify-btn mt-40 ${
                    formValid ? " btn-primary-valid-trade" : ""
                  }`}
                >
                  Verify
                </button>
                {isError && (
                  <p style={{ color: "red" }} className="mt-20">
                  {submitError?.data?.error || "Failed to submit form"}
                  </p>
                )}
                {isSuccess && (
                  <p style={{ color: "green" }} className="isSuceesMsg">
                    Form submitted successfully!
                  </p>
                )}
                {isLoading && <div class="loader-trade"></div>}
              </form>
            </div>
          </Col>
        </Row>
        {showProductTypes && (
          <ProcuctTypes handlecloseProductTypeModal={handlecloseProductModal} />
        )}
      </section>
    </div>
  );
}

export default CcOwnerSignUp;
