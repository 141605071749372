import React, { useState, useEffect, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import AlertImg from "../Assets/Images/contact-alert.svg";
import validator from "validator";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useBranchEditWithAccountHolderIdMutation,
  useGetStatesQuery,
  useGetBranchDatawithAccountHolderIdQuery,
  useAddMultipleBranchMutation,
  useFlagbranchAddWithAccountHolderIdMutation,
  useGetFlagshipBranchesQuery,
  useEditFlagshipBranchMutation,
  useGetBranchListWithoutFlagshipQuery
} from "../Services/CcownerAPI";
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import Swal from 'sweetalert2';
const libraries = ["places"];

function BusinessDetails({ data, onNext, handlePrevious, regBranchId, BranchId }) {
  const currentBranchID = localStorage.getItem("branchId_current_selected");
  const location = useLocation();
  const { showcreateList, locationId } = location.state || {};
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState(data || {});
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [branchId, setBranchId] = useState("")

  useEffect(() => {
    if (regBranchId) {
      setBranchId(regBranchId);
    }
    else if (currentBranchID) {
      setBranchId(currentBranchID);
    }
    else {
      setBranchId(BranchId);
    }
  }, [regBranchId, BranchId, currentBranchID]);

  const accountHolderId = localStorage.getItem("accountHolderId");


  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useBranchEditWithAccountHolderIdMutation();

  const [submitFlagshipForm] = useFlagbranchAddWithAccountHolderIdMutation();
  const [editFlagshipForm] = useEditFlagshipBranchMutation();

  const { data: statesData } = useGetStatesQuery();
  const { data: getflagshipdetail, refetch: refetchFlagshipData } = useGetFlagshipBranchesQuery();
  const {data:otherlocationData, refetch:refetchOtherLOcationData} = useGetBranchListWithoutFlagshipQuery();


  useEffect(()=>{
if(otherlocationData){
  console.log("otherlocationData", otherlocationData);
}
  }, [otherlocationData])
  const [flagshipData, setflagshipData] = useState({
    location: "",
    address: "",
  });

  const [alllocationData, setOtherLOcationData] = useState({
    location: "",
    address: "",
  });

  useEffect(() => {
    if (getflagshipdetail && getflagshipdetail.length > 0) {
      const flagshipDetail = getflagshipdetail[0];
      setflagshipData({
        location: flagshipDetail.location || "",
        address: flagshipDetail.address || "",
      });
    }
  }, [getflagshipdetail]);

  // useEffect(() => {
  //   if (otherlocationData && Array.isArray(otherlocationData)) {
  //     setLocations(
  //       otherlocationData.map((loc) => ({
  //         location: loc.location || "",
  //         address: loc.address || "",
  //       }))
  //     );
  //   }
  // }, [otherlocationData]);


  useEffect(() => {
    refetchFlagshipData();
  }, []);


  const onflagLocationChanged = () => {
    const places = searchBoxflagRef.current.getPlaces();
    if (!places || places.length === 0) return;

    const place = places[0];
    const address = "";
    const locationName =place.formatted_address || "";

    // Extract and round latitude and longitude
    let lat = null,
      lng = null;
    if (place.geometry && place.geometry.location) {
      lat = parseFloat(place.geometry.location.lat().toFixed(6));
      lng = parseFloat(place.geometry.location.lng().toFixed(6));
    }

    setflagshipData((prev) => ({
      ...prev,
      location: locationName,
      address,
      latitude: lat,
      longitude: lng,
      flagship: true
    }));
  };


  const [addBranchForm, { }] = useAddMultipleBranchMutation();
  const [locations, setLocations] = useState([]);
  const [flaglocations, setFlagLocations] = useState([]);


  const handleSelectChange = (event) => {
    const selectedLocation = event.target.value;

    if (selectedLocation !== "") {
      setLocations([...locations, { location: selectedLocation, address: "" }]);
    }
  };

  const handleLocationChange = (index, event) => {
    const newLocations = [...locations];
    newLocations[index].location = event.target.value;
    setLocations(newLocations);
  };

  const handleAddressChange = (index, event) => {
    const newLocations = [...locations];
    newLocations[index].address = event.target.value;
    setLocations(newLocations);
  };

  const { data: branchData, refetch } =
    useGetBranchDatawithAccountHolderIdQuery(branchId);

  useEffect(() => {
    refetch();
    if (showcreateList) {
      setFormData({
        businessName: branchData?.business_name || "",
        abn: branchData?.abn || "",
        email: branchData?.email || "",
        contactNumber: branchData?.contact_number || "",
        state: branchData?.state || "",
        postCode: branchData?.post_code || "",
        address: branchData?.address || "",
        location: branchData?.location || "",
        weburl: branchData?.weburl || "",
        instagram: branchData?.instagram || "",
        facebook: branchData?.facebook || "",
        linkedin: branchData?.linkedin || "",
        twitter: branchData?.twitter || "",
        tiktok: branchData?.tiktok || "",
        youtube: branchData?.youtube || "",
      });
      setBranchId(locationId);
    } else {
      setFormData({
        businessName: branchData?.business_name || "",
        abn: branchData?.abn || "",
        email: branchData?.email || "",
        contactNumber: branchData?.contact_number || "",
        state: branchData?.state || "",
        postCode: branchData?.post_code || "",
        address: branchData?.address || "",
        location: branchData?.location || "",
        weburl: branchData?.weburl || "",
        instagram: branchData?.instagram || "",
        facebook: branchData?.facebook || "",
        linkedin: branchData?.linkedin || "",
        twitter: branchData?.twitter || "",
        tiktok: branchData?.tiktok || "",
        youtube: branchData?.youtube || "",
      });

      if (regBranchId) {
        setBranchId(regBranchId);
      }
      if (BranchId) {
        setBranchId(BranchId);
      }

    }
    setIsChecked(branchData?.flagship);
    localStorage.setItem("subscription_type", branchData?.subscription_type);
  }, [branchData, showcreateList, locationId]);



  // useEffect(() => {
  //   if (branchData) {
  //     setFormData({
  //       businessName: branchData.business_name || "",
  //       abn: branchData.abn || "",
  //       email: branchData.email || "",
  //       contactNumber: branchData.contact_number || "",
  //       state: branchData.state || "",
  //       postCode: branchData.post_code || "",
  //       address: branchData.address || "",
  //       location: branchData.location || "",
  //       weburl: branchData.weburl || "",
  //       instagram: branchData.instagram || "",
  //       facebook: branchData.facebook || "",
  //       linkedin: branchData.linkedin || "",
  //       twitter: branchData.twitter || "",
  //       tiktok: branchData.tiktok || "",
  //     });
  //     setIsChecked(branchData.flagship);
  //   }
  // }, [branchData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  useEffect(() => {
    const {
      businessName,
      email,
      contactNumber,
      state,
      postCode,
      address,
      location,
    } = formData;

    if (
      businessName &&
      email &&
      contactNumber &&
      state &&
      postCode &&
      address &&
      location
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.businessName)
      newErrors.businessName = "Business name is required.";
    // if (!formData.abn) newErrors.abn = "ABN is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.contactNumber)
      newErrors.contactNumber = "Contact number is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.postCode) newErrors.postCode = "Post code is required.";
    if (!formData.address) newErrors.address = "Address  is required.";
    if (!formData.location) newErrors.location = "Location  is required.";
    // if (!validator.isEmail(formData.email)) {
    //   newErrors.email = "Invalid email format.";
    // }
    return newErrors;
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();
  //   const validationErrors = validateForm();
  //   if (Object.keys(validationErrors).length === 0) {
  //     onNext(formData);
  //   } else {
  //     setErrors(validationErrors);
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validateForm()) {
      if(branchData?.location==null && mainBranchlat==""){
        Swal.fire({
          icon: 'error',
          text: 'Please choose a valid location from the suggestions.',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        });
        return;
      }

      let postdata = {};

      if (formData.businessName) postdata.business_name = formData.businessName;
      if (formData.abn) postdata.abn = formData.abn;
      if (formData.email) postdata.email = formData.email;
      if (formData.contactNumber)
        postdata.contact_number = formData.contactNumber;
      if (formData.state) postdata.state = formData.state;
      if (formData.address) postdata.address = formData.address;
      if (formData.postCode) postdata.post_code = formData.postCode;
      if (addressMain) postdata.location = addressMain;
      if (formData.weburl) postdata.weburl = formData.weburl;
      if (formData.instagram) postdata.instagram = formData.instagram;
      if (formData.facebook) postdata.facebook = formData.facebook;
      if (formData.linkedin) postdata.linkedin = formData.linkedin;
      if (formData.twitter) postdata.twitter = formData.twitter;
      if (formData.youtube) postdata.youtube = formData.youtube;
      if (formData.tiktok) postdata.tiktok = formData.tiktok;
      if (isChecked) postdata.flagship = isChecked;

      if (mainBranchlat) postdata.latitude = mainBranchlat;
      if (mainBranchlng) postdata.longitude = mainBranchlng;

      try {
        const response = await submitForm({ postdata, branchId }).unwrap();
        if (response) {
          onNext(response.id);
        }
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }

    if (!showcreateList && !isChecked) {
      try {
        const response = await submitFlagshipForm(flagshipData).unwrap();
        if (response) {
        }
      } catch (err) {
        console.error("Failed to submit form:", err);
        if (err.status == 400) {
          const flagshipid = getflagshipdetail[0]?.id;
          try {
            const response = await editFlagshipForm({ flagshipData, flagshipid }).unwrap();
            if (response) {
            }
          } catch (err) {
            console.error("Failed to submit form:", err);
          }
        }
      }
    }

    if (locations.length > 0) {
      try {
        const response = await addBranchForm(locations).unwrap();
        if (response) {
        }
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleBack = (e) => {
    handlePrevious();
  };

  const [address, setAddress] = useState("");
  const [addressMain, setMainAddress] = useState("");
  const [mainBranchlat, setMainbranchlat] = useState("");
  const [mainBranchlng, setMainbranchlng] = useState("");

  const searchBoxRef = useRef(null);
  const searchBoxMainRef = useRef(null);
  const searchBoxflagRef = useRef(null);

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_LOCATION,
    libraries,
  });

  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  // const onPlacesChanged = () => {
  //   const places = searchBoxRef.current.getPlaces();
  //   const place = places[0];

  //   if (place) {
  //     setAddress(place.formatted_address);
  //   }
  // };

  const onPlacesChanged = () => {
    const places = searchBoxRef.current.getPlaces();
    if (!places || places.length === 0) return;

    const place = places[0];
    const address = "";
    const locationName = place.formatted_address || "";

    // Check for duplicate addresses
    // const exists = locations.some((loc) => loc.address === address);
    // if (exists) {
    //   alert("Location already added");
    //   return;
    // }

    let lat = null,
      lng = null;
    if (place.geometry && place.geometry.location) {
      lat = parseFloat(place.geometry.location.lat().toFixed(6));
      lng = parseFloat(place.geometry.location.lng().toFixed(6));
    }

    setLocations([...locations, {
      location: locationName, address, latitude: lat,
      longitude: lng,
    }]);

  };

  const onMainPlacesChanged = () => {
    const places = searchBoxMainRef.current.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const lat = place.geometry.location.lat().toFixed(6);
      const lng = place.geometry.location.lng().toFixed(6);

      // Update the latitude and longitude
      setMainbranchlat(lat);
      setMainbranchlng(lng);

      // Update the address if place exists
      if (place) {
        const address = place.formatted_address; // Get the formatted address
        setMainAddress(address);

        // Update formData with the new location
        setFormData({
          ...formData,
          location: address, // Use 'address' for updating location
        });
      }
    }
  };
  return (
    <div className="add-details">
      <h4>Business Details</h4>
      <p>
        Complete directory listings amplify engagement. Ensure your business
        shines by filling in all criteria.
      </p>
      <hr />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Business name <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                placeholder="Enter your business name"
                name="businessName"
                value={formData.businessName || ""}
                onChange={handleInputChange}
                required
              />
              {errors.businessName && (
                <div className="error-message">{errors.businessName}</div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>ABN</label>
              <br />
              <input
                type="text"
                onChange={handleInputChange}
                value={formData.abn || ""}
                placeholder="12 345 678 910"
                name="abn"
                maxLength="11"

              />
              {/* {errors.abn && <div className="error-message">{errors.abn}</div>} */}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Email <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                onChange={handleInputChange}
                name="email"
                value={formData.email || ""}
                placeholder="abcd123@gmail.com"
                required
              />
              {errors.email && (
                <div className="error-message">{errors.email}</div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Contact Number <span className="red-star">*</span>
              </label>
              <br />
              <div style={{ display: 'flex', alignItems: 'center' }} className="phone-input">
              <span style={{ marginRight: '5px' }}>+61</span>
              <input
                type="text"
                onChange={handleInputChange}
                name="contactNumber"
                value={formData.contactNumber || ""}
                placeholder="XXXXXXXXXX"
                required
              />
              </div>
              {errors.contactNumber && (
                <div className="error-message">{errors.contactNumber}</div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Address <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                placeholder="Enter your address"
                name="address"
                value={formData.address || ""}
                onChange={handleInputChange}
                required
              />
              {errors.address && (
                <div className="error-message">{errors.address}</div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                State <span className="red-star">*</span>
              </label>
              <br />
              <select
                name="state"
                onChange={handleInputChange}
                value={formData.state || ""}
                required
              >
                <option>Select your state</option>
                {statesData?.map((state) => (
                  <option value={state.id}>{state.name}</option>
                ))}
              </select>
              {errors.state && (
                <div className="error-message">{errors.state}</div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Location <span className="red-star">*</span>
              </label>
              <br />
              <StandaloneSearchBox
                onLoad={(ref) => (searchBoxMainRef.current = ref)}
                onPlacesChanged={onMainPlacesChanged}
              >
                <input
                  type="text"
                  placeholder="Enter location"
                  className="autocomplete-input"
                  style={{ width: "100%", padding: "10px" }}
                  required
                  value={formData.location}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      location: e.target.value,
                    })
                  }
                />
              </StandaloneSearchBox>
              {errors.location && (
                <div className="error-message">{errors.location}</div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Post Code <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                name="postCode"
                placeholder="123 456"
                value={formData.postCode || ""}
                onChange={handleInputChange}
                required
                maxLength={8}
              />
              {errors.postCode && (
                <div className="error-message">{errors.postCode}</div>
              )}
            </div>
          </Col>
          {!showcreateList && <Col sm={6} xs={6} md={6} lg={6} className="d-flex mgtb">
            <input
              type="checkbox"
              className="checkbox"
              id="checkbox1"
              onChange={handleCheckboxChange}
              checked={isChecked}
              name="flagship"
            />
            <label class="form-check-labe save-falgship" for="checkbox1">
              Save this as your flagship
            </label>
          </Col>}
        </Row>
        {!isChecked && !showcreateList && (
          <>
            <h4>
              Flagship Location{" "}
              <div class="tooltip-common">
                <img src={AlertImg} alt="AlertImg" />
                <span class="tooltip-commontext">
                  Highlights your primary branch, whether you have one or more
                </span>
              </div>
            </h4>
            <Row>
              <Col xs={12} sm={12} lg={6} md={6}>
                <div className="form-control">
                  <label>
                    Location: <span className="red-star">*</span>
                  </label>
                  <br />
                  <StandaloneSearchBox
                    onLoad={(ref) => (searchBoxflagRef.current = ref)}
                    onPlacesChanged={onflagLocationChanged}
                  >
                    <input
                      type="text"
                      placeholder="Enter location"
                      className="autocomplete-input"
                      style={{ width: "100%", padding: "10px" }}
                      value={flagshipData.location}

                      onChange={(e) =>
                        setflagshipData({
                          ...flagshipData,
                          location: e.target.value,
                        })
                      }
                    />
                  </StandaloneSearchBox>
                </div>
              </Col>
              <Col xs={12} sm={12} lg={6} md={6}>
                <div className="form-control">
                  <label>
                    Address: <span className="red-star">*</span>
                  </label>
                  <br />
                  <input type="text" placeholder="Enter your address" onChange={(e) =>
                    setflagshipData({
                      ...flagshipData,
                      address: e.target.value,
                    })
                  } value={flagshipData.address} />
                </div>
              </Col>
            </Row>
          </>
        )}
        {!showcreateList && (
          <div>
            <h4>Other Location</h4>
            <Row>
              <Col xs={12} sm={12} lg={6} md={6}>
                <div className="form-control">
                  <label>Location</label>
                  <br />
                  {/* <select onChange={handleSelectChange}>
                    <option value="">Select other location</option>
                    <option value="Location 1">Location 1</option>
                    <option value="Location 2">Location 2</option>
                    <option value="Location 3">Location 3</option>
                  </select> */}
                  <StandaloneSearchBox
                    onLoad={(ref) => (searchBoxRef.current = ref)}
                    onPlacesChanged={onPlacesChanged}
                  >
                    <input
                      type="text"
                      placeholder="Enter location"
                      className="autocomplete-input"
                      style={{ width: "100%", padding: "10px" }}
                    />
                  </StandaloneSearchBox>
                </div>
              </Col>
            </Row>
          </div>
        )}

        {locations.map((loc, index) => (
          <Row key={index}>
            <Col xs={12} sm={12} lg={6} md={6}>
              <div className="form-control">
                <label>Location Name</label>
                <br />
                <input
                  type="text"
                  value={loc.location}
                  onChange={(e) => handleLocationChange(index, e)}
                  placeholder="Enter Location Name"
                  disabled
                />
              </div>
            </Col>
            <Col xs={12} sm={12} lg={6} md={6}>
              <div className="form-control">
                <label>Address</label>
                <br />
                <input
                  type="text"
                  value={loc.address}
                  onChange={(e) => handleAddressChange(index, e)}
                  placeholder="Enter Address"
                />
              </div>
            </Col>
          </Row>
        ))}
        <h4>Online Presence</h4>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>Web URL</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                onChange={handleInputChange}
                name="weburl"
                value={formData.weburl || ""}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>Instagram</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                onChange={handleInputChange}
                name="instagram"
                value={formData.instagram || ""}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>Facebook</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                name="facebook"
                onChange={handleInputChange}
                value={formData.facebook || ""}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>LinkedIn</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                name="linkedin"
                onChange={handleInputChange}
                value={formData.linkedin || ""}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>Twitter</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                name="twitter"
                onChange={handleInputChange}
                value={formData.twitter || ""}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>TikTok</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                name="tiktok"
                onChange={handleInputChange}
                value={formData.tiktok || ""}
              />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>YouTube</label>
              <br />
              <input
                type="url"
                placeholder="https//"
                name="youtube"
                onChange={handleInputChange}
                value={formData.youtube || ""}
              />
            </div>
          </Col>
        </Row>
        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button
              type="button"
              className="prev-btn btn-64t35ne"
              onClick={handleBack}
            >
              Back
            </button>
            <button
              type="submit"
              className={`next-btn btn-primary-disabled ${formValid ? "btn-primary-valid" : ""
                }`}
            >
              Next
            </button>
          </Col>
          {isError && (
            <p style={{ color: "red" }}>
              Error: {error?.data?.message || "Failed to submit form"}
            </p>
          )}
          {isSuccess && (
            <p style={{ color: "green" }} className="isSuceesMsg">
              Form submitted successfully!
            </p>
          )}
          {isLoading && <div class="loader"></div>}
        </Row>
      </form>
    </div>
  );
}

export default BusinessDetails;