import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/CcOwnerSignUp.css";
import "../Styles/VerifyOtp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ElipseIMg from "../Assets/Images/enterprenurship-elipse1.svg";
import CrossIcon from "../Assets/Images/cross-icon.svg";
import TickIcon from "../Assets/Images/tick-icon.svg";
import ExploreMoreBtmImg from "../Assets/Images/explore-more-bottom-img.png";
import CrownStar from "../Assets/Images/crown-star-lite.svg";
import AlertImg from "../Assets/Images/contact-alert.svg";
import OtpInput from "react-otp-input";
import CheckCircle from "../Assets/Images/check-circle.svg";
import "../Styles/CcOwnerVerificationComplete.css";

import WhiteAlertImg from "../Assets/Images/alert-white.svg";
import ProcuctTypes from "./ProductTypes";
import { useGethuddlesessionPriceforWebQuery } from "../Services/SecondaryAPI";
import {useCreateonBoardingAssistanceMutation} from "../Services/SupportAPI"
import {
  useGetRefreshTokenMutation
} from "../Services/CommonAPI"
import { useGetceAmountQuery } from "../Services/CommonAPI";

function CcOwnerSignUp() {
  const [activeTab, setActiveTab] = useState(0);
  const location = useLocation();
  const navigate = useNavigate();
  const { id, email, subscription_type, regBranchId, onboarding_option } = location.state || {};
  const [showLIteUser, setshowLiteUser] = useState(false);
  const { data: propricece } = useGetceAmountQuery();
  const [showProductTypes, setshowProductTypes] = useState(false);

  const [createTicket, { }] =
  useCreateonBoardingAssistanceMutation();

  const handleMouseEnter = () => {
    setshowProductTypes(true);
  };
  const handlecloseProductModal = () => {
    setshowProductTypes(false);
  }

  useEffect(() => {
    if (subscription_type === "lite") {
      setshowLiteUser(true);
    } else {
      setshowLiteUser(false);
    }
  }, [subscription_type]);

   const handleNavigateContinue =()=>{
   navigate("/ccowner-account-activation", {
      state: { regBranchId},
    });
   }
const handleNavigatePayment=()=>{
  navigate("/ccowner-payment", {
     state: { regBranchId},
   });
  }

  const [submitRefreshToken, { isSuccessRefreshToken, isError:isErrorRefreshToken }] =
  useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
 const [refreshtokenNew, setRefreshTokenNew] = useState("")
 const [gstAmount, setGstAmount] = useState(0);
 const [subtotal, setSubtotal] = useState(0);
 const {data:huddlePrice} = useGethuddlesessionPriceforWebQuery ();
  const handleSubmitRefreshToken = async () => {
  const  RefreshToken =localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(()=>{
    if(isErrorRefreshToken){
      navigate("/");
      localStorage.clear();
    }
    }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 10000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);



  const fetchData = async () => {
    
    try {
      let postData = {
        directory_listing_id : regBranchId
      }
      const response = await createTicket(postData).unwrap();
     
    } catch (error) {
     
    }
  };
  
  useEffect(() => {
    const timer = setTimeout(() => {
      if (onboarding_option) {
        fetchData();
      }
    }, 300);
    return () => clearTimeout(timer);
  }, [onboarding_option]);

  
  const tabs = [
    {
      label: "",
      heading: "Free Marketing & Exposure",
      content: (
        <ul>
          <li>Premium Directory Listings & Profiles</li>
          <li>Strategic Branding & Positioning</li>
          <li>Targeted Exposure for Established Cafes</li>
          <li>Real-Time Startup Exposure</li>
          <li>Complimentary Marketing Support</li>
          <li>Increased Customer Visibility</li>
          <li>Effective Event Promotion</li>
          <li>Positive Review Enhancement</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Automated Industry Networking ",
      content: (
        <ul>
          <li>24/7 Automated Ecosystem Development</li>
          <li>Enhanced Industry Education & Exposure</li>
          <li>Foodservice / Trades & Services Partners</li>
          <li>Direct Communication & Account Applications</li>
          <li>Detailed Profiles & Comprehensive Support</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Expansion & Multisite ",
      content: (
        <ul>
          <li>Financial Planning & Budgeting</li>
          <li>Scalability & Risk Management</li>
          <li>Supply Chain Optimisation</li>
          <li>Advanced Technology Integration</li>
          <li>Operational Efficiency Assessment</li>
          <li>Evaluating Adaptability</li>
          <li>Advanced Training & Development</li>
          <li>Market Research & Analysis</li>
          <li>Marketing Strategy Development</li>
          <li>Competitive Benchmarking</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Start-ups & Incubation",
      content: (
        <ul>
          <li>Early Industry & Public Visibility</li>
          <li>Preliminary Budgeting & Business Planning</li>
          <li>Initial Branding, Marketing & Menu Drafting</li>
          <li>Action Planning & Financial Modelling</li>
          <li>Expert Guidance & Industry Tools</li>
          <li>Accessible Products & Services</li>
          <li>Build Your Own Supportive Ecosystem</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Mentorship",
      content: (
        <ul>
          <li>Accredited Business Coaching</li>
          <li>Leading Hospitality Experts</li>
          <li>Vital Business Management</li>
          <li>80+ Areas of Mentorship</li>
          <li>Creative & Analytical Thinking</li>
          <li>Financial Literacy Coaching</li>
          <li>Crisis Management & Adaptability</li>
          <li>Effective Leadership Strategies</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Menu Development",
      content: (
        <ul>
          <li>Pricing, Profitability & Productivity</li>
          <li>Data-Driven Decision Alignment</li>
          <li>COGS Reduction</li>
          <li>Menu Development & Engineering</li>
          <li>Size, Variety, Seasonality & Simplicity</li>
          <li>Branding, Positioning & Crafting Specials</li>
          <li>Creating Catering Menus</li>
          <li>Executive Chef Mentorship</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Development Materials",
      content: (
        <ul>
          <li>Visual Learning Support</li>
          <li>Member Only Resources</li>
          <li>Operational Templates</li>
          <li>Editable Workbooks</li>
          <li>Editable Checklists</li>
        </ul>
      ),
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length); // Change to the next tab
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);
  return (
    <div className="cc-owner-signup verification-completed">
      <section className="section">
        <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
            <div className="image-div">
              <h3>
                <img src={ElipseIMg} alt="ElipseIMg" />
                Cafe Entrepreneurship
                {/* <img
                  src={WhiteAlertImg}
                  alt={WhiteAlertImg}
                  className="services-indication-icon"
                  onMouseEnter={handleMouseEnter}
                /> */}
              </h3>
              <p className="sub-text">
                Discover the Breakthrough You've Been Looking For with
                Australia's Premier Cafe Support Platform
              </p>
              <p className="sub-text">(Tax Deductible!)</p>
              <p>Enjoy 1 month of free access to all PRO-level features. After your trial, choose between 'LITE' (pay-as-you-go) or 'PRO' (all-inclusive). No commitment until you decide!
              </p>

              <div className="signup-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="align-middle">
                        Features
                      </th>
                      <th scope="col">
                        <p className="mrl_-60">
                          <b>Lite </b>Membership{" "}
                          <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" />
                            <span class="tooltip-commontext">
                            Ideal for essential support with flexible pay-as-you-go access to all services.
                            </span>
                          </div>
                          <br />
                          <span>
                            <b>$0</b>/ month
                          </span>{" "}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="mrl_20">
                          <b>Pro </b> Membership{" "}
                          <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" />
                            <span class="tooltip-commontext">
                            Top-tier support and a vibrant community at a low fixed monthly fee, delivering outstanding value.
                            </span>
                          </div>
                          <br />
                          <span>
                            <b>${propricece?.amount}</b>/ month
                          </span>{" "}
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Real-Time Professional Directory
                          Listing
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Marketing & Customer Growth
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Tailored Business Connections
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Communication with Industry Experts
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Practical Tools & Guides
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Unlimited Access to Specialist Courses
                        </p>
                      </td>
                      <td className="bold-txt text-center">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Unlimited Group Mentorship Sessions
                        </p>
                      </td>
                      <td className="bold-txt text-center">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> 1:1 Mentorship & Roadmapping
                        </p>
                      </td>
                      <td className="bold-txt text-center">$169.99</td>
                      <td className="bold-txt text-center">$79.99</td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Exclusive Discounts on Products & Services
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Priority Support When You Need It
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Industry Updates & Compliance Support
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                        <td >
                          <p className="d-flex"> <span>*</span> Unlimited Access to Online Expos & Events</p>
                         
                        </td>
                        <td className="text-center">
                          <img src={CrossIcon} />
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                      </tr>
                    <tr>
                        <td >
                        <p className="d-flex">  <span>*</span> Live Chat & Community Noticeboard </p>
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="explore-more">
                <h4>Explore more features for you</h4>
                <div className="tabs-container">
                  <div className="tabs">
                    {tabs.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? "active" : ""}`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.label}
                      </div>
                    ))}
                  </div>
                  <div className="tab-content">
                    <h3 className="tab-heading">{tabs[activeTab].heading}</h3>
                    {tabs[activeTab].content}
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="form-div">
              <div className="text-center">
                <img src={CheckCircle} alt="CheckCircle" />
                <p className="mt-30">Verification completed successfully</p>
              </div>
              {!showLIteUser && (
           
                  <button className="proceed-to-payment-btn mt-40" onClick={handleNavigatePayment}>
                    Proceed to payment
                  </button>
              
              )}
              {showLIteUser && (
          
                  <button className="proceed-to-payment-btn mt-40" onClick={handleNavigateContinue}>
                    Continue
                  </button>
                
              )}
            </div>
          </Col>
        </Row>
        {showProductTypes && (
          <ProcuctTypes handlecloseProductTypeModal={handlecloseProductModal} />
        )}
      </section>
    </div>
  );
}

export default CcOwnerSignUp;
