import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_ARTICLE_DEV_URL;

export const websiteapi = createApi({
  reducerPath: 'websiteapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitContactForm: builder.mutation({
      query: (newPost) => ({
        url: 'api/contact',
        method: 'POST',
        body: newPost,
      }),
    }),

    getArticleData: builder.query({
      query: () => `api/list-articles`,
    }),
    getStates:builder.query({
      query: () => "/common/states/",
    }),
    
  }),
});

export const {
  useSubmitContactFormMutation,
  useGetArticleDataQuery,
  useGetStatesQuery
} = websiteapi;
