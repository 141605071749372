import React from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/NoticeBoard.css";
import moment from 'moment';

const announementsListings = [
  {
    id: 1,
    title:
      "Carbonite web goalkeeper gloves are ergonomically designed to give easy fit",
    description:
      "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula.",
    publishDate: "10/02/2024",
  },
  {
    id: 2,
    title:
      "The beautiful range of Apple Naturalé that has an exciting mix of natural ingredients. With the Goodness of 100% Natural Ingredients",
    description:
      "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula.",
    publishDate: "10/03/2024",
  },
  {
    id: 2,
    title: "The Football Is Good For Training And Recreational Purposes",
    description:
"Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula.",
    publishDate: "10/03/2024",
  },
];
function formatDate(dateString) {
  return moment(dateString).format('DD/MM/YYYY');
}

function announementsListing({data}) {
  return (
    <div className="notice-board-card-listing">
   {data?.data.length >0 ? (data?.data?.map((announements) => (
        <div key={announements.id} className="card">
          <Row>
            <Col xs={12} sm={12} md={12} lg={12}>
              <h5>{announements.title}</h5>
              <p className="description">{announements.content}</p>
              <p className="publish-date">Added on {formatDate(announements?.created_at ?? "")}</p>
            </Col>
          </Row>
        </div>
    ))
  ):(<p>No data available</p>)}
    </div>
  );
}

export default announementsListing;
