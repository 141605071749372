// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/AddAnotherLocation.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/AddNewNotice.css"
import ArrowDownImg from "../Assets/Images/arrow-down-icon.svg"
import{useGetChatCategoriesQuery,useSaveChatTopicMutation} from "../Services/CommunityAPI"

const ModalComponent = ({ show, handlecloseModal }) => {
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    title: "",
    category: "",
    description: "",
  });
const {data:chatCategories}=useGetChatCategoriesQuery();
const [submitForm, { isLoading, isError, isSuccess, error }] =
useSaveChatTopicMutation();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.title) formErrors.title = "Title is required.";
    if (!formData.category) formErrors.category = "Category is required.";
    if (!formData.description) {
      formErrors.description = "Description is required.";
    } else {
      // Split the description into words and count them
      const wordCount = formData.description.trim().split(/\s+/).length;
      
      if (wordCount > 200) {
        formErrors.description = "Maximum allowed length is 200 words.";
      }
    }
    // }
    
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    const accountHolderId = localStorage.getItem("accountHolderId");
    const branchId = localStorage.getItem("branchId_current_selected");
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        topic:formData.title,
        chat_category_id:formData.category,
        directory_listing_id:branchId,
        description:formData.description,
        user_id:accountHolderId,
        subscription_type:"pro"
      }
      try {
        const response = await submitForm(postData).unwrap();
        setFormData({
          title: "",
          category: "",
          description: "",
        });
        
        const timer = setTimeout(() => {
          navigate("/chat-list");
          handlecloseModal();
        }, 3000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }

    }
  };
  const handlecloseaddnewtopicModal = () => {
    handlecloseModal();
  };


  return (
    <>
      <Modal
        show={show}
        onHide={handlecloseModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h2>Add Your Topic</h2>
          <p className="sub-head"> 
          All submissions are reviewed and approved before being published.
          </p>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>
                Topic <span className="red-star">*</span>
                </label><br />
                <input placeholder="Enter your topic" name="title" onChange={handleChange} />
                </div>
                {errors.title && (
                  <p className="error">{errors.title}</p>
                )}
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>
                Category <span className="red-star">*</span>
                </label><br />
                <img src={ArrowDownImg} alt="ArrowDownImg" className="arrow-down"/>
                <select name="category" onChange={handleChange}>
                    <option>Select your category</option>
                    {chatCategories?.data?.map((category)=>(
                         <option value={category.id}>{category.category_name}</option>
                    ))}
                </select>
                {errors.category && (
                  <p className="error">{errors.category}</p>
                )}
                </div>
              </Col>
          
              <Col xs={12} sm={12} md={12} lg={12}>
              <div className="form-control">
                <label>
                Description <span className="red-star">*</span>
                </label><br />
              <textarea rows={4} placeholder="Add your description" name="description" onChange={handleChange}>

              </textarea>
              <p className="max-word">Maximum of 200 words</p>
                </div>
                {errors.description && (
                  <p className="error">{errors.description}</p>
                )}
              </Col>
            </Row>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handlecloseaddnewtopicModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-save-primary">
                  Add
                </button>
              </Col>
              {isError && (
                    <p style={{ color: "red" }}>
                      Error: {error?.data?.error || "Failed to submit form"}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                      Form submitted successfully!
                    </p>
                  )}
                  {isLoading && <div class="loader"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
