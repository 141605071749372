import React from "react";
import "../Styles/HomeExploreCollabrator.css";
import CollabratorImg1 from "../Assets/Images/collabration-img-1.png";
import CollabratorImg2 from "../Assets/Images/collabration-img-2.png";

function HomeExploreCollabrator() {
  return (
    <section className="section home-explore-collabrator">
      <div className="collabrator-head-desk">
        <h3>Connect with Leading Food & Trade</h3>
        <h3>Service Providers 24/7</h3>
      </div>
      <div className="collabrator-head-mob">
        <h3>Connect with Leading Food & Trade Service Providers 24/7</h3>
      </div>

      <div className="explore-collbrator-logo">
        <img src={CollabratorImg1} className="img-respnsive scroll-back-and-forth" alt="Collaborator 1" />
      </div>


    </section>
  );
}

export default HomeExploreCollabrator;