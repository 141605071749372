import React from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/NoticeBoard.css";
import moment from "moment";
import { Link } from "react-router-dom";

const jobListings = [
  {
    id: 1,
    title: "Coffee Maker",
    category: "Category name",
    description:
      "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula.",
    publishDate: "10/02/2024",
  },
  {
    id: 2,
    title: "Coffee Maker",
    category: "Another Category",
    description:
      "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula.",
    publishDate: "10/03/2024",
  },
  {
    id: 2,
    title: "Coffee Maker",
    category: "Another Category",
    description:
      "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula.",
    publishDate: "10/03/2024",
  },
];
function formatDate(dateString) {
  return moment(dateString).format("DD/MM/YYYY");
}

function JobListing({ data }) {
  return (
    <div className="notice-board-card-listing">

      
  { data?.data?.length > 0 ?  (data?.data?.map((job) => (
          <div key={job.id} className="card">
            {/* <Row>
              <div className="d-flex">
              <img />
              </div>
            </Row> */}
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <h5>{job.title}</h5>
                <p className="category-name">{job.category}</p>
                <p className="description">{job.content}</p>
                <p className="publish-date">
                  Added on {formatDate(job?.created_at ?? "")}
                </p>

                <hr />
                <Link to={`/cafe-view/${job.directory_listing_id}`}>
                  <p className="view-directory-listing">
                    View directory listing
                  </p>
                </Link>
              </Col>
            </Row>
          </div>
        ))
      ):
      (<p>No data available</p>
    )}
    </div>
  );
}

export default JobListing;
