import React, { useState, useEffect } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import LoggedNavBar from  "./NavbarLoggedUser"

const Layout = ({ children }) => {
  const [accessToken, setAccessToken] = useState(null);

    useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);

  return (
    <div>
      
      {!accessToken &&   <Navbar />}
      {accessToken &&  <LoggedNavBar />}
      <div className="main">{children}</div>
      <Footer />
    </div>
  );
};

export default Layout;
