import React from "react";
import "../Styles/PrivacyPolicy.css"

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h3>Privacy Policy</h3>
      <p>
        This Privacy Policy applies to all personal information collected by
        Clever Cafe Company (ABN 30 659 337 578) via the website located at
        <a href="https://www.clevercafecompany.com.au/" target="_blank"> https://www.clevercafecompany.com.au/.</a>
      </p>
      <h5>1.What is “personal information”?</h5>
      <ul>
        <li>
          (a) The Privacy Act 1988 (Cth) currently defines “personal
          information” as meaning information or an opinion about an identified
          individual or an individual who is reasonably identifiable:
          <ul>
            <li>(i) whether the information or opinion is true or not; and</li>
            <li>
              (ii) whether the information or opinion is recorded in a material
              form or not.
            </li>
          </ul>
        </li>
        <li>
          (b) If the information does not disclose your identity or enable your
          identity to be ascertained, it will in most cases not be classified as
          “personal information” and will not be subject to this privacy policy.
        </li>
      </ul>
      <h5>2.What information do we collect?</h5>
      <p>
        The kind of personal information that we collect from you will depend on
        how you use the website. The personal information which we collect and
        hold about you may include: login credentials, payment details, email
        address, name, phone number and address.
      </p>
      <h5>3.How we collect your personal information</h5>
      <ul>
        <li>
          (a) We may collect personal information from you whenever you input
          such information into the website.
        </li>
        <li>
          (b) We also collect cookies from your computer which enable us to tell
          when you use the website and also to help customise your website
          experience. As a general rule, however, it is not possible to identify
          you personally from our use of cookies.
        </li>
      </ul>
      <h5>4.Purpose of collection</h5>
      <ul>
        <li>
          (a) The purpose for which we collect personal information is to
          provide you with the best service experience possible on the website.
        </li>
        <li>
          (b) We customarily disclose personal information only to our service
          providers who assist us in operating the website. Your personal
          information may also be exposed from time to time to maintenance and
          support personnel acting in the normal course of their duties.
        </li>
        <li>
          (c) By using our website, you consent to the receipt of direct
          marketing material. We will only use your personal information for
          this purpose if we have collected such information direct from you,
          and if it is material of a type which you would reasonably expect to
          receive from us. We do not use sensitive personal information in
          direct marketing activity. Our direct marketing material will include
          a simple means by which you can request not to receive further
          communications of this nature.
        </li>
      </ul>
      <h5>5.Access and correction</h5>
      <p>
        Australian Privacy Principle 12 permits you to obtain access to the
        personal information we hold about you in certain circumstances, and
        Australian Privacy Principle 13 allows you to correct inaccurate
        personal information subject to certain exceptions. If you would like to
        obtain such access, please contact us as set out below.
      </p>
      <h5>6.Complaint procedure</h5>
      <p>
        If you have a complaint concerning the manner in which we maintain the
        privacy of your personal information, please contact us as set out
        below. All complaints will be considered by the Privacy Officer and we
        may seek further information from you to clarify your concerns. If we
        agree that your complaint is well founded, we will, in consultation with
        you, take appropriate steps to rectify the problem. If you remain
        dissatisfied with the outcome, you may refer the matter to the Office of
        the Australian Information Commissioner.
      </p>
      <h5>7.How to contact us about privacy</h5>
      <p>
        If you have any queries, or if you seek access to your personal
        information, or if you have a complaint about our privacy practices, you
        can contact us through: <a href="mailto:hello@clevercafecompany.com.au">hello@clevercafecompany.com.au.</a>
      </p>
    </div>
  );
}

export default PrivacyPolicy;
