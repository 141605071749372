import React, { useState, useEffect } from "react";
import AccountHolderDetails from "../Components/AccountHolderDetails";
import BusinessDetails from "../Components/BusinessDetails";
import AdditionalDetails from "../Components/Additionaldetails";
import EcosystemCriteria from "../Components/EcosystemCriteria";
import CompletedImg from "../../../Assets/Images/trade-tab-complete.svg"
import "../Styles/AddAccountDetails.css";
import { useLocation } from "react-router-dom";

const MultiStepForm = () => {
  const [activeTab, setActiveTab] = useState(1); // Start at the first tab by default
  const [completedSteps, setCompletedSteps] = useState([
    false,
    false,
    false,
    false,
  ]);
  const [BranchId,  setBranchId] = useState("")
  const [formData, setFormData] = useState({
    accountHolderDetails: {},
    businessDetails: {},
    additionalDetails: {},
    ecosystemCriteria: {},
  });
  const location = useLocation();
  const { frompreviewpage, regBranchId,  CBranchId} = location.state || {};

  useEffect(() => {
    const savedCompletedSteps = localStorage.getItem("completedSteps");
    if (savedCompletedSteps) {
      setCompletedSteps(JSON.parse(savedCompletedSteps));
    }
  }, []);

  useEffect(() => {
    if (BranchId) {
      setBranchId(BranchId);
    } else {
      setBranchId(CBranchId);
    }
  }, [BranchId, CBranchId]);

  // Navigate to the Ecosystem Criteria tab if from preview page
  useEffect(() => {
    if (frompreviewpage) {
      setActiveTab(4);
    }
  }, [frompreviewpage]);

  // Save progress to localStorage after form update
  useEffect(() => {
    localStorage.setItem("completedSteps", JSON.stringify(completedSteps));
    localStorage.setItem("activeTab", activeTab);
  }, [completedSteps, activeTab]);

  const handleNext = (id) => {  
 
  setBranchId(id)
    setCompletedSteps((prev) => {
      const updated = [...prev];
      updated[activeTab - 1] = true;
      return updated;
    });
    setActiveTab((prev) => {
      const newTab = prev + 1;
      return newTab <= 4 ? newTab : prev;
    });
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(()=>{

  }, [BranchId])
  useEffect(()=>{
 
      }, [])
  const handlePrevious = () => {
    setActiveTab((prev) => Math.max(prev - 1, 1));
  };

  return (
    <div className="td-add-account-details">
      <h2>Manage Listing</h2>
      <p className="required-message-impoertant">Listings will not appear in the ecosystem until the business name and location address are correctly entered.</p>
      <div className="step-nav">
        {[1, 2, 3, 4].map((step) => (
          <div
            key={step}
            className={`step ${activeTab === step ? "active" : ""}`}
          >
            <p className="d-flex">
              <span className="circle">
                {completedSteps[step - 1] ? (<img src={CompletedImg} alt="CompletedImg" />)  : step}
              </span>
              <span className="label">
                {step === 1 && "Account Holder Details"}
                {step === 2 && "Business Details"}
                {step === 3 && "Additional Details"}
                {step === 4 && "Ecosystem Criteria"}
              </span>
            </p>
          </div>
        ))}
      </div>
      <div className="tabs">
        {activeTab === 1 && <AccountHolderDetails onNext={handleNext}  BranchId={BranchId}/>}
        {activeTab === 2 && (
          <BusinessDetails
            onNext={handleNext}
            handlePrevious={handlePrevious}
            regBranchId={regBranchId}
            BranchId={BranchId}
          />
        )}
        {activeTab === 3 && (
          <AdditionalDetails
            onNext={handleNext}
            handlePrevious={handlePrevious}
            BranchId={BranchId}
          />
        )}
        {activeTab === 4 && (
          <EcosystemCriteria
            onNext={handleNext}
            handlePrevious={handlePrevious}
            activeTab={activeTab}
            BranchId={BranchId}
          />
        )}
      </div>
    </div>
  );
};

export default MultiStepForm;
