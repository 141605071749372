import logo from "./logo.svg";
import "./App.css";
import Routes from "./Routes";
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-time-picker/dist/TimePicker.css';
import { Provider } from 'react-redux';
import { store } from './Store/Store';

function App() {
  return (
    <Provider store={store}>
    <div className="App">
      <Routes />
    </div>
    </Provider>
  );
}

export default App;
