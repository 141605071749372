import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/CcOwnerSignUp.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ElipseIMg from "../Assets/Images/enterprenurship-elipse1.svg";
import CrossIcon from "../Assets/Images/cross-icon.svg";
import TickIcon from "../Assets/Images/tick-icon.svg";
import ExploreMoreBtmImg from "../Assets/Images/explore-more-bottom-img.png";
import CrownStar from "../Assets/Images/crown-star.svg";
import AlertImg from "../Assets/Images/contact-alert.svg";
import BackIcon from "../Assets/Images/Back-icon.svg";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import validator from "validator";
import {
  useFreeTrialRegisterMutation,
  useGetStatesQuery,
} from "../Services/CcownerAPI";
import TermsandCondition from "../Assets/pdf/Termsandconditions.pdf"
import PrivacyPolicy from "../Assets/pdf/Privacypolicy.pdf"

import WhiteAlertImg from "../Assets/Images/alert-white.svg";
import ProcuctTypes from "./ProductTypes";
import { useGethuddlesessionPriceforWebQuery } from "../Services/SecondaryAPI";
import { useGetOperationalStatusQuery ,useGetceAmountQuery} from "../Services/CommonAPI";
import AlertBoxModalAlreadyLoggedIn from "../Components/AlertBoxAlreadySignedInMsg"

function CcOwnerSignUp() {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [formData, setFormData] = useState({
    confirmation_status: "",
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    state: "",
    postCode: "",
    contactNumber: "",
    userName: "",
    password: "",
    confirmPassword: "",
    agreeTerms: false,
    onboardingAssistance: false,
  });

  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useFreeTrialRegisterMutation();
  const { data: statesData } = useGetStatesQuery();
  const [operationalStatus, setOperationalStatus] = useState([]);
  const { data: operationalStatusData } = useGetOperationalStatusQuery();
  const {data:huddlePrice} = useGethuddlesessionPriceforWebQuery ();
  const { data: propricece } = useGetceAmountQuery();
  const [displayMessage, setDisplayMessage] = useState(
    "Pro Members Planning to Open can respond to ecosystem inquiries with hidden account holder details for admin use only."
  );

  const [formValid, setFormValid] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({
    planning: false,
    open: false,
    exiting: false,
  });

  const [showProductTypes, setshowProductTypes] = useState(false);

  const handleMouseEnter = () => {
    setshowProductTypes(true);
  };
  const handlecloseProductModal = () => {
    setshowProductTypes(false);
  };

  const handleChangeStatus = (e) => {
    const { value } = e.target;

    if (value === "planning") {
      setSelectedOptions((prevState) => {
        // Toggle the planning option
        const isPlanningChecked = !prevState.planning;

        return {
          planning: isPlanningChecked,
          open: false,
          exiting: false,
        };
      });

      setOperationalStatus((prevStatus) => {
        // If "Planning to open" is unchecked, reset operationalStatus
        return prevStatus.includes(1) ? [] : [1];
      });
    } else {
      setSelectedOptions((prevState) => {
        const updatedOptions = {
          ...prevState,
          planning: false,
          [value]: !prevState[value],
        };

        // Build the updated operationalStatus array
        const updatedStatus = [];
        if (updatedOptions.open) updatedStatus.push(2); // ID for 'Open for business'
        if (updatedOptions.exiting) updatedStatus.push(3); // ID for 'Looking to exit'

        setOperationalStatus(updatedStatus); // Update operationalStatus with the array

        return updatedOptions;
      });
    }

    // Clear any existing errors for the field
    const { name, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    // Update form data based on the checkbox
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;

    // Clear the error for the field being changed
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };
  useEffect(() => {
    const {
      confirmation_status,
      firstName,
      lastName,
      email,
      confirmEmail,
      state,
      postCode,
      contactNumber,
      userName,
      password,
      confirmPassword,
      agreeTerms,
    } = formData;

    if (
      confirmation_status &&
      firstName &&
      lastName &&
      email &&
      confirmEmail &&
      state &&
      postCode &&
      contactNumber &&
      userName &&
      password &&
      confirmPassword &&
      agreeTerms
    ) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);
  const handleKeyPressNumersOnly = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  const validateForm = () => {
    let formErrors = {};
    if (!formData.confirmation_status)
      formErrors.confirmation_status = "Operational status is required";
    if (!formData.firstName) {
      formErrors.firstName = "First name is required";
    } else {
      const regex = /^[a-zA-Z\s.]*$/;

      if (!regex.test(formData.firstName)) {
        formErrors.firstName =
          "First name  can only contain letters, dots, and spaces";
      } else if (formData.firstName.length > 50) {
        formErrors.firstName = "First name  cannot exceed 50 characters";
      }
    }

    if (!formData.lastName) {
      formErrors.lastName = "Last name is required";
    } else {
      const regex = /^[a-zA-Z\s.]*$/;

      if (!regex.test(formData.lastName)) {
        formErrors.lastName =
          "Last name can only contain letters, dots, and spaces";
      } else if (formData.lastName.length > 50) {
        formErrors.lastName = "Last name cannot exceed 50 characters";
      }
    }

    if (!formData.lastName) formErrors.lastName = "Last name is required";
    if (!formData.email) formErrors.email = "Email is required";
    if (formData.email !== formData.confirmEmail)
      formErrors.confirmEmail = "Emails do not match";
    if (!formData.state) formErrors.state = "State is required";
    if (!formData.postCode) formErrors.postCode = "Post Code is required";
    if (!formData.contactNumber) {
      formErrors.contactNumber = "Contact number is required";
    } else if (formData.contactNumber.length < 10) {
      formErrors.contactNumber =
        "Contact number must be at least 10 digits long";
    } else if (formData.contactNumber.length > 12) {
      formErrors.contactNumber = "Contact number cannot exceed 12 digits";
    }
    if (!formData.userName) formErrors.userName = "User Name is required";
    if (!formData.password) {
      formErrors.password = "Password is required";
    } else {
      const passwordRegex =
        /^(?=.*[0-9])(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,16}$/;

      if (formData.password.length > 16) {
        formErrors.password = "Password must not exceed 16 characters";
      } else if (!passwordRegex.test(formData.password)) {
        formErrors.password =
          "Password must be 8-16 characters long, and include at least one number and one special character.";
      }
    }
    if (formData.password !== formData.confirmPassword) {
      formErrors.confirmPassword = "Passwords do not match";
    } else if (formData.confirmPassword.length > 16) {
      formErrors.confirmPassword = "Password must not exceed 16 characters";
    }
    if (!formData.agreeTerms)
      formErrors.agreeTerms = "You must agree to the terms and conditions";

    if (!validator.isEmail(formData.email)) {
      formErrors.email = "Invalid email format.";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    if (validateForm()) {
      let PostData = {
        username: formData.userName,
        email: formData.email,
        email2: formData.confirmEmail,
        contact_number: formData.contactNumber,
        password: formData.password,
        password2: formData.confirmPassword,
        first_name: formData.firstName,
        last_name: formData.lastName,
        state: formData.state,
        post_code: formData.postCode,
        operational_status: operationalStatus,
        agreed_to_terms_and_privacy_policy:formData.agreeTerms,
        opted_in_for_onboarding:formData.onboardingAssistance
      };
      try {
        const response = await submitForm(PostData).unwrap();

        setFormData({
          confirmation_status: "",
          firstName: "",
          lastName: "",
          email: "",
          confirmEmail: "",
          state: "",
          postCode: "",
          contactNumber: "",
          userName: "",
          password: "",
          confirmPassword: "",
          agreeTerms: false,
          onboardingAssistance: false,
        });
        const id = response?.user.id;
        const email = response.user.email;
        const operational_status = response.branch?.operational_status;
        const subscription_type = response.branch?.subscription_type;

        const onboarding_option = formData.onboardingAssistance

        const timer = setTimeout(() => {
          navigate("/ccowner-OTP-free-trial", {
            state: { id, email, subscription_type, operational_status, onboarding_option },
          });
        }, 1000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  const tabs = [
    {
      label: "",
      heading: "Free Marketing & Exposure",
      content: (
        <ul>
          <li>Premium Directory Listings & Profiles</li>
          <li>Strategic Branding & Positioning</li>
          <li>Targeted Exposure for Established Cafes</li>
          <li>Real-Time Startup Exposure</li>
          <li>Complimentary Marketing Support</li>
          <li>Increased Customer Visibility</li>
          <li>Effective Event Promotion</li>
          <li>Positive Review Enhancement</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Automated Industry Networking ",
      content: (
        <ul>
          <li>24/7 Automated Ecosystem Development</li>
          <li>Enhanced Industry Education & Exposure</li>
          <li>Foodservice / Trades & Services Partners</li>
          <li>Direct Communication & Account Applications</li>
          <li>Detailed Profiles & Comprehensive Support</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Expansion & Multisite ",
      content: (
        <ul>
          <li>Financial Planning & Budgeting</li>
          <li>Scalability & Risk Management</li>
          <li>Supply Chain Optimisation</li>
          <li>Advanced Technology Integration</li>
          <li>Operational Efficiency Assessment</li>
          <li>Evaluating Adaptability</li>
          <li>Advanced Training & Development</li>
          <li>Market Research & Analysis</li>
          <li>Marketing Strategy Development</li>
          <li>Competitive Benchmarking</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Start-ups & Incubation",
      content: (
        <ul>
          <li>Early Industry & Public Visibility</li>
          <li>Preliminary Budgeting & Business Planning</li>
          <li>Initial Branding, Marketing & Menu Drafting</li>
          <li>Action Planning & Financial Modelling</li>
          <li>Expert Guidance & Industry Tools</li>
          <li>Accessible Products & Services</li>
          <li>Build Your Own Supportive Ecosystem</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Mentorship",
      content: (
        <ul>
          <li>Accredited Business Coaching</li>
          <li>Leading Hospitality Experts</li>
          <li>Vital Business Management</li>
          <li>80+ Areas of Mentorship</li>
          <li>Creative & Analytical Thinking</li>
          <li>Financial Literacy Coaching</li>
          <li>Crisis Management & Adaptability</li>
          <li>Effective Leadership Strategies</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Menu Development",
      content: (
        <ul>
          <li>Pricing, Profitability & Productivity</li>
          <li>Data-Driven Decision Alignment</li>
          <li>COGS Reduction</li>
          <li>Menu Development & Engineering</li>
          <li>Size, Variety, Seasonality & Simplicity</li>
          <li>Branding, Positioning & Crafting Specials</li>
          <li>Creating Catering Menus</li>
          <li>Executive Chef Mentorship</li>
        </ul>
      ),
    },
    {
      label: "",
      heading: "Development Materials",
      content: (
        <ul>
          <li>Visual Learning Support</li>
          <li>Member Only Resources</li>
          <li>Operational Templates</li>
          <li>Editable Workbooks</li>
          <li>Editable Checklists</li>
        </ul>
      ),
    },
  ];
  useEffect(() => {
    // Update the message on the first load based on the selected option
    if (selectedOptions.planning) {
      setDisplayMessage(
        "Pro Members Planning to Open can respond to ecosystem inquiries with hidden account holder details for admin use only."
      );
    } else if (selectedOptions.open || selectedOptions.exiting) {
      setDisplayMessage(
        "Pro members can respond to ecosystem enquiries. Account holder details are hidden and for admin use only."
      );
    }
  }, [selectedOptions]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveTab((prevTab) => (prevTab + 1) % tabs.length);
    }, 3000);

    return () => clearInterval(interval);
  }, []);
  // useEffect(() => {
  //   localStorage.removeItem("accessToken");
  //   localStorage.removeItem("accountHolderId");
  //   localStorage.removeItem("subscriptionType");
  //   localStorage.removeItem("branchId");
  //   localStorage.removeItem("activeTab");
  //   localStorage.removeItem("formData");
  //   localStorage.removeItem("completedSteps");
  // }, []);

  const acccesstoken = localStorage.getItem('accessToken');
  useEffect(()=>{
    if(acccesstoken){
      setShowAlertModal(true)
    }

  }, [acccesstoken])
  const handleCloseAlertModal = ()=>{
    localStorage.clear();
    setShowAlertModal(false)
  }

  return (
    <div className="cc-owner-signup">
      <section className="section">
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="image-div">
              <h3>
                <img src={ElipseIMg} alt="ElipseIMg" />
                Cafe Entrepreneurship
                {/* <img
                  src={WhiteAlertImg}
                  alt={WhiteAlertImg}
                  className="services-indication-icon"
                  onMouseEnter={handleMouseEnter}
                /> */}
              </h3>
              <p className="sub-text">
                Discover the Breakthrough You've Been Looking For with
                Australia's Premier Cafe Support Platform
              </p>
              <p className="sub-text">(Tax Deductible!)</p>
              <p>Enjoy 1 month of free access to all PRO-level features. After your trial, choose between 'LITE' (pay-as-you-go) or 'PRO' (all-inclusive). No commitment until you decide!
              </p>

              <div className="signup-table">
                <table class="table">
                  <thead>
                    <tr>
                      <th scope="col" className="align-middle">
                        Features
                      </th>
                      <th scope="col">
                        <p className="mrl_-60">
                          <b>Lite </b>Membership{" "}
                          <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" />
                            <span class="tooltip-commontext">
                              Pay-as-you-go - great for trying new ideas or staying flexible.
                            </span>
                          </div>
                          <br />
                          <span>
                            <b>$0</b>/ month
                          </span>{" "}
                        </p>
                      </th>
                      <th scope="col">
                        <p className="mrl_20">
                          <b>Pro </b> Membership{" "}
                          <div class="tooltip-common">
                            <img src={AlertImg} alt="AlertImg" />
                            <span class="tooltip-commontext">
                              All-in-one - best for smooth operations and steady growth.
                            </span>
                          </div>
                          <br />
                          <span>
                            <b>${propricece?.amount}</b>/ month
                          </span>{" "}
                        </p>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Real-Time Professional Directory
                          Listing
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Marketing & Customer Growth
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Tailored Business Connections
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Communication with Industry Experts
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Practical Tools & Guides
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Unlimited Access to Specialist Courses
                        </p>
                      </td>
                      <td className="bold-txt text-center">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Unlimited Group Mentorship Sessions
                        </p>
                      </td>
                      <td className="bold-txt text-center">${huddlePrice?.[0]?.lite_price ? huddlePrice[0].lite_price : "0"}</td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> 1:1 Mentorship & Roadmapping
                        </p>
                      </td>
                      <td className="bold-txt text-center">$169.99</td>
                      <td className="bold-txt text-center">$79.99</td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Exclusive Discounts on Products & Services
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Priority Support When You Need It
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={CrossIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p className="d-flex">
                          <span>*</span> Industry Updates & Compliance Support
                        </p>
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                      <td className="text-center">
                        <img src={TickIcon} />
                      </td>
                    </tr>
                    <tr>
                        <td >
                          <p className="d-flex"> <span>*</span> Unlimited Access to Online Expos & Events</p>
                         
                        </td>
                        <td className="text-center">
                          <img src={CrossIcon} />
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                      </tr>
                    <tr>
                        <td >
                        <p className="d-flex">  <span>*</span> Live Chat & Community Noticeboard </p>
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                        <td className="text-center">
                          <img src={TickIcon} />
                        </td>
                      </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="explore-more">
                <h4>Explore more features for you</h4>
                <div className="tabs-container">
                  <div className="tabs">
                    {tabs.map((tab, index) => (
                      <div
                        key={index}
                        className={`tab ${activeTab === index ? "active" : ""}`}
                        onClick={() => setActiveTab(index)}
                      >
                        {tab.label}
                      </div>
                    ))}
                  </div>
                  <div className="tab-content">
                    <h3 className="tab-heading">{tabs[activeTab].heading}</h3>
                    {tabs[activeTab].content}
                  </div>
                </div>
              </div> */}
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="form-div">
              <p>
                <Link to="/">
                  {" "}
                  <img src={BackIcon} alt="BackIcon" />
                </Link>
              </p>
              <div>
                {/* <Link to="/ccowner-signup">
                  <button type="button" className="lite-btn">
                    Lite
                  </button>
                </Link> */}
                {/* <Link to="/ccowner-signup-pro"> */}
                <button type="button" className="pro-btn">
                  <img
                    src={CrownStar}
                    alt="CrownStar"
                    className="crownstar"
                  />
                  Pro
                </button>
                {/* </Link> */}
              </div>
              <form onSubmit={handleSubmit}>
                <p className="main-head">
                  Confirm your operational status{" "}
                  <div class="tooltip-common">
                    <img src={AlertImg} alt="AlertImg" />
                    <span class="tooltip-commontext tooltip-mob-text">
                      Provides the right level of support and matches within the
                      Ecosystem
                    </span>
                  </div>
                </p>

                {/* <div className="status-block">
      {operationalStatusData?.map((status) => {
        const isDisabled =
          (status.name === "Planning to open" &&
            (selectedOptions["Open for business"] ||
              selectedOptions["Looking to exit"])) ||
          ((status.name === "Open for business" ||
            status.name === "Looking to exit") &&
            selectedOptions["Planning to open"]);

        return (
          <>
            <input
              type="checkbox"
              id={status.name.toLowerCase().replace(/\s+/g, "-")}
              name="confirmation_status"
              value={status.name}
              onChange={handleChangeStatus}
              className="radio-btn"
              checked={selectedOptions[status.name] || false}
              disabled={isDisabled}
            />
            <label
              className={`label-for-radio-btn ${
                isDisabled ? "disabled-label" : ""
              }`}
              htmlFor={status.name.toLowerCase().replace(/\s+/g, "-")}
            >
              {status.name}
            </label>
          </>
        );
      })}
    </div> */}

                <div className="status-block">
                  <input
                    type="checkbox"
                    id="planning"
                    name="confirmation_status"
                    value="planning"
                    onChange={handleChangeStatus}
                    className="radio-btn"
                    checked={selectedOptions.planning}
                    disabled={selectedOptions.open || selectedOptions.exiting}
                  />
                  <label
                    className={`label-for-radio-btn ${selectedOptions.open || selectedOptions.exiting
                        ? "disabled-label"
                        : ""
                      }`}
                    htmlFor="planning"
                  >
                    Planning to open
                  </label>
                  <input
                    type="checkbox"
                    id="open"
                    name="confirmation_status"
                    value="open"
                    onChange={handleChangeStatus}
                    className="radio-btn"
                    checked={selectedOptions.open}
                    disabled={selectedOptions.planning}
                  />
                  <label
                    className={`label-for-radio-btn ${selectedOptions.planning ? "disabled-label" : ""
                      }`}
                    htmlFor="open"
                  >
                    Open for business
                  </label>

                  <input
                    type="checkbox"
                    id="exiting"
                    name="confirmation_status"
                    value="exiting"
                    onChange={handleChangeStatus}
                    className="radio-btn"
                    checked={selectedOptions.exiting}
                    disabled={selectedOptions.planning}
                  />
                  <label
                    className={`label-for-radio-btn ${selectedOptions.planning ? "disabled-label" : ""
                      }`}
                    htmlFor="exiting"
                  >
                    Looking to exit
                  </label>
                  {errors.confirmation_status && (
                    <p className="error">{errors.confirmation_status}</p>
                  )}
                </div>
                <p className="main-head">Account holder details</p>
                <p className="sub-head">
                  To create an account, first enter the personal details of the
                  account holder.
                </p>
                {displayMessage && <p className="text-ylw">{displayMessage}</p>}
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      First name <span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="John"
                      name="firstName"
                      onChange={handleChange}
                      value={formData.firstName}
                    />
                    {errors.firstName && (
                      <p className="error">{errors.firstName}</p>
                    )}
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Last name <span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="John"
                      name="lastName"
                      onChange={handleChange}
                      value={formData.lastName}
                    />
                    {errors.lastName && (
                      <p className="error">{errors.lastName}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      Email<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="abcd123@gmail.com"
                      name="email"
                      onChange={handleChange}
                      value={formData.email}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      Confirm email<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="abcd123@gmail.com"
                      name="confirmEmail"
                      onChange={handleChange}
                      value={formData.confirmEmail}
                    />
                    {errors.confirmEmail && (
                      <p className="error">{errors.confirmEmail}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      State<span className="star">*</span>
                    </label>
                    <br />
                    <select
                      name="state"
                      onChange={handleChange}
                      value={formData.state}
                    >
                      <option>Select state</option>
                      {statesData?.map((state) => (
                        <option value={state.id}>{state.name}</option>
                      ))}
                    </select>
                    {errors.state && <p className="error">{errors.state}</p>}
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Post Code<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="123 466"
                      name="postCode"
                      onChange={handleChange}
                      value={formData.postCode}
                    />
                    {errors.postCode && (
                      <p className="error">{errors.postCode}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      Contact number<span className="star">*</span>
                    </label>
                    <br />
                    <div style={{ display: 'flex', alignItems: 'center' }} className="phone-input">
                      <span style={{ marginRight: '5px' }}>+61</span>
                      <input
                        type="text"
                        placeholder="XXXXXXXXXX"
                        name="contactNumber"
                        onChange={handleChange}
                        value={formData.contactNumber}
                        onKeyPress={handleKeyPressNumersOnly}
                      />
                      </div>
                    {errors.contactNumber && (
                      <p className="error">{errors.contactNumber}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={12} md={12}>
                    <label>
                      User Name<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="text"
                      placeholder="John"
                      name="userName"
                      onChange={handleChange}
                      value={formData.userName}
                    />
                  </Col>
                  {errors.userName && (
                    <p className="error">{errors.userName}</p>
                  )}
                </Row>
                <Row className="mb-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Password<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="password"
                      placeholder="********"
                      value={formData.password}
                      name="password"
                      onChange={handleChange}
                    />
                    {errors.password && (
                      <p className="error">{errors.password}</p>
                    )}
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <label>
                      Confirm Password<span className="star">*</span>
                    </label>
                    <br />
                    <input
                      type="password"
                      placeholder="********"
                      name="confirmPassword"
                      onChange={handleChange}
                      value={formData.confirmPassword}
                    />
                    {errors.confirmPassword && (
                      <p className="error">{errors.confirmPassword}</p>
                    )}
                  </Col>
                </Row>
                <Row className="mb-16 mt-30">
                  <Col sm={12} xs={12} md={12} lg={12} className="d-flex mb-10">
                  <input
                      type="checkbox"
                      className="checkbox"
                      name="agreeTerms"
                      onChange={handleChange}
                      checked={formData.agreeTerms}
                    />
                    <label class="form-check-label termsCondition" for="checkbox1">
                      I have read and agree to the <a href={TermsandCondition} target="_blank">terms and conditions</a> and
                      <a href={PrivacyPolicy} target="_blank"> privacy policy.</a><span className="star">*</span>
                    </label>
                  </Col>
                  {errors.agreeTerms && (
                    <p className="error">{errors.agreeTerms}</p>
                  )}
                  <Col sm={12} xs={12} md={12} lg={12} className="d-flex">
                  <input type="checkbox" className="checkbox" name="onboardingAssistance" checked={formData.onboardingAssistance} onChange={handleChange} />
                    <label class="form-check-label" for="checkbox1">
                      Add free onboarding assistance?
                      <div class="tooltip-common">
                        <img src={AlertImg} alt="AlertImg" />
                        <span class="tooltip-commontext tooltip-mob-text">
                          A team member will contact you to help create your
                          profile and listing
                        </span>
                      </div>
                    </label>
                  </Col>
                </Row>
                <Row className="mb-16 ml-0">
                  <button
                    type="submit"
                    className={`btn-primary-disabled ${formValid ? "btn-primary-valid" : ""
                      }`}
                  >
                    Get Started
                  </button>
                  {isError && (
                    <p style={{ color: "red" }} className="mt-20">
                       Failed to Create Account: {error?.data?.error || "Failed to submit form"}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                       Creating an Account.
                    </p>
                  )}
                  {isLoading && <div class="loader"></div>}
                </Row>
              </form>
            </div>
          </Col>
        </Row>
        {showProductTypes && (
          <ProcuctTypes handlecloseProductTypeModal={handlecloseProductModal} />
        )}
      </section>
      <AlertBoxModalAlreadyLoggedIn show={showAlertModal} handleCloseAlertModal={handleCloseAlertModal} />
    </div>
  );
}

export default CcOwnerSignUp;
