import React, { useState } from "react";

function SessionsessionDetails({ sessionData, price }) {

  return (
    <div className="session-details-data">
      <div className="listng">
        <h4>Session details</h4>
        <hr />
        <h4>{sessionData?.head1}</h4>
        <p className="para mb-23">
        {sessionData?.desc1}
        </p>
        <h4>{sessionData?.head2}</h4>
        <p className="para mb-23">
        {sessionData?.desc2}
        </p>
        <h4>{sessionData?.head3}</h4>
        <p className="para mb-23" >
        {sessionData?.desc3}
        </p>
        <h4>{sessionData?.head4}</h4>
        <p className="para mb-23">
        {sessionData?.desc4}
        </p>
        <h4>{sessionData?.head5}</h4>
        <p className="para mb-23">
        {sessionData?.desc5}
        </p>
        <h4>{sessionData?.head6}</h4>
        <p className="para mb-23">
        {sessionData?.desc6}
        </p>
        <h4>{sessionData?.head7}</h4>
        <p className="para mb-23">
        {sessionData?.desc7}
        </p>
        <h4>{sessionData?.head8}</h4>
        <p className="para">
        {sessionData?.desc8}
        </p>
        <h4>{sessionData?.head9}</h4>
        <p className="para mb-23">
        {sessionData?.desc9}
        </p>
        <h4>{sessionData?.head10}</h4>
        <p className="para mb-23">
        {sessionData?.desc10}
        </p>
      </div>
    </div>
  );
}

export default SessionsessionDetails;
