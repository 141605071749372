import React from "react";
import "../Styles/ProductTypes.css";
import CloseImg from "../../../Assets/Images/close.svg";
import { useGetTradeServiceCategoryQuery} from "../../../Services/CcownerAPI";

function ProductTypes({ handlecloseProductTypeModal }) {
  const handleClose = () => {
    handlecloseProductTypeModal();
  };
  const { data: producttypes } = useGetTradeServiceCategoryQuery();

  return (
    <div className="trades-product-types">
      <img
        src={CloseImg}
        alt="CloseImg"
        className="close-img"
        onClick={handleClose}
      />
      <div>
        <h4>Service Types Available</h4>
        <div className="d-flex">
          {producttypes?.map((product) => (
            <button>{product.name}</button>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ProductTypes;
