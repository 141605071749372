import React, { useEffect, useState } from "react";
import ProStar from "../Assets/Images/crown-star-pro.svg";
import ManageListing from "../Assets/Images/manage-listing.svg";
import CreateListing from "../Assets/Images/create-listing.svg";
import ActionIMg from "../Assets/Images/actions-img.svg";
import LocationIcon from "../Assets/Images/location-icon.svg";
import { Row, Col } from "react-bootstrap";
import AddLocationMOdal from "../Components/AddAnotheLocation";
import "../Styles/DirectoryPayment.css";
import { Link, useLocation } from "react-router-dom";
import "../Styles/subscription.css";
import DownloadInvoice from "../Assets/Images/download-invoice.svg";
import moment from 'moment';

import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useGetAccountHolderDataQuery,
  useGetSubscriptionListQuery,
  useGetCeInvoiceQuery
} from "../Services/CcownerAPI";

function Subscription() {
  const [showAddLocationpModal, setshowAddLocationpModal] = useState(false);
  const [recordId, setRecordId] = useState("")
  const handleshowAdLocationModal = () => {
    setshowAddLocationpModal(true);
  };
  const handleCloseModal = () => {
    setshowAddLocationpModal(false);
  };


const { data: completedbranch, isLoading: isLoadingCompletedData, refetch:refetchCompletedLOcationData } =
  useGetCompletedBranchesQuery();


  const {data:SubscriptionList} = useGetSubscriptionListQuery();
  const {data:SubscriptionInvoice, refetch:refetchInvoice} = useGetCeInvoiceQuery({recordId});
  function formatDate(dateString) {
    return moment(dateString).format('DD/MM/YYYY');
  }

  const handleGenerateInvoice = (recordId) => {

    setRecordId(recordId);  // First, set the recordId
  };
  
  // Call refetch when recordId changes
  useEffect(() => {
    if (recordId) {
      refetchInvoice();
    }
  }, [recordId]);

  useEffect(()=>{
    if(SubscriptionInvoice){

      const url = SubscriptionInvoice;
      window.open(url, '_blank'); 
    }
      }, [SubscriptionInvoice])
  return (
    <div className="dashboard-listing">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Subscription</h4>
        </Col>
        {/* <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button
            className="add-another-location"
            onClick={handleshowAdLocationModal}
          >
            Add another location
          </button>
        </Col> */}
      </Row>

      {/* Pro Locations */}
      {SubscriptionList ? (
        <div className="list-card">
          <Row className="flagship-div">
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>
                <img src={ProStar} alt="Star" />
                <span>Pro</span>
              </p>
            </Col>
          </Row>
          <>
  {SubscriptionList.length > 0 ? (
    SubscriptionList.map((location) => (
      <div className="card" key={location.id}>
        <Row className="brdr-btm align-bottom">
          <Col xs={12} sm={12} md={8} lg={8}>
            <p className="location-head">
              {location?.branch?.flagship && <img src={LocationIcon} alt="LocationIcon" />}
              {location?.branch?.location}
              {location?.branch?.operational_status.length > 0 ? (
                location.branch.operational_status.map((status) => (
                  <button key={status.id} className="open-for-business-btn">
                    {status.name}
                  </button>
                ))
              ) : (
                <button className="open-for-business-btn">
                  Unknown status
                </button>
              )}
            </p>
          </Col>
          <Col xs={12} sm={12} md={2} lg={2} className="text-end">
            <p
              className="subscription-invoice"
              onClick={() => handleGenerateInvoice(location?.record_data?.id)}
            >
              <img src={DownloadInvoice} alt="DownloadInvoice" />
              Subscription invoice
            </p>
          </Col>
        </Row>
        <Row>
          <div className="review-date d-flex align-space-between">
            <p>Added on {formatDate(location?.record_data?.subscription_start_date)}</p>
            <p>Renews on {formatDate(location?.record_data?.subscription_current_period_end)}</p>
          </div>
        </Row>
      </div>
    ))
  ) : (
    <p className="subscription-no-data">No listings found</p>
  )}
</>
        </div>
      ):(<p>No data available</p>)}

     

      <AddLocationMOdal
        show={showAddLocationpModal}
        handleCloseLocationModal={handleCloseModal}
      />
    </div>
  );
}

export default Subscription;
