import React from 'react'
import { Modal, Button } from "react-bootstrap";
import "../Styles/AlertBoxModal.css"
import { useNavigate } from 'react-router-dom';

function AlertBoxAccountDeleted({ show, handleCloseAlertModal }) {
    const navigate = useNavigate();

    const handleBackClick = () => {
        navigate(-1);
    };
    return (
        <div>
            <Modal
                show={show}
                backdrop="true"
                dialogClassName="alert-account-modal"
            >

                <Modal.Body>
                    <p>You are already logged in. Please log out to continue</p>
                    <button type='button' onClick={handleCloseAlertModal} className='btn-modal-primary'>Logout</button>
                    <button type='button' onClick={handleBackClick} className='btn-modal-outline'>Back</button>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AlertBoxAccountDeleted