import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import uploadimg from "../../../Assets/Images/upload-trade.svg";
import DiverIMg from "../../../Assets/Images/Divider.svg";
import CloseIcon from "../../../Assets/Images/close.svg";
import { useNavigate } from "react-router-dom";
import { useUploadMediaFilesMutation,useGetMediaGalleryQuery } from "../../../Services/TradeserviceAPI";

function UploadModal({ show, handleCloseModal }) {
  const navigate = useNavigate();
  const [dragOver, setDragOver] = useState(false);
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const branchId =localStorage.getItem("tdservicebranchId_current");
  const [submitForm, { isLoading, isError, isSuccess, error }] = useUploadMediaFilesMutation();

  const { data: mediagalleryData, refetch } = useGetMediaGalleryQuery(branchId);
  // File type and size validation
  const validateFiles = (fileList) => {
    const allowedTypes = ["application/pdf", "video/mp4"];
    const maxVideoSize = 10 * 1024 * 1024; // 15MB
    let isValid = true;

    Array.from(fileList).forEach((file) => {
      if (!allowedTypes.includes(file.type)) {
        setErrorMessage("Only PDF and MP4 video files are allowed.");
        isValid = false;
      } else if (file.type.startsWith("video") && file.size > maxVideoSize) {
        setErrorMessage("Video file size should not exceed 10MB.");
        isValid = false;
      }
    });

    return isValid;
  };

  // Handle file drop
  const handleDrop = (e) => {
    e.preventDefault();
    setDragOver(false);
    const fileList = e.dataTransfer.files;
    if (validateFiles(fileList)) {
      handleFiles(fileList);
      setErrorMessage("");
    }
  };

  // Handle file selection via button
  const handleFileSelect = (e) => {
    const fileList = e.target.files;
    if (validateFiles(fileList)) {
      handleFiles(fileList);
      setErrorMessage("");
    }
  };

  // Common file handling function
  const handleFiles = (fileList) => {
    setFiles((prevFiles) => [...prevFiles, ...Array.from(fileList)]);
  };

  // Handle drag events
  const handleDragOver = (e) => {
    e.preventDefault();
    setDragOver(true);
  };

  const handleDragLeave = () => {
    setDragOver(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      setErrorMessage("Please upload at least one file.");
      return;
    }

    const formData = new FormData();
    files.forEach((file) => {
      formData.append("file", file);
    });

    try {
      const response = await submitForm({formData,branchId}).unwrap();
      if (response) {
        const timer = setTimeout(() => {
          handleCloseModal();
          refetch();
          window.location.reload();
        }, 3000);
       
      }
    } catch (err) {
      console.error("Failed to submit files:", err);
    }
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={handleCloseModal}
        backdrop="true"
        dialogClassName="modal-dialog-centered upgrade-modal media-gallery-modal media-gallery-trade"
      >
        <Modal.Body>
          <form onSubmit={handleSubmit}>
            <img src={CloseIcon} alt="Close" className="close-img" onClick={handleCloseModal} />
            <h4>Media Upload</h4>
            <p>Add your documents here, and you can upload up to 5 files max</p>
            <p className="mb-20">
              Uploaded documents can only be published after receiving admin approval.
            </p>
            <div
              className={`drag-drop-container ${dragOver ? "drag-over" : ""}`}
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              onDragLeave={handleDragLeave}
            >
              <img src={uploadimg} alt="upload" />
              <div className="drag-drop-text">
                Drag your file(s) to start uploading
                <br />
                <img src={DiverIMg} alt="Divider" />
                <br />
                <label htmlFor="file-upload" className="browse-button">
                  Browse Files
                </label>
                <input
                  type="file"
                  id="file-upload"
                  multiple
                  style={{ display: "none" }}
                  onChange={handleFileSelect}
                />
              </div>
              {files.length > 0 && <p className="file-name">{files.map((file) => file.name).join(", ")}</p>}
            </div>
            <div>
              <p className="file-type-text">Only supports pdf and video file</p>
              <p>Video file size should not exceed more than 10MB</p>
            </div>
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
            <div className="action-buttons-div">
              <button type="button" className="btn-outline" onClick={handleCloseModal}>Cancel</button>
              <button type="submit" className="btn-next">Next</button>
              {isError && <p style={{ color: "red" }}>Error: {error?.data?.message || "Failed to upload files"}</p>}
              {isSuccess && <p style={{ color: "green" }}>Files uploaded successfully!</p>}
              {isLoading && <div className="loader-trade"></div>}
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default UploadModal;
