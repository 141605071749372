import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useAddMultipleBranchMutation ,   useUserGetEditQuery,} from "../Services/CcownerAPI";
import DeleteIcon from "../Assets/Images/delete-list.svg";
import {
  GoogleMap,
  useLoadScript,
  StandaloneSearchBox,
} from "@react-google-maps/api";

const libraries = ["places"];

const ModalComponent = ({ show, handleCloseLocationModal,refetchCompletedLOcationData,refetchinCompletedLOcationData}) => {
  const navigate = useNavigate();
  const [locations, setLocations] = useState([{ id: 1 }]);
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useAddMultipleBranchMutation();
    const { data: userData } = useUserGetEditQuery();


  const handleAddNew = () => {
    setLocations([...locations, { id: locations.length + 1 }]);
  };

  const handleInputChange = (id, field, value) => {
    let formattedValue = value;

    // Validation for businessName: only letters and spaces allowed
    if (field === "businessName") {
      const isValid = /^[A-Za-z\s]*$/.test(value);
      if (!isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            businessName: "Only letters and spaces are allowed.",
          },
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            businessName: "",
          },
        }));
      }
    }

    // Validation for address: remove special characters except common address symbols
    if (field === "address") {
      formattedValue = value.replace(/[^A-Za-z0-9\s,.-]/g, ""); // Allows letters, numbers, spaces, commas, and periods
      if (value !== formattedValue) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            address: "Special characters removed.",
          },
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          [id]: {
            ...prevErrors[id],
            address: "",
          },
        }));
      }
    }

    setFormValues((prevValues) => ({
      ...prevValues,
      [id]: {
        ...prevValues[id],
        [field]: formattedValue,
      },
    }));
  };
  const validateForm = () => {
    let isValid = true;
    const newErrors = {};
  
    locations.forEach((location) => {
      const values = formValues[location.id] || {};
  
      // Validate business name
      if (!values.businessName) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          businessName: "Please enter your business name.",
        };
      }
  
      // Validate location
      if (!values.location) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          location: "Please enter your location.",
        };
      }
  
      // Validate latitude and longitude
      if (!values.lat || !values.lng) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          location: "Please choose a valid location from the suggestions.",
        };
      }
  
      // Validate address
      if (!values.address) {
        isValid = false;
        newErrors[location.id] = {
          ...newErrors[location.id],
          address: "Please enter your address.",
        };
      }
    });
  
    setErrors(newErrors);
    return isValid;
  };
  

  // const validateForm = () => {
  //   let isValid = true;
  //   const newErrors = {};

  //   locations.forEach((location) => {
  //     const values = formValues[location.id] || {};
  //     // if (!operationalStatus.length) {
  //     //   isValid = false;
  //     //   newErrors[location.id] = {
  //     //     ...newErrors[location.id],
  //     //     confirmation_status: "Please select a status.",
  //     //   };
  //     // }
  //     // Additional validations remain unchanged
  //     if (!values.businessName) {
  //       isValid = false;
  //       newErrors[location.id] = {
  //         ...newErrors[location.id],
  //         businessName: "Please enter your business name.",
  //       };
  //     }
  //     if (!values.location) {
  //       isValid = false;
  //       newErrors[location.id] = {
  //         ...newErrors[location.id],
  //         location: "Please enter your location.",
  //       };
  //     }
  //     if (!values.address) {
  //       isValid = false;
  //       newErrors[location.id] = {
  //         ...newErrors[location.id],
  //         address: "Please enter your address.",
  //       };
  //     }
  //   });


  //   const currentLocation = formValues[location.id];
    
  //   // Validate if location is selected
    

  //   // Validate latitude and longitude
  //   if (!currentLocation?.lat || !currentLocation?.lng) {
  //     isValid = false;
  //     newErrors[location.id] = {
  //       ...newErrors[location.id],
  //       location: "Please choose a valid location from the suggestions.",
  //     };
  //   }
    
  //   setErrors(newErrors);
  //   return isValid;
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const formattedData = locations.map((location) => ({
        business_name: formValues[location.id].businessName,
        location: formValues[location.id].location,
        address: formValues[location.id].address,
        latitude: formValues[location.id].lat, // Pass latitude
        longitude: formValues[location.id].lng, // Pass longitude
        operational_status: formValues[location.id]?.confirmation_status || [],
      }));

      try {
        const response = await submitForm(formattedData).unwrap();
        if (response) {
          if(userData?.free_trial == "active"){
            window.location.replace("/directory-listing");
            handleCloseLocationModal();
            refetchinCompletedLOcationData();
            refetchCompletedLOcationData()
          }
          else{
            navigate("/directory-payment");
          }
         
        }
      } catch (err) {
        console.error("Failed to submit form, please enter a correct place:", err);
      }
    }
  };

  const handleRemoveLocation = (id) => {
    setLocations((prevLocations) =>
      prevLocations.filter((loc) => loc.id !== id)
    );
    setFormValues((prevValues) => {
      const newValues = { ...prevValues };
      delete newValues[id];
      return newValues;
    });
    setErrors((prevErrors) => {
      const newErrors = { ...prevErrors };
      delete newErrors[id];
      return newErrors;
    });
  };
  const [operationalStatus, setOperationalStatus] = useState([]);

  const [selectedOptions, setSelectedOptions] = useState({});

  const handleChangeStatus = (e, locationId) => {
    const { id, checked, value } = e.target;

    // Update selected options for each location
    setSelectedOptions((prevState) => {
      const updatedOptions = {
        ...prevState,
        [locationId]: {
          ...prevState[locationId],
          [value]: checked,
        },
      };

      // Build the updated operationalStatus array based on selected options
      const updatedStatus = [];
      if (updatedOptions[locationId]?.planning) updatedStatus.push(1); // ID for 'Planning to open'
      if (updatedOptions[locationId]?.open) updatedStatus.push(2); // ID for 'Open for business'
      if (updatedOptions[locationId]?.exiting) updatedStatus.push(3); // ID for 'Looking to exit'

      // Update form values with operational status for the specific location
      setFormValues((prevValues) => ({
        ...prevValues,
        [locationId]: {
          ...prevValues[locationId],
          confirmation_status: updatedStatus,
        },
      }));

      return updatedOptions;
    });
  };

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLEMAP_API_LOCATION,
    libraries,
  });
  const searchBoxRefs = useRef({});
  if (loadError) {
    return <div>Error loading Google Maps API</div>;
  }
  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const onPlacesChanged = (id) => {

    if (searchBoxRefs.current[id]) {
      const places = searchBoxRefs.current[id].getPlaces();
      if (places && places.length > 0) {
        const selectedPlace = places[0];
        const locationName = selectedPlace.name || "";

        let lat = null,
            lng = null;
  
        // Extract latitude and longitude if available
        if (selectedPlace.geometry && selectedPlace.geometry.location) {
          lat = parseFloat(selectedPlace.geometry.location.lat().toFixed(6));
          lng = parseFloat(selectedPlace.geometry.location.lng().toFixed(6));
        }
  
        // Update the form values for the specific location
        setFormValues((prevValues) => ({
          ...prevValues,
          [id]: {
            ...prevValues[id],
            location: locationName, // Set the selected location name
            lat,                    // Set the latitude
            lng,                    // Set the longitude
          },
        }));

        // setErrors((prevErrors) => {
        //   const updatedErrors = { ...prevErrors };
        //   if (updatedErrors[id]?.location) {
        //     delete updatedErrors[id].location;
        //   }
        //   return updatedErrors;
        // });

      }
    }
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleCloseLocationModal}
        backdrop="true"
        dialogClassName="modal-dialog-centered add-another-location-modal"
      >
        <Modal.Body>
          <h2>Add New Location</h2>
          <p>
            Complete directory listings amplify engagement. Ensure your business
            shines by filling in all criteria
          </p>
          <p className="required-message-impoertant">Cafe Listings will not appear on the public directory map until the business name and location address are correctly entered.</p>
          <form onSubmit={handleSubmit}>
            {locations.map((location) => (
              <div key={location.id}>
                <h4 className="mb-16">Location {location.id}</h4>

                <label>
                  Confirm your operational status
                  <span className="star">*</span>
                </label>
                <br />
                <Row className="status-block" key={location.id}>
                  <Col sm={12} xs={12} md={4} lg={4}>
                    <input
                      type="checkbox"
                      id="planning"
                      name={`confirmation_status-${location.id}`}
                      value="planning"
                      onChange={(e) => handleChangeStatus(e, location.id)} // Pass location ID here
                      className="radio-btn"
                      checked={selectedOptions[location.id]?.planning || false} // Reflect correct state
                      disabled={
                        selectedOptions[location.id]?.open ||
                        selectedOptions[location.id]?.exiting
                      }
                    />
                    <label
                      className={`label-for-radio-btn ${
                        selectedOptions[location.id]?.open ||
                        selectedOptions[location.id]?.exiting
                          ? "disabled-label"
                          : ""
                      }`}
                      htmlFor="planning"
                    >
                      Planning to open
                    </label>
                  </Col>
                  <Col sm={12} xs={12} md={4} lg={4}>
                    <input
                      type="checkbox"
                      id="open"
                      name={`confirmation_status-${location.id}`}
                      value="open"
                      onChange={(e) => handleChangeStatus(e, location.id)}
                      className="radio-btn"
                      checked={selectedOptions[location.id]?.open || false}
                      disabled={selectedOptions[location.id]?.planning}
                    />
                    <label
                      className={`label-for-radio-btn ${
                        selectedOptions[location.id]?.planning
                          ? "disabled-label"
                          : ""
                      }`}
                      htmlFor="open"
                    >
                      Open for business
                    </label>
                  </Col>
                  <Col sm={12} xs={12} md={4} lg={4}>
                    <input
                      type="checkbox"
                      id="exiting"
                      name={`confirmation_status-${location.id}`}
                      value="exiting"
                      onChange={(e) => handleChangeStatus(e, location.id)}
                      className="radio-btn"
                      checked={selectedOptions[location.id]?.exiting || false}
                      disabled={selectedOptions[location.id]?.planning}
                    />
                    <label
                      className={`label-for-radio-btn ${
                        selectedOptions[location.id]?.planning
                          ? "disabled-label"
                          : ""
                      }`}
                      htmlFor="exiting"
                    >
                      Looking to exit
                    </label>
                  </Col>
                  {errors[`confirmation_status-${location.id}`] && (
                    <p className="error">
                      {errors[`confirmation_status-${location.id}`]}
                    </p>
                  )}
                </Row>
                {errors[location.id]?.confirmation_status && (
                  <p className="error">
                    {errors[location.id].confirmation_status}
                  </p>
                )}
                <Row className="mt-16">
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <div className="form-control">
                      <label>
                        Business name<span className="red-star">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter your business name"
                        value={formValues[location.id]?.businessName || ""}
                        onChange={(e) =>
                          handleInputChange(
                            location.id,
                            "businessName",
                            e.target.value
                          )
                        }
                      />
                      {errors[location.id]?.businessName && (
                        <p className="error">
                          {errors[location.id].businessName}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <div className="form-control">
                      <label>
                        Location<span className="red-star">*</span>
                      </label>
                      <br />
                      <StandaloneSearchBox
                          onLoad={(ref) => (searchBoxRefs.current[location.id] = ref)}
                          onPlacesChanged={() => onPlacesChanged(location.id)} 
                      >
                        <input
                          type="text"
                          placeholder="Enter your location"
                        
                          className="autocomplete-input"
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [location.id]: {
                                ...formValues[location.id],
                                location: e.target.value,
                              },
                            })
                          }
                          style={{ width: "100%", padding: "10px" }}
                        />
                      </StandaloneSearchBox>
                      {errors[location.id]?.location && (
                        <p className="error">{errors[location.id].location}</p>
                      )}
                    </div>
                  </Col>
                  <Col xs={12} sm={12} lg={6} md={6}>
                    <div className="form-control">
                      <label>
                        Address<span className="red-star">*</span>
                      </label>
                      <br />
                      <input
                        type="text"
                        placeholder="Enter your address"
                        value={formValues[location.id]?.address || ""}
                        onChange={(e) =>
                          handleInputChange(
                            location.id,
                            "address",
                            e.target.value
                          )
                        }
                      />
                      {errors[location.id]?.address && (
                        <p className="error">{errors[location.id].address}</p>
                      )}
                    </div>
                  </Col>
                </Row>
                {locations.length > 1 && (
                  <button
                    type="button"
                    className="prev-btn m-0"
                    onClick={() => handleRemoveLocation(location.id)}
                  >
                    Delete
                  </button>
                )}
                <hr />
              </div>
            ))}
            {/* <div className="btn-add-another-location">
              <Button type="button" onClick={handleAddNew}>
                + Add Another Location
              </Button>
            </div>
            <div className="location-modal-footer">
              <Button type="submit" className="save-btn">
                {isLoading ? "Submitting..." : "Save & Proceed"}
              </Button>
              <Button className="cancel-btn" onClick={handleCloseLocationModal}>
                Cancel
              </Button>
            </div> */}
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={6} md={6} className="text-start">
                <button
                  type="button"
                  className="prev-btn m-0"
                  onClick={handleAddNew}
                >
                  Add new
                </button>
              </Col>
              <Col xs={12} sm={12} lg={6} md={6} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handleCloseLocationModal}
                >
                  Cancel
                </button>
                <button type="submit" className="btn-save-primary" disabled={isLoading}>
                  Add
                </button>
              </Col>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.error || "Failed to submit form"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                  Location added successfully!
                </p>
              )}
              {isLoading && <div class="loader"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
