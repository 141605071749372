import React, { useState, useEffect } from "react";
import "../Styles/ServiceCafe.css";
import { Row, Col } from "react-bootstrap";
import KeyPointImg from "../Assets/Images/key-points.svg";
import ElipseBlue from "../Assets/Images/service-item-blue.svg";
import ElipsePink from "../Assets/Images/service-item-pink.svg";
import ElipseYellow from "../Assets/Images/service-item-ylw.svg";
import CafeMarketing from "../Assets/Images/cafe-marketing.png";
import CafeProfitability from "../Assets/Images/cafe-profitability.png";
import CafeExpansion from "../Assets/Images/cafe-expansion.png";
import CafeFoodservicePartner from "../Assets/Images/cafe-foodservice-partner.png";
import Caferesource from "../Assets/Images/cafe-resource.png";
import DirectoryEcoSystem from "../Assets/Images/direcory-ecosystem.png";
import Whatwedo from "../Assets/Images/fs-service-top-img.png";
import DirectoryImg from "../Assets/Images/service-images/FS Directory.png";
import ReviewBoostingImg from "../Assets/Images/service-images/FS Directory Bottom Section.png";
import CustomerEducation from "../Assets/Images/service-images/FS - Media gallery.png";
import EasyNetwork from "../Assets/Images/service-images/FS Matches - Filter.png";
import CafeAccountApplication from "../Assets/Images/fs- cafe account application.jpg";
import EventPromotionImg from "../Assets/Images/service-images/Eco enquiry - FS.png"
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet';
import LandingPagePopup from './LandingPagePopup'

function Services() {
  const location = useLocation();

  const [showLandingModal, setShowLandingModal] = useState(false);
  const handleCloseLandingModal = () => {
    setShowLandingModal(false);
  }

  useEffect(()=>{
    setTimeout(() => {
      setShowLandingModal(true);
    }, 4000);
  }, [])
  
  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const yOffset = -100; // Offset to leave 50px space above the element
        const y =
          element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    zIndex: 999, // Ensures the overlay is on top
  };


  <Helmet>
  <title>Clever Cafe | Where Every Day Is an Online Foodservice Expo for Australian Cafes</title>
  <meta name="description" content="Join Clever Cafe’s online foodservice expo, designed to connect Australian cafes with expert professionals. Access live integrations, automated tools, and professional listings to drive collaboration and growth." />
  <meta name="keywords" content="food service australia, food expo australia, cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship" />
</Helmet>

  return (
    <div className="service-cafe service-food">
      <section className="service-banner">
        <div className="service-banner-content">
          <h2>Delivering New Cafe Partners to Foodservices</h2>
          <p>
            We’re as tired of seeing cafes close as you are. That’s why we
            educate cafe owners with proven strategies for success, increasing
            their chances of thriving as valuable long-term partners for
            foodservice suppliers. By joining our directory, we’ll match you
            with more relevant customers.
          </p>

          <p>
            Watch this <b><a href="https://youtu.be/7zp2Vu5VHJw" target="_blank">quick video </a> </b>to see how we create value from the moment
            you sign up for your free trial. Find more details below.
          </p>
        </div>
      </section>
      <section>
        <div className="home-what-we-do">
          <div className="what-we-do">
            <Row>
              <Col sm={12} lg={8} md={8}>
              <div className="head-desk">
                <h3>Let us connect you with new cafe</h3>
                <h3>owners at the right time.</h3>
                </div>
                <div className="head-mob">
                  <h3>Let us connect you with new cafe owners at the right time.</h3>
                </div>
                <Row>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>01</h4>
                      <h4>Save Time</h4>
                      <p>
                        We’ll automatically match you directly with informed and
                        highly-relevant business owners that suit your offering
                        and location.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>02</h4>
                      <h4> Don’t Miss Opportunities</h4>
                      <p>
                        Have a direct line to both start-up businesses in their
                        pre-launch stage, as well as established businesses
                        optimising their cafe. Have customers reach out to you
                        directly, or look for potential leads within the
                        directory.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>03</h4>
                      <h4>Lower Your Risk</h4>
                      <p className="mb-50">
                        Customers you match with via Clever Cafe will be
                        well-educated in the industry, have an expertly audited
                        menu, and a vetted business model that is likely to
                        succeed and continue paying their suppliers on time.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>04</h4>
                      <h4>Easy to Follow</h4>
                      <p>
                        Simply enter your business details and we’ll start
                        sending well-suited customers your way. You can even
                        create a digital showcase alongside your listing to
                        display what your business offers.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} lg={4} md={4}>
                <img src={Whatwedo} alt="Whatwedo" className="what-we-do-img" />
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="service-items" id="section1">
        <div className="card">
          <h3>Directory & Ecosystem</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                This isn’t your typical listing. It’s crafted to showcase your
                business in a more impactful way, cutting through the noise and
                highlighting what makes you stand out. Update your listing
                anytime to ensure your customers always have the latest
                information.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "As a small business trying to get established in the cafe
                arena, Clever Cafe have already had an impact and have increased
                our customer base."
              </p>
              <p className="testimonial-owner">Fatema K | Owner</p>
              <p className="testimonial-owner">The Chai Room, Sydney, NSW</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> make a powerful first impression
                </li>
                <li>
                  <img src={KeyPointImg} /> target specific cafe owners & states
                </li>
                <li>
                  <img src={KeyPointImg} /> showcase your unique brand
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={DirectoryImg}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Join our ecosystem & become easily discoverable </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Join our ecosystem
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section2">
        <div className="card">
          <h3>Easy Networking</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Expos are valuable, but if you're looking for consistent,
                day-to-day support, our platform is the solution. You'll save
                time and resources with tailored matches in your ad-free space,
                providing continuous opportunities throughout the year.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "A great way to advertise an Australian Cafe Foodservice
                Business."
              </p>
              <p className="testimonial-owner">Danny H | Owner</p>
              <p className="testimonial-owner">Imperial Gluten Free Bakery</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> high-quality partnerships, daily
                  support
                </li>
                <li>
                  <img src={KeyPointImg} />
                  automated criteria-based networking
                </li>
                <li>
                  <img src={KeyPointImg} /> 24/7 relevant connections
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={EasyNetwork}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Simplify networking with our online community.</h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">Connect now</button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section3">
        <div className="card">
          <h3>Review Boosting</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Strengthen visibility and credibility with ecosystem
                recommendations and integrated review links. Attract cafe
                operators by simplifying customer feedback & engagement.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  boost visibility with ecosystem recommendations
                </li>
                <li>
                  <img src={KeyPointImg} />
                  seamlessly connect reviews & customer sharing
                </li>
                <li>
                  <img src={KeyPointImg} /> elevate digital credibility &
                  influence
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={ReviewBoostingImg}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Boost online credibility with our comprehensive ecosystem
                  features.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Increase reviews
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section4">
        <div className="card">
          <h3>Customer Education</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Showcase your products by uploading brochures, price lists, and
                promotional materials that highlight special offers. Use the
                digital showcase to present your offerings, attract attention,
                and enhance interaction.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  highlight exclusive offers & drive sales
                </li>
                <li>
                  <img src={KeyPointImg} /> clarify your value & attract
                  customers
                </li>
                <li>
                  <img src={KeyPointImg} /> inclusive business showcase
                  interview upon signup
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CustomerEducation}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Supercharge your business awareness with our media gallery
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Upload content
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section5">
        <div className="card">
          <h3>Event Promotion</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Sharing your events, whether in-person or virtual, strengthens
                community and boosts engagement. Promote your activities, and we
                will regularly invite you to participate in relevant programs
                that align with your business.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  deepen customer relationships
                </li>
                <li>
                  <img src={KeyPointImg} /> increase visibility with streamlined
                  promotion
                </li>
                <li>
                  <img src={KeyPointImg} /> engage your ideal audience
                  effectively
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={EventPromotionImg}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Promote your events effortlessly & boost engagement.</h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Promote your event
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section6">
        <div className="card">
          <h3>Cafe Account Application</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                We empower entrepreneurs with vetted business plans, audited
                menus, and advanced business management skills, fostering
                lasting relationships with foodservice partners. You can add
                links for cafe members to apply for wholesale accounts, allowing
                direct registration when ready.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  educated, aware & savvy applicants
                </li>
                <li>
                  <img src={KeyPointImg} /> enhanced B2B alignment for clear
                  expectations
                </li>
                <li>
                  <img src={KeyPointImg} /> extra onboarding option for more
                  applicants
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeAccountApplication}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Access assistance with onboarding from well-prepared cafe
                  applicants.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Streamline onboarding
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {showLandingModal && (
        <div style={overlayStyle}></div>
      )}
      <LandingPagePopup showLandingModal={showLandingModal} handleCloseLandingModal={handleCloseLandingModal} />
    </div>
  );
}

export default Services;
