import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/NoticeBoard.css";
import { Tabs, Tab } from "react-bootstrap";
import "../Styles/NoticeBoard.css";
import DeleteIcon from "../Assets/Images/delete-icon.svg";
import moment from 'moment';
import { Link } from "react-router-dom";
import { useDeleteNoticeMutation, useShowhideNoticeMutation } from "../Services/CommunityAPI"
import Swal from 'sweetalert2';

function formatDate(dateString) {
  return moment(dateString).format('DD/MM/YYYY');
}


function MyNoticeBoard({ mynoticeData, refetchMynotice }) {


  const [showHideNotice, { }] = useShowhideNoticeMutation();

  const [deleteNotice, { }] = useDeleteNoticeMutation();

  const [key, setKey] = useState("joblist");

  const jobListings = mynoticeData?.data.filter(
    (item) => item.category_name === "Job Listings"
  );
  const news = mynoticeData?.data.filter(
    (item) => item.category_name === "News"
  );

  const announcements = mynoticeData?.data.filter(
    (item) => item.category_name === "Announcements"
  );
  const handleShowHideJOb = async (id) => {
    try {
      const response = await showHideNotice(id).unwrap();
      
      const isvisible = response?.data?.is_visible
      if (isvisible) {
        Swal.fire({
          icon: 'success',
          text: 'Visible to Other Cafes',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
        refetchMynotice();
      }
      else {
        Swal.fire({
          icon: 'success',
          text: 'Hide the content from all cafe users',
          confirmButtonText: 'OK',
          allowOutsideClick: false,
          allowEscapeKey: false,
        })
      }
      refetchMynotice();
    } catch (err) {

    }
  }
  const handleDeleteNotice = async (id) => {
    try {
      const response = await deleteNotice(id).unwrap();
      alert("deleted");
      window.location.reload();
    } catch (err) {

    }
  }
 
  return (
    <>
      <Tabs
        id="controlled-tab-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        className="mb-3 my-notice-board-tab"
      >
        <Tab eventKey="joblist" title="Job Listing" className="my-notice-list">
          <div className="notice-board-card-listing">
            {jobListings && jobListings?.map((job) => (
              <div key={job.id} className="card">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex">
                      <div>
                        <h5>{job.title}</h5>
                        <p className="category-name">{job.category}</p>
                      </div>
                      <div>
                        <label class="switch-status" title="Toggle will hide the content from all cafe users">
                          <input type="checkbox" onChange={() => handleShowHideJOb(job.id)} checked={job.is_visible === 1} />
                          <span class="slider-status round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="description">{job.content}</p>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="location">
                          {job.location} . <span>{job.operational_status}</span>
                        </p>
                        <p className="pin">{job.pin}</p>
                      </div>
                      <p className="publish-date">Added on {formatDate(job?.created_at ?? "")}</p>
                    </div>

                    <hr />
                    <div className="d-flex">
                      <Link to="/directory-listing"><p className="view-directory-listing">
                        View directory listing
                      </p></Link>
                      <p className="status">
                        {job.status === "pending" ? "Pending Approval" : "Approved"}
                      </p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Tab>

        <Tab eventKey="news" title="News" className="my-notice-list">
          <div className="notice-board-card-listing">
            {news && news?.map((news) => (
              <div key={news.id} className="card">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex">
                      <div>
                        <h5>{news.title}</h5>
                        <p className="category-name">{news.category}</p>
                      </div>
                      <div>
                        <label class="switch-status" title="Toggle will hide the content from all cafe users">
                          <input type="checkbox" onChange={() => handleShowHideJOb(news.id)} checked={news.is_visible === 1}/>
                          <span class="slider-status round"></span>
                        </label>
                      </div>
                    </div>
                    <p className="description">{news.content}</p>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="location">
                          {news.location} . <span>{news.operational_status}</span>
                        </p>
                        <p className="pin">{news.pin}</p>
                      </div>
                      <p className="publish-date">Added on {formatDate(news?.created_at ?? "")}</p>
                    </div>

                    <hr />
                    <div className="d-flex">
                      <Link to="/directory-listing"><p className="view-directory-listing">
                        View directory listing
                      </p></Link>
                      <p className="status">Pending approval</p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Tab>

        <Tab
          eventKey="announcement"
          title="Announcements"
          className="my-notice-list"
        >
          <div className="notice-board-card-listing">
            {announcements && announcements?.map((announcement) => (
              <div key={announcement.id} className="card">
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="d-flex">
                      <div>
                        <h5>{announcement.title}</h5>
                        <p className="category-name">{announcement.category}</p>
                      </div>
                      <div>
                        <img src={DeleteIcon} alt="DeleteIcon" onClick={() => handleDeleteNotice(announcement.id)} />
                      </div>
                    </div>
                    <p className="description">{announcement.content}</p>
                    <div className="d-flex align-items-center">
                      <div>
                        <p className="location">
                          {announcement.location} .{" "}
                          <span>{announcement.operational_status}</span>
                        </p>
                        <p className="pin">{announcement.pin}</p>
                      </div>
                      <p className="publish-date">
                        Added on {formatDate(announcement?.created_at ?? "")}
                      </p>
                    </div>

                    <hr />
                    <div className="d-flex">
                      <Link to="/directory-listing"><p className="view-directory-listing">
                        View directory listing
                      </p></Link>
                      <p className="status">Pending approval</p>
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </Tab>
      </Tabs>
    </>
  );
}

export default MyNoticeBoard;
