import React, { useState, useCallback, useRef, useEffect } from "react";
import "../Styles/EcoSystemMatches.css";
import { Row, Col } from "react-bootstrap";
import FilterIcon from "../../../Assets/Images/filter-icon.svg";
import ActionIcon from "../../../Assets/Images/actions-img.svg";
import ComapnyLogo from "../../../Assets/Images/company-logo.png";
import InactiveBUtton from "../../../Assets/Images/Inactive Button.svg";
import ActiveButton from "../../../Assets/Images/food-service-accept-btn.svg";
import { Link, useLocation } from "react-router-dom";
import LockIcon from "../../../Assets/Images/lock-page.svg";
import ArrowdownIcon from "../../../Assets/Images/food-service-dropdownarow.svg";
import switchImg from "../../../Assets/Images/Switch.svg";
import heartImg from "../../../Assets/Images/heart-food.svg"
import heartImgFill from "../../../Assets/Images/FS-fill-like.svg"
import {
  useGetEcosystemFoodSericeQuery,
  useFoodserviceLikeMutation,
  useDeclinedListQuery
} from "../../../Services/FoodserviceAPI";
import {
  useGetAmbienceQuery,
  useGetFeaturesQuery,
  useGetTechStackQuery,
  useGetMenuHighlihtsQuery,
  useGetSpecialistDietryServiceQuery,
  useGetStatesQuery,
  useFsacceptcafeMutation,
} from "../../../Services/CcownerAPI";

const SortDropdown = ({ handleClickOrdering }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const onSelect = (item) => {
    setActiveItem(item);
  
    setIsOpen(false);
    handleClickOrdering(item);
  };

  return (
    <div className="custom-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Sort
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button
            className={`dropdown-item ${activeItem === "latest" ? "active" : ""
              }`}
            onClick={() => onSelect("latest")}
          >
            Latest
          </button>
          <button
            className={`dropdown-item ${activeItem === "oldest" ? "active" : ""
              }`}
            onClick={() => onSelect("oldest")}
          >
            Oldest
          </button>
          <button
            className={`dropdown-item ${activeItem === "recommendation" ? "active" : ""
              }`}
            onClick={() => onSelect("recommendation")}
          >
            Recommendation
          </button>
          <button
            className={`dropdown-item ${activeItem === "bestmatches" ? "active" : ""
              }`}
            onClick={() => onSelect("bestmatches")}
          >
            Best matches
          </button>
        </div>
      )}
    </div>
  );
};

const FilterDropdown = ({
  featuredata,
  menuhighlitsData,
  stateData,
  ambienceData,
  specialdietryServiceData,
  handleFeaturesButtonclick,
  handleMenuButtonclick,
  handleAmbienceButtonclick,
  handleDietryButtonclick,
  handleStateButtonclick,
  handleTradeServiceApplyClick,
  featuesCategories,
  menucategory,
  ambiencecategory,
  statesCategory,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const onSelect = (item) => {
    setActiveItem(item);
  
    setIsOpen(false); // Close dropdown after selection
  };
  const [selectedStatuses, setSelectedStatuses] = useState([]);

  const handleCheckboxChange = (event) => {
    const value = event.target.value;
    if (event.target.checked) {
      setSelectedStatuses([...selectedStatuses, value]);
    } else {
      setSelectedStatuses(
        selectedStatuses.filter((status) => status !== value)
      );
    }
  };

  return (
    <div className="custom-dropdown filter-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Filter
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="apply-btns">
            <p className="filter-text">Filter</p>
            <div>
              <button className="reset-button">Reset</button>
              <button
                className="apply-btn"
                onClick={handleTradeServiceApplyClick}
              >
                Apply
              </button>
            </div>
          </div>
          <div className="prefered-oprational-status">
            <p>Preferred operational status</p>
            <label>
              <input
                type="checkbox"
                value="Planning to open"
                checked={selectedStatuses.includes("Planning to open")}
                onChange={handleCheckboxChange}
              />
              <span>Planning to open</span>
            </label>

            <label>
              <input
                type="checkbox"
                value="Open for business"
                checked={selectedStatuses.includes("Open for business")}
                onChange={handleCheckboxChange}
              />
              <span> Open for business</span>
            </label>

            <label>
              <input
                type="checkbox"
                value="Looking to exit"
                checked={selectedStatuses.includes("Looking to exit")}
                onChange={handleCheckboxChange}
              />
              <span>Looking to exit</span>
            </label>
          </div>
          <div>
            <div className="d-flex align-space-between category-head">
              <p>Features</p>
              <img src={ArrowdownIcon} alt="ArrowdownIcon" />
            </div>
            <div className="menu-items">
              {featuredata?.map((featuredata) => (
                <button
                  className={
                    featuesCategories?.includes(featuredata.id) ? "active" : ""
                  }
                  onClick={() => handleFeaturesButtonclick(featuredata.id)}
                >
                  {featuredata.name}
                </button>
              ))}
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>Menu highlights</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {menuhighlitsData?.map((menuhighlitsData) => (
                  <button
                    className={
                      menucategory?.includes(menuhighlitsData.id)
                        ? "active"
                        : ""
                    }
                    onClick={() => handleMenuButtonclick(menuhighlitsData.id)}
                  >
                    {menuhighlitsData.name}
                  </button>
                ))}
              </div>
            </div>

            <div>
              <div className="d-flex align-space-between category-head">
                <p>States</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {stateData?.map((stateData) => (
                  <button
                    onClick={() => handleStateButtonclick(stateData.id)}
                    className={
                      statesCategory?.includes(stateData.id) ? "active" : ""
                    }
                  >
                    {stateData.name}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Card = ({
  companyLogo,
  businessName,
  location,
  address,
  dietrayTypes,
  productTypes,
  serviceTypes,
  onActionClick,
  handleClikTradeserviceLike,
  features,
  menu_highlights,
  ambience,
  csbranchid,
  liked,
  decision,
  specialist_dietary_services,
  refetch
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [fstocsaccept, { }] = useFsacceptcafeMutation();
  // const [cstofsdeclined, {}] = useFscafeMutation();
  const currentbranchbranchId = localStorage.getItem(
    "fdservicebranchId_current"
  );

  const handleActiveClick = async () => {
    const postdata = {
      ce_branch_id: csbranchid,
      status: "pending",
    };
    try {
      const response = await fstocsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);
        // alert("Failed to Accept");
        refetch();
      }
    } catch (err) {
      alert("Failed to Accept");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  const handleInactiveClick = async () => {
    const postdata = {
      ce_branch_id: csbranchid,
      status: "pending",
    };
    try {
      const response = await fstocsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        // setIsActive(true);
        // setIsHidden(false);
        refetch();
        alert("Unblocked succesfully");
      }
    } catch (err) {
      alert("Failed to unblock");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  if (isHidden) {
    return null;
  }

  return (
    <div className="card">
      <div className="d-flex justify-space-between">
        <div className="d-flex">
          <div>
            <img src={companyLogo} alt="CompanyLogo" />
          </div>
          <div className="mlr-20">
            <h4>{businessName}</h4>
            <p>{location}</p>
          </div>
        </div>
        {/* <div>
          <img
            src={liked ? heartImgFill : heartImg}
            alt="heart-img"
            onClick={handleClikTradeserviceLike}
          />{" "}
          <img src={ActionIcon} alt="ActionIcon" onClick={onActionClick} />
        </div> */}
      </div>
      <div className="d-flex justify-space-between flex-end">
        <div>
          <p className="address">{address}</p>
        </div>

        <button type="button" className="unblock-btn-food" onClick={handleInactiveClick}>
          Rematch
        </button>
      </div>
      <hr />
      <div className="service-type types">
        <h5>Features</h5>
        {features?.map((type, index) => (
          <button key={index}>{type.name}</button>
        ))}
      </div>
      <div className="dietary-type types">
        <h5>Menu highlights</h5>
        {menu_highlights?.map((type, index) => (
          <button key={index}>{type.name}</button>
        ))}
      </div>
      <div className="product-type types">
        <h5>Ambience</h5>
        {ambience?.map((type, index) => (
          <button key={index}>{type.name}</button>
        ))}
      </div>
      <div className="product-type types">
        <h5>Dietary option</h5>
        {specialist_dietary_services?.map((type, index) => (
          <button key={index}>{type.name}</button>
        ))}
      </div>
    </div>
  );
};

function EcoSystemMatches() {
  const [activeTab, setActiveTab] = useState("tab1");
  const branchId = localStorage.getItem("fdservicebranchId_current");
  const username = localStorage.getItem("name");

  const [tradeServiceParams, settradeServiceParams] = useState({
    id: branchId,
    features: [],
    menu_highlights: [],
    ambience: [],
    dietryCategory: [],
    states: [],
    recommendation: "",
    ordering: "",
    delivery_range: "",
  });

  const [submitTradeLike, { }] = useFoodserviceLikeMutation();

  const [progress, setProgress] = useState(0);
  const [deliveryRange, setDeliveryRange] = useState("100km");
  const barRef = useRef(null);

  useEffect(() => {
    settradeServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      // delivery_range: 100,
    }));
    // setProgress(100);
    // setDeliveryRange("100km");
  }, []); // Empty dependency array to run only once on mount

  const updateProgress = useCallback((clientX) => {
    if (!barRef.current) return;
    const barRect = barRef.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100);
    const roundedvalue = Math.round(
      Math.min(Math.max((offsetX / barWidth) * 100, 0), 100)
    );
    settradeServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      delivery_range: roundedvalue,
    }));

    setProgress(newProgress);
    setDeliveryRange(`${Math.round(newProgress)}km`);
  }, []);

  const handleMouseDown = (e) => {
    updateProgress(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMove);
      },
      { once: true }
    );
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.buttons > 0) {
        updateProgress(e.clientX);
      }
    },
    [updateProgress]
  );

  const [upgradeModal, setupgradeModal] = useState(false);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  const { data: tradeServiceData, isLoading, refetch } =
    useDeclinedListQuery(branchId);

    useEffect(()=>{
      refetch();
    }, [])
  const handleClickOrdering = (orderingValue) => {
    if (orderingValue !== "bestmatches") {
      if (orderingValue !== "recommendation") {
        // Pass only the ordering value and clear recommendation
        settradeServiceParams((prevtradeServiceParams) => ({
          ...prevtradeServiceParams,
          ordering: orderingValue,
          recommendation: null, // Clear the recommendation if ordering is set
        }));
      } else {
        // Pass only recommendation and clear ordering
        settradeServiceParams((prevtradeServiceParams) => ({
          ...prevtradeServiceParams,
          recommendation: "most-liked",
          ordering: null, // Clear the ordering if recommendation is set
        }));
      }
    } else {
      settradeServiceParams({ id: branchId });
    }
  };


  const handleClikTradeserviceLike = async (id) => {
    const postdata = {
      ce_branch_id: id,
    };
    try {
      const response = await submitTradeLike({ postdata, branchId }).unwrap();
      if (response) {
        refetch();
      }
    } catch (err) {
      alert("Failed to like");
    }
  };
  const { data: featuredata } = useGetFeaturesQuery();
  const { data: ambienceData } = useGetAmbienceQuery();

  const { data: techstackData } = useGetTechStackQuery();

  const { data: menuhighlitsData } = useGetMenuHighlihtsQuery();

  const { data: specialdietryServiceData } =
    useGetSpecialistDietryServiceQuery();
  const { data: stateData } = useGetStatesQuery();

  const [featuesCategories, setFeaturesCategories] = useState([]);
  const handleFeaturesButtonclick = (serviceTypeId) => {
    setFeaturesCategories((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [menucategory, setMenu] = useState([]);
  const handleMenuButtonclick = (serviceTypeId) => {
    setMenu((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [ambiencecategory, setAmbience] = useState([]);
  const handleAmbienceButtonclick = (serviceTypeId) => {
    setAmbience((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [dietryCategory, setDietry] = useState([]);
  const handleDietryButtonclick = (serviceTypeId) => {
    setDietry((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [statesCategory, setStates] = useState([]);
  const handleStateButtonclick = (serviceTypeId) => {
    setStates((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleTradeServiceApplyClick = () => {
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      features: featuesCategories,
      menu_highlights: menucategory,
      ambience: ambiencecategory,
      dietryCategory: dietryCategory,
      states: statesCategory,
    }));
  };

  return (
    <div className="ecosystem-matches ecosysyem-match-foodservice">
      {" "}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4>Decline list</h4>
        </Col>
      </Row>
      <div className="match-tabs-container">
        <div className="match-tabs-content">
          {activeTab === "tab1" && (
            <div className="match-tab-content">

              <Row>
                {tradeServiceData ? (
                  tradeServiceData?.length > 0 ? (
                    tradeServiceData?.map((data, index) => (
                      <Col sm={12} xs={12} md={4} lg={4} key={index}>
                        <Card
                          companyLogo={data?.companyLogo || ComapnyLogo}
                          csbranchid={data.id}
                          businessName={data?.business_name || "N/A"}
                          location={data?.location || "N/A"}
                          address={data?.address || "N/A"}
                          status={data?.status || "N/A"}
                          liked={data.liked}
                          decision={data.decision}
                          features={data.additional_detail?.features || []}
                          menu_highlights={
                            data.additional_detail?.menu_highlights || []
                          }
                          ambience={data.additional_detail?.ambience || []}
                          specialist_dietary_services={
                            data.additional_detail
                              ?.specialist_dietary_services || []
                          }
                          onActionClick={data?.onActionClick}
                          handleClikTradeserviceLike={() =>
                            handleClikTradeserviceLike(data.id)
                          }
                          refetch={refetch}
                        />
                      </Col>
                    ))
                  ) : (
                    <div>No data available</div>
                  )
                ) : (
                  <div>No data available</div>
                )}
                {isLoading && <div class="loader-food"></div>}
              </Row>
            </div>
          )}
        </div>
      </div>
      {/* <div className="lock-page">
        <img src={LockIcon} alt={LockIcon} />
        <br />
        <Link  onClick={showupgradeModal}>
          <button type="button" className="upgrade-btn">Upgrade to pro</button>
        </Link>
      </div> */}
    </div>
  );
}

export default EcoSystemMatches;
