import React, { useEffect } from "react";
import CheckCircle from "../Assets/Images/check-circle.svg";
import "../Styles/CcOwnerAccountActivation.css";
import { useNavigate } from "react-router-dom";
import PaymentCancelledIcon from "../Assets/Images/payment-cancelled.svg";
import Navbar from "../Components/Navbar";

function PaymentCancelled() {
  return (
    <div>
      {" "}
      <Navbar />
      <section className="Ccowner-account-activation">
        <img
          src={PaymentCancelledIcon}
          n
          alt="CheckCircle"
          className="check-circle"
        />
        <h3>Payment Cancelled</h3>
        <p>
          Welcome to our community. Now, it's time to start building your
          profile. If you{" "}
          <span className="disply-blk">
            need any assistance, click{" "}
            <span className="support">"Support"</span> and we will contact you
            as soon as possible{" "}
          </span>
          to keep you moving forward.
        </p>
      </section>
    </div>
  );
}

export default PaymentCancelled;
