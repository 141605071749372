import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import VerifiedImg from "../Assets/Images/verified.png";
import VerifyOtp from "../Components/VerifyOtpModal";
import AlertImg from "../Assets/Images/email-alert-yellow.svg";
import { useGetAccountHolderDataQuery, useSubmitAccountHolderDataMutation, useGetStatesQuery, useVerifyContactNumberMutation } from "../Services/CcownerAPI";
import { Link, useLocation, useNavigate } from "react-router-dom";

const AccountHolderDetails = ({ data, onNext, BranchId }) => {
  const [showVerifyOtpModal, setShowVerifyOtpModal] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    contactNumber: "",
    state: "",
    postCode: "",
  });
  const [errors, setErrors] = useState({});
  const [formValid, setFormValid] = useState(false);
  const location = useLocation();
  const { showcreateList, locationdata } = location.state || {};
  const [phoneverified, setPhoneverified] = useState(false);

  const { data: statesData } = useGetStatesQuery();
  const { data: accouldHolderData, isLoading: accountholderdataloading, refetch } = useGetAccountHolderDataQuery();
  const [submitForm, { isLoading, isError, isSuccess, error }] = useSubmitAccountHolderDataMutation();
  const [submitContactNum] = useVerifyContactNumberMutation();

  useEffect(() => {
    if (accouldHolderData) {
      setFormData({
        firstName: accouldHolderData.first_name || "",
        lastName: accouldHolderData.last_name || "",
        email: accouldHolderData.email || "",
        contactNumber: accouldHolderData.contact_number || "",
        state: accouldHolderData.state || "",
        postCode: accouldHolderData.post_code || "",
      });
      setPhoneverified(accouldHolderData.con_num_verify);
    } else if (data) {
      setFormData(data);
    }
  }, [accouldHolderData, data]);

  useEffect(() => {
    const { firstName, lastName, email, contactNumber, state, postCode } = formData;
    if (firstName && lastName && email && contactNumber && state && postCode) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [formData]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First name is required.";
    if (!formData.lastName) newErrors.lastName = "Last name is required.";
    if (!formData.email) newErrors.email = "Email is required.";
    if (!formData.contactNumber) newErrors.contactNumber = "Contact number is required.";
    if (!formData.state) newErrors.state = "State is required.";
    if (!formData.postCode) newErrors.postCode = "Post code is required.";
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      let PostData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        contact_number: formData.contactNumber,
        state: formData.state,
        post_code: formData.postCode
      };
      try {
        const response = await submitForm(PostData).unwrap();
        if (response) {
          if (BranchId) {
            onNext(BranchId);
          }
          else {
            onNext();
          }
        }
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    } else {
      setErrors(newErrors);
    }
  };

  const handleVerify = async () => {
    let postData = {
      contact_number: '+61' + formData.contactNumber
    };
    try {
      const response = await submitContactNum(postData).unwrap();
      setShowVerifyOtpModal(true);
    } catch (err) {
      alert(error?.data?.error);
    }
  };

  const handleCloseModal = () => {
    setShowVerifyOtpModal(false);
  };
  const handleKeyPressNumersOnly = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
    }
  };
  return (
    <div className="add-details">
      <h4>Account Holder Details</h4>
      <p>
        Pro Members can respond to ecosystem inquiries with hidden account
        holder details for admin use only.
      </p>
      <hr />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                First name <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                name="firstName"
                placeholder="John"
                value={formData.firstName || ""}
                onChange={handleInputChange}
              />
              {errors.firstName && <div className="error-message">{errors.firstName}</div>}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Last name <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                name="lastName"
                placeholder="Mathew"
                value={formData.lastName || ""}
                onChange={handleInputChange}
              />
              {errors.lastName && <div className="error-message">{errors.lastName}</div>}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Email <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                name="email"
                placeholder="abcd123@gmail.com"
                value={formData.email || ""}
                onChange={handleInputChange}
                disabled
              />
              <div className="email-alert">
                <div className="tooltip-common">
                  <img src={AlertImg} alt="AlertImg" />
                  <span className="tooltip-commontext tooltip-mob-text">
                    Ensures added account security
                  </span>
                </div>
              </div>
              {errors.email && <div className="error-message">{errors.email}</div>}
              <img src={VerifiedImg} alt="Verified" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Contact Number <span className="red-star">*</span>
              </label>
              <br />
              <div style={{ display: 'flex', alignItems: 'center' }} className="phone-input">
                <span style={{ marginRight: '5px' }}>+61</span>
                <input
                  type="text"
                  name="contactNumber"
                  placeholder="XXXXXXXXXX"
                  value={formData.contactNumber || ""}
                  onChange={handleInputChange}
                  maxLength={10}
                  onKeyPress={handleKeyPressNumersOnly}
                  disabled={phoneverified}
                />
              </div>
              {errors.contactNumber && <div className="error-message">{errors.contactNumber}</div>}
              {phoneverified ? <img src={VerifiedImg} alt="Verified" /> : (
                <span className="verify-text">Not verified</span>
              )}
              {!phoneverified && (
                <div className="verify-phone" onClick={handleVerify}>
                  Verify
                </div>
              )}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                State <span className="red-star">*</span>
              </label>
              <br />
              <select
                name="state"
                value={formData.state || ""}
                onChange={handleInputChange}
              >
                <option value="">Select</option>
                {statesData?.map((state) => (
                  <option key={state.id} value={state.id}>{state.name}</option>
                ))}
              </select>
              {errors.state && <div className="error-message">{errors.state}</div>}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label>
                Post Code <span className="red-star">*</span>
              </label>
              <br />
              <input
                type="text"
                name="postCode"
                placeholder="XXXX"
                value={formData.postCode || ""}
                onChange={handleInputChange}
              />
              {errors.postCode && <div className="error-message">{errors.postCode}</div>}
            </div>
          </Col>
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button type="submit" className={`next-btn btn-primary-disabled ${formValid ? "btn-primary-valid" : ""
              }`}>
              Next
            </button>
          </Col>
        </Row>
      </form>
      <VerifyOtp show={showVerifyOtpModal} refetch={refetch} handleClose={handleCloseModal} contactnum={formData.contactNumber} />
    </div>
  );
};

export default AccountHolderDetails;
