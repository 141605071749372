import React, { useState } from "react";
import ProStar from "../Assets/Images/crown-star-pro.svg";
import ManageListing from "../Assets/Images/manage-listing.svg";
import CreateListing from "../Assets/Images/create-listing.svg";
import ActionIMg from "../Assets/Images/actions-img.svg";
import LocationIcon from "../Assets/Images/location-icon.svg";
import { Row, Col } from "react-bootstrap";
import AddLocationMOdal from "../Components/AddAnotheLocation";
import "../Styles/DirectoryPayment.css";
import { Link, useLocation } from "react-router-dom";
import DowngradetoLiteModal from "../Components/DowngradetoLiteModal";
import {useGetCompletedBranchesQuery,useGetInCompletedBranchesQuery} from "../Services/CcownerAPI"
import { useNavigate } from "react-router-dom";
import Swal from 'sweetalert2';

function DashboardDirectoryListing() {
  const [showAddLocationpModal, setshowAddLocationpModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [upgradeModal, setupgradeModal] = useState(false);
  const navigate = useNavigate();
const handleCheckboxChange = (id, isPro) => {
  setSelectedLocations((prevSelected) => {
    const isSelected = prevSelected.some((loc) => loc.id === id);
    if (isSelected) {
      return prevSelected.filter((loc) => loc.id !== id);
    } else {
      return [...prevSelected, { id, payment: isPro ? 47 : 0 }];
    }
  });
};


const selectedBranchIds = selectedLocations.map((loc) => loc.id);




  const handleshowAdLocationModal = () => {
    setshowAddLocationpModal(true);
  };
  const handleCloseUpgradModal =() =>{
    setupgradeModal(false);
  }
  const handleCloseModal = () => {
    setshowAddLocationpModal(false);
  };
  const showupgradeModal = () => {
    if(selectedLocations.length>0){
    setupgradeModal(true);
    }
    else{
      Swal.fire({
        icon: 'error',
        text: 'Please choose a location',
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
     }
  };
 

  // const proLocations = locations.filter((loc) => loc.status === "Pro");
  // const liteLocations = locations.filter((loc) => loc.status === "Lite");
  // const unlistedLocations = locations.filter(
  //   (loc) => loc.status === "Unlisted"
  // );

  const { data: unlistedLocations,isLoading } = useGetInCompletedBranchesQuery();
    const { data: completedbranch, isLoading:isLoadingCompletedData } = useGetCompletedBranchesQuery();

  const proLocations = completedbranch?.filter((loc) => loc?.subscription_type === "pro");
  const liteLocations = completedbranch?.filter((loc) => loc?.subscription_type === "lite");

  const handleBack = ()=>{
    navigate(-1);
  }

  return (
        <div className="dashboard-listing">
          <Row>
            <Col xs={12} sm={12} md={8} lg={8}>
              <h4>Account & Directory Listing</h4>
              <p className="sub-head">
                Complete directory listings amplify engagement. Ensure your
                business shines by filling in all criteria.
              </p>
            </Col>
            <Col xs={12} sm={12} md={4} lg={4} className="text-end">
              <button
                className="add-another-location"
                onClick={handleshowAdLocationModal}
              >
                Add another location
              </button>
            </Col>
          </Row>
          {isLoading && <div class="loader"></div>}
          {isLoadingCompletedData && <div class="loader"></div>}
          {/* Pro Locations */}
          {proLocations?.length > 0 && (
            <div className="list-card">
              <Row className="flagship-div">
                <Col xs={12} sm={12} md={12} lg={12}>
                  <p>
                    <img src={ProStar} alt="Star" />
                    <span>Pro</span>
                  </p>
                </Col>
              </Row>
              {proLocations?.map((location) => (
              <>{!location?.ce_subscription_record?.subscription_cancel_at_period_end &&   <div className="card" key={location.id}>
                  <Row className="brdr-btm">
                  <Col xs={12} sm={12} md={8} lg={8}>
                      <div className="d-flex">
                        <div className="mr-15">
                          <input
                            type="checkbox"
                            onChange={() =>
                              handleCheckboxChange(
                                location.id,
                                selectedLocations.some(
                                  (loc) =>
                                    loc.id === location.id && loc.payment === 47
                                )
                              )
                            }
                            checked={selectedLocations.some(
                              (loc) => loc.id === location.id
                            )}
                          />
                        </div>
                        <div>
                          <p className="location-head">
                          {location.location ?? "Location"}
                    {location.operational_status.length > 0 ? (
                      location.operational_status.map((status) => (
                        <button
                          key={status.id}
                          className="open-for-business-btn"
                        >
                          {status.name}
                        </button>
                      ))
                    ) : (
                      <button className="open-for-business-btn">
                        Unknown status
                      </button>
                    )}
                          </p>
                          <p className="address">{location.address ?? "Address"}</p>
                          {/* <Link to="/upgrade-to-pro">
                            <p className="upgrade-downdrade-btn">
                            Downgrade to Lite
                            </p>
                          </Link> */}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12} sm={4} md={4} lg={4} className="text-end">
                      <img src={ActionIMg} alt="ActionIMg" />
                      <div className="progressbar-div">
                        <p className="percentage-text">
                        {location.completion_percentage.toFixed(2)}% Completed
                        </p>
                        <div className="progress">
                          <div
                            className="progress-bar"
                            role="progressbar"
                            style={{ width: `${location.completion_percentage}%` }}
                            aria-valuenow= {location.completion_percentage}
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <div className="manage-listing">
                      <p>
                        <img src={ManageListing} alt="ManageListing" />
                        Manage listing
                      </p>
                    </div>
                  </Row>
                </div>}</> 
              ))}
            </div>
          )}


          <div className="selected-location-proceed">
            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="mob-selected-location-div">
                <p className="selected">Selected</p>
                <p className="location-count">{selectedLocations.length} locations </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                {" "}
                <Row className="action-btn">
                  <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                    <button type="button" className="prev-btn" onClick={handleBack}>
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn-save-primary"
                      onClick={showupgradeModal}
                    >
                      Proceed
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <DowngradetoLiteModal selectedBranchIds={selectedBranchIds} show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} />
          <AddLocationMOdal
            show={showAddLocationpModal}
            handleCloseLocationModal={handleCloseModal}
          />
        </div>
  );
}

export default DashboardDirectoryListing;
