import React from "react";
import { Accordion, Card, Button } from "react-bootstrap";
import "../Styles/HomeQuestioner.css";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import { Helmet } from 'react-helmet';

const AccordionComponent = () => {
  <Helmet>
  <title>Clever Cafe | FAQ - Your Cafe Industry Platform Questions Answered
   </title>
  <meta name="description" content="Explore our FAQ section to find quick and detailed answers to all your questions about the Clever Cafe platform and how it benefits the cafe industry." />
  <meta name="keywords" content="cafe industry supporter in australia, cafe industry in australia, opening a cafe checklist, australian cafe entrepreneurship, cafe planning and modelling in australia" />
</Helmet>
  return (
    <section className=" section accordian-content">
      <div className="accordian-section">
        <h3>Frequently Asked Questions</h3>
        {/* <p>
          Lorem ipsum dolor sit amet consectetur. Diam vitae porttitor enim
          ultrices. Id <br />
          lacus at praesent nec ornare non faucibus .
        </p> */}
        <div className="accordian-box">
          <Tabs
            defaultActiveKey="cafeenterprenurship"
            id="uncontrolled-tab-example"
            className="mb-3"
          >
            <Tab
              eventKey="foodservices"
              title=" 
 	
Foodservice Accounts & Membership"
            >
              <div>
                <Accordion>
                  <Accordion.Item>
                    <Accordion.Header>
                      What Are the Benefits of Listing a Business in the Cafe
                      Foodservice Industry Directory?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        When you register your business in the foodservice industry directory,
                        you join our ecosystem, where we match you with business owners that
                        align with your offerings and location. Our platform provides ongoing,
                        tailored support and matches, showcasing what makes your business stand out
                        in an ad-free environment.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      Can I Have More Than One Branch in the Same Account?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, you can. Hosting multiple branches with their own
                        directory listings under one account extends your reach
                        to a wider audience of cafe startups and established
                        operators, both locally and beyond. This ensures you
                        connect with those that align with your operation,
                        products, and services.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      How Do Cafe Entrepreneurs Connect with Me?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We facilitate two-way communication with cafe members and provide
                        direct links for all members to register an account with you.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      What Types of Foodservice Businesses Are Relevant to Cafe
                      Entrepreneurs?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We welcome all foodservice sectors to join the industry directory,
                        including suppliers, distributors, brands, manufacturers, and producers
                        offering products for cafe menus. The account criteria clarify how cafe
                        entrepreneurs can best utilise your services.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Is there a free trial, and what happens after it ends?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, you’ll enjoy 1 month of free access to explore all features. Once the trial ends, you can subscribe to a paid account, customised to align with your geographical, operational, and advertising needs. Remember, your membership fee will be tax-deductible.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Tab>
            <Tab
              eventKey="cafeenterprenurship"
              title="Cafe Entrepreneurs Accounts & Membership
"
            >
              <div>
                <Accordion>
                  <Accordion.Item>
                    <Accordion.Header>
                      What Is the Difference Between Lite & Pro Accounts?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Pro offers an all-inclusive package, while Lite follows a pay-as-you-go approach.
                        Every cafe member receives free onboarding, an expert menu review,
                        and a premium directory listing as standard.
                      </p>
                      {/* <br />
                      <p>
                        Lite Accounts <br />
                        Lite accounts offer limited services but still provide
                        access to our directories, ensuring accessibility for
                        all members.
                      </p> */}
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How Do the Ecosystem & Directory Help Me Reach More
                      Customers?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We feature startup and established cafe members in our public directory
                        at no cost, promoting them to both local and national audiences
                        through social media, online ads, email campaigns, and business partnerships
                        to ensure they are easily discovered.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I Create an Account if I'm Just Planning to Open a
                      Cafe & It's Not Open Yet?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, you can. Whether you're researching, calculating startup costs,
                        or preparing to open, our online pre-launch support is here to assist.
                        Create your directory listing in 'Planning to Open'
                        mode and update your details as you go. Any missing information will be marked
                        'coming soon.'
                      </p>
                      <p>
                        When you're ready, switch to 'Open for Business' mode to seamlessly integrate
                        your full details. Our team and ecosystem will provide tailored support as you grow.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      Do I Need a Cafe Business Plan to Open or Refine My Cafe
                      Business?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Prior to opening a cafe, it's wise to develop a
                        comprehensive cafe business plan. While plans may evolve
                        post-opening, having a clear strategy remains
                        invaluable. We offer guidance in various aspects,
                        including finding optimal cafe locations, defining
                        target audiences, menu creation, marketing strategies,
                        financial planning, and contingency preparations.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      Is there a free trial, and what happens after it ends?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        You’ll get 1 month of free access to explore all Pro features. After the trial, choose between the Lite plan (pay-as-you-go) or the Pro All-in-One plan (full access). There’s no commitment required until you decide. And remember, your membership fee is tax-deductible.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </Tab>
            <Tab
              eventKey="trade"
              title="Trades & Services Accounts & Membership"
            >
              <div>
                <Accordion>
                  <Accordion.Item>
                    <Accordion.Header>
                      What Are the Benefits of Listing a Business in the Cafe
                      Trades & Services Industry Directory?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        When you register your business in the trades and services industry directory,
                        you join our ecosystem, where we match you with business owners that align with
                        your services, products and location. Our platform provides ongoing, tailored
                        support and matches, showcasing what makes your business stand out in an ad-free environment.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="2">
                    <Accordion.Header>
                      How Do the Ecosystem & Directory Help Me Reach More
                      Customers?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We feature our members in our public directory at no
                        cost, targeting both local and national audiences. We
                        actively promote the directory through social media,
                        online ads, email campaigns, and partnerships with
                        related businesses and organisations, ensuring potential
                        customers discover the listed cafes. Detailed profiles
                        highlight each cafe’s story, menu, branding, social
                        media, photos, events, location, and hours, offering
                        targeted exposure 24/7, 365 days a year.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item> */}
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>
                      Can I Have More Than One Branch in the Same Account?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, you can. Hosting multiple branches with their own
                        directory listings under one account extends your reach
                        to a wider audience of cafe startups and established
                        operators, both locally and beyond. This ensures you
                        connect with those that align with your operation,
                        trades, and services.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="4">
                    <Accordion.Header>
                      How Do Cafe Entrepreneurs Connect with Me?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We facilitate two-way communication with cafe members and
                        provide direct links for all members to register an account with you.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="5">
                    <Accordion.Header>
                      What Types of Trades & Services Are Relevant to Cafe
                      Entrepreneurs?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        We offer over 30 areas of operational support, which you can
                        explore through tooltips before signing up. The account criteria help
                        cafe entrepreneurs understand how to best utilise your services.
                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="6">
                    <Accordion.Header>
                      Is there a free trial, and what happens after it ends?
                    </Accordion.Header>
                    <Accordion.Body>
                      <p>
                        Yes, you’ll enjoy 1 month of free access to explore all features. Once the trial ends, you can subscribe to a paid account, customised to align with your geographical, operational, and advertising needs. Remember, your membership fee will be tax-deductible.

                      </p>
                    </Accordion.Body>
                  </Accordion.Item>

                </Accordion>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </section>
  );
};

export default AccordionComponent;
