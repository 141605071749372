import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import AddNewTicket from "../Components/AddNewTicket";
import SearchIcon from "../../../Assets/Images/search-icon.svg";
import { Table } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useFetchTicketsQuery } from "../../../Services/SupportAPI";
import "../Styles/Support.css";

function FoodServicesSupport() {
  const [showAddNewModal, setshowAddNewModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState(""); // Add state for search term
  const handleAddNew = () => {
    setshowAddNewModal(true);
  };
  const handlecloseModal = () => {
    setshowAddNewModal(false);
  };

  const { data: ticketList, isLoading, refetch } = useFetchTicketsQuery();

  // Filter tickets based on search term
  const filteredTickets = ticketList?.tickets?.filter((ticket) =>
    ticket.category_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ticket.subcategory_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ticket.status.toLowerCase().includes(searchTerm.toLowerCase()) ||
    ticket.Assigned_To.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="support support-food">
      <Row>
        <Col xs={12} sm={12} md={9} lg={9}>
          <h4>Support</h4>
          <p className="sub-head">
            We recognise that all issues are important and aim to resolve all
            tickets by the next business day or sooner.
          </p>
        </Col>
        <Col xs={12} sm={12} md={3} lg={3} className="text-end">
          <button className="add-ticket-food" onClick={handleAddNew}>
            Create new ticket
          </button>
        </Col>
      </Row>
      <Row className="category-search">
        <Col xs={12} sm={12} g={6} md={6}>
          <h5>My tickets</h5>
        </Col>
        <Col xs={12} sm={12} g={4} md={4} className="text-end">
          <form>
            <div className="search-box-div">
              <img src={SearchIcon} alt="SearchIcon" className="SearchIcon" />
              <input
                type="text"
                placeholder="search"
                name="search"
                className="serach-box"
                value={searchTerm} // Bind search term state
                onChange={(e) => setSearchTerm(e.target.value)} // Update search term state
              />
            </div>
          </form>
        </Col>
      </Row>
      <div>
      <div className="table-div">
        <Table bordered>
          <thead>
            <tr>
              <th>Category</th>
              <th>Subcategory</th>
              <th>Added on</th>
              <th>Status</th>
              <th>Assigned to</th>
              <th>View image</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {filteredTickets?.length > 0 ? (
              filteredTickets.map((ticket) => (
                <tr key={ticket.id}>
                  <td className="ticket-category">{ticket.category_name}</td>
                  <td>{ticket.subcategory_name}</td>
                  <td>{ticket.added_on}</td>
                  <td>{ticket.status}</td>
                  <td>
                    {ticket.Assigned_To}
                    <span className="ticket-category-assigneto">
                      {ticket.category}
                    </span>
                  </td>
                  <td className="text-end">
                 {ticket.file_url && <a href={ticket.file_url} target="_blank">
                      <button className="btn-primary-text-food">View image</button>
                    </a>}
                  </td>
                  <td>
                    <Link to={`/food-support-viewticket/${ticket.id}`}>
                      <button className="ticket-view-btn-food" type="button">
                        View ticket
                      </button>
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="no-data">
                  No data available
                </td>
              </tr>
            )}
          </tbody>
        </Table>
        </div>
        {isLoading && <div class="loader-food"></div>}
      </div>
      <AddNewTicket
        show={showAddNewModal}
        handleCloseAddNewModal={handlecloseModal}
        refetch={refetch}
      />
    </div>
  );
}

export default FoodServicesSupport;
