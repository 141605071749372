import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import DummyPdf from "../Assets/pdf/dummy-pdf1.pdf";
import {useEditRecordsMutation} from "../Services/SecondaryAPI"

function PdfEdit() {
  const location = useLocation();
  const { record } = location.state || {};
  const [filepath, setFilepath] = useState("");
  const containerRef = useRef(null);
  const pspdfkitInstance = useRef(null); // Store PSPDFKit instance
  const [submitForm, { isLoading, isError, isSuccess, error }] = useEditRecordsMutation();


  useEffect(() => {
    if (record?.record?.file_path) {
      setFilepath(record?.record?.file_path); // dynamic path
    } else {
      setFilepath(DummyPdf); // fallback PDF if no path in record
    }
  
  }, [record]);

  useEffect(() => {
    let PSPDFKit;
  
    if (filepath) {
      (async function () {
        try {
          PSPDFKit = await import("pspdfkit");
  
          // Initialize PSPDFKit instance
          pspdfkitInstance.current = await PSPDFKit.load({
            container: containerRef.current, // The container to mount PSPDFKit
            document: filepath, // The dynamically loaded PDF document
            baseUrl: process.env.REACT_APP_BASEURL_CLIENT, // Base URL for PSPDFKit assets
  
            // Customize the toolbar to remove the download button
            toolbarItems: PSPDFKit.defaultToolbarItems.filter(
              (item) => item.type !== "export-pdf" // Ensure "export-pdf" is excluded (download button)
            ),
          });
        } catch (error) {
          console.error("Error loading PSPDFKit", error);
        }
      })();
    }
  
    // Cleanup when component unmounts or filepath changes
    return () => {
      if (pspdfkitInstance.current) {
        // Ensure the instance or container exists before unloading
        PSPDFKit.unload(containerRef.current) // Or use pspdfkitInstance.current if appropriate
          .catch((error) => {
            console.error("Error unloading PSPDFKit", error);
          });
  
        pspdfkitInstance.current = null; // Clear the instance after unloading
      }
    };
  }, [filepath]);


  // const handleSave = async () => {
  //   try {
  //     const pdfBlob = await pspdfkitInstance.current.exportPDF();
  //     const pdfUrl = URL.createObjectURL(new Blob([pdfBlob], { type: "application/pdf" }));
  //     window.open(pdfUrl, "_blank");
      
  //     setTimeout(() => {
  //       URL.revokeObjectURL(pdfUrl);
  //     }, 10000); 

  //   } catch (error) {
  //     console.error("Error exporting or displaying PDF", error);
  //     alert("An error occurred while saving the PDF.");
  //   }
  // };

  const handleSave = async (e) => {
    e.preventDefault();
    try {
   
  
      // Get the PDF data (ArrayBuffer or Blob)
      const pdfData = await pspdfkitInstance.current.exportPDF();
    
  
      const recordId = record?.id;
  
      // Check if the returned data is not already a Blob
      let pdfBlob;
      if (pdfData instanceof Blob) {
        pdfBlob = pdfData;
      } else {
        // Convert ArrayBuffer or Uint8Array to Blob if needed
        pdfBlob = new Blob([pdfData], { type: "application/pdf" });
      }
  
      // Prepare FormData
      const formData = new FormData();
      formData.append("file", pdfBlob, "document.pdf");
      formData.append("recordId", recordId);
  
  
  
      // Submit the formData to the backend
      const response = await submitForm({formData,recordId}).unwrap();
   
  
      if (response) {
        alert("PDF saved successfully!");
        window.location.replace("/my-materials")
      }
  
    } catch (error) {
      console.error("Error occurred in the try block:", error);
      alert("An error occurred while saving the PDF.");
    }
  };
  

  return (
    <div>
      <div className="d-flex justify-content-space-between">
      <h3>PDF Editor</h3>
      <button onClick={handleSave} style={{ marginTop: "10px" }} className="btn-primary btn-pdfedit">
            Save pdf
          </button>
      </div>
      
      {filepath ? (
        <div>
          <div ref={containerRef} style={{ width: "100%", height: "80vh" }} />
          
        </div>
      ) : (
        <p>No PDF file available.</p>
      )}
    </div>
  );
}

export default PdfEdit;
