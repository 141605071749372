import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "../Styles/Navbar.css";
import Logo from "../Assets/Images/logo.svg";
import ArrowDown from "../Assets/Images/arrow-down-menu.svg";
import { Dropdown, Nav, } from "react-bootstrap";
import AlertBoxModalDeleted from "../Components/AlertBoxAccountDeleted"
import ProfileIMg from "../Assets/Images/default-profile-img.png";
import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useGetAccountHolderDataQuery,
  useUserGetEditQuery,
  useCafeNotificationMarkAllReadMutation,
  useLogoutMutation
} from "../Services/CcownerAPI";

import {
  useGetRefreshTokenMutation
} from "../Services/CommonAPI"

import {
  useUserGetEditfsQuery,
} from "../Services/FoodserviceAPI";

import {
  useUserGetEditTsQuery,
} from "../Services/TradeserviceAPI";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const [accessToken, setAccessToken] = useState(null);
  const [username, setUsername] = useState("")
  const [userImage, setUserImage] = useState("")

  const { data: userDatace } = useUserGetEditQuery();
  const { data: userDatafs } = useUserGetEditfsQuery();
  const { data: userDatats } = useUserGetEditTsQuery();
  const [showAlertModal, setShowAlertModal] = useState(false);
  useEffect(() => {
    if (userDatace) {
      setUsername(userDatace?.username);
      setUserImage(userDatace.image)
    }

  }, [userDatace])


  useEffect(() => {
    if (userDatafs) {
      setUsername(userDatafs?.username);
      setUserImage(userDatafs.image)
    }

  }, [userDatafs])


  useEffect(() => {
    if (userDatats) {
      setUsername(userDatats?.username);
      setUserImage(userDatats.image)
    }

  }, [userDatats])


  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const [showDropdown, setShowDropdown] = useState(false);
  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken, error }] =
    useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")
  const [showDefaultNavbar, setShowDefaultNavbar] = useState(false)
  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {

        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {

    if (isErrorRefreshToken) {
      const ErrorMessage = error?.data?.detail;
      if (ErrorMessage == "User does not exist.") {
        setShowAlertModal(true);
      }
      else{
        setShowDefaultNavbar(true);
        localStorage.clear();
      }
     
    }
   
  }, [isErrorRefreshToken, error])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 1000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  const toggleDropdownMenu = () => {
    setShowDropdown(!showDropdown);
  };

  // Check localStorage for accessToken when the component mounts
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    setAccessToken(token);
  }, []);
  const navigate = useNavigate();
  const [submitLogout, { isSuccess: isSucessLogout }] = useLogoutMutation();
  const handleLogout = async () => {

    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitLogout(postData).unwrap();
      if (response) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("accountHolderId");
        localStorage.removeItem("subscription_type_current_selected");
        localStorage.removeItem("branchId_current_selected");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("formData");
        localStorage.removeItem("completedSteps");
        localStorage.clear();
        window.location.reload();
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }



  };

  useEffect(() => {
    if (isSucessLogout) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accountHolderId");
      localStorage.removeItem("subscription_type_current_selected");
      localStorage.removeItem("branchId_current_selected");
      localStorage.removeItem("activeTab");
      localStorage.removeItem("formData");
      localStorage.removeItem("completedSteps");
      localStorage.clear();
      window.location.reload();
    }
  }, [isSucessLogout])
  const handleAccountSettings = () => {
    if (userDatace) {
      navigate("/preference");
    }
    else if (userDatafs) {
      navigate("/food-service-preference")
    }
    else if (userDatats) {
      navigate("/trade-preference")
    }
  };
  const handleCloseAlertModal = ()=>{
    localStorage.clear();
    setShowAlertModal(false)
    setShowDefaultNavbar(true);
  }

  return (
  <>
    <nav className="navbar web-nav">
      <div className="nav-logo">
        <Link to="/" onClick={toggleMenu}>
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <div className={`nav-links ${isOpen ? "open" : ""}`}>
        <Link
          to="/"
          className={location.pathname === "/" ? "active" : ""}
          onClick={toggleMenu}
        >
          Home
        </Link>
        <Dropdown
          show={showDropdown}
          onToggle={toggleDropdownMenu}
          className="main-nav-dropdown"
        >
          <Dropdown.Toggle
            variant="link"
            className={location.pathname.includes("/service") ? "active" : ""}
            id="dropdown-basic"
          >
            Services <img src={ArrowDown} alt="ArrowDown" />
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item
              as={Link}
              to="/service-cafe-startup"
              onClick={toggleMenu}
            >
              Cafe Entrepreneurship
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/service-food" onClick={toggleMenu}>
              Foodservice
            </Dropdown.Item>
            <Dropdown.Item as={Link} to="/service-trade" onClick={toggleMenu}>
              Trades & Services
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Link
          to="/public-cafe-directory"
          className={
            location.pathname === "/public-cafe-directory" ? "active" : ""
          }
          onClick={toggleMenu}
        >
          Public Cafe Directory
        </Link>
        <Link
          to="/about-us"
          className={location.pathname === "/about-us" ? "active" : ""}
          onClick={toggleMenu}
        >
          About Us
        </Link>
        <Link
          to="/contact-us"
          className={location.pathname === "/contact-us" ? "active" : ""}
          onClick={toggleMenu}
        >
          Contact
        </Link>
        {/* Conditionally render Sign Up and Log In buttons */}
        {!accessToken || showDefaultNavbar ? (
          <>
            <Link to="/common-signup" onClick={toggleMenu}>
              <button type="button" className="btn primary-button sign-up-btn">
                Sign Up
              </button>
            </Link>
            <Link to="/cafe-login" onClick={toggleMenu}>
              <button type="button" className="btn primary-button">
                Log In
              </button>
            </Link>
          </>
        ) : (
          <div className="profile-dropdown web-profile-dropdown">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Nav.Link}
                id="profile-dropdown"
                className="d-flex align-items-center"
              >
                <img
                  src={userImage || ProfileIMg}
                  alt="ProfileIMg"
                  className="nav-profile-img"
                />
                <span className="ms-2">{username}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleAccountSettings}>
                  Account Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}



        {/* {showDefaultNavbar ? (
          <>
            <Link to="/common-signup" onClick={toggleMenu}>
              <button type="button" className="btn primary-button sign-up-btn">
                Sign Up
              </button>
            </Link>
            <Link to="/cafe-login" onClick={toggleMenu}>
              <button type="button" className="btn primary-button">
                Log In
              </button>
            </Link>
          </>
        ):()} */}

      </div>
     
      <div className="nav-toggle" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </nav>
     <AlertBoxModalDeleted show={showAlertModal} handleCloseAlertModal={handleCloseAlertModal}/>
     </>
  );
};

export default Navbar;
