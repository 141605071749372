import React, { useRef, useEffect, useState } from "react";
import "../Styles/HomeEntrepreneship.css";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import Elipse from "../Assets/Images/enterprenurship-elipse1.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import Protext from "../Assets/Images/Pro.svg";
import Elipse1 from "../Assets/Images/enterprunurshipelipse1.svg";
import Elipse2 from "../Assets/Images/enterprenurship-elipse2.svg";
import Elipse3 from "../Assets/Images/entreprenurship-elipse3.svg";
import Elipse4 from "../Assets/Images/entreprenurshio-elipse4.svg";
import Celabrationgif from "../Assets/Images/Ceebration gif.gif";
import ProPng from "../Assets/Images/Pro-png.png";
import TradersElipse from "../Assets/Images/traders-elipse.svg";
import { Link, useLocation } from "react-router-dom";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function HomeEntrepreneship() {
  const settings = {
    initialSlide: 1, // This will make the second slide the initial center slide
    centerMode: true,
    centerPadding: "1%",
    slidesToShow: 1,
    autoplay: false,
    arrows: false,
    infinite: true,
    draggable: true,
    variableWidth: true,
    loop: true,
  };
  const [isChecked, setIsChecked] = useState(false);
  const [showGif, setShowGif] = useState(false);

  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
        }
      },
      { threshold: 0.1 } // Adjust the threshold as needed
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current);
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current);
      }
    };
  }, []);
  const handleToggle = () => {
    setIsChecked(!isChecked);
    if (!isChecked) {
      setShowGif(true);
    }
  };
  useEffect(() => {
    let timer;
    if (showGif) {
      timer = setTimeout(() => {
        setShowGif(false);
      }, 3000);
    }
    return () => clearTimeout(timer);
  }, [showGif]);
  return (
    <>
      <section className="section home-entreprenurship desktop-view">
        <div className="entreprenurship-bg" ref={sectionRef}>
          <h3 className={isVisible ? "fade-in" : ""}>
            Choose your solution and access your
            <span> cafe ecosystem membership</span>
          </h3>
          {/* <div className="entreprenurship-tab-section" style={{ backgroundColor: isChecked ? 'rgb(255 240 249)' : '#fff' }}> */}
          <div
            className={
              isChecked
                ? "entreprenurship-tab-section-pro"
                : "entreprenurship-tab-section"
            }
          >
            <div className="overlay">
              {" "}
              <img src={ProPng} alt="Overlay Image" />
            </div>
            <Tabs
              defaultActiveKey="Entrepreneurship"
              id="uncontrolled-tab-example"
              className="mb-3"
            >
              <Tab eventKey="foodservices" title="foodservices">
                <div className="food-service">
                  <div>
                    <Row>
                      <Col xs={12} lg={4} sm={12} md={4}>
                        <div className="image-div">
                          <h4>
                            <img src={Elipse} className="elipse-img" />
                            Food <br />
                            Services
                          </h4>
                          <p>
                            <div className="circle"></div><span className="mr-20">Suppliers</span>
                            <div className="circle"></div><span className="mr-20">Brand</span>
                            <div className="circle"></div><span className="mr-20">Producer</span>
                          </p>
                          <p>
                            <div className="circle"></div><span className="mr-20">Distributers</span>
                            <div className="circle"></div><span className="mr-20">Manufactures</span>
                          </p>
                        </div>
                      </Col>
                      <Col
                        xs={12}
                        lg={4}
                        sm={12}
                        md={4}
                        className="text-start pdl-20"
                      >
                        <p className="price">
                          <span>$15 </span>/monthly
                        </p>
                        <p>What's included</p>
                        <ul>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Professional Directory Listing
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Ecosystem integration
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            24/7 relevant connections
                          </li>

                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Automated networking
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Local & national market reach
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Strengthen online presence
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Review boosting
                          </li>
                        </ul>
                      </Col>
                      <Col
                        xs={12}
                        lg={4}
                        sm={12}
                        md={4}
                        className="text-start pdl-20"
                      >
                        <ul className="mt-113">
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Media uploads
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Story sharing
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Brand awareness
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Service showcase
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Event promotion
                          </li>
                          <li>
                            <FontAwesomeIcon icon={faCheckCircle} />
                            Direct account links
                          </li>
                        </ul>
                      </Col>

                     <Link to="/food-service-signup"><button type="button" className="register-btn">
                        Register
                      </button></Link> 
                    </Row>
                  </div>
                </div>
              </Tab>
              <Tab eventKey="Entrepreneurship" title="Cafe Entrepreneurship">
                <div className="cafe-tab">
                  <Row>
                    <Col xs={12} lg={4} sm={12} md={4}>
                      <div className="image-div">
                        <h4>
                          <img src={Elipse} className="elipse-img" />
                          Cafe <br />
                          Entrepreneurship
                        </h4>
                        <p>
                          <div className="circle"></div>Start Up & Established{" "}
                          <br />
                          Operators
                        </p>
                      </div>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      sm={12}
                      md={4}
                      className="text-start pdl-20"
                    >
                      <p className="price">
                        <span>${isChecked ? "15" : "0"} </span>/monthly
                      </p>
                      <p>What's included</p>
                      <ul>
                        <li>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Professional Directory Listing
                        </li>
                        <li>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Access to 1:1 Mentorship
                        </li>
                        <li className={isChecked ? "hidden" : "lined-text"}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Preferred Rates for Products & <br />
                          Services
                        </li>
                        <li className={isChecked ? "hidden" : "lined-text"}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Ecosystem Integration & <br />
                          Matching
                        </li>
                        <li className={isChecked ? "hidden" : "lined-text"}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Full Ecosystem Communication
                        </li>
                      </ul>
                    </Col>
                    <Col
                      xs={12}
                      lg={4}
                      sm={12}
                      md={4}
                      className="text-start pdl-20"
                    >
                      <div className="swich-box">
                        <span className="lite-text">Lite</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                        {/* <img src={Protext} className="pro-text" /> */}
                        <img
                          src={Protext}
                          className={`pro-text ${
                            isChecked ? "zoom-in-out" : ""
                          }`}
                          alt="PRO"
                        />

                        {showGif && (
                          <img
                            src={Celabrationgif}
                            className="celebration-img"
                          />
                        )}
                      </div>

                      <ul className="mt-113">
                        <li className={isChecked ? "hidden" : "lined-text"}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Enhanced Industry Exposure
                        </li>
                        <li className={isChecked ? "hidden" : "lined-text"}>
                          <FontAwesomeIcon icon={faCheckCircle} />
                          Free Group Mentorship
                        </li>
                      </ul>
                    </Col>

                    <Link to="/ccowner-signup"><button type="button" className="register-btn">
                      Register
                    </button></Link>
                  </Row>
                </div>
              </Tab>
              <Tab eventKey="Trades" title="Trades & Services">
                <div>
                  <div className="traders">
                    <div>
                      <Row>
                        <Col xs={12} lg={4} sm={12} md={4}>
                          <div className="image-div">
                            <h4>
                              <img src={TradersElipse} className="elipse-img" />
                              Traders &
                              <br />
                              Services
                            </h4>
                            <p>
                              <div className="circle"></div>Operational Support
                              Network
                            </p>
                          </div>
                        </Col>
                        <Col
                          xs={12}
                          lg={4}
                          sm={12}
                          md={4}
                          className="text-start pdl-20"
                        >
                          <p className="price">
                            <span>$15 </span>/monthly
                          </p>
                          <p>What's included</p>
                          <ul>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Professional Directory Listing
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Ecosystem integration
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              24/7 relevant connections
                            </li>

                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Automated networking
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Local & national market reach
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Strengthen online presence
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Review boosting
                            </li>
                          </ul>
                        </Col>
                        <Col
                          xs={12}
                          lg={4}
                          sm={12}
                          md={4}
                          className="text-start pdl-20"
                        >
                          <ul className="mt-113">
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Media uploads
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Story sharing
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Brand awareness
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Service showcase
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Event promotion
                            </li>
                            <li>
                              <FontAwesomeIcon icon={faCheckCircle} />
                              Direct account links
                            </li>
                          </ul>
                        </Col>

                       <Link to="/trade-service-signup"><button type="button" className="register-btn">
                          Register
                        </button></Link> 
                      </Row>
                    </div>
                  </div>
                </div>
              </Tab>
            </Tabs>
            {!isChecked && (
              <img src={Elipse1} alt="elipse" className="elipse1" />
            )}
            {!isChecked && (
              <img src={Elipse2} alt="elipse" className="elipse2" />
            )}
            {!isChecked && (
              <img src={Elipse3} alt="elipse" className="elipse3" />
            )}
            {!isChecked && (
              <img src={Elipse4} alt="elipse" className="elipse4" />
            )}
          </div>
        </div>
      </section>
      <section
        className={
          isChecked
            ? "enterprenurship-mobile-pro"
            : "enterprenurship-mobile-view"
        }
      >
        <h3 className={isVisible ? "fade-in" : ""}>
          Choose your solution and <br />
          access your
          <br />
          cafe ecosystem membership
        </h3>
        <div className="slider-container">
          <Slider {...settings}>
            <div className="slide">
              <div className="card">
                <div className="overlay"></div>
                <div className="card-image">
                  <div className="image-div">
                    <h4>
                      <img src={Elipse} className="elipse-img" />
                      Cafe <br />
                      Entrepreneurship
                    </h4>
                    <p>
                      <div className="circle"></div>Start Up & Established{" "}
                      <br />
                      Operators
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col
                      xs={5}
                      lg={5}
                      sm={5}
                      md={5}
                      className="text-start pdl-20"
                    >
                      <p className="price">
                        <b>
                          <span>${isChecked ? "15" : "0"} </span>
                        </b>
                        /monthly
                      </p>
                    </Col>
                    <Col
                      xs={7}
                      lg={7}
                      sm={7}
                      md={7}
                      className="text-start pdl-20"
                    >
                      <div className="swich-box">
                        <span className="lite-text">Lite</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                        {/* <img src={Protext} className="pro-text" /> */}
                        <img
                          src={Protext}
                          className={`pro-text ${
                            isChecked ? "zoom-in-out" : ""
                          }`}
                          alt="PRO"
                        />

                        {showGif && (
                          <img
                            src={Celabrationgif}
                            className="celebration-img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <p className="whats-included">What's included</p>
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Professional Directory Listing
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Access to 1:1 Mentorship
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Preferred Rates for Products & Services
                    </li>
                  </ul>
                  <button type="button" className="register-btn">
                    Register
                  </button>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="card">
                <div className="overlay"></div>
                <div className="card-image">
                  <div className="image-div">
                    <h4>
                      <img src={Elipse} className="elipse-img" />
                      Cafe <br />
                      Entrepreneurship
                    </h4>
                    <p>
                      <div className="circle"></div>Start Up & Established{" "}
                      <br />
                      Operators
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col
                      xs={5}
                      lg={5}
                      sm={5}
                      md={5}
                      className="text-start pdl-20"
                    >
                      <p className="price">
                        <b>
                          <span>${isChecked ? "15" : "0"} </span>
                        </b>
                        /monthly
                      </p>
                    </Col>
                    <Col
                      xs={7}
                      lg={7}
                      sm={7}
                      md={7}
                      className="text-start pdl-20"
                    >
                      <div className="swich-box">
                        <span className="lite-text">Lite</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                        {/* <img src={Protext} className="pro-text" /> */}
                        <img
                          src={Protext}
                          className={`pro-text ${
                            isChecked ? "zoom-in-out" : ""
                          }`}
                          alt="PRO"
                        />

                        {showGif && (
                          <img
                            src={Celabrationgif}
                            className="celebration-img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <p className="whats-included">What's included</p>
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Professional Directory Listing
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Access to 1:1 Mentorship
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Preferred Rates for Products & Services
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Ecosystem Integration & Matching
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Full Ecosystem Communication
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Professional Directory Listing
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Access to 1:1 Mentorship
                    </li>
                  </ul>
                  <button type="button" className="register-btn">
                    Register
                  </button>
                </div>
              </div>
            </div>
            <div className="slide">
              <div className="card">
                <div className="overlay"></div>
                <div className="card-image">
                  <div className="image-div">
                    <h4>
                      <img src={Elipse} className="elipse-img" />
                      Cafe <br />
                      Entrepreneurship
                    </h4>
                    <p>
                      <div className="circle"></div>Start Up & Established{" "}
                      <br />
                      Operators
                    </p>
                  </div>
                </div>
                <div className="card-body">
                  <Row>
                    <Col
                      xs={5}
                      lg={5}
                      sm={5}
                      md={5}
                      className="text-start pdl-20"
                    >
                      <p className="price">
                        <b>
                          <span>${isChecked ? "15" : "0"} </span>
                        </b>
                        /monthly
                      </p>
                    </Col>
                    <Col
                      xs={7}
                      lg={7}
                      sm={7}
                      md={7}
                      className="text-start pdl-20"
                    >
                      <div className="swich-box">
                        <span className="lite-text">Lite</span>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isChecked}
                            onChange={handleToggle}
                          />
                          <span className="slider round"></span>
                        </label>
                        {/* <img src={Protext} className="pro-text" /> */}
                        <img
                          src={Protext}
                          className={`pro-text ${
                            isChecked ? "zoom-in-out" : ""
                          }`}
                          alt="PRO"
                        />

                        {showGif && (
                          <img
                            src={Celabrationgif}
                            className="celebration-img"
                          />
                        )}
                      </div>
                    </Col>
                  </Row>
                  <p className="whats-included">What's included</p>
                  <ul>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Professional Directory Listing
                    </li>
                    <li>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Access to 1:1 Mentorship
                    </li>
                    <li className={isChecked ? "hidden" : "lined-text"}>
                      <FontAwesomeIcon icon={faCheckCircle} />
                      Preferred Rates for Products & Services
                    </li>
                  </ul>
                  <button type="button" className="register-btn">
                    Register
                  </button>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
    </>
  );
}

export default HomeEntrepreneship;
