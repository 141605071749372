import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import ProfileIMg from "../../../Assets/Images/default-profile-img.png";
import Notification from "../../../Assets/Images/notification-green.svg";
import NotificationText from "../../../Assets/Images/notification-text-green.svg";
import SupportImg from "../../../Assets/Images/support_green.svg";
import DropdownImg from "../../../Assets/Images/Dropdown-green.svg";
import {
  useGetCompletedBranchesQuery,
  useUserGetEditQuery,
  useNotificationMarkAllReadMutation
} from "../../../Services/FoodserviceAPI";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import {
  useGetRefreshTokenMutation
} from "../../../Services/CommonAPI"

import {
  useLogoutMutation
} from "../../../Services/CcownerAPI";
import { useLocation } from "react-router-dom";

const AppNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBusinessName, setSelectedBusinessName] = useState("");
  const location = useLocation();
  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  // const handleOptionClick = (option) => {

  //   localStorage.setItem("fdservicebranchId_current", option.id);
  //   setSelectedOption(option.location);
  //   setShowDropdown(false);
  // };

  const handleOptionClick = (option) => {
    localStorage.setItem(
      "subscription_type_current_selected",
      option.subscription_type
    );
    localStorage.setItem("fdservicebranchId_current", option.id);
    localStorage.setItem("location_current_selected", option.location); 
    localStorage.setItem("location_current_businessName_selected", option.business_name);

    setSelectedOption(option.location);
    setSelectedBusinessName(option.business_name);
    setShowDropdown(false);
    window.location.reload();
  };

  const navigate = useNavigate();
  const [submitLogout, { isSuccess: isSucessLogout }] = useLogoutMutation();
  const handleLogout = async () => {

    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };

    try {
      const response = await submitLogout(postData).unwrap();
      if (response) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("accountHolderId");
        localStorage.removeItem("subscriptionType");
        localStorage.removeItem("branchId");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("formData");
        localStorage.removeItem("completedSteps");
        localStorage.removeItem("name");
        localStorage.clear();
        window.location.replace("/food-login");
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };


  useEffect(()=>{
    if(isSucessLogout){
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accountHolderId");
      localStorage.removeItem("subscriptionType");
      localStorage.removeItem("branchId");
      localStorage.removeItem("activeTab");
      localStorage.removeItem("formData");
      localStorage.removeItem("completedSteps");
      localStorage.removeItem("name");
      localStorage.clear();
      window.location.replace("/food-login");
    }
      }, [isSucessLogout])

  const handleAccountSettings = () => {
    navigate("/food-service-preference");
  };

  const { data } = useGetCompletedBranchesQuery();
  const { data: userData } = useUserGetEditQuery();

  const username = userData?.username;

  // useEffect(() => {
  //   if (data && data.length > 0 && !selectedOption) {
  //     const defaultOption = data[0];
  //     setSelectedOption(defaultOption.location);
  //     localStorage.setItem("fdservicebranchId_current", defaultOption.id);
  //   }
  // }, [data]);

  useEffect(() => {
    if (data && data.length > 0 && !selectedOption) {
      const defaultOption = data[0];
      setSelectedOption(defaultOption.location);
      setSelectedBusinessName(defaultOption.business_name);
      localStorage.setItem(
        "subscription_type_current_selected",
        defaultOption.subscription_type
      );
      localStorage.setItem("fdservicebranchId_current", defaultOption.id);
      localStorage.setItem("location_current_selected", defaultOption.location); 
      localStorage.setItem("location_current_businessName_selected", defaultOption.business_name);
    }
  }, [data, selectedOption]);

  useEffect(() => {
    const storedLocation = localStorage.getItem("location_current_selected");
    const currentBusinessNameSelected = localStorage.getItem("location_current_businessName_selected");

    if (storedLocation) {
      setSelectedOption(storedLocation);
      setSelectedBusinessName(currentBusinessNameSelected);
    }
  }, []);

  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useNotificationMarkAllReadMutation();

  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('accessToken');
  const websocketUrl = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/food/portal-notifications/unread-count/?token=${token}`; // Pass the token in the URL


  const [enquiryNotification, setEnquiryNotification] = useState([]);
  const curentBranchid = localStorage.getItem("fdservicebranchId_current")
  const enquirywebSocketUrl = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/chat/count/food_service_branch/${curentBranchid}/?token=${token}`;


  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket(websocketUrl);

    // Handle connection open
    socket.onopen = () => {
      
    };

    // Handle incoming messages
    socket.onmessage = (event) => {
      const notification = JSON.parse(event.data);
    
      setNotifications(notification);
    };

    // Handle connection close
    socket.onclose = () => {
      
    };

    // Clean up the socket connection on unmount
    return () => {
      socket.close();
    };
  }, [websocketUrl]);

  // Effect for enquiry notifications
  useEffect(() => {
    const enquirySocket = new WebSocket(enquirywebSocketUrl);

    enquirySocket.onopen = () => {
     
    };
    enquirySocket.onmessage = (event) => {
      const enquiryNotificationData = JSON.parse(event.data);
     
      setEnquiryNotification(enquiryNotificationData);
    };
    enquirySocket.onclose = () => {
     
    };

    return () => {
      enquirySocket.close();
    };
  }, [enquirywebSocketUrl]);


  const handleMarkAllRead = async (e) => {
    try {
      const response = await submitForm().unwrap();
      //  window.location.reload();
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  }

  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
    useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")

  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {
        
        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {
   
    if (isErrorRefreshToken) {
      navigate("/food-login");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 30000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  const lastSegment = location.pathname.split("/").filter(Boolean).pop();
  const isDashboard = lastSegment === "food-service-Accountdetails" || lastSegment === "food-service-directorypagelisting" || lastSegment === "food-service-directory-payment" || lastSegment === "food-service-subscription" || lastSegment === "food-service-preference" || lastSegment === "food-service-support-listing";

  return (
    <Navbar variant="dark" expand="lg" className="dashboard-nav trade-nav">
      <Navbar.Brand href="#home">Navbar</Navbar.Brand>
      {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
      <Navbar id="basic-navbar-nav">
        {!isDashboard && (<div className="dropdown">
          <button className="dropbtn" onClick={toggleDropdown}>
            Current Location <br />
            <span>
              {(selectedOption || "Select Location").length > 15
                ? (selectedOption || "Select Location").slice(0, 15) + "..."
                : selectedOption || "Select Location"}
              <img src={DropdownImg} alt="DropdownImg" />
            </span><br />
            <span className="location_business_name">
              {(selectedBusinessName || "Business name").length > 15
                ? (selectedBusinessName || "Business name").slice(0, 15) + "..."
                : selectedBusinessName || "Business name"}
             
            </span>
          </button>
          {showDropdown && (
            <div className="dropdown-content">
               {data?.map((loc) => (
                <a href="#" key={loc.id} onClick={() => handleOptionClick(loc)}>
                  <span>{loc.location}</span><br />
                  <span className="location_business_name">{loc.business_name}</span>
                </a>
              ))}
            </div>
          )}
        </div>)}
        <Nav className="ml-auto">
          <Link to="/food-service-support-listing" title="Support">
            <img src={SupportImg} alt="Support" />
          </Link>
          <Link to="/food-service-notification" title="Ecosystem notification">
            <div className="ecosystem-notification-div">
              <img src={Notification} alt="Notification" onClick={handleMarkAllRead} />
              {notifications?.unread_count > 0 && <div className="notification-count-food">
                {notifications?.unread_count}
              </div>}
            </div>
          </Link>
          <Link to="/food-service-ecosystem-enquiry" title="Eco enquiry"><div className="ecosystem-notification-div"> <img src={NotificationText} alt="NotificationText" />
          {enquiryNotification?.unseen_count > 0 && <div className="notification-count-food">
                {enquiryNotification?.unseen_count}
              </div>}
          </div>

          </Link>
          <div className="profile-dropdown">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Nav.Link}
                id="profile-dropdown"
                className="d-flex align-items-center"
              >
                <img
                  src={userData?.image || ProfileIMg}
                  alt="ProfileIMg"
                  className="nav-profile-img"
                />
                <span className="ms-2">{username}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleAccountSettings}>
                  Account Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
    </Navbar>
  );
};

export default AppNavbar;
