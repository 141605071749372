import React, { useState, useEffect } from "react";
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import ProfileIMg from "../Assets/Images/default-profile-img.png";
import Notification from "../Assets/Images/notification.svg";
import NotificationText from "../Assets/Images/notification-text.svg";
import SupportImg from "../Assets/Images/support_nav.svg";
import DropdownImg from "../Assets/Images/Dropdown.svg";
import { useNavigate, Link } from "react-router-dom";
import AlertBoxModalDeleted from "../Components/AlertBoxAccountDeleted"
import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useGetAccountHolderDataQuery,
  useUserGetEditQuery,
  useCafeNotificationMarkAllReadMutation,
  useLogoutMutation
} from "../Services/CcownerAPI";
import { useLocation } from "react-router-dom";

import {
  useGetRefreshTokenMutation
} from "../Services/CommonAPI"

const AppNavbar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedBusinessName, setSelectedBusinessName] = useState("");
  const { data, refetch } = useGetCompletedBranchesQuery();
  const { data: userData } = useUserGetEditQuery();
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useCafeNotificationMarkAllReadMutation();
  const location = useLocation();
  const [showAlertModal, setShowAlertModal] = useState(false);
  // useState for forcing re-render
  const [subscriptionType, setSubscriptionType] = useState(localStorage.getItem("subscription_type_current_selected"));

  useEffect(() => {
    if (data && data.length > 0 && !selectedOption) {
      const defaultOption = data[0];  // Set default to the first option
      setSelectedOption(defaultOption.location);
      setSelectedBusinessName(defaultOption.business_name)
      // Store values in localStorage
      localStorage.setItem("subscription_type_current_selected", defaultOption.subscription_type);
      localStorage.setItem("subscription_status_current_selected", defaultOption.subscription_status);
      localStorage.setItem("branchId_current_selected", defaultOption.id);
      localStorage.setItem("location_current_selected", defaultOption.location);
      localStorage.setItem("location_current_businessName_selected", defaultOption.business_name);

      // Set state for re-render
      setSubscriptionType(defaultOption.subscription_type);
    }
  }, [data, selectedOption]);

  // Sync with localStorage and update subscription type if different
  useEffect(() => {
    const storedBranchId = localStorage.getItem("branchId_current_selected");


    const currentSubscriptionType = localStorage.getItem("subscription_type_current_selected");
    const currentSubscriptionStatus = localStorage.getItem("subscription_status_current_selected");

    // Find the selected branch based on branchId, not location
    const selectedBranch = data?.find((branch) => branch.id.toString() === storedBranchId);

    if (selectedBranch) {
      // Update subscription type if changed
      if (selectedBranch.subscription_type !== currentSubscriptionType) {
        localStorage.setItem("subscription_type_current_selected", selectedBranch.subscription_type);
        setSubscriptionType(selectedBranch.subscription_type);  // Trigger state update
      }

      // Update subscription status if changed
      if (selectedBranch.subscription_status !== currentSubscriptionStatus) {
        localStorage.setItem("subscription_status_current_selected", selectedBranch.subscription_status);
      }
    }
  }, [data]);



  // Initialize selected option from local storage
  useEffect(() => {
    const storedLocation = localStorage.getItem("location_current_selected");
    const currentBusinessNameSelected = localStorage.getItem("location_current_businessName_selected");
    if (storedLocation) {
      setSelectedOption(storedLocation);
      setSelectedBusinessName(currentBusinessNameSelected)
    }
  }, []);

  useEffect(() => {

  }, [isError])

  const handleOptionClick = (option) => {
    localStorage.setItem("subscription_type_current_selected", option.subscription_type);
    localStorage.setItem("subscription_status_current_selected", option.subscription_status);
    localStorage.setItem("branchId_current_selected", option.id);
    localStorage.setItem("location_current_selected", option.location);
    localStorage.setItem("location_current_businessName_selected", option.business_name);

    setSelectedOption(option.location);
    setSelectedBusinessName(option.business_name);
    setSubscriptionType(option.subscription_type); 
    setShowDropdown(false);
    window.location.reload();
  };


  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('accessToken');
  const websocketUrl = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/cafe/portal-notifications/unread-count/?token=${token}`; // Pass the token in the URL

  const [enquiryNotification, setEnquiryNotification] = useState([]);
  const curentBranchid = localStorage.getItem("branchId_current_selected")
  const enquirywebSocketUrl = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/chat/count/cafe_branch/${curentBranchid}/?token=${token}`;

  useEffect(() => {
    const socket = new WebSocket(websocketUrl);

    socket.onopen = () => {
    };
    socket.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      setNotifications(notification);
    };
    socket.onclose = () => {

    };
    return () => {
      socket.close();
    };
  }, [websocketUrl]);


  // Effect for enquiry notifications
  useEffect(() => {
    const enquirySocket = new WebSocket(enquirywebSocketUrl);

    enquirySocket.onopen = () => {

    };
    enquirySocket.onmessage = (event) => {
      const enquiryNotificationData = JSON.parse(event.data);

      setEnquiryNotification(enquiryNotificationData);
    };
    enquirySocket.onclose = () => {

    };

    return () => {
      enquirySocket.close();
    };
  }, [enquirywebSocketUrl]);


  const toggleDropdown = () => {
    setShowDropdown((prev) => !prev);
  };
  const username = userData?.username;


  const [submitLogout, { isSuccess: isSucessLogout }] = useLogoutMutation();

  const navigate = useNavigate();
  const handleLogout = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitLogout(postData).unwrap();
      if (response) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("accountHolderId");
        localStorage.removeItem("subscription_type_current_selected");
        localStorage.removeItem("branchId_current_selected");
        localStorage.removeItem("activeTab");
        localStorage.removeItem("formData");
        localStorage.removeItem("completedSteps");
        window.location.replace("/cafe-login");
        localStorage.clear();
      }
    } catch (err) {
      alert("Failed to logout!");
    }

  };

  useEffect(() => {
    if (isSucessLogout) {
      localStorage.removeItem("accessToken");
      localStorage.removeItem("accountHolderId");
      localStorage.removeItem("subscription_type_current_selected");
      localStorage.removeItem("branchId_current_selected");
      localStorage.removeItem("activeTab");
      localStorage.removeItem("formData");
      localStorage.removeItem("completedSteps");
      window.location.replace("/cafe-login");
      localStorage.clear();
    }
  }, [isSucessLogout])

  const handleAccountSettings = () => {
    navigate("/preference");
  };
  const handleMarkAllRead = async (e) => {
    try {
      const response = await submitForm().unwrap();
      //  window.location.reload();
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  }
  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken, error:refreshtokenError }] =
    useGetRefreshTokenMutation();

  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")


  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {

        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {
    if (isErrorRefreshToken) {
      navigate("/cafe-login");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])

  // useEffect(() => {

  //   if (isErrorRefreshToken) {
  //     const ErrorMessage = refreshtokenError?.data?.detail;
  //     if (ErrorMessage == "User does not exist.") {
  //       setShowAlertModal(true)
  //     }
  //     else{
  //       navigate("/cafe-login");
  //       localStorage.clear();
  //     }
     
  //   }
   
  // }, [isErrorRefreshToken, refreshtokenError])



  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 30000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);

  const lastSegment = location.pathname.split("/").filter(Boolean).pop();
  const isDashboard = lastSegment === "dashboard" || lastSegment === "session-details-booknow" || lastSegment === "directory-listing" || lastSegment === "directory-payment" || lastSegment === "ccowner-subscription" || lastSegment === "preference" || lastSegment === "support-listing" || lastSegment === "downgrade-to-lite" || lastSegment == "upgrade-to-pro";

  const handleCloseAlertModal = ()=>{
    localStorage.clear();
    setShowAlertModal(false)
  }

  return (
    <>
    <Navbar variant="dark" expand="lg" className="dashboard-nav">
      <Navbar.Brand href="#home">Navbar</Navbar.Brand>
      <Navbar id="basic-navbar-nav">

        {!isDashboard && (<div className="dropdown">
          <button className="dropbtn curent-location-dropdown-mob" onClick={toggleDropdown}>
            Current Location <br />
            <span>
              {(selectedOption || "Select Location").length > 15
                ? (selectedOption || "Select Location").slice(0, 15) + "..."
                : selectedOption || "Select Location"}
              <img src={DropdownImg} alt="DropdownImg" />
            </span><br />
            
            <span className="location_business_name">
              {(selectedBusinessName || "Business name").length > 15
                ? (selectedBusinessName || "Business name").slice(0, 15) + "..."
                : selectedBusinessName || "Business name"}
             
            </span>
          </button>
          {showDropdown && (
            <div className="dropdown-content">
              {data?.map((loc) => (
                <a href="#" key={loc.id} onClick={() => handleOptionClick(loc)}>
                  <span>{loc.location}</span><br />
                  <span className="location_business_name">{loc.business_name}</span>
                </a>
              ))}
            </div>
          )}

        </div>)}

        <Nav className="ml-auto">
          <Link to="/support-listing" title="Support">
            <img src={SupportImg} alt="Support" />
          </Link>

          <Link to="/ecosystem-notification" title="Ecosystem notification">
            <div className="ecosystem-notification-div">
              <img src={Notification} alt="Notification" onClick={handleMarkAllRead} />
              {notifications?.unread_count > 0 && <div className="notification-count-cafe">
                {notifications?.unread_count}
              </div>}
            </div>
          </Link>

          <Link to="/eco-enquiry" title="Eco enquiry">
            <div className="ecosystem-notification-div">
              <img src={NotificationText} alt="NotificationText" />
              {enquiryNotification?.unseen_count > 0 && <div className="notification-count-cafe">
                {enquiryNotification?.unseen_count}
              </div>}
            </div>
          </Link>

          {/* <Nav.Link href="#">
            <img src={ProfileIMg} alt="ProfileIMg" className="nav-profile-img" />
            <span>{username}</span>
          </Nav.Link> */}
          <div className="profile-dropdown">
            <Dropdown align="end">
              <Dropdown.Toggle
                as={Nav.Link}
                id="profile-dropdown"
                className="d-flex align-items-center"
              >
                <img
                  src={userData?.image || ProfileIMg}
                  alt="ProfileIMg"
                  className="nav-profile-img"
                />
                <span className="ms-2">{username}</span>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={handleAccountSettings}>
                  Account Settings
                </Dropdown.Item>
                <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </Nav>
      </Navbar>
    </Navbar>
    <AlertBoxModalDeleted show={showAlertModal} handleCloseAlertModal={handleCloseAlertModal}/>
    </>
  );
};

export default AppNavbar;
