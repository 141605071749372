import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/SignUpPayment.css";
import ElipseImg from "../../../Assets/Images/trade-service-elispse.svg";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import PaymentForm from "../Components/SignUpPaymentForm"
import { Link, useLocation } from "react-router-dom";

import { useGetceAmountQuery, useGetfsAmountQuery,
  useGettsAmountQuery } from "../../../Services/CommonAPI"

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function CcOwnerPayment() {
  const location = useLocation();
  const [gstAmount, setGstAmount] = useState(0);
  const [subtotal, setSubtotal] = useState(0);
  const { data: propricets } = useGettsAmountQuery();
  const { id, email, subscription_type, regBranchId } = location.state || {};

  useEffect(() => {
    if (propricets) {
      const gstRate = 0.10; // 10%
      const amount = propricets?.amount || 0; // Default to 0 if proamount or amount is not defined
      const calculatedGstAmount = amount * gstRate; // Calculate GST
      const calculatedSubtotal = amount + calculatedGstAmount; // Calculate subtotal
      setGstAmount(calculatedGstAmount); // Set GST amount
      setSubtotal(calculatedSubtotal); // Set subtotal
    }
  }, [propricets]);

  return (
    <div className="td-signup-payment">
      <Row>
        <Col xs={12} sm={12} lg={6} md={6}>
          <div className="left-sec">
            <div className="d-flex align-space-btwn main-head">
              <h3>
                <img src={ElipseImg} alt="ElipseImg" className="elipseimg" />
                <span>Trades & Services
                </span>
              </h3>
              <p className="pay-rupee"><span>${propricets?.amount} </span>/monthly</p>
            </div>
            <p className="sub-text">
            All the great benefits of a Clever Cafe Foodservice account at an affordable monthly rate
            </p>
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${propricets?.amount.toFixed(2)}</p>
            </div>
            <div className="d-flex align-space-btwn">
              <p className="head">GST (10%)</p>
              <p className="value">${gstAmount?.toFixed(2)}</p> 
            </div>
            <hr />
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${subtotal?.toFixed(2)}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} md={6}>
        <Elements stripe={stripePromise}>
      <PaymentForm regBranchId={regBranchId}/>
    </Elements>
        </Col>
      </Row>
    </div>
  );
}

export default CcOwnerPayment;
