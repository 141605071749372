import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { Row, Col } from "react-bootstrap";
import CreditCard from "../../../Assets/Images/credit-card.png";
import CSVIcon from "../../../Assets/Images/csv-icon.svg";
import CreditCardBands from "../../../Assets/Images/creditCardBrands.png";
import { useState, useEffect } from "react";
import { useSubcsriptionMultipleCreateMutation } from "../../../Services/FoodserviceAPI";
import Swal from 'sweetalert2';

function PaymentForm({ locationIds }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [cardNumberError, setCardNumberError] = useState("");
  const [expiryError, setExpiryError] = useState("");
  const [cvcError, setCvcError] = useState("");
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubcsriptionMultipleCreateMutation();
  const [formValid, setFormValid] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const handleSubmit = async (event) => {
    setDisableButton(true);
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }
    if (cardNumberError || expiryError || cvcError) {
      setErrorMessage("Please fix the errors in the form before submitting.");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
    });

    if (error) {
      setErrorMessage(error.message);
    } else {

      const postData = {
        branch_ids: locationIds,
        payment_method_id: paymentMethod.id,
      };
      try {
        const response = await submitForm(postData).unwrap();

        const timer = setTimeout(() => {
          navigate("/food-service-directorypagelisting");
          window.location.reload();
        }, 100);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  const handleCardNumberChange = (event) => {
    if (event.error) {
      setCardNumberError(event.error.message);
    } else {
      setCardNumberError("");
    }
  };

  const handleExpiryChange = (event) => {
    if (event.error) {
      setExpiryError(event.error.message);
    } else {
      setExpiryError("");
    }
  };

  const handleCvcChange = (event) => {
    if (event.error) {
      setCvcError(event.error.message);
    } else {
      setCvcError("");
      setFormValid(true);
    }

    // Handle payment submission
  };

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Payment successfull",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
        }
      });
    }
    if (isError) {
      Swal.fire({
        icon: 'error',
        title: 'Payment failed!',
        text: error?.data?.error || "Payment failed..!",
        confirmButtonText: 'OK',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    }
  }, [isSuccess, isError]);

  return (
    <form
      onSubmit={handleSubmit}
      className="payment-form fd-directory-payment-form"
    >
      <h4>Payment method</h4>
      <img src={CreditCard} alt="CreditCard" />
      <div className="stripe-form-field">
        <label style={{ display: "block", marginBottom: "0.5rem" }}>
          Card Number
        </label>
        <img
          src={CreditCardBands}
          alt="CreditCardBands"
          className="creditcard-bands"
        />
        <CardNumberElement
          className="stripe-card-element"
          onChange={handleCardNumberChange}
          options={{
            style: {
              base: {
                fontSize: "20px",
                color: "#424770",
                border: "1px solid #ccc",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        {cardNumberError && <p className="error">{cardNumberError}</p>}
      </div>
      <div className="d-flex">
        <div className="stripe-form-field w-50 mr-10">
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            Expiry Date
          </label>
          <CardExpiryElement
            className="stripe-card-element"
            onChange={handleExpiryChange}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  border: "1px solid #ccc",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {expiryError && <p className="error">{expiryError}</p>}
        </div>

        <div className="stripe-form-field w-50 ml-10">
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            CVC
          </label>
          <img src={CSVIcon} alt="csv-icon" className="csv-icon" />
          <CardCvcElement
            className="stripe-card-element"
            onChange={handleCvcChange}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  border: "1px solid #ccc",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {cvcError && <p className="error">{cvcError}</p>}
        </div>
      </div>

      <button
        type="submit"
        disabled={disableButton}
        className={`payment-submit-btn-directory ${formValid ? "btn-primary-valid-food" : ""}`}
      >
        Proceed to payment
      </button>
      <p>
        SSL Encrypted payment <br />
        Your information is protected by 256-bit SSL encryption.
      </p>
      {isError && (
        <p style={{ color: "red" }}>
          Error: {error?.data?.error || "Failed to submit form"}
        </p>
      )}
      {isSuccess && (
        <p style={{ color: "green" }} className="isSuceesMsg">
          Payment Successful!
        </p>
      )}
      {isLoading && <div class="loader-food"></div>}
    </form>
  );
}

export default PaymentForm;
