import {
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    useStripe,
    useElements,
  } from "@stripe/react-stripe-js";
  import { Link, useLocation } from "react-router-dom";
  import { useNavigate } from "react-router-dom";
  import { useState,useEffect } from "react";
  
  import { Row, Col } from "react-bootstrap";
  import CreditCard from "../Assets/Images/credit-card.png";
  import CSVIcon from "../Assets/Images/csv-icon.svg";
  import CreditCardBands from "../Assets/Images/creditCardBrands.png";
  import Swal from 'sweetalert2';
  import { useHuddleSessionPaymentMutation,useGethuddlesessionPaymentQuery, useSubmitInvitationRespondMutation } from "../Services/SecondaryAPI";
  import {  useUserGetEditQuery} from  "../Services/CcownerAPI"

  function PaymentForm({invitations, price}) {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();

     const {data:UseData} = useUserGetEditQuery();
    const [errorMessage, setErrorMessage] = useState("");
    const [cardNumberError, setCardNumberError] = useState("");
    const [expiryError, setExpiryError] = useState("");
    const [cvcError, setCvcError] = useState("");
    const [submitForm, { isLoading, isError, isSuccess, error }] =
    useHuddleSessionPaymentMutation();
    const [submitRespondForm, {  }] =
    useSubmitInvitationRespondMutation();
    const [formValid, setFormValid] = useState(false);
    const { data } = useGethuddlesessionPaymentQuery();
    const [disableButton, setDisableButton] = useState(false);
    const handleSubmit = async (event) => {
      setDisableButton(true);
      event.preventDefault();
  
      if (!stripe || !elements) {
        return;
      }
      if (cardNumberError || expiryError || cvcError) {
        setErrorMessage("Please fix the errors in the form before submitting.");
        return;
      }
  
      const cardElement = elements.getElement(CardNumberElement);
  
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });
  
      if (error) {
        setErrorMessage(error.message);
      } else {
       
        

        const branchId = localStorage.getItem("branchId_current_selected");
        const subscription_type = localStorage.getItem("subscription_type_current_selected");
        const postData = {
          user_id:UseData?.user_id,
          session_title:invitations?.huddle_session?.session_title,
          invitation_id:invitations.id,
          currency:"usd",
          email:UseData?.email,
          name:UseData?.username,
          directory_listing_id:branchId,
          payment_method_id: paymentMethod.id,
          subscription_type:subscription_type,
          huddle_session_id:invitations?.huddle_session?.id,
          amount:data?.[0]?.lite_price ? parseFloat(data[0].lite_price) : 0
        }
        try {
          const response = await submitForm(postData).unwrap();
          console.log("payment_status", response.payment_status)
          if(response.payment_status == "succeeded"){
            handleAccept();
          }
          
         
          else if(response.payment_status == "requires_action"){
            const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
              response.clientSecret
            );
            if (confirmError) {
              setDisableButton(false);
              return;
            }
            handleAccept();
          }
          // const timer = setTimeout(() => {
          //   navigate("/huddlesession");
          //   window.location.reload();
          // }, 3000);
        } catch (err) {
          console.error("Failed to submit form:", err);
        }
      }
    };

    const handleAccept = async () => {
 
        const ivitationId = invitations.id;
        const accountHolderId = UseData?.user_id
        let postData = {
          user_id: accountHolderId,
          invitation_id: ivitationId,
          status: "approved",
        };
        try {
          const response = await submitRespondForm(postData).unwrap();
          if (response) {
            Swal.fire({
              icon: 'success',
              text: 'Accepeted',
              confirmButtonText: 'OK',
              allowOutsideClick: false,
              allowEscapeKey: false,
            });
            navigate("/huddlesession")
          }
        } catch (err) {
          alert(err?.data?.error);
        }
    };
  
    const handleCardNumberChange = (event) => {
      if (event.error) {
        setCardNumberError(event.error.message);
      } else {
        setCardNumberError("");
      }
    };
  
    const handleExpiryChange = (event) => {
      if (event.error) {
        setExpiryError(event.error.message);
      } else {
        setExpiryError("");
      }
    };
  
    const handleCvcChange = (event) => {
      if (event.error) {
        setCvcError(event.error.message);
      } else {
        setCvcError("");
        setFormValid(true);
      }
    };

  

  
    return (
      <form onSubmit={handleSubmit} className="payment-form">
      <h4>Payment method</h4>
      <img src={CreditCard} alt="CreditCard" />
      <div className="stripe-form-field">
        <label style={{ display: "block", marginBottom: "0.5rem" }}>
          Card Number
        </label>
        <img
          src={CreditCardBands}
          alt="CreditCardBands"
          className="creditcard-bands"
        />
        <CardNumberElement
          className="stripe-card-element"
          onChange={handleCardNumberChange}
          options={{
            style: {
              base: {
                fontSize: "20px",
                color: "#424770",
                border: "1px solid #ccc",
                "::placeholder": {
                  color: "#aab7c4",
                },
              },
              invalid: {
                color: "#9e2146",
              },
            },
          }}
        />
        {cardNumberError && <p className="error">{cardNumberError}</p>}
      </div>
      <div className="d-flex">
        <div className="stripe-form-field w-50 mr-10">
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            Expiry Date
          </label>
          <CardExpiryElement
            className="stripe-card-element"
            onChange={handleExpiryChange}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  border: "1px solid #ccc",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {expiryError && <p className="error">{expiryError}</p>}
        </div>

        <div className="stripe-form-field w-50 ml-10">
          <label style={{ display: "block", marginBottom: "0.5rem" }}>
            CVC
          </label>
          <img src={CSVIcon} alt="csv-icon" className="csv-icon" />
          <CardCvcElement
            className="stripe-card-element"
            onChange={handleCvcChange}
            options={{
              style: {
                base: {
                  fontSize: "20px",
                  color: "#424770",
                  border: "1px solid #ccc",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
          {cvcError && <p className="error">{cvcError}</p>}
        </div>
      </div>
   
      <button type="submit" disabled={disableButton} className={`payment-submit-btn ${
                      formValid ? "btn-primary-valid" : ""
                    }`}>
        Proceed to payment
      </button>
      <p>
        SSL Encrypted payment <br />
        Your information is protected by 256-bit SSL encryption.
      </p>
      {isError && (
        <p style={{ color: "red" }}>
          Error: {error?.data?.error || "Failed to submit form"}
        </p>
      )}
      {/* {isSuccess && (
        <p style={{ color: "green" }} className="isSuceesMsg">
          Form submitted successfully!
        </p>
      )} */}
      {isLoading && <div class="loader"></div>}
    </form>
    );
  }
  
  export default PaymentForm;
  