import React, { useEffect, useState } from "react";
import "../../../Styles/EcosystemNotification.css";
import { Row, Col } from "react-bootstrap";
import { formatDistanceToNow } from 'date-fns';

function Ecosystemnotification() {
  const [notifications, setNotifications] = useState([]);
  const token = localStorage.getItem('accessToken');
  const websocketUrl = `wss:/${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/food/portal-notifications/?token=${token}`; // Pass the token in the URL

  useEffect(() => {
    // Create a new WebSocket connection
    const socket = new WebSocket(websocketUrl);

    // Handle connection open
    socket.onopen = () => {

    };

    // Handle incoming messages
    socket.onmessage = (event) => {
      const notification = JSON.parse(event.data);
      // Log the received notification
      setNotifications((prevNotifications) => [notification, ...prevNotifications]); // Prepend new notifications
    };

    // Handle connection close
    socket.onclose = () => {

    };

    // Clean up the socket connection on unmount
    return () => {
      socket.close();
    };
  }, [websocketUrl]);
  return (
    <div className="ecosystem-notification">
      <h4>Notifications</h4>
      <p className="sub-head">Stay updated with the latest alerts and messages.</p>
      {notifications?.map((notification) => (<div className="notification-list">
        <div className="card">
          <h6>
            {notification?.title}
          </h6>
          {/* <p>
        {notification?.message ? notification?.message.replace(/<[^>]+>/g, '') : ''}
        </p> */}
          {notification?.additional_data?.category && (
            <p className="paragraph">
              {`${notification?.additional_data?.category || ""} > ${notification?.additional_data?.industry_update || ""}`}
            </p>
          )}
          <p
            className="paragraph"
            dangerouslySetInnerHTML={{ __html: notification?.message }}
          ></p>
          <div className="text-end">
            <p className="time">{formatDistanceToNow(new Date(notification?.created_at))}</p>
          </div>
        </div>
      </div>))}
    </div>
  );
}

export default Ecosystemnotification;
