import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "../Styles/Dashboard.css";
import Logo from "../../../Assets/Images/logo.svg";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DirectoryListingImg from "../../../Assets/Images/directory-listing-icon.svg";
import BusinessMentorship from "../../../Assets/Images/business-mentorship.svg";
import Community from "../../../Assets/Images/community.svg";
import EcosystemImg from "../../../Assets/Images/ecosystem-menu-icon.svg";
import Settings from "../../../Assets/Images/settings.svg";
import Support from "../../../Assets/Images/support-img.svg";
import { Link } from "react-router-dom";
import MenuToogleIcon from "../../../Assets/Images/menu-toogle-icon.svg";
import { useUserGetEditQuery } from "../../../Services/TradeserviceAPI";

function SidebarComponent({ handleChange }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const closeSidebar = () => {
    setIsSidebarOpen(false);
  };
  const { data: userData } = useUserGetEditQuery();

  const handleSubMenuClick = (menuName) => {
    setActiveSubMenu((prev) => (prev === menuName ? null : menuName));
  };

  return (
    <div className="td-service-sidebar">
      <button className="toggle-btn" onClick={toggleSidebar}>
        <img src={MenuToogleIcon} alt="MenuToogleIcon" width={30} />
      </button>
      <Sidebar className={isSidebarOpen ? "Sidebar active" : "Sidebar"}>
        <button className="close-btn" onClick={closeSidebar}>
          ×
        </button>
        <Link to={process.env.REACT_APP_BASEURL_CLIENT}>
          {" "}
          <img src={Logo} className="img sidebar-logo-mob" alt="Logo" />
        </Link>
        <Menu>
          <SubMenu
            label="Directory Listing"
            icon={
              <img
                src={DirectoryListingImg}
                alt="DirectoryListingImg"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "DirectoryListing"}
            onClick={() => handleSubMenuClick("DirectoryListing")}
          >
            <Link to="/trade-service-directorypagelisting">
              <MenuItem
                className={
                  window.location.pathname ===
                    "/trade-service-directorypagelisting"
                    ? "sub-menu-item-active"
                    : ""
                }
                onClick={closeSidebar}
              >
                Manage
              </MenuItem>
            </Link>
            {userData?.free_trial !== "active" &&  <Link to="/trade-service-directory-payment">
              <MenuItem
                className={
                  window.location.pathname === "/trade-service-directory-payment"
                    ? "sub-menu-item-active"
                    : ""
                }
                onClick={closeSidebar}
              >
                Payments & Invoicing
              </MenuItem>
            </Link>}
          </SubMenu>
          <SubMenu
            label="Ecosystem"
            icon={
              <img
                src={EcosystemImg}
                alt="EcosystemImg"
                className="menu-icon"
              />
            }
            open={activeSubMenu === "Ecosystem"}
            onClick={() => handleSubMenuClick("Ecosystem")}
          >
            <Link
              to="/trade-eco-matches"
              className={
                window.location.pathname === "/trade-eco-matches"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Matches</MenuItem>
            </Link>
            <Link
              to="/trade-eco-enquiry"
              className={
                window.location.pathname === "/trade-eco-enquiry"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Enquiries</MenuItem>
            </Link>
            <Link
              to="/trade-recommendation"
              className={
                window.location.pathname === "/trade-recommendation"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Recommendations</MenuItem>
            </Link>

            <Link
              to="/trade-decline-list"
              className={
                window.location.pathname === "/trade-decline-list"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Decline List</MenuItem>
            </Link>


          </SubMenu>
          <Link
            to="/trade-media-gallery"
            className={
              window.location.pathname === "/trade-media-gallery"
                ? "sub-menu-item-active"
                : ""
            }
            onClick={closeSidebar}
          >  <MenuItem className="pl-5">
              <img src={BusinessMentorship} alt="BusinessMentorship" className="menu-icon" />
              Media Gallery
            </MenuItem></Link>

          <SubMenu
            label=" Account Settings"
            icon={<img src={Settings} alt="Settings" className="menu-icon" />}
            open={activeSubMenu === "AccontSettings"}
            onClick={() => handleSubMenuClick("AccontSettings")}
          >
            {/* <MenuItem>Subscription</MenuItem> */}

            {userData?.free_trial !== "active"  &&  <Link
              to="/trade-subscription"
              className={

                window.location.pathname === "/trade-subscription"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Subscription</MenuItem>
            </Link>}
            <Link
              to="/trade-preference"
              className={

                window.location.pathname === "/trade-preference"
                  ? "sub-menu-item-active"
                  : ""
              }
              onClick={closeSidebar}
            >
              <MenuItem>Preferences</MenuItem>
            </Link>



          </SubMenu>
          <Link
            to="/trade-support"
            className={
              window.location.pathname === "/trade-support"
                ? "sub-menu-item-active"
                : ""
            }
            onClick={closeSidebar}
          >
            {" "}
            <MenuItem className="pl-5">
              <img src={Support} alt="Support" className="menu-icon" />
              Support
            </MenuItem>
          </Link>
        </Menu>
      </Sidebar>
    </div>
  );
}

export default SidebarComponent;
