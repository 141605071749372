import React, { useEffect, useState } from "react";
import ProStar from "../Assets/Images/crown-star-pro.svg";
import ManageListing from "../Assets/Images/manage-listing.svg";
import CreateListing from "../Assets/Images/create-listing.svg";
import ActionIMg from "../Assets/Images/actions-img.svg";
import LocationIcon from "../Assets/Images/location-icon.svg";
import { Row, Col } from "react-bootstrap";
import AddLocationMOdal from "../Components/AddAnotheLocation";
import "../Styles/DirectoryPayment.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UpgradeModal from "../Components/UpgradeModal";
import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
} from "../Services/CcownerAPI";
import Swal from 'sweetalert2';
import { useGetceAmountQuery } from "../Services/CommonAPI"

function DashboardDirectoryListing() {
  const navigate = useNavigate();
  const [showAddLocationpModal, setshowAddLocationpModal] = useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [upgradeModal, setupgradeModal] = useState(false);
  const [proprice,setProprice] = useState(0);
  const {data:proamount} = useGetceAmountQuery();


  useEffect(()=>{
    if(proamount){
      setProprice(proamount?.amount)
     
    }

  },[proamount])

  // const handleCheckboxChange = (id, isPro) => {
  //   setSelectedLocations((prevSelected) => {
  //     const isSelected = prevSelected.some((loc) => loc.id === id);
  //     if (isSelected) {
  //       return prevSelected.filter((loc) => loc.id !== id);
  //     } else {
  //       return [...prevSelected, { id, payment: isPro ? proprice : 0 }];
  //     }
  //   });
  // };
  const [selectedLocation, setSelectedLocation] = useState(null);
  const handleCheckboxChange = (location) => {
    setSelectedLocation(location); // Set the selected location to the current location
  };
  const handleshowAdLocationModal = () => {
    setshowAddLocationpModal(true);
  };
  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const handleCloseModal = () => {
    setshowAddLocationpModal(false);
  };
  const showupgradeModal = () => {
    if(selectedLocations.length>0){
      setupgradeModal(true);
    }
   else{
    Swal.fire({
      icon: 'error',
      text: 'Please choose a location',
      confirmButtonText: 'OK',
      allowOutsideClick: false,
      allowEscapeKey: false,
    });
   }
  };
  const locations = [
    {
      id: 1,
      name: "Sydney",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      status: "Pro",
      progress: 10,
    },
    {
      id: 2,
      name: "Victoria",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      status: "Lite",
      progress: 10,
    },
    {
      id: 3,
      name: "Perth",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      status: "Unlisted",
      progress: 0,
    },
    {
      id: 4,
      name: "Queensland",
      address: "Mrs Smith 813 Howard Street Oswego, NY 13126 USA",
      status: "Lite",
      progress: 10,
    },
  ];

  // const proLocations = locations.filter((loc) => loc.status === "Pro");
  // const liteLocations = locations.filter((loc) => loc.status === "Lite");
  // const unlistedLocations = locations.filter(
  //   (loc) => loc.status === "Unlisted"
  // );

  const { data: unlistedLocations, isLoading } =
    useGetInCompletedBranchesQuery();
  const { data: completedbranch, isLoading: isLoadingCompletedData } =
    useGetCompletedBranchesQuery();

  const proLocations = completedbranch?.filter(
    (loc) => loc?.subscription_type === "pro"
  );
  const liteLocations = completedbranch?.filter(
    (loc) => loc?.subscription_type === "lite"
  );
  const handleNavigatetoCreateListing = (location) => {
    navigate("/dashboard", {
      state: { showcreateList: true, locationdata: location },
    });
  };

  const handleClickUpgrade = () => {
    const allselectedLocations = selectedLocation;
    navigate("/upgrade-payment", {
      state: { allselectedLocations },
    });
  };
  return (
    <div className="dashboard-listing">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Account & Directory Listing</h4>
          <p className="sub-head">
            Complete directory listings amplify engagement. Ensure your business
            shines by filling in all criteria.
          </p>
          <p className="required-message-impoertant">Cafe Listings will not appear on the public directory map until the business name and location address are correctly entered.</p>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button
            className="add-another-location"
            onClick={handleshowAdLocationModal}
          >
            Add another location
          </button>
        </Col>
      </Row>
      {isLoading && <div class="loader"></div>}
      {isLoadingCompletedData && <div class="loader"></div>}
      {/* Pro Locations */}
    
      {/* Lite Locations */}
      {liteLocations?.length > 0 && (
        <div className="list-card">
          <Row className="flagship-div">
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>
                <span>Lite</span>
              </p>
            </Col>
          </Row>
          {liteLocations?.map((location) => (
  <div className="card" key={location.id}>
    <Row className="brdr-btm">
      <Col xs={12} sm={12} md={8} lg={8}>
        <div className="d-flex">
          <div className="mr-15">
            <input
              type="radio"
              onChange={() => handleCheckboxChange(location)}
              checked={selectedLocation?.id === location.id} // Check if the current location is selected
            />
          </div>
          <div>
            <p className="location-head">
              {location.location ?? "Location"}
              {location.operational_status.length > 0 ? (
                location.operational_status.map((status) => (
                  <button
                    key={status.id}
                    className="open-for-business-btn"
                  >
                    {status.name}
                  </button>
                ))
              ) : (
                <button className="open-for-business-btn">
                  Unknown status
                </button>
              )}
            </p>
            <p className="address">{location.address ?? "Address"}</p>
          </div>
        </div>
      </Col>
      <Col xs={12} sm={4} md={4} lg={4} className="text-end">
        <div className="progressbar-div">
          <p className="percentage-text">
            {location.completion_percentage}% Completed
          </p>
          <div className="progress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${location.completion_percentage}%` }}
              aria-valuenow={location.completion_percentage}
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div>
      </Col>
    </Row>
    <Row>
      <div className="manage-listing">
        <p>
          <img src={ManageListing} alt="ManageListing" />
          Manage listing
        </p>
      </div>
    </Row>
  </div>
))}

        </div>
      )}

      {/* Unlisted Locations */}
   

      <div className="selected-location-proceed">
        <Row>
          <Col xs={12} sm={12} md={6} lg={6} className="mob-selected-location-div">
            {/* <p className="selected">Selected</p>
            <p className="location-count">{selectedLocations.length} locations</p> */}
          </Col>
          <Col xs={12} sm={12} md={6} lg={6}>
            {" "}
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
              <Link to="/directory-listing"><button type="button" className="prev-btn">
                  Cancel
                </button></Link>  
                <button
                  type="submit"
                  className="btn-save-primary"
                  onClick={handleClickUpgrade}
                >
                  Proceed
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <UpgradeModal
        show={upgradeModal}
        selectedLocations={selectedLocations}
        handleCloseUpgradeModal={handleCloseUpgradModal}
      />
      <AddLocationMOdal
        show={showAddLocationpModal}
        handleCloseLocationModal={handleCloseModal}
      />
    </div>
  );
}

export default DashboardDirectoryListing;
