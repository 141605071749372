// ModalComponent.jsx
import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "../Styles/AddAnotherLocation.css";
import { Link, useLocation } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../Styles/AddNewNotice.css";
import ArrowDownImg from "../../../Assets/Images/arrow-down-icon.svg";
import FileUpload from "../../../Assets/Images/file-upload.svg";
import Select from "react-select";
import {useAccountChangePasswordMutation} from "../../../Services/TradeserviceAPI"

import "../Styles/Support.css";
import {
  useGetCategoriesQuery,
  useGetSubCategoriesQuery,
  useCreateTicketMutation,
  useFetchTicketsQuery
} from "../../../Services/SupportAPI";

import { useGetBranchListQuery } from "../../../Services/TradeserviceAPI";

const ModalComponent = ({ showEditModal, handleCloseEDitPasswordModal }) => {
  const [selectedFile, setselectedFile] = useState(null);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [formData, setFormData] = useState({
    oldpassword: "",
    newpassword: "",
    confirmpassword: "",
  });
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState([]);
  const handleMultiSelectChange = (options) => {
    setSelectedOptions(options);
  
    // Check if options are selected and remove the validation error
    if (options && options.length > 0) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        directory_listing: "", // Clear the specific validation error
      }));
    }
  };

  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useAccountChangePasswordMutation();

  const handlecloseModal = () => {
    handleCloseEDitPasswordModal();
  };

  const handlefileChange = (e) => {
    const file = e.target.files[0];
    setselectedFile(file);
  };

  const { data: categories } = useGetCategoriesQuery();
  const { data: subcategories } = useGetSubCategoriesQuery();
  const { data: listedLocations } = useGetBranchListQuery();
  const { data: ticketList } = useFetchTicketsQuery();



  const validateForm = () => {
    const formErrors = {};
    if (!formData.oldpassword) formErrors.oldpassword = "Old password is required";
    if (!formData.newpassword) {
      formErrors.newpassword = "New password is required";
    } else if (formData.newpassword.length > 16) {
      formErrors.newpassword = "Password must not exceed 16 characters";
    } else if (formData.newpassword.length < 8) {
      formErrors.newpassword = "Password must be at least 8 characters long";
    } else if (!/[A-Z]/.test(formData.newpassword)) {
      formErrors.newpassword = "Password must contain at least one uppercase letter";
    } else if (!/[a-z]/.test(formData.newpassword)) {
      formErrors.newpassword = "Password must contain at least one lowercase letter";
    } else if (!/[0-9]/.test(formData.newpassword)) {
      formErrors.newpassword = "Password must contain at least one number";
    } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(formData.newpassword)) {
      formErrors.newpassword = "Password must contain at least one special character";
    }
  
  
    if (formData.newpassword !== formData.confirmpassword) {
      formErrors.confirmpassword = "Passwords do not match";
    } else if (formData.confirmpassword.length > 16) {
      formErrors.confirmpassword = "Password must not exceed 16 characters";
    }
   

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      const postData = {
        old_password:formData.oldpassword,
        new_password1:formData.newpassword,
        new_password2:formData.confirmpassword
      }
      try {
        const response = await submitForm(postData).unwrap();
        if(response){
          setFormData({
            oldpassword: "",
            newpassword: "",
            confirmpassword: "",
          });
          const timer = setTimeout(() => {
            handleCloseEDitPasswordModal();
          }, 3000);
        }
      
      } catch (err) {
        console.error("Failed to submit form:", err);
      }

    }
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  return (
    <>
      <Modal
        show={showEditModal}
        onHide={handleCloseEDitPasswordModal}
        backdrop="true"
        dialogClassName="add-new-modal"
      >
        <Modal.Body>
          <h2>Edit Password</h2>
          <form onSubmit={handleSubmit}>
            <Row>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                  Old Password<span className="red-star">*</span>
                  </label>
                  <input type="password" name="oldpassword" value={formData.oldpassword} onChange={handleChange} placeholder="Enter old password"/>
                  {errors.oldpassword && (
                    <p className="error">{errors.oldpassword}</p>
                  )}
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="form-control">
                  <label>
                  New Password<span className="red-star">*</span>
                  </label>
                  <br />
                  <input type="password" name="newpassword" value={formData.newpassword} onChange={handleChange} placeholder="Enter new password"/>
                  {errors.newpassword && (
                    <p className="error">{errors.newpassword}</p>
                  )}
                </div>
              </Col>
            
              <Col xs={12} sm={12} md={12} lg={12}>
              <div className="form-control">
                  <label>
                  Confirm New Password<span className="red-star">*</span>
                  </label>
                  <br />
                  <input type="password" name="confirmpassword" value={formData.confirmpassword} onChange={handleChange} placeholder="Confirm New Password"/>
                  {errors.confirmpassword && (
                    <p className="error">{errors.confirmpassword}</p>
                  )}
                </div>
              </Col>
              
             
            </Row>
            <Row className="action-btn">
              <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                <button
                  type="button"
                  className="prev-btn btn-64t35ne"
                  onClick={handlecloseModal}
                >
                  Cancel
                </button>
                <button type="submit" className="primary-btn-trade">
                  Change
                </button>
              </Col>
              {isError && (
                    <p style={{ color: "red" }}>
                      {error?.data?.error || error?.data?.old_password?.[0] || "Failed to submit form"}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                     Password has been changed successfully
                    </p>
                  )}
                  {isLoading && <div class="loader-trade"></div>}
            </Row>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ModalComponent;
