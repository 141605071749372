// Services/FoodserviceAPI.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASEURL_SERVER;
// const baseUrl = process.env.REACT_APP_COMMUNITY_DEV_URL;

export const foodserviceapi = createApi({
  reducerPath: 'foodserviceapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');
     
      
      const noAuthEndpoints = ['submitForm', 'verifyRegisterOTP', 'resendRegisterOTP', 'submitLogin'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitForm: builder.mutation({
      query: (newPost) => ({
        url: 'auth/food-service/register/',
        method: 'POST',
        body: newPost,
      }),
    }),

    verifyRegisterOTP: builder.mutation({
      query: ({Postata, id}) => ({
        url: `auth/fs-verify-otp/${id}/`,
        method: 'PATCH',
        body: Postata,
      }),
    }),
    
    resendRegisterOTP: builder.mutation({
      query: ({id}) => ({
        url: `auth/resend-otp/${id}/`,
        method: 'PATCH',
      }),
    }),

    registerPaymentSubmit: builder.mutation({
      query: (postdata) => ({
        url: 'stripe/fs-subscription/create/',
        method: 'POST',
        body: postdata,
      }),
    }),

    // getAccountHolderData: builder.query({
    //   query: (id) => `food/account-holders/${id}/`,
    // }),
    getAccountHolderData: builder.query({
      query: () => 'auth/fs-user/get/edit/',
    }),

    // submitAccountHolderData: builder.mutation({
    //   query: ({postdata, accountHolderId}) => ({
    //     url: `food/account-holders/${accountHolderId}/`,
    //     method: 'PATCH',
    //     body: postdata,
    //   }),
    // }),
    submitAccountHolderData: builder.mutation({
      query: (postdata) => ({
        url: `auth/fs-user/get/edit/`,
        method: 'PATCH',
        body: postdata,
      }),
    }),

    saveBranches: builder.mutation({
      query: (postdata) => ({
        url: `food/branches/`,
        method: 'POST',
        body: postdata,
      }),
    }),

    branchEditWithBranchId :builder.mutation({
      query: ({postdata, branchId}) => ({
        url: `food/branches/${branchId}/`,
        method: 'PATCH',
        body: postdata,
      }),
    }),

    getBranchDatawithAccountHolderId: builder.query({
      query: (id) => `food/branches/${id}/`,
    }),
    addMultipleBranch:builder.mutation({
      query: (postData) => ({
        url: `/food/branch/miltple/create/`,
        method: 'POST',
        body: postData,
      }),
    }),
    additionalDetailsEditWithAccountHolderId :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `food/additional-detail/branch/${branchId}/`,
        method: 'POST',
        body: postData,
      }),
    }),
    additionalDetailsAddWithAccountHolderId :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `food/additional-detail/branch/${branchId}/`,
        method: 'PATCH',
        body: postData,
      }),
    }),
    getadditionalDetailswithAccountHolderId: builder.query({
      query: (id) => `food/additional-detail/branch/${id}/`,
    }),

    getFeatures:builder.query({
      query: () => "cafe/features/",
    }),
    getMenuHighlihts:builder.query({
      query: () => "cafe/menu-highlights/",
    }),
    getSpecialistDietryService:builder.query({
      query: () => "cafe/menu-highlights/",
    }),
    getAmbience:builder.query({
      query: () => "cafe/ambiences/",
    }),
    getTechStack:builder.query({
      query: () => "/cafe/tech-stacks/",
    }),
    getServiceType:builder.query({
      query: () => "food/service-categories/",
    }),
    getProductType:builder.query({
      query: () => "/cafe/product-types/",
    }),
    getcafeDeietryService:builder.query({
      query: () => "/cafe/specialist-dietary-services/",
    }),
   getServiceCategory:builder.query({
    query: () => "/trade/service-categories/",
  }),
  uploadMediaFiles:builder.mutation({
    query: ({formData,branchId}) => ({
      url: `food/branch/${branchId}/media-gallery/`,
      method: 'POST',
      body: formData,
    }),
  }),

  getMediaGallery:builder.query({
    query: (branchId) => `food/branch/${branchId}/media-gallery/`,
  }),

  getMediaGallerymatchpreview:builder.query({
    query: (branchId) => `food/branch/${branchId}/media-gallery/approved/list/`,
  }),

  submitEcosystemRecommendation: builder.mutation({
    query: (postdata) => ({
      url: 'api/invite-friend',
      method: 'POST',
      body: postdata,
    }),
  }),

  getCompletedBranches:builder.query({
    query: () => "food/completed/branches/",
  }),
  getInCompletedBranches:builder.query({
    query: () => "food/unlisted/branches/",
  }),

  getBranchList:builder.query({
    query: () => `food/branches/`,
  }),

  getEcosystemcafeSerice: builder.query({
    query: ({ id, service_categories = [], states = [], ordering = '' }) => {
      const searchParams = new URLSearchParams();
      service_categories.forEach(category => searchParams.append('service_categories', category));
      states.forEach(state => searchParams.append('states', state));
      if (ordering) {
        searchParams.append('ordering', ordering);
      }
      return `cafe/ecosystem-criteria/ce/matches/${id}/?${searchParams.toString()}`;
    }
  }),

  getStates:builder.query({
    query: () => "/common/states/",
  }),

  getServiceType:builder.query({
    query: () => "cafe/service-categories/",
  }),

  accountChangeProfile: builder.mutation({
    query: (postdata) => ({
      url: 'auth/fs-profile-picture/update/',
      method: 'PATCH',
      body: postdata,
    }),
  }),

  accountDelete :builder.mutation({
    query: () => ({
      url: 'auth/fs-user/delete/with-subscription-cancel/',
      method: 'DELETE',
    }),
  }),

  accountChangePassword: builder.mutation({
    query: (postdata) => ({
      url: 'auth/fs-change-password/',
      method: 'POST',
      body: postdata,
    }),
  }),
  verifyFoodContactNumber: builder.mutation({
    query: (postdata) => ({
      url: 'auth/send-sms-otp/',
      method: 'PATCH',
      body: postdata,
    }),
  }),
  
  verifysmsotpfood: builder.mutation({
    query: (postdata) => ({
      url: 'auth/verify-sms-otp/',
      method: 'PATCH',
      body: postdata,
    }),
  }),
  userGetEdit: builder.query({
    query: () => `auth/ts-user/get/edit/`,
  }),

  userGetEditfs: builder.query({
    query: () => `auth/fs-user/get/edit/`,
  }),

  getProductTypeFood:builder.query({
    query: () => "food/product-types/",
  }),
  getServiceTypeFood:builder.query({
    query: () => "food/service-categories/",
  }),
  getDietryServiceFood:builder.query({
    query: () => "food/specialist-dietary-services/",
  }),
  submitEcosystemCrietirabyBranchid :builder.mutation({
    query: ({postData, branchId}) => ({
      url: `food/ecosystem-criteria/branch/${branchId}/`,
      method: 'POST',
      body: postData,
    }),
  }),

  submitEditEcosystemCrietirabyBranchid :builder.mutation({
    query: ({postData, branchId}) => ({
      url: `food/ecosystem-criteria/branch/${branchId}/`,
      method: 'PATCH',
      body: postData,
    }),
  }),

  getEcosystemDetailswithAccountHolderId: builder.query({
    query: (id) => `food/ecosystem-criteria/branch/${id}/`,
  }),

  subcsriptionMultipleCreate: builder.mutation({
    query: (postdata) => ({
      url: 'stripe/fs-subscription/multiple/create/',
      method: 'POST',
      body: postdata,
    }),
  }),

  userGetEdit: builder.query({
    query: () => `auth/fs-user/get/edit/`,
  }),
  branchDelete :builder.mutation({
    query: (id) => ({
      url: `food/branch/${id}/delete/with-subscription-cancel/`,
      method: 'DELETE',
    }),
  }),

  submitLogin: builder.mutation({
    query: (postdata) => ({
      url: `auth/fs-user/login/`,
      method: 'POST',
      body: postdata,
    }),
  }),
  getAllBranchDetails : builder.query({
    query: ({branchId}) => `food/branch/${branchId}/detail/`,
  }),

  getEcosystemFoodSerice: builder.query({
    query: ({ id, features = [], states = [], menu_highlights=[],ambience=[],dietryCategory=[],recommendation = '', ordering = '' , delivery_range='',operationalStatus=[] }) => {
      const searchParams = new URLSearchParams();
      features.forEach(category => searchParams.append('features', category));
      menu_highlights.forEach(category => searchParams.append('menu_highlights', category));
      states.forEach(state => searchParams.append('states', state));
    
      operationalStatus.forEach(operationalStatus => searchParams.append('operational_status', operationalStatus));
      if (ordering) {
        searchParams.append('ordering', ordering);
      }
      if (recommendation) {
        searchParams.append('recommendation', recommendation);
      }
     
      // if (delivery_range) {
      //   searchParams.append('delivery_range', delivery_range);
      // }
      return `food/ecosystem-criteria/ce/matches/${id}/?${searchParams.toString()}`;
    }
  }),

  foodserviceLike: builder.mutation({
    query: ({postdata, branchId}) => ({
      url: `food/ce-branch/like/toggle/${branchId}/`,
      method: 'POST',
      body: postdata,
    }),
  }),

  FlagbranchAddWithAccountHolderId :builder.mutation({
    query: (postdata) => ({
      url: `food/branches/`,
      method: 'POST',
      body: postdata,
    }),
  }),

getFlagshipBranches : builder.query({
query: () => `food/headquarter-branches/`,
}),


editFlagshipBranch :builder.mutation({
query: ({flagshipData, flagshipid}) => ({
  url: `food/branches/${flagshipid}/`,
  method: 'PATCH',
  body: flagshipData,
}),
}),

editMultipleBrancg :builder.mutation({
  query: ({postdata}) => ({
    url: `food/branch/miltple/create/`,
    method: 'PATCH',
    body: postdata,
  }),
}),

getEcoenquirylist:builder.query({
  query: (CurrentBranch) => `enquiry/conversations/food_service_branch/${CurrentBranch}/`,
}),
getpreviewbranches: builder.query({
  query: () => `food/branch/lt/lg/`,
}),

getpreviewbranchesMatches: builder.query({
  query: (id) => `food/branch/${id}/lt/lg/`,
}),

DeclinedList: builder.query({
  query: (branchId) => `food/branches/${branchId}/declined-ce-branches/`,
}),
NotificationMarkAllRead :builder.mutation({
  query: () => ({
    url: `food/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),

freeTrialRegister: builder.mutation({
  query: (newPost) => ({
    url: 'auth/food-service/free-trial/register/',
    method: 'POST',
    body: newPost,
  }),
}),

freeTrialverifyRegisterOTP: builder.mutation({
  query: ({Postata, id}) => ({
    url: `auth/fs/free-trial/verify-otp/${id}/`,
    method: 'PATCH',
    body: Postata,
  }),
}),

emailNotificationStatus: builder.mutation({
  query: ({Postata}) => ({
    url: `food/email-notification-status/get/edit/`,
    method: 'PATCH',
    body: Postata,
  }),
}),
getEmailNotificationStatus: builder.query({
  query: () => `food/email-notification-status/get/edit/`,
}),

getAllBranchDetailsForallUser : builder.query({
  query: ({branchId}) => `food/branch/${branchId}/detail/for-all-authed-users/`,
}),

getFsSubscriptionList : builder.query({
  query: () => `stripe/fs-subscriptions/list/`,
}),

getFsInvoice: builder.query({
  query: (recordId) => `stripe/fs-subscription/${recordId}/invoice/`,
}),

SubmitStarred : builder.mutation({
  query: (Postata) => ({
    url: `enquiry/favorite/toggle/`,
    method: 'POST',
    body: Postata,
  }),
}),
getStareedMessages :builder.query({
  query: (branchId) => `enquiry/favorites/food_service_branch/${branchId}/`,
}),

getfoodDeliveryPartner:builder.query({
  query: () => `food/distribution-partners/`,
}),

  }),
});

export const {
  useSubmitFormMutation,
  useVerifyRegisterOTPMutation,
  useResendRegisterOTPMutation,
  useRegisterPaymentSubmitMutation,
  useGetAccountHolderDataQuery,
  useSubmitAccountHolderDataMutation,
  useSaveBranchesMutation,
  useBranchEditWithBranchIdMutation,
  useGetBranchDatawithAccountHolderIdQuery,
  useAddMultipleBranchMutation,
  useAdditionalDetailsEditWithAccountHolderIdMutation,
  useGetadditionalDetailswithAccountHolderIdQuery,
  useGetFeaturesQuery,
useGetMenuHighlihtsQuery,
useGetSpecialistDietryServiceQuery,
useGetAmbienceQuery,
useGetTechStackQuery,
useGetServiceTypeQuery,
useGetProductTypeQuery,
useGetcafeDeietryServiceQuery,
useGetStatesQuery,
useGetServiceCategoryQuery,
useUploadMediaFilesMutation,
useGetMediaGalleryQuery,
useSubmitEcosystemRecommendationMutation,
useGetCompletedBranchesQuery,
useGetInCompletedBranchesQuery,
useGetBranchListQuery,
useGetEcosystemcafeSericeQuery,
useAccountChangeProfileMutation,
useAccountDeleteMutation,
useAccountChangePasswordMutation,
useVerifyFoodContactNumberMutation,
useVerifysmsotpfoodMutation,
useUserGetEditQuery,
useGetProductTypeFoodQuery,
useGetServiceTypeFoodQuery,
useGetDietryServiceFoodQuery,
useAdditionalDetailsAddWithAccountHolderIdMutation,
useSubmitEcosystemCrietirabyBranchidMutation,
useSubmitEditEcosystemCrietirabyBranchidMutation,
useSubcsriptionMultipleCreateMutation,
useBranchDeleteMutation,
useSubmitLoginMutation,
useGetAllBranchDetailsQuery,
useGetEcosystemDetailswithAccountHolderIdQuery,
useGetEcosystemFoodSericeQuery,
useFoodserviceLikeMutation,
useEditFlagshipBranchMutation,
useFlagbranchAddWithAccountHolderIdMutation,
useGetFlagshipBranchesQuery,
useEditMultipleBrancgMutation,
useGetEcoenquirylistQuery,
useGetpreviewbranchesQuery,
useDeclinedListQuery,
useNotificationMarkAllReadMutation,
useFreeTrialRegisterMutation,
useFreeTrialverifyRegisterOTPMutation,
useEmailNotificationStatusMutation,
useGetEmailNotificationStatusQuery,
useGetAllBranchDetailsForallUserQuery,
useUserGetEditfsQuery, 
useGetFsInvoiceQuery, 
useGetFsSubscriptionListQuery,
useGetStareedMessagesQuery,
useSubmitStarredMutation, 
useGetfoodDeliveryPartnerQuery,
useGetpreviewbranchesMatchesQuery,
useGetMediaGallerymatchpreviewQuery
} = foodserviceapi;
