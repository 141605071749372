// Services/CcownerAPI.js
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_BASEURL_SERVER;

export const ccownerapi = createApi({
  reducerPath: 'ccownerapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');
  
      
      const noAuthEndpoints = ['submitForm', 'getbranchesPublic', 'verifyRegisterOTP', 'ResetPassword','submitLogin','resendRegisterOTP','getStates','getallbranches'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
    submitForm: builder.mutation({
      query: (newPost) => ({
        url: 'auth/cafe-entrepreneurship/register/',
        method: 'POST',
        body: newPost,
      }),
    }),

    verifyRegisterOTP: builder.mutation({
      query: ({Postata, id}) => ({
        url: `auth/ce-verify-otp/${id}/`,
        method: 'PATCH',
        body: Postata,
      }),
    }),
    
    resendRegisterOTP: builder.mutation({
      query: ({id}) => ({
        url: `auth/resend-otp/${id}/`,
        method: 'PATCH',
      }),
    }),

    registerPaymentSubmit: builder.mutation({
      query: (postdata) => ({
        url: 'stripe/ce-subscription/create/',
        method: 'POST',
        body: postdata,
      }),
    }),

    getAccountHolderData: builder.query({
      query: () => 'auth/ce-user/get/edit/',
    }),

    // getAccountHolderData: builder.mutation({
    //   query: () => ({
    //     url: '/auth/ce-user/edit/',
    //     method: 'PATCH',
    //   }),
    // }),
    
   submitAccountHolderData: builder.mutation({
      query: (postdata) => ({
        url: 'auth/ce-user/get/edit/',
        method: 'PATCH',
        body: postdata,
      }),
    }),

    branchEditWithAccountHolderId :builder.mutation({
      query: ({postdata, branchId}) => ({
        url: `cafe/branches/${branchId}/`,
        method: 'PATCH',
        body: postdata,
      }),
    }),


    FlagbranchAddWithAccountHolderId :builder.mutation({
      query: (postdata) => ({
        url: `cafe/branches/`,
        method: 'POST',
        body: postdata,
      }),
    }),

getFlagshipBranches : builder.query({
  query: () => `cafe/flagship-branches/`,
}),


editFlagshipBranch :builder.mutation({
  query: ({flagshipData, flagshipid}) => ({
    url: `cafe/branches/${flagshipid}/`,
    method: 'PATCH',
    body: flagshipData,
  }),
}),

// FlagbranchEditWithAccountHolderId :builder.mutation({
//   query: ({flagshipData, flagshipid}) => ({
//     url: `cafe/branches/${flagshipid}`,
//     method: 'PATCH',
//     body: flagshipData,
//   }),
// }),

    getBranchDatawithAccountHolderId: builder.query({
      query: (id) => `cafe/branches/${id}/`,
    }),

    additionalDetailsAddWithAccountHolderId :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `cafe/additional-detail/branch/${branchId}/`,
        method: 'POST',
        body: postData,
      }),
    }),

    additionalDetailsEditWithAccountHolderId :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `cafe/additional-detail/branch/${branchId}/`,
        method: 'PATCH',
        body: postData,
      }),
    }),

    getadditionalDetailswithAccountHolderId: builder.query({
      query: (id) => `cafe/additional-detail/branch/${id}/`,
    }),

    getFeatures:builder.query({
      query: () => "cafe/features/",
    }),
    getMenuHighlihts:builder.query({
      query: () => "cafe/menu-highlights/",
    }),
    getSpecialistDietryService:builder.query({
      query: () => "cafe/specialist-dietary-services/",
    }),
    getAmbience:builder.query({
      query: () => "cafe/ambiences/",
    }),
    getTechStack:builder.query({
      query: () => "/cafe/tech-stacks/",
    }),
    getServiceType:builder.query({
      query: () => "food/service-categories/",
    }),
    getProductType:builder.query({
      query: () => "/food/product-types/",
    }),
    getfoodDeietryService:builder.query({
      query: () => "/food/specialist-dietary-services/",
    }),
    getStates:builder.query({
      query: () => "/common/states/",
    }),
    getTradeServiceCategory:builder.query({
      query: () => "/trade/service-categories/",
    }),

    submitEcosystemCrietirabyBranchid :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `cafe/ecosystem-criteria/branch/${branchId}/`,
        method: 'POST',
        body: postData,
      }),
    }),

    submitEditEcosystemCrietirabyBranchid :builder.mutation({
      query: ({postData, branchId}) => ({
        url: `cafe/ecosystem-criteria/branch/${branchId}/`,
        method: 'PATCH',
        body: postData,
      }),
    }),

    getEcosystemDetailswithAccountHolderId: builder.query({
      query: (id) => `cafe/ecosystem-criteria/branch/${id}/`,
    }),
    addMultipleBranch:builder.mutation({
      query: (postData) => ({
        url: `/cafe/branch/miltple/create/`,
        method: 'POST',
        body: postData,
      }),
    }),
    getCompletedBranches:builder.query({
      query: () => "cafe/completed/branches/",
    }),
    getInCompletedBranches:builder.query({
      query: () => "cafe/unlisted/branches/",
    }),
    getEcosystemFoodSerice: builder.query({
      query: ({ id, service_categories = [], product_type= [], delivery_range='', specialist_dietary_services=[],states = [], ordering = '', recommendation }) => {
        const searchParams = new URLSearchParams();
        service_categories.forEach(category => searchParams.append('service_categories', category));
        product_type.forEach(category => searchParams.append('product_type', category));
        specialist_dietary_services.forEach(category => searchParams.append('specialist_dietary_services', category));
        states.forEach(state => searchParams.append('states', state));
        if (ordering) {
          searchParams.append('ordering', ordering);
        }
        if (recommendation) {
          searchParams.append('recommendation', recommendation);
        }
        // if (delivery_range) {
        //   searchParams.append('delivery_range', delivery_range);
        // }
        return `cafe/ecosystem-criteria/fs/matches/${id}/?${searchParams.toString()}`;
      }
    }),
    getEcosystemTradeSerice: builder.query({
      query: ({ id, service_categories = [], states = [], recommendation = '', ordering = '' , delivery_range='', }) => {
        const searchParams = new URLSearchParams();
        service_categories.forEach(category => searchParams.append('service_categories', category));
        states.forEach(state => searchParams.append('states', state));
        if (ordering) {
          searchParams.append('ordering', ordering);
        }
        if (recommendation) {
          searchParams.append('recommendation', recommendation);
        }
        if (delivery_range) {
          searchParams.append('delivery_range', delivery_range);
        }
        return `cafe/ecosystem-criteria/ts/matches/${id}/?${searchParams.toString()}`;
      }
    }),

  subcsriptionMultipleCreate: builder.mutation({
      query: (postdata) => ({
        url: 'stripe/ce-subscription/multiple/create/',
        method: 'POST',
        body: postdata,
      }),
    }),
getBranchList:builder.query({
  query: () => `cafe/branches/`,
}),


subcsriptionCancle: builder.mutation({
  query: (postdata) => ({
    url: 'stripe/ce-subscription/multiple/cancel/',
    method: 'POST',
    body: postdata,
  }),
}),

submitLiteLocationData: builder.mutation({
  query: (postdata) => ({
    url: 'cafe/branch-type/to-lite/',
    method: 'PATCH',
    body: postdata,
  }),
}),

verifyContactNumber: builder.mutation({
  query: (postdata) => ({
    url: 'auth/send-sms-otp/',
    method: 'PATCH',
    body: postdata,
  }),
}),

verifysmsotp: builder.mutation({
  query: (postdata) => ({
    url: 'auth/verify-sms-otp/',
    method: 'PATCH',
    body: postdata,
  }),
}),



ecosystematchFoodserviceLike: builder.mutation({
  query: (postdata) => ({
    url: 'auth/send-sms-otp/',
    method: 'PATCH',
    body: postdata,
  }),
}),

accountChangePassword: builder.mutation({
  query: (postdata) => ({
    url: 'auth/ce-change-password/',
    method: 'POST',
    body: postdata,
  }),
}),

accountChangeProfile: builder.mutation({
  query: (postdata) => ({
    url: 'auth/ce-profile-picture/update/',
    method: 'PATCH',
    body: postdata,
  }),
}),

accountDelete :builder.mutation({
  query: () => ({
    url: 'auth/ce-user/delete/with-subscription-cancel/',
    method: 'DELETE',
  }),
}),
foodserviceLike: builder.mutation({
  query: ({postdata, branchId}) => ({
    url: `cafe/fs-branch/like/toggle/${branchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),
tradeserviceLike: builder.mutation({
  query: ({postdata, branchId}) => ({
    url: `cafe/ts-branch/like/toggle/${branchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),

submitLogin: builder.mutation({
  query: (postdata) => ({
    url: `auth/ce-user/login/`,
    method: 'POST',
    body: postdata,
  }),
}),

getAllBranchDetails : builder.query({
  query: ({branchId}) => `cafe/branch/${branchId}/detail/`,
}),


branchDelete :builder.mutation({
  query: (id) => ({
    url: `cafe/branch/${id}/delete/with-subscription-cancel/`,
    method: 'DELETE',
  }),
}),
userGetEdit: builder.query({
  query: () => `auth/ce-user/get/edit/`,
}),
getallbranches: builder.query({
  query: () => `common/ce-branch/lt/lg/`,
}),
getpreviewbranches: builder.query({
  query: () => `cafe/branch/lt/lg/`,
}),

getpreviewbranchesMatches: builder.query({
  query: (id) => `cafe/branch/${id}/lt/lg/`,
}),

getEcoenquirylist:builder.query({
  query: (CurrentBranch) => `enquiry/conversations/cafe_branch/${CurrentBranch}/`,
}),
cafeacceptfd :builder.mutation({
  query: ({postdata, currentbranchbranchId}) => ({
    url: `cafe/fs-branch-decision/toggle/${currentbranchbranchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),
editMultipleBrancg :builder.mutation({
  query: ({postdata}) => ({
    url: `cafe/branch/miltple/create/`,
    method: 'PATCH',
    body: postdata,
  }),
}),
caferejectfd :builder.mutation({
  query: ({postdata, currentbranchbranchId}) => ({
    url: `cafe/fs-branch-decision/toggle/${currentbranchbranchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),
fsacceptcafe :builder.mutation({
  query: ({postdata, currentbranchbranchId}) => ({
    url: `food/ce-branch-decision/toggle/${currentbranchbranchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),

tdrejectcafe :builder.mutation({
  query: ({postdata, currentbranchbranchId}) => ({
    url: `cafe/ts-branch-decision/toggle/${currentbranchbranchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),
tdacceptcafe :builder.mutation({
  query: ({postdata, currentbranchbranchId}) => ({
    url: `cafe/ts-branch-decision/toggle/${currentbranchbranchId}/`,
    method: 'POST',
    body: postdata,
  }),
}),
// usefsacceptcafeMutation

cafeDeclinedFoodList: builder.query({
  query: (branchId) => `cafe/branches/${branchId}/declined-fs-branches/`,
}),
cafeDeclinedTradeList: builder.query({
  query: (branchId) => `cafe/branches/${branchId}/declined-ts-branches/`,
}),

cafeNotificationMarkAllRead :builder.mutation({
  query: () => ({
    url: `cafe/portal-notifications/mark-all-read/`,
    method: 'POST',
  }),
}),

freeTrialRegister: builder.mutation({
  query: (newPost) => ({
    url: 'auth/cafe-entrepreneurship/free-trial/register/',
    method: 'POST',
    body: newPost,
  }),
}),

freeTrialverifyRegisterOTP: builder.mutation({
  query: ({Postata, id}) => ({
    url: `auth/ce/free-trial/verify-otp/${id}/`,
    method: 'PATCH',
    body: Postata,
  }),
}),

emailNotificationStatus: builder.mutation({
  query: ({Postata}) => ({
    url: `cafe/email-notification-status/get/edit/`,
    method: 'PATCH',
    body: Postata,
  }),
}),


getEmailNotificationStatus: builder.query({
  query: () => `cafe/email-notification-status/get/edit/`,
}),

ResetPassword: builder.mutation({
  query: (Postata) => ({
    url: `auth/password-reset/`,
    method: 'POST',
    body: Postata,
  }),
}),

changePasswordVerifyOTP: builder.mutation({
  query: (Postata) => ({
    url: `auth/password/verify-otp/`,
    method: 'POST',
    body: Postata,
  }),
}),

ChangePassword :builder.mutation({
  query: (Postata) => ({
    url: `auth/reset-password/`,
    method: 'POST',
    body: Postata,
  }),
}),


getCommonFeatures:builder.query({
  query: () => "common/ce-features/",
}),
getCommonMenuHighlihts:builder.query({
  query: () => "common/ce-menu-highlights/",
}),
getCommonSpecialistDietryService:builder.query({
  query: () => "common/ce-specialist-dietary-services/",
}),
getCommonAmbience:builder.query({
  query: () => "common/ce-ambiences/",
}),


getMapFilterData: builder.query({
  query: ({ id, features = [], states = [], menu_highlights=[],ambience=[],dietryCategory=[] }) => {
    const searchParams = new URLSearchParams();
    features.forEach(category => searchParams.append('features', category));
    menu_highlights.forEach(category => searchParams.append('menu_highlights', category));
    dietryCategory.forEach(category => searchParams.append('specialist_dietary_services', category));
    ambience.forEach(category => searchParams.append('ambience', category));
    states.forEach(category => searchParams.append('states', category));
    return `common/ce-branch/filter/lt/lg/?${searchParams.toString()}`;
  }
}),

getAllBranchDetailsForallUsers : builder.query({
  query: ({branchId}) => `cafe/branch/${branchId}/detail/for-all-authed-users/`,
}),
getbranchesPublic: builder.query({
  query: ({branchId}) => `common/ce-branch/${branchId}/lt/lg/`,
}),

getBranchPublicCafe: builder.query({
  query: ({branchId}) => `cafe/branch/${branchId}/detail/for-all-authed-users/`,
}),
getCafeDetailforPublic: builder.query({
  query: ({branchId}) => `common/ce-branch/${branchId}/details/`,
}),
getpreviewbranchesbeforelogin: builder.query({
  query: (id) => `common/ce-branch/${id}/lt/lg/`,
}),

getSubscriptionList : builder.query({
  query: () => `stripe/ce-subscriptions/list/`,
}),

getCeInvoice: builder.query({
  query: ({recordId}) => `stripe/ce-subscription/${recordId}/invoice/`,
}),

logout: builder.mutation({
  query: (Postata) => ({
    url: `auth/logout/`,
    method: 'POST',
    body: Postata,
  }),
}),

SubmitStarred : builder.mutation({
  query: (Postata) => ({
    url: `enquiry/favorite/toggle/`,
    method: 'POST',
    body: Postata,
  }),
}),
getStareedMessages :builder.query({
  query: (branchId) => `enquiry/favorites/cafe_branch/${branchId}/`,
}),

getBranchListWithoutFlagship :builder.query({
  query: () => `cafe/branch/list/without-flagship/`,
}),
submitCafeUpgradeMessage: builder.mutation({
  query: () => ({
    url: `cafe/send-upgrade-message/`,
    method: 'POST'
  }),
}),

getFoodviewMoreDesicion :builder.query({
  query: ({csbranchId, fsBranchid}) => `cafe/fs-view-more-decision-status/branch_id/${csbranchId}/fs_branch_id/${fsBranchid}/`,
}),

getTradeviewMoreDesicion :builder.query({
  query: ({csbranchId, fsBranchid}) => `cafe/ts-view-more-decision-status/branch_id/${csbranchId}/ts_branch_id/${fsBranchid}/`,
}),

getCafeViewMoreFromTrade:builder.query({
  query: ({tsbranchId, ceBranchid}) => `trade/ce-view-more-decision-status/branch_id/${tsbranchId}/ce_branch_id/${ceBranchid}/`,
}),

getCafeViewMoreFromFood:builder.query({
  query: ({fsbranchId, ceBranchid}) => `food/ce-view-more-decision-status/branch_id/${fsbranchId}/ce_branch_id/${ceBranchid}/`,
}),

  }),
});

export const {
  useSubmitFormMutation,
  useVerifyRegisterOTPMutation,
  useResendRegisterOTPMutation,
  useRegisterPaymentSubmitMutation,
  useGetAccountHolderDataQuery,
  useSubmitAccountHolderDataMutation,
  useBranchEditWithAccountHolderIdMutation,
  useGetBranchDatawithAccountHolderIdQuery,
  useAdditionalDetailsEditWithAccountHolderIdMutation,
  useGetadditionalDetailswithAccountHolderIdQuery,
  useGetFeaturesQuery,
useGetMenuHighlihtsQuery,
useGetSpecialistDietryServiceQuery,
useGetAmbienceQuery,
useGetTechStackQuery,
useGetServiceTypeQuery,
useGetProductTypeQuery,
useGetfoodDeietryServiceQuery,
useGetStatesQuery,
useGetTradeServiceCategoryQuery,
useSubmitEcosystemCrietirabyBranchidMutation,
useAddMultipleBranchMutation,
useGetCompletedBranchesQuery,
useGetInCompletedBranchesQuery,
useGetEcosystemFoodSericeQuery,
useGetEcosystemTradeSericeQuery,
useGetBranchListQuery,
useSubcsriptionMultipleCreateMutation,
useSubcsriptionCancleMutation,
useSubmitLiteLocationDataMutation,
useAdditionalDetailsAddWithAccountHolderIdMutation,
useSubmitEditEcosystemCrietirabyBranchidMutation,
useVerifyContactNumberMutation,
useVerifysmsotpMutation,
useEcosystematchFoodserviceLikeMutation,
useAccountChangePasswordMutation,
useAccountChangeProfileMutation,
useAccountDeleteMutation,
useFoodserviceLikeMutation,
useTradeserviceLikeMutation,
useSubmitLoginMutation,
useGetAllBranchDetailsQuery,
useBranchDeleteMutation,
useUserGetEditQuery,
useGetEcosystemDetailswithAccountHolderIdQuery,
useGetallbranchesQuery,
useGetpreviewbranchesQuery,
useFlagbranchAddWithAccountHolderIdMutation,
useGetFlagshipBranchesQuery,
useEditFlagshipBranchMutation,
useGetEcoenquirylistQuery,
useCafeacceptfdMutation,
useEditMultipleBrancgMutation,
useCaferejectfdMutation,
useFsacceptcafeMutation,
useTdacceptcafeMutation,
useTdrejectcafeMutation,
useCafeDeclinedFoodListQuery,
useCafeDeclinedTradeListQuery,
useCafeNotificationMarkAllReadMutation,
useFreeTrialRegisterMutation,
useFreeTrialverifyRegisterOTPMutation,
 useEmailNotificationStatusMutation,
 useGetEmailNotificationStatusQuery,
 useResetPasswordMutation,
 useChangePasswordVerifyOTPMutation,
 useChangePasswordMutation,
 useGetCommonAmbienceQuery,
 useGetCommonFeaturesQuery,
 useGetCommonMenuHighlihtsQuery,
 useGetCommonSpecialistDietryServiceQuery,
 useGetMapFilterDataQuery,
 useGetAllBranchDetailsForallUsersQuery,
 useGetbranchesPublicQuery,
 useGetpreviewbranchesbeforeloginQuery,
 useGetSubscriptionListQuery,
 useGetCeInvoiceQuery,
  useGetBranchPublicCafeQuery,
  useLogoutMutation,
  useSubmitStarredMutation,
  useGetStareedMessagesQuery,
  useGetCafeDetailforPublicQuery,
  useGetpreviewbranchesMatchesQuery,
  useGetBranchListWithoutFlagshipQuery,
   useSubmitCafeUpgradeMessageMutation,
   useGetFoodviewMoreDesicionQuery,
   useGetTradeviewMoreDesicionQuery,
   useGetCafeViewMoreFromTradeQuery,
   useGetCafeViewMoreFromFoodQuery
} = ccownerapi;
