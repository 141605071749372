import React, { useEffect , useState} from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/CcOwnerPayment.css";
import ElipseImg from "../Assets/Images/enterprenurship-elipse1.svg";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "../Components/HuddlesessionPaymentForm";
import { useGethuddlesessionPaymentQuery } from "../Services/SecondaryAPI";
import { Link, useLocation, useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);


function CcOwnerPayment() {
  const { data } = useGethuddlesessionPaymentQuery();
  const location = useLocation();
  const { invitations} = location.state || {};

const [subtotal, setSubToatal] = useState(0);
const [gst, setGst] = useState(0);

  useEffect(()=>{
    if(data){
     
      const litePrice = parseFloat(data[0]?.lite_price) || 0; // Convert to number, fallback to 0
      const subtotal = gst + litePrice;
      const gstValue = litePrice * 0.1;
      setSubToatal(subtotal);
      setGst(gstValue);
    }
  }, [data])
  return (
    <div className="Ccowner-payment directory-payment-list">
      <Row>
        <Col xs={12} sm={12} lg={6} md={6}>
          <div className="left-sec">
            <div className="d-flex align-space-btwn main-head"></div>
            <div className="d-flex align-space-btwn location-details">
              <div>
                <h5>{invitations?.huddle_session?.session_title}</h5>
              </div>
              <div>
                {" "}
               {data &&  <p className="payment-value">${data[0]?.lite_price}</p>} 
              </div>
            </div>

            <div className="d-flex align-space-btwn mt-60">
              <p className="head">Total</p>
              {data && <p className="value">${data[0]?.lite_price}</p>}
            </div>
            <div className="d-flex align-space-btwn">
              <p className="head">GST (10%)</p>
              <p className="value"> ${gst.toFixed(2)}</p>
            </div>
            <hr />
            <div className="d-flex align-space-btwn">
              <p className="head">Subtotal</p>
              <p className="value">${subtotal}</p>
            </div>
          </div>
        </Col>
        <Col xs={12} sm={12} lg={6} md={6}>
          <Elements stripe={stripePromise}>
            <PaymentForm invitations={invitations}/>
          </Elements>
        </Col>
      </Row>
    </div>
  );
}

export default CcOwnerPayment;
