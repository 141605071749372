import React, { useState, useEffect } from "react";
import { Tabs, Tab } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/NoticeBoard.css";
import JobListing from "../Components/JobLising";
import Announcement from "../Components/Announcements";
import AddNewNotice from "../Components/AddNewNotice";
import MyNoticeBoard from "../Components/MyNoticeBoard";
import News from "../Components/News";
import {
  useGetNoticeCategoryQuery,
  useGetNoticesQuery,
  useGetMyNoticeBoardQuery
} from "../Services/CommunityAPI";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";

function NoticeBoard() {
  const [showAddNewModal, setshowAddNewModal] = useState(false);
  const [key, setKey] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(1);
  const [jobListingCategoryId, setJobListingCategoryId] = useState(null);
  const [announcementCategoryId, setAnnouncementCategoryId] = useState(null);
  const [newsCategoryId, setNewsCategoryId] = useState(null);

  const accountHolderId = localStorage.getItem("accountHolderId");
  const branchId = localStorage.getItem("branchId_current_selected");

  const { data: noticecategory, isLoading: categoryLoading, refetch } =
    useGetNoticeCategoryQuery();

// useEffect(()=>{
//   refetch();
//   refetchMynotice();
// })

  useEffect(() => {
    if (noticecategory?.data?.length) {
      const jobCategory = noticecategory.data.find(
        (category) => category.name === "Job Listings"
      );
      const announcementCategory = noticecategory.data.find(
        (category) => category.name === "Announcements"
      );
      const newsCategory = noticecategory.data.find(
        (category) => category.name === "News"
      );

      if (jobCategory) {
        setJobListingCategoryId(jobCategory.id);
      }
      if (announcementCategory) {
        setAnnouncementCategoryId(announcementCategory.id);
      }
      if (newsCategory) {
        setNewsCategoryId(newsCategory.id);
      }
      // Set the initial tab to "Job Listings"
      if (jobCategory) {
        setKey(jobCategory.id);
        setSelectedCategoryId(jobCategory.id);
      }
    }
  }, [noticecategory]);

  const handleAddNew = () => {
    setshowAddNewModal(true);
  };

  const handlecloseModal = () => {
    setshowAddNewModal(false);
  };

  const handleSelectCategory = (id) => {
    setSelectedCategoryId(id);
  };
  const subscription_type = localStorage.getItem("subscription_type_current_selected");

  // Fetch notices based on selected category
  const { data: noticeData, isLoading: noticesLoading } = useGetNoticesQuery({
    userId: accountHolderId,
    categoryId: selectedCategoryId,
    directoryListingId: branchId,
    subscriptionType: subscription_type,
  });

  // Fetch Job Listings data
  const { data: jobListingData, isLoading: jobLoading, refetch:jobListingRefetch } = useGetNoticesQuery({
    userId: accountHolderId,
    categoryId: jobListingCategoryId,
    directoryListingId: branchId,
    subscriptionType: subscription_type,
  });

  // Fetch Announcements data
  const { data: announcementData, isLoading: announcementLoading , refetch:announcementRefetch } =
    useGetNoticesQuery({
      userId: accountHolderId,
      categoryId: announcementCategoryId,
      directoryListingId: branchId,
      subscriptionType: subscription_type,
    });

  const { data: newsData, isLoading: newsDat, refetch:NewsRefetch } = useGetNoticesQuery({
    userId: accountHolderId,
    categoryId: newsCategoryId,
    directoryListingId: branchId,
    subscriptionType: subscription_type,
  });


  const {data:mynoticeData, refetch:refetchMynotice, refetch:MynoticeRefetch} = useGetMyNoticeBoardQuery({
    userId: accountHolderId,
    directoryListingId: branchId,
    subscriptionType: subscription_type,
  });

  useEffect(()=>{
    refetchMynotice();
  },[mynoticeData])
  const [upgradeModal, setupgradeModal] = useState(false);

  const subscription_status  = localStorage.getItem(
    "subscription_status_current_selected"
  );
    useEffect(() => {
      if((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))){
        setupgradeModal(false);
      }
     else {
        setupgradeModal(true);
      }
    }, [subscription_type,subscription_status]);
  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  return (
    // <div
    //   className={`notice-board ${
    //     subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial")) 
    //         ? ""
    //         : "chat-disable-for-liteuser"
    //   }`}
    // >
      <div
      className="notice-board"
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Notice Board</h4>
          <p className="sub-head">
            All submissions are reviewed and approved before being published.
          </p>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button className="add-another-location" onClick={handleAddNew}>
            Add new notice
          </button>
        </Col>
      </Row>
      <div className="notice-tab">
        {categoryLoading ? (
          <div className="loader"></div>
        ) : (
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              handleSelectCategory(k);
            }}
            className="mb-3"
          >
            {noticecategory?.data?.map((category) => (
              <Tab
                key={category.id}
                eventKey={category.id}
                title={category.name}
              >
                {noticesLoading ? (
                  <div className="loader"></div>
                ) : (
                  <>
                    {category.name === "Job Listings" && (
                      <JobListing data={jobListingData} />
                    )}
                    {category.name === "Announcements" && (
                      <Announcement data={announcementData} />
                    )}
                    {category.name === "News" && <News data={newsData} />}

                    {![
                      "Job Listings",
                      "Announcements",
                      "News",
                      "My Notice Board",
                    ].includes(category.name) && <div>{category.name}</div>}
                  </>
                )}
              </Tab>
            ))}
            <Tab eventKey="my-notice-board" title="My Notice Board">
              {noticesLoading ? (
                <div className="loader"></div>
              ) : (
                <MyNoticeBoard
                mynoticeData={mynoticeData}
                refetchMynotice={refetchMynotice}
                />
              )}
            </Tab>
          </Tabs>
        )}
      </div>
      <AddNewNotice
        show={showAddNewModal}
        handleCloseAddNewModal={handlecloseModal}
        jobListingRefetch={jobListingRefetch}
        announcementRefetch={announcementRefetch}
        NewsRefetch={NewsRefetch}
        MynoticeRefetch={MynoticeRefetch}
      />
      {/* <UpgradeModal
        show={upgradeModal}
        handleCloseUpgradeModal={handleCloseUpgradModal}
      /> */}
    </div>
  );
}

export default NoticeBoard;
