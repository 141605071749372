import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
// import { useSubmitEcosystemRecommendationMutation } from "../../../Services/FoodserviceAPI";
import { useSubmitEcosystemRecommendationMutation } from "../../../Services/CommunityAPI";
import {
  useUserGetEditQuery,
} from "../../../Services/FoodserviceAPI";
import Swal from 'sweetalert2';

function EcosystemRecommendationModal({ show, handleCloseAddNewModal }) {
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({
    email: "",
  });

  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubmitEcosystemRecommendationMutation();

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "Email is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const { data: userData } = useUserGetEditQuery();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        email: formData.email,
        name:userData.first_name + userData.last_name
      };
      try {
        const response = await submitForm(postData).unwrap();
       
        setFormData({
          email: "",
        });
        const timer = setTimeout(() => {
          window.location.reload();
          }, 3000);
      } catch (err) {
        console.error("Failed to submit form:", err);
      }
    }
  };

  useEffect(() => {
    if (isSuccess) {
      // Swal.fire({
      //   icon: "success",
      //   title: "Success",
      //   text: "Invitation sent successfully!",
      // });
      Swal.fire({
        toast: true,
        position: "top-end",
        icon: "success",
        title: "Invitation sent successfully!",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.onmouseenter = Swal.stopTimer;
          toast.onmouseleave = Swal.resumeTimer;
          toast.parentNode.style.marginTop = '80px';
        }
      });
    }
}, [isSuccess])

  return (
    <Modal
      show={show}
      onHide={handleCloseAddNewModal}
      backdrop="true"
      dialogClassName="add-new-modal recommendationmodal"
    >
      <Modal.Body>
        <p>
        Know a great business owner that deserves recognition? Invite them to join and showcase their work in the Directory. Just grab their email and enter it below.
        </p>
        <form onSubmit={handleSubmit}>
          <div className="form-control">
            <label>
            Email <span className="red-star">*</span>
            </label>
            <br />
            <input
              type="email"
              name="email"
              placeholder="abcd123@gmail.com"
              onChange={handleChange}
              value={formData.email}
            />
             {errors.email && <p className="error">{errors.email}</p>}
          </div>
          <button type="submit" className="btn-primary-food-w100">Invite</button>
          {isError && (
                    <p style={{ color: "red" }} className="mt-20">
                      Error: {error?.data?.error || "Failed to submit form"}
                    </p>
                  )}
                  {isSuccess && (
                    <p style={{ color: "green" }} className="isSuceesMsg">
                   {isSuccess.data?.message || "Invitation sent successfully!"}   
                    </p>
                  )}
                  {isLoading && <div class="loader-food"></div>}
        </form>
      </Modal.Body>
    </Modal>
  );
}

export default EcosystemRecommendationModal;
