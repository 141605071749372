import React from 'react'
import "../Styles/HomeJoinUs.css"
import { Link } from 'react-router-dom'

function HomeJoinus() {
  return (
    <div className='home-join-us'>
  <div className='join-us-content'>
<h5>Ready to get started?</h5>
{/* <h2>Join Australia's Premier Cafe<span className='disply-blk'>Ecosystem</span></h2> */}
<h2>Join now and experience a 1-month <span className='disply-blk'>free trial.</span></h2>
<div className='btns'>
 <Link to="/food-service-signup-free"> <button className='btn-outline'>Foodservice</button></Link>
 <Link to="/ccowner-signup-free-trial">  <button className='btn-primary'>Cafe Entrepreneurship</button></Link>
 <Link to="/trade-service-signup-free">  <button className='btn-outline'>Trades & Services</button></Link>
</div>
  </div>
    </div>
  )
}

export default HomeJoinus