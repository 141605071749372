import React, { useEffect, useState } from "react";
import ProStar from "../../../Assets/Images/location-fs-managelisting.svg";
import ManageListing from "../../../Assets/Images/trade-manage-listing.svg";
import CreateListing from "../../../Assets/Images/create-listing.svg";
import ActionIMg from "../../../Assets/Images/actions-img.svg";
import LocationIcon from "../../../Assets/Images/location-icon.svg";
import { Row, Col } from "react-bootstrap";
import AddBranchMOdal from "../Components/AddAnotherBranch";
import CreateListImg from "../../../Assets/Images/create-list-green.svg";
import "../Styles/DirectoryPageListing.css";
import DeleteIcon from "../../../Assets/Images/delete-icon.svg";
import EditIcon from "../../../Assets/Images/edit-2-yellow.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useBranchDeleteMutation
} from "../../../Services/TradeserviceAPI";
import ShowCreateLIst from "../Components/ShowcreateList";
import "../Styles/DirectoryPageListing.css"

function DashboardDirectoryListing() {
  const navigate = useNavigate();
  const [showAddLocationpModal, setshowAddLocationpModal] = useState(false);
  const [showActionTypesLite, setShowActionTypeLite] = useState(null);

  const handleshowAdLocationModal = () => {
    setshowAddLocationpModal(true);
  };

  const {
    data: unlistedLocations,
    isLoading,
    refetch: refetchinCompletedLOcationData,
  } = useGetInCompletedBranchesQuery();

  const {
    data: completedbranch,
    isLoading: isLoadingCompletedData,
    refetch: refetchCompletedLOcationData,
  } = useGetCompletedBranchesQuery();

  const handleCloseModal = () => {
    setshowAddLocationpModal(false);
  };

  const handleNavigatetoCreateListing = (locationId) => {
    navigate("/trade-service-Accountdetails", {
      state: { showcreateList: true, locationId },
    });
  };
  useEffect(() => {
    refetchCompletedLOcationData();
  }, []);
  useEffect(() => {
    refetchinCompletedLOcationData();
  }, []);

  const [deleteBranch] = useBranchDeleteMutation();
  const handleDeleteBranch = async (branchId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this branch?");
    if (!isConfirmed) return;
  
    try {
      const response = await deleteBranch(branchId).unwrap();
      alert("Branch deleted successfully");
      refetchinCompletedLOcationData();
      refetchCompletedLOcationData();
    } catch (err) {
      console.error("Failed to delete branch:", err);
      alert("Failed to delete branch");
    }
  };
 
  const [showCreatelist, seShowCReateList] = useState(false);
  const [CurrentBranchId, setCurrentBranchIdId] = useState("");
  const handleshowCreatelist = (id) => {
    seShowCReateList(true);
    setCurrentBranchIdId(id);
  };

  const handlecloseShowCreatelist = () => {
    seShowCReateList(false);
  };
  const handleShowActionTypeLite = (id) => {
    setShowActionTypeLite((prevState) => (prevState === id ? null : id));
  };
  return (
    <div className="td-dashboard-listing">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Account & Directory Listing</h4>
          <p className="sub-head">
            Complete directory listings amplify engagement. Ensure your business
            shines by filling in all criteria.
          </p>
          <p className="required-message-impoertant">Listings will not appear in the ecosystem until the business name and location address are correctly entered.</p>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button
            className="add-another-branchbtn"
            onClick={handleshowAdLocationModal}
          >
            Add another Branch
          </button>
        </Col>
      </Row>

      {/* <div className="list-card empty-list">
        <Row className="flagship-div">
          <Col xs={12} sm={12} md={12} lg={12}>
          <div className="d-flex align-items-center">
            <p>
              <img src={ProStar} alt="Star" />
            </p>
            <div>
            <p className="headquarters">Headquarters</p>
            <p>Location</p>
            </div>
            </div>
          </Col>
        </Row>

        <div className="card">
          <Row className="brdr-btm">
            <Col xs={12} sm={12} md={8} lg={8}>
              <p className="location-head">
              Branch name
              </p>
            </Col>
            <Col xs={12} sm={12} md={2} lg={2} className="text-end">
              <img src={ActionIMg} alt="ActionIMg" />
              <div className="progressbar-div">
                <p className="percentage-text">0% Completed</p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "0" }}
                    aria-valuenow="0"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="manage-listing">
              <p>
                <img src={ManageListing} alt="ManageListing" />
                Manage listing
              </p>
            </div>
          </Row>
        </div>
      </div> */}

      {/* Pro Locations */}
      {completedbranch?.length > 0 && (
        <div className="list-card">
          {completedbranch
                ?.filter((branch) => branch.headquarter === true)
                .map((branch) => (
          <Row className="flagship-div">
            <Col xs={12} sm={12} md={12} lg={12}>
              
                  <div className="d-flex align-items-center">
                    <p>
                      <img src={ProStar} alt="Star" />
                    </p>
                    <div className="headquatterlocation">
                      <p className="headquarters">Headquarters</p>
                      <p>{branch.location}</p>
                    </div>
                  </div>
                
            </Col>
          </Row>
          ))}
          {completedbranch?.map((location) => (
            <div className="card" key={location.id}>
              <Row className="brdr-btm">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <p className="location-head m-0">
                    {location.location ?? "Location"}
                  </p>
                  <p className="location_business_name">{location.business_name ?? "Business Name"}</p>
                  <p className="address">{location.address ?? "Address"}</p>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} className="text-end">
                  <img
                    src={ActionIMg}
                    alt="ActionIMg"
                    onClick={() => handleShowActionTypeLite(location.id)}
                  />
                   {showActionTypesLite === location.id && (
                      <div className="actiontypes">
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          onClick={() => handleDeleteBranch(location.id)}
                        />
                        <img
                          src={EditIcon}
                          alt="EditIcon"
                          onClick={() =>
                            handleNavigatetoCreateListing(location.id)
                          }
                        />
                      </div>
                    )}
                  <div className="progressbar-div">
                    <p className="percentage-text">
                      {location.completion_percentage}% Completed
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${location.completion_percentage}%` }}
                        aria-valuenow={location.completion_percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div
                  className="manage-listing"
                  onClick={() => handleNavigatetoCreateListing(location.id)}
                >
                  <p>
                    <img src={ManageListing} alt="ManageListing" />
                    Manage listing
                  </p>
                </div>
              </Row>
            </div>
          ))}
        </div>
      )}

   
        <p className="view-other-branch-text">View other branches</p>
      
      {/* Unlisted Locations */}
      {unlistedLocations?.length > 0 && (
        <div className="list-card">
          <Row>
            <div className="un-listing">
              <p>Unlisted</p>
            </div>
          </Row>
          {unlistedLocations?.map((location) => (
            <div className="card" key={location.id}>
              <Row className="brdr-btm">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <p className="location-head m-0">
                    {" "}
                    {location.location ?? "Location"}
                  </p>
                  <p className="location_business_name">{location.business_name ?? "Business Name"}</p>
                  <p className="address">{location.address}</p>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} className="text-end">
                <img
                    src={ActionIMg}
                    alt="ActionIMg"
                    onClick={() => handleShowActionTypeLite(location.id)}
                  />
                   {showActionTypesLite === location.id && (
                      <div className="actiontypes">
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          onClick={() => handleDeleteBranch(location.id)}
                        />
                        <img
                          src={EditIcon}
                          alt="EditIcon"
                          onClick={() =>
                            handleNavigatetoCreateListing(location.id)
                          }
                        />
                      </div>
                    )}
                </Col>
              </Row>
              <Row>
                <div
                  className="manage-listing"
                  onClick={() => handleshowCreatelist(location.id)}
                >
                  <p>
                    <img src={CreateListImg} alt="CreateListImg" />
                    Create listing
                  </p>
                </div>
              </Row>
            </div>
          ))}
        </div>
      )}
      <ShowCreateLIst
        CurrentBranchId={CurrentBranchId}
        show={showCreatelist}
        handleCloseLocationModal={handlecloseShowCreatelist}
      />
      <AddBranchMOdal
        show={showAddLocationpModal}
        handleCloseLocationModal={handleCloseModal}
        refetchCompletedLOcationData={refetchCompletedLOcationData}
        refetchinCompletedLOcationData={refetchinCompletedLOcationData}
      />
    </div>
  );
}

export default DashboardDirectoryListing;
