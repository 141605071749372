import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Tabs, Tab, Table } from "react-bootstrap";
import SearchIcon from "../Assets/Images/search-icon.svg";
import "../Styles/Chat.css";
import AddNewTopic from "../Components/AddNewTopicModal";
import { Link, useLocation } from "react-router-dom";
import BackIcon from "../Assets/Images/Back-icon.svg";
import ActionIcon from "../Assets/Images/actions-img.svg";
import defaultprofileImg from "../Assets/Images/default-profile-img.png";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import {
  useGetPostByIdQuery,
  usePostChatReplyMutation,
  useGetMyChatTopicQuery
} from "../Services/CommunityAPI";
import moment from "moment";

const usersData = [
  {
    category: "Barista",
    noofPosts: 20,
    lastposted: "Jan 4, 2024",
    author: "@john.doe",
  },
  {
    category: "Dashboard screenshot",
    noofPosts: 30,
    lastposted: "Jan 4, 2024",
    author: "@jane.smith",
  },
  {
    category: "Dashboard prototype recording",
    noofPosts: 40,
    lastposted: "Jan 4, 2024",
    author: "@mary.jones",
  },
  // Add more data as needed
];
function formatDate(dateString) {
  return moment(dateString).format("DD MMMM YYYY HH:mm");
}

function Chat() {
  const { id } = useParams();
  const branchId = localStorage.getItem("branchId_current_selected");
  const subscription_type = localStorage.getItem("subscription_type_current_selected");
  const accountHolderId = localStorage.getItem("accountHolderId");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    message: "",
  });

  const { data, isLoading , refetch} = useGetPostByIdQuery({
    id: id,
    directory_listing_id: branchId,
    subscription_type: subscription_type,
  });

  const [submitForm, { isLoading: formsubmitting, isError, isSuccess, error }] =
    usePostChatReplyMutation();

  const validateForm = () => {
    const formErrors = {};
    if (!formData.message) formErrors.message = "Field is  required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const [key, setKey] = useState("allTopics");
  const [showAddNewTopicModal, setshowAddNewTopicModal] = useState(false);
  const [showTypeMessageForm, setshowTypeMessageForm] = useState(false);
  const navigate = useNavigate();

  const handleAddNewTopic = () => {
    setshowAddNewTopicModal(true);
  };
  const handleclodeAddnewTopicModal = () => {
    setshowAddNewTopicModal(false);
  };
  const goBack = () => {
    navigate(-1);
  };
  const handleShowTypeMessageForm = () => {
    setshowTypeMessageForm(true);
  };
  const handleHideTypeMessageForm = () => {
    setshowTypeMessageForm(false);
  };

  const handleSubmit = async (e) => {
    const branchId = localStorage.getItem("branchId_current_selected");
    const subscription_type = localStorage.getItem("subscription_type_current_selected");
    e.preventDefault();
    if (validateForm()) {
      let postData = {
        post_id: id,
        comment: formData.message,
        directory_listing_id: branchId,
        subscription_type: subscription_type,
      };
      try {
        const response = await submitForm(postData).unwrap();
        alert("Comment added successfully")
        refetch();
        setFormData({
          message: "",
        });

        // const timer = setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      } catch (err) {
        console.error("Failed to submit form:", err);
        alert("Failed to comment")
      }
    }
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const { data: mytopic, isLoading: mytopicloding } =
  useGetMyChatTopicQuery({
      user_id: accountHolderId,
      directory_listing_id: branchId,
      subscription_type: subscription_type,
    });

  return (
    <div className="chat-list chat-list-view">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Chat</h4>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button className="add-another-location" onClick={handleAddNewTopic}>
            Add Your topic
          </button>
        </Col>
      </Row>
      <div className="chat-list-tab">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="allTopics" title="All topics">
            <Row className="category-search">
              <Col xs={12} sm={12} g={6} md={6}>
                <h5>
                  <Link onClick={goBack}>
                    <img src={BackIcon} alt="BackIcon" />
                  </Link>
                  {data?.post?.post_title}
                </h5>
              </Col>
              <Col xs={12} sm={12} g={4} md={4} className="text-end">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      name="search"
                      className="serach-box"
                    />
                  </div>
                </form>
              </Col>
            </Row>
            <div className="chat-list-reply-div">
              <div className="comment-section">
                <div className="d-flex">
                  <div className="d-flex">
                    <div>
                    <img
                            src={
                              data?.post?.author_profile_image
                                ? data?.post?.author_profile_image
                                : defaultprofileImg
                            }
                            alt="profile"
                            className="default-chat-profile-img"
                          />
                    </div>
                    <div className="profile">
                      <p className="person-name">{data?.post?.author_name}</p>

                      <p className="date">
                        {" "}
                        {formatDate(data?.post?.post_created_at)}{" "}
                      </p>
                    </div>
                  </div>

                  <img src={ActionIcon} alt="ActionIcon" />
                </div>
                <h5>{data?.post?.post_title}</h5>
                <p className="comments">{data?.post?.post_content}</p>

                <hr />
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Type your message here ..."
                    onChange={handleChange}
                    value={formData.message}
                    name="message"
                  />
                  {errors.message && <p className="error">{errors.message}</p>}
                  <Row className="action-btn">
                    <Col xs={12} sm={12} lg={12} md={12} className="text-end">
                      <button type="button" className="prev-btn btn-64t35ne">
                        Cancel
                      </button>
                      <button type="submit" className="btn-save-primary">
                        Comment
                      </button>
                    </Col>
                    {formsubmitting && <div class="loader"></div>}
                  </Row>
                </form>
              </div>
              {/* <div className="already-added-comments">
                <div className="comments">
                  <div className="br-l">
                    <div className="d-flex">
                      <div className="d-flex">
                        <div>
                          <img src={defaultprofileImg} alt="defaultprofileImg" />
                        </div>
                        <div className="profile">
                          <p className="person-name">
                            {data?.post?.author_name}
                          </p>

                          <p className="date">
                            {" "}
                            {formatDate(data?.post?.post_created_at)}
                          </p>
                        </div>
                      </div>

                      <img src={ActionIcon} alt="ActionIcon" />
                    </div>
                    <p className="comments pt-10">{data?.post?.post_content}</p>
                    <hr />
                    <hr />
                    <p className="posted-on">Posted on 10/02/2024</p>
                  </div>
                  <div className="sub-comments">
                    {data?.post?.comments?.map((comment) => (
                      <div className="br-l" key={comment.comment_id}>
                        <div className="d-flex">
                          <div>
                            <p className="person-name">
                              {comment.comment_author}
                            </p>
                            <p className="date">
                              {formatDate(comment.comment_created_at)}
                            </p>
                          </div>
                          <img src={ActionIcon} alt="ActionIcon" />
                        </div>
                        <p className="comments pt-10">
                          {comment.comment_content}
                        </p>
                        <hr />
                      </div>
                    ))}
                  </div>
                </div>
              </div> */}
              <div className="already-added-comments">
  <div className="comments">

      {data?.post?.comments?.map((comment) => (
        <div className="br-l" key={comment.comment_id}>
          <div className="d-flex">
            <div className="d-flex">
              <div>
                {/* If comment profile image exists, display it, otherwise show default */}
                <img
                  src={comment.comment_profile_image || defaultprofileImg}
                  alt="profile"
                  className="default-chat-profile-img"
                />
              </div>
              <div className="profile">
                <p className="person-name">{comment.comment_author || "Unknown"}</p>
                <p className="date">{formatDate(comment.comment_created_at)}</p>
              </div>
            </div>
            <img src={ActionIcon} alt="ActionIcon" />
          </div>
          <p className="comments pt-10">{comment.comment_content}</p>
          <hr />

          {/* Loop through the replies if they exist */}
          {comment.replies?.length > 0 && (
            <div className="sub-comments">
              {comment.replies.map((reply) => (
                <div className="br-l" key={reply.reply_id}>
                  <div className="d-flex">
                    <div className="profile">
                      <p className="person-name">{reply.reply_author}</p>
                      <p className="date">{formatDate(reply.reply_created_at)}</p>
                    </div>
                    <img src={ActionIcon} alt="ActionIcon" />
                  </div>
                  <p className="comments pt-10">{reply.reply_content}</p>
                  <hr />
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  </div>

            </div>
          </Tab>
          <Tab eventKey="mytopics" title="Manage my topics">
            <Row className="category-search">
              <Col xs={12} sm={12} g={6} md={6}>
                <h5>Categories</h5>
              </Col>
              <Col xs={12} sm={12} g={4} md={4} className="text-end">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      name="search"
                      className="serach-box"
                    />
                  </div>
                </form>
              </Col>
            </Row>
            <div>
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>No of posts</th>
                    <th>Last posted</th>
                    <th>Author</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {mytopic?.data?.map((user) => (
                    <tr key={user.id}>
                         <td>{user.category_name}</td>
                      <td>{user.number_of_comments_on_user_posts}</td>
                      <td>{formatDate(user.last_post_date ?? "")}</td>
                      <td>{user.last_comment_author}</td>
                      <td>
                        <Link to={`/chat-list-view-my-topic/${user.category_id}`}>
                          <button className="table-view-btn" type="button">
                            View
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddNewTopic
        show={showAddNewTopicModal}
        handlecloseModal={handleclodeAddnewTopicModal}
      />
    </div>
  );
}

export default Chat;
