import React from "react";
import "../Styles/Services.css";
import { Row, Col } from "react-bootstrap";
import KeyPointImg from "../Assets/Images/key-points.svg"

function services() {
  return (
    <div className="services">
      <section className="service-banner">
        <div className="service-banner-content">
          <h2>Foodservices</h2>
          <p>
            While we call Sydney our home, we proudly serve the entire
            Australian cafe industry. Great cafes can thrive{" "}
            <span className="disply-blk">
              anywhere, and geography does not limit our support. That’s why we
              collaborate with people, businesses,{" "}
            </span>
            <span className="disply-blk">
              and industry partners across the nation, constantly seeking
              innovative ways to expand our services, value,
            </span>
            and impact.
          </p>
        </div>
      </section>
      <section className="service-items">
        <div className="card">
          <h3>Directory & Ecosystem</h3>
          <hr />
          <Row>
            <Col lg={8} md={8} sm={12}>
              <h4>Service & Offerings</h4>
              <p>
                We present professional business listings that cut through the
                noise, better showcasing your brand and telling your story,
                making it easier to educate potential cafe customers about your
                offerings. Your directory entry is integrated into the
                ecosystem, meaning when you sign up and create your page, you
                become discoverable by cafe entrepreneurs who are also in the
                network.
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
            <h4>Key benefits</h4>
            <ul>
                <li><img src={KeyPointImg} /> make a powerful introduction</li>
                <li><img src={KeyPointImg} /> professional business listings</li>
            </ul>
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
}

export default services;
