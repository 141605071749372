import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { Tabs, Tab, Table } from "react-bootstrap";
import SearchIcon from "../Assets/Images/search-icon.svg";
import "../Styles/Chat.css";
import AddNewTopic from "../Components/AddNewTopicModal";
import { Link, useLocation } from "react-router-dom";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import {
  useGetAllChatTopicQuery,
  useGetMyChatTopicQuery,
} from "../Services/CommunityAPI";
import moment from 'moment';

const usersData = [
  {
    category: "Barista",
    noofPosts: 20,
    lastposted: "Jan 4, 2024",
    author: "@john.doe",
  },
  {
    category: "Dashboard screenshot",
    noofPosts: 30,
    lastposted: "Jan 4, 2024",
    author: "@jane.smith",
  },
  {
    category: "Dashboard prototype recording",
    noofPosts: 40,
    lastposted: "Jan 4, 2024",
    author: "@mary.jones",
  },
  // Add more data as needed
];
function formatDate(dateString) {
  return moment(dateString).format('DD MMMM YYYY');
}

function Chat() {
  const [key, setKey] = useState("allTopics");
  const [showAddNewTopicModal, setshowAddNewTopicModal] = useState(false);
  const handleAddNewTopic = () => {
    setshowAddNewTopicModal(true);
  };
  const handleclodeAddnewTopicModal = () => {
    setshowAddNewTopicModal(false);
  };
  const branchId = localStorage.getItem("branchId_current_selected");
  const accountHolderId = localStorage.getItem("accountHolderId");
  const subscription_type = localStorage.getItem("subscription_type_current_selected");
  const { data: Alltopic, isLoading } = useGetAllChatTopicQuery({
    directory_listing_id: branchId,
    subscription_type: subscription_type,
  });
  const { data: mytopic, isLoading: mytopicloding } =
    useGetMyChatTopicQuery({
      user_id: accountHolderId,
      directory_listing_id: branchId,
      subscription_type: subscription_type,
    });
  const [upgradeModal, setupgradeModal] = useState(false);


  const subscription_status = localStorage.getItem(
    "subscription_status_current_selected"
  );
  useEffect(() => {
    if ((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))) {
      setupgradeModal(false);
    }
    else {
      setupgradeModal(true);
    }
  }, [subscription_type, subscription_status]);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  }
  const showupgradeModal = () => {
    setupgradeModal(true);
  };
  return (
    // <div className={`chat-list ${ subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial")) 
    //   ? ""
    //   : "chat-disable-for-liteuser"}`}>

    <div className="chat-list">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Chat</h4>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button className="add-another-location" onClick={handleAddNewTopic}>
            Add Your topic
          </button>
        </Col>
      </Row>

      <div className="chat-list-tab">
        <Tabs
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          <Tab eventKey="allTopics" title="All topics">
            <Row className="category-search">
              <Col xs={12} sm={12} g={6} md={6}>
                <h5>Categories</h5>
              </Col>
              <Col xs={12} sm={12} g={4} md={4} className="text-end">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      name="search"
                      className="serach-box"
                    />
                  </div>
                </form>
              </Col>
            </Row>
            <div className="table-div">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>No of posts</th>
                    <th>Last posted</th>
                    <th>Author</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {Alltopic?.data?.map((user) => (
                    <tr key={user.id}>
                      <td>{user.category_name}</td>
                      <td>{user.number_of_posts}</td>
                      <td>{user.last_posted_at ? formatDate(user.last_posted_at) : ""}</td>
                      <td>{user.last_post_author}</td>
                      <td>
                        <Link to={`/chat-list-view/${user.id}`}>
                          <button className="table-view-btn" type="button">
                            View
                          </button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {isLoading && <div class="loader"></div>}
            </div>
          </Tab>
          <Tab eventKey="mytopics" title="Manage my topics">
            <Row className="category-search">
              <Col xs={12} sm={12} g={6} md={6}>
                <h5>Categories</h5>
              </Col>
              <Col xs={12} sm={12} g={4} md={4} className="text-end">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="search"
                      name="search"
                      className="serach-box"
                    />
                  </div>
                </form>
              </Col>
            </Row>
            <div>
              <div className="table-div">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>No of posts</th>
                      <th>Last posted</th>
                      <th>Author</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {mytopic?.data?.map((user) => (
                      <tr key={user.id}>
                        <td>{user.category_name}</td>
                        <td>{user.number_of_comments_on_user_posts}</td>
                        <td>{user.last_post_date ? formatDate(user.last_post_date) : ""}</td>
                        <td>{user.last_comment_author}</td>
                        <td>
                          <Link to={`/chat-list-view-my-topic/${user.category_id}`}>
                            <button className="table-view-btn" type="button">
                              View
                            </button>
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {mytopicloding && <div class="loader"></div>}
            </div>
          </Tab>
        </Tabs>
      </div>
      <AddNewTopic
        show={showAddNewTopicModal}
        handlecloseModal={handleclodeAddnewTopicModal}
      />
      {/* <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} /> */}
    </div>
  );
}

export default Chat;
