import React, { useEffect } from "react";
import CheckCircle from "../Assets/Images/check-circle.svg";
import "../Styles/CcOwnerAccountActivation.css";
import { useNavigate } from "react-router-dom";

function CcOwnerAccountActivation() {
  const navigate = useNavigate();
  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/directory-listing");
    }, 3000);
  }, []);
  return (
    <div>
      <section className="Ccowner-account-activation">
        <img src={CheckCircle} n alt="CheckCircle" className="check-circle" />

        <p>Your listing(s) have been downgraded to Lite</p>
      </section>
    </div>
  );
}

export default CcOwnerAccountActivation;
