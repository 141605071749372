import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import SessionDetailsImg from "../Assets/Images/session-details-img.png";
import "../Styles/SessionDetails.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useGetSessionQuery } from "../Services/SecondaryAPI";
import CafestartupImg from "../Assets/Images/sessions/Cafe Startup.jpg"
import BusinessPlanningImg from "../Assets/Images/sessions/Business planning and stratergy.jpg"
import LeasingImg from "../Assets/Images/sessions/Leasing 101.jpg"
import CreativityImg from "../Assets/Images/sessions/Creativity & menu.jpg"
import OperationImg from "../Assets/Images/sessions/Operations.jpg"
import TeamImg from "../Assets/Images/sessions/Team.jpg"
import LeadershipImg from "../Assets/Images/sessions/Leadership.jpg"
import ProfitabilityImg from "../Assets/Images/sessions/Profitability.jpg"
import ExitstrategyImg from "../Assets/Images/sessions/Exit stratergy.jpg"

const sessionData = [
  {
    id: 1,
    title: "Cafe Startup",
    description: "Starting a new business demands a shift, from employee to business owner mindset; enthusiasm is vital, but knowledge is key.",
    price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Vision & Goals", "Market Research", "Concept & Offerings", "Finance & Funding", "Core Team Members", "Legal & Regulatory ", "Preliminary Budgeting", "Initial Branding & Marketing", "nitial Branding & Marketing", "Preliminary Business Planning", "Action planning"],
    head1: "Vision & Goals",
    desc1: "Ensure realistic and achievable goals align with your cafe's vision for success.",
    head2: "Market Research",
    desc2: "Conduct effective market research to set your cafe apart in a competitive marketplace.",
    head3: "Concept & Offerings",
    desc3: "Align your offerings with your target demographic and location for a successful concept.",
    head4: "Finance & Funding",
    desc4: "Plan thoroughly to avoid overspending and stay within budget during startup.",
    head5: "Core Team Members",
    desc5: "Determine staffing needs early on to ensure sustainable cafe management.",
    head6: "Legal & Regulatory",
    desc6: "Address legal matters correctly before opening to avoid operational setbacks.",
    head7: "Preliminary Budgeting",
    desc7: "Learn how to prepare, implement, and monitor a budget for financial health.",
    head8: "Initial Branding & Marketing",
    desc8: "Connect your brand with your offering and resonate with your customer base through effective marketing and branding strategies.",
    head9: "Preliminary Business Planning",
    desc9: "Receive guidance on accurate planning and focus areas for your cafe business.",
    head10: "Action Planning",
    desc10: "Maintain organisation and focus with a clear roadmap to achieve your objectives.",
    img:CafestartupImg,
    meet_pro:'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688060',
    meet_lite:'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000216004'
  }, 
  {
    id: 2,
    title: "Business Planning & Strategy",
    description: "Successful cafe entrepreneurs rely on clear strategies, not just intuition; you can have a blueprint too.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Complete Business Plan Development", "Concept & Offering Refinement", "SWOT Analysis", "Financial Management & Forecasting", "Marketing & Branding Strategy", ""],
   
    head1: "Complete Business Plan Development",
    desc1: "Create a comprehensive plan using available tools and resources, leveraging market insights.",
    head2: "Concept & Offering Refinement",
    desc2: "Fine-tune your cafe's scope and menu to align with customer preferences for consistent foot traffic.",
    head3: "SWOT Analysis",
    desc3: "Assess internal strengths and weaknesses, growth opportunities, and potential threats for clear direction.",
    head4: "Financial Management & Forecasting",
    desc4: "Master budgeting, target setting, and precise forecasting for effective business navigation.",
    head5: "Marketing & Branding Strategy",
    desc5: "Develop a well-defined marketing strategy to increase foot traffic and enhance social presence.",
    head6: "Operation & Menu Considerations",
    desc6: "Assess staffing, labour, overhead, and cost of goods sold for long-term sustainability.",
    head7: "Demographic & Target Audience",
    desc7: "Define and enhance cafe offerings based on your ideal customers.",
    head8: "Predicting Personal Hurdles",
    desc8: "Prepare for potential personal and professional obstacles through accredited coaching.",
    head9: "Contingency & Risk Planning",
    desc9: "Identify and mitigate potential hazards to ensure a resilient business foundation.",
    img:BusinessPlanningImg,
    meet_pro:"https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000218056",
    meet_lite:"https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688122"
  },
  {
    id: 3,
    title: "HospoSure Support",
    description: "Clever Cafe grants HospoSure users access to real-time progress tracking and support via our coaching portal.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Recap & Progress Overview", "30/30/30/10 Assessment", "Warning Signs & Risk Assessment", "Pricing & Costing Skill Assessment", "Menu Development Analysis", "Projected vs. Actual Performance"],
    
    head1: "Recap & Progress Overview",
    desc1: "Track your progress with a comprehensive account overview and receive guidance on potential improvement areas.",
   
    head2: "Warning Signs & Risk Assessment",
    desc2: "Identify fundamental flaws and hazards in your business model to mitigate risks before launching.",
   
    head3: "30/30/30/10 Assessment",
    desc3: "Receive an honest assessment to identify vulnerabilities and suggest corrective measures for improvement.",
    
    head4: "Pricing & Costing Skill Assessment",
    desc4: "Evaluate pricing versus production costs and operational realities to understand the true cost of running a cafe operation effectively.",
   
    head5: "Menu Development Analysis",
    desc5: "Ensure menu sustainability by evaluating allocation for preparation and labour.",
   
    head6: "Projected vs. Actual Performance",
    desc6: "Review your dashboard and receive recommendations for improvements based on projected versus actual performance.",
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224046',
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688158'
  },
  {
    id: 4,
    title: "Leasing 101",
    description: "Ensure clarity in decision-making, save time, and understand potential location risks and benefits thoroughly.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Location Scouting ", "Target Audience & Demographics", "Lease Negotiation & Terms", "Due Diligence", "Lease Management & Documentation", "Exit Strategies", "Legal Considerations"],
    
    head1: "Location Scouting",
    desc1: "Understand your chosen location thoroughly, considering local competition, infrastructure, accessibility, and demographics to guide your business planning.",
    
    head2: "Target Audience & Demographics",
    desc2: "Identify your ideal customer's preferences and visit frequency to position your cafe for success in the local area.",
    
    head3: "Lease Negotiation & Terms",
    desc3: "Utilise effective strategies to negotiate with landlords and secure favourable lease terms for your cafe.",
    
    head4: "Due Diligence",
    desc4: "Conduct thorough research to determine appropriate lease costs and ensure readiness before committing to a location.",
    
    head5: "Lease Management & Documentation",
    desc5: "Navigate lease agreements with guidance to secure the best deal and understand key terms to avoid potential pitfalls.",
    
    head6: "Exit Strategies",
    desc6: "Plan for the long-term potential and market dynamics of your location, including strategies for exiting if needed.",
    img:LeasingImg,
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224062',
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688196'
  },
  {
    id: 5,
    title: "Creativity & Menu",
    description: "Crafting a menu that balances creativity and profitability drives growth and success in cafes.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Simplicity", "Seasonality", "Menu Size & Variety", "Flavour Profiling", "Productivity", "Chef/Cook Proficiency", " Menu Design & Grammar", " Menu Evolution", "Catering"],
   
    head1: "Simplicity",
    desc1: "Refine creativity, optimise menu, tailor feedback for expansion, considering location, audience, demographics.",
    
    head2: "Seasonality",
    desc2: "Maximise profit by adapting to seasonal changes, understanding buyer behavior, and supplier communication.",
    
    head3: "Kitchen Practicalities",
    desc3: "Balance menu with size, equipment, and functionality for control and profitability.",
    
    head4: "Menu Size & Variety",
    desc4: "Craft a balanced menu for dietary needs, preferences, avoiding exclusion.",
    
    head5: "Flavor Profiling",
    desc5: "Align menu with customer preferences for balance and coherence.",
    
    head6: "Productivity",
    desc6: "Improve menu preparation for speed, consistency, customer satisfaction, uncovering cost-saving opportunities.",
    
    head7: "Profitability",
    desc7: "Understand menu item profitability, optimise costs, assess existing costing methods.",
    
    head8: "Chef/Cook Proficiency",
    desc8: "Assess kitchen team skills, identify improvement areas for consistency, customer satisfaction.",
    img:CreativityImg,
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224078',
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688234'
  },
  {
    id: 6,
    title: "Operations",
    description: "Optimising processes saves time, money, and stress, letting staff prioritise customer service.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Standard Operating Procedures (SOPs)", "Workflow Optimisation", "Quality Control & Consistency", "Supplier Relationships & Tendering", "Customer Experience Enhancement", "Performance Monitoring & Metrics", "HACCP & Health & Safety", "Scaling & Expansion"],
    
    head1: "Standard Operating Procedures (SOPs)",
    desc1: "Ensure seamless operations through documented procedures and training. Evaluate, improve, or implement SOPs for efficient team performance.",
    
    head2: "Workflow Optimisation",
    desc2: "Define clear workflows for each team member to enhance efficiency and productivity. Harmonise workflows for streamlined operations and increased revenue.",
    
    head3: "Quality Control & Consistency",
    desc3: "Establish systems for monitoring and maintaining consistency in customer satisfaction and team performance. Implement standardised methods for adherence.",
    
    head4: "Supplier Relationships & Tendering",
    desc4: "Strengthen supplier connections and negotiate competitive pricing for cost-effective management. Optimise COGS to align with revenue goals.",
    
    head5: "Customer Experience Enhancement",
    desc5: "Enhance customer satisfaction through systematic assessment and improvement of the overall experience. Establish effective feedback systems.",
    
    head6: "Performance Monitoring & Metrics",
    desc6: "Benchmark operational systems to identify strengths and weaknesses. Utilise data analysis for informed decision-making.",
    
    head7: "HACCP & Health & Safety",
    desc7: "Ensure compliance with health and safety regulations for a safe business operation. Streamline monitoring and data-keeping processes for efficiency.",
    
    head8: "Scaling & Expansion",
    desc8: "Plan for growth opportunities while maintaining operational excellence. Evaluate market trends and customer demands to inform expansion strategies.",
    img:OperationImg,
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688272',
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224094'
  },
  {
    id: 7,
    title: "Team",
    description: "Turning new customers into regulars hinges on your cafe crew's experience and consistent service.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Staff Recruitment & Hiring", "On boarding & Training", "Employee Engagement & Motivation", "Performance Management & KPIs", "Workforce Scheduling & Time Management", "Staff Retention & Career Development", "Insurance & Compliance"],
    
    head1: "Staff Recruitment & Hiring",
    desc1: "Ensure you have the right people on board with effective recruitment and hiring processes, including job ads, interviews, and candidate trials.",
    
    head2: "Onboarding & Training",
    desc2: "Implement a comprehensive training program to empower your team and ensure they have the skills they need to thrive.",
    
    head3: "Employee Engagement & Motivation",
    desc3: "Keep your team passionate and driven with a customised plan to align their goals with those of your cafe.",
    
    head4: "Performance Management & KPIs",
    desc4: "Strengthen your business model and boost team morale with personalised KPIs to monitor and improve performance.",
    
    head5: "Workforce Scheduling & Time Management",
    desc5: "Optimise labour operations to save costs without compromising quality through effective roster management.",
    
    head6: "Staff Retention & Career Development",
    desc6: "Retain key team members and incentivise their growth with tailored development programs integrated into succession planning.",
    
    head7: "Preliminary Budgeting",
    desc7: "Learn how to prepare, implement, and monitor a budget for financial health.",
    
    head8: "Insurance & Compliance",
    desc8: "Ensure compliance with labour laws and fair work practices, and secure appropriate insurance coverage for your cafe.",
    img:TeamImg,
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688310',
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224110'
  },
  {
    id: 8,
    title: "Leadership",
    description: "Effective leadership fosters team commitment, trust, and morale, ensuring top-notch service delivery.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Leadership Styles & Approaches", " Sharing Vision & Goals", "Building a Team", "Communication & Feedback", "Decision Making & Problem Solving", " Emotional Intelligence & Empathy", "Conflict Resolution & Mediation"],
    
    head1: "Leadership Styles & Approaches",
    desc1: "Tailor leadership styles to align seamlessly with your team for retention and productivity.",
    
    head2: "Sharing Vision & Goals",
    desc2: "Regain clarity on goals and implement team improvements for unified direction.",
    
    head3: "Building a Team",
    desc3: "Create a cohesive, high-morale team with a sustainable blend of qualities and skills.",
    
    head4: "Communication & Feedback",
    desc4: "Develop a structured approach to gather and incorporate effective feedback.",
    
    head5: "Decision Making & Problem Solving",
    desc5: "Minimise necessary decisions for effective cafe management based on experience.",
    
    head6: "Emotional Intelligence & Empathy",
    desc6: "Improve team management skills and assess needs for effective leadership.",
    
    head7: "Conflict Resolution & Mediation",
    desc7: "Achieve optimal outcomes through effective issue resolution and mediation.",
    img:LeadershipImg,
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688348',
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224126'
  },
  {
    id: 9,
    title: "Profitability",
    description: "Evaluating business model optimisation can significantly impact your bottom line positively.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Financial Health Check", "Pricing Strategy", "Cost Control & Expense Management", "Current Revenue Split Analysis", "Operational Efficiency & Productivity", "Costing & Profit Analysis"],
    
    head1: "Financial Health Check",
    desc1: "Assess sustainability, revenue-to-profit balance, lease-to-revenue ratio, and expenses to guide improvement plans.",
    
    head2: "Pricing Strategy",
    desc2: "Evaluate sustainability, psychology of pricing, menu layout, and competitor analysis for effective pricing strategies.",
    
    head3: "Cost Control & Expense Management",
    desc3: "Streamline expenses, payment timing, and supplier arrangements for enhanced efficiency.",
    
    head4: "Revenue Split Analysis",
    desc4: "Optimise revenue distribution by evaluating labour costs, overheads, and month-end balances.",
    
    head5: "Operational Efficiency & Productivity",
    desc5: "Identify growth opportunities and optimise output-to-revenue ratio.",
    
    head6: "Costing & Profit Analysis",
    desc6: "Grasp menu item costs and evaluate pricing strategy for optimal profitability.",
    img:ProfitabilityImg,
    meet_pro : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224142',
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688386'
  },
  {
    id: 10,
    title: "Exit Strategy",
    description: "Considering restructuring your cafe business? Extra assistance can weigh selling or ownership change pros and cons.",
     price_pro: {
      new: 79.99,
      old: 169.99
    },
    price_lite: {
      new: 169.99,
    },
    mentorshipAreas: ["Defining Exit Objectives", "Business Valuation", "Legal & Financial Considerations", "Succession Planning", "Transition & Handover Process", "Emotional Preparedness", "Timing & Market Conditions"],
    
    head1: "Defining Exit Objectives",
    desc1: " Establish clear goals for a smooth exit. Craft a tailored plan to achieve this.",
    
    head2: "Business Validation",
    desc2: "Assess your cafe's value and prepare it for sale by analysing revenue, market trends, and growth potential.",
    
    head3: "Legal & Financial Considerations",
    desc3: "Simplify navigating complex regulations, leases, contracts, and taxes with tailored guidance.",
    
    head4: "Succession Planning",
    desc4: "Develop an exit strategy and transition plan for a seamless handover when selling your cafe.",
    
    head5: "Transition & Handover Process",
    desc5: "Refine the handover process with defined responsibilities and deadlines for a smoother transition.",
    
    head6: "Emotional Preparedness",
    desc6: "Prepare emotionally for a successful sale with support to navigate challenges effectively.",
    
    head7: "Timing & Market Conditions",
    desc7: " Maximise profits by timing your sale in a favourable market. Receive advice on the best timing for your cafe sale.",
    img:ExitstrategyImg,
    meet_pro: "https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000224158",
    meet_lite : 'https://ccc-clevercafecompany.zohobookings.com.au/portal-embed#/17362000000688424'
  },
];


function SessionDetails() {
  const { data: sessionDetails } = useGetSessionQuery();
  const [price, setPrice] = useState("");
  const subscription_type = localStorage.getItem("subscription_type_current_selected");

  // useEffect(() => {
  //   if (sessionDetails && sessionDetails?.length > 0) {
  //     const oneOnOneSession = sessionDetails?.find(
  //       (session) => session?.session_type === "1:1 Session"
  //     );
  //     if (subscription_type == "pro") {
  //       if (oneOnOneSession?.pro_price === null) {
  //         setPrice("Free");
  //       }
  //       else {
  //         setPrice(oneOnOneSession?.pro_price);
  //       }
  //     }
  //     else {
  //       if (oneOnOneSession?.lite_price === null) {
  //         setPrice("Free");
  //       }
  //       else {
  //         setPrice(oneOnOneSession?.lite_price);
  //       }
  //     }

  //   }
  // }, [sessionDetails]);

  const subscriptiontype = localStorage.getItem("subscription_type_current_selected");

  const navigate = useNavigate();
  const handlebookNow = (session) => {
   
    navigate("/session-details-booknow", {
      state: { session, price },
    });
  };
  return (
    <div className="session-details">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Session details</h4>
          <p className="sub-head">
            1:1 Personalised Sessions: Book these yourself for personalised
            advice tailored to your specific needs.
          </p>
        </Col>
      </Row>
      {sessionData.map((session) => (
        <div className="session-details-listing" key={session.id}>
          <div className="card">
            <Row>
              <Col xs={12} sm={12} md={2} lg={2}>
                <img src={session?.img || SessionDetailsImg} alt="SessionDetailsImg" className="session-details-img" />
              </Col>
              <Col xs={12} sm={12} md={10} lg={10}>
                <h3>{session.title}</h3>
                <p className="description">{session.description}</p>
                <p className="mentorship-area">Mentorship areas</p>
                <hr className="m-0" />
                <div className="tags">
                  {session.mentorshipAreas.map((area, index) => (
                    <button key={index}>{area}</button>
                  ))}
                </div>
                {subscriptiontype === 'lite' && (
                <div className="text-end d-flex">
                  <p className="new-price">${session.price_lite.new} </p>
                  {/* <p className="old-price">${session.price_lite.old}</p> */}
                  <button className="book-now-btn" onClick={() => handlebookNow(session)}>Book now</button>
                </div>
                )}
                {subscriptiontype === 'pro' && (
                <div className="text-end d-flex">
                 <p className="new-price">${session.price_pro.new} </p>
                 <p className="old-price">${session.price_pro.old}</p>
                  <button className="book-now-btn" onClick={() => handlebookNow(session)}>Book now</button>
                </div>
                )}
              </Col>
            </Row>
          </div>
        </div>
      ))}
    </div>
  );
}

export default SessionDetails;
