import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import MUltiSelectAdd from "../Assets/Images/multiselct-Add.svg";
import { useNavigate } from "react-router-dom";
import {
  useSubmitEcosystemCrietirabyBranchidMutation,
  useGetEcosystemDetailswithAccountHolderIdQuery,
  useSubmitEditEcosystemCrietirabyBranchidMutation,
  useGetStatesQuery,
  useGetServiceTypeQuery,
  useGetProductTypeQuery,
  useGetfoodDeietryServiceQuery,
  useGetTradeServiceCategoryQuery,
} from "../Services/CcownerAPI";
import { Link, useLocation } from "react-router-dom";

function EcosystemCriteria({ onNext, handlePrevious, activeTab, BranchId }) {
  const location = useLocation();
  const { CBranchId } = location.state || {};
  const navigate = useNavigate();
  const handleBack = (e) => {
    handlePrevious();
  };
  const [selectedOptions, setSelectedOptions] = useState([]);

 

  const handleOptionChange = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();
  const { data: tradeServicesType } = useGetTradeServiceCategoryQuery();
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubmitEcosystemCrietirabyBranchidMutation();

  const [
    submitEditForm,
    {
      isLoading: editisloading,
      isError: editIserror,
      isSuccess: editisSuccess,
      error: editError,
    },
  ] = useSubmitEditEcosystemCrietirabyBranchidMutation();

  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedDietryService, setSelectedDietryService] = useState([]);
  const [selectedFSStates, setSelectedFsStates] = useState([]);
  const [selectedTSStates, setSelectedTSStates] = useState([]);
  const [selectedTradeServiceType, setSelectedTradeServiceType] = useState([]);
  const [branchId, setBranchId] = useState("");

  useEffect(() => {
    if (BranchId) {
      setBranchId(BranchId);
    } else {
      setBranchId(CBranchId);
    }
  }, [BranchId, CBranchId]);

  const { data: additionalDataFetch, refetch } =
    useGetEcosystemDetailswithAccountHolderIdQuery(branchId);
  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (additionalDataFetch) {
      setSelectedFeatures(additionalDataFetch[0]?.fs_service_categories || []);
      setSelectedProducts(additionalDataFetch[0]?.fs_product_types || []);
      setSelectedDietryService(
        additionalDataFetch[0]?.fs_specialist_dietary_services || []
      );
      setSelectedFsStates(additionalDataFetch[0]?.fs_state || []);
      setSelectedTSStates(additionalDataFetch[0]?.ts_state || []);
      setSelectedTradeServiceType(
        additionalDataFetch[0]?.ts_service_categories || []
      );
    }
  }, [additionalDataFetch]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let postData = {
      fs_service_categories: selectedFeatures,
      fs_product_types: selectedProducts,
      fs_specialist_dietary_services: selectedDietryService,
      fs_state: selectedTSStates,
      ts_service_categories: selectedTradeServiceType,
      ts_state: selectedTSStates,
    };

    try {
      const response = await submitEditForm({ postData, branchId }).unwrap();
      if (response) {
        navigate("/directory-listing");
        window.location.reload();
      }
    } catch (err) {
      if (err.status == 404) {
        try {
          const response = await submitForm({ postData, branchId }).unwrap();
          if (response) {
            navigate("/directory-listing");
            window.location.reload();
          }
        } catch (err) {}
      }
    }
  };

  const handleFeatureChange = (id) => {
    setSelectedFeatures((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleProcductChange = (id) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleDietryServiceChange = (id) => {
    setSelectedDietryService((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleFsStateChange = (id) => {
    setSelectedFsStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleTsStateChange = (id) => {
    setSelectedTSStates((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };

  const handleChangeTradeServiceType = (id) => {
    setSelectedTradeServiceType((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((featureId) => featureId !== id)
        : [...prevSelected, id]
    );
  };



  const goToPreview = async (e) => {
    e.preventDefault();
    let postData = {
      fs_service_categories: selectedFeatures,
      fs_product_types: selectedProducts,
      fs_specialist_dietary_services: selectedDietryService,
      fs_state: selectedTSStates,
      ts_service_categories: selectedTradeServiceType,
      ts_state: selectedTSStates,
    };

    try {
      const response = await submitEditForm({ postData, branchId }).unwrap();
      if (response) {
        navigate(`/industry-directory`, { state: { activeTab, BranchId } });
      }
    } catch (err) {
      if (err.status == 404) {
        try {
          const response = await submitForm({ postData, branchId }).unwrap();
          if (response) {
            navigate(`/industry-directory`, { state: { activeTab, BranchId } });
          }
        } catch (err) {}
      }
    }
  };

  // const goToPreview = () => {
  //   navigate(`/preview-cafe`, { state: { activeTab, BranchId } });
  // };

  return (
    <div className="add-details add-ecosystem-criteria">
      <h4 className="mb-20">Foodservice</h4>
      <hr />
      <form onSubmit={handleSubmit}>
        <Row>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                Which service types interest you?{" "}
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {serviceTypes?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedFeatures.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleFeatureChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedFeatures.includes(option.id)}
                        onChange={() => handleFeatureChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label
                        htmlFor={`option${option.id}`}
                        title={option.name} // Show full name on hover
                      >
                        {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                What products are you interested in?
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {productTypes?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedProducts.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleProcductChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedProducts.includes(option.id)}
                        onChange={() => handleProcductChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`} title={option.name}>
                      {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                Specialist Dietary Services
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {dietryServices?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedDietryService.includes(option.id)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleDietryServiceChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedDietryService.includes(option.id)}
                        onChange={() => handleDietryServiceChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`} title={option.name}>
                      {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
          {/* <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                States
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {states?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedFSStates.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleFsStateChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedFSStates.includes(option.id)}
                        onChange={() => handleFsStateChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                      {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>
        <h4 className="mb-20">Trades and Services</h4>
        <hr />
        <Row>
          <Col xs={12} sm={12} lg={6} md={6} className="pdr-40">
            <div className="form-control">
              <label className="choose-label">
                What trades or services are you interested in?
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {tradeServicesType?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedTradeServiceType.includes(option.id)
                          ? "selected"
                          : ""
                      }`}
                      onClick={() => handleChangeTradeServiceType(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedTradeServiceType.includes(option.id)}
                        onChange={() => handleChangeTradeServiceType(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`} title={option.name}>
                      {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            {/* <div className="see-more">See more..</div> */}
          </Col>
{/* 
          <Col xs={12} sm={12} lg={6} md={6}>
            <div className="form-control">
              <label className="choose-label">
                States
                <span className="red-star">*</span>
              </label>
              <br />
              <div className="multi-select-wrapper">
                {states?.map((option) => (
                  <div key={option.id} className="multi-select-item">
                    <div
                      className={`multi-select-container ${
                        selectedTSStates.includes(option.id) ? "selected" : ""
                      }`}
                      onClick={() => handleTsStateChange(option.id)}
                    >
                      <input
                        type="checkbox"
                        id={`option${option.id}`}
                        name="services"
                        value={option.id}
                        checked={selectedTSStates.includes(option.id)}
                        onChange={() => handleTsStateChange(option.id)}
                        style={{ display: "none" }}
                      />
                      <img src={MUltiSelectAdd} alt="MUltiSelectAdd" />
                      <label htmlFor={`option${option.id}`}>
                      {option.name.length > 20
                          ? `${option.name.substring(0, 20)}...`
                          : option.name}
                      </label>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Col> */}
        </Row>
        <Row className="action-btn">
          <Col xs={12} sm={12} lg={12} md={12} className="text-end">
            <button
              type="button"
              className="prev-btn btn-64t35ne"
              onClick={goToPreview}
            >
              Preview
            </button>
            <button
              type="button"
              className="prev-btn btn-64t35ne"
              onClick={handleBack}
            >
              Back
            </button>
            <button type="submit" className="btn-save-primary">
              Save and continue
            </button>
          </Col>
          {isError && (
            <p style={{ color: "red" }}>
              Error: {error?.data?.message || "Failed to submit form"}
            </p>
          )}
          {isSuccess && (
            <p style={{ color: "green" }} className="isSuceesMsg">
              Form submitted successfully!
            </p>
          )}
          {isLoading && <div class="loader"></div>}

          {editisSuccess && (
            <p style={{ color: "green" }} className="isSuceesMsg">
              Form submitted successfully!
            </p>
          )}
          {editisloading && <div class="loader"></div>}
        </Row>
      </form>
    </div>
  );
}

export default EcosystemCriteria;
