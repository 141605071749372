import React, { useEffect } from "react";
import CheckCircle from "../Assets/Images/check-circle.svg";
import "../Styles/CcOwnerAccountActivation.css";
import { useNavigate } from "react-router-dom";
import PaymentCompletedIcon from "../Assets/Images/check-circle.svg";
import Navbar from "../Components/Navbar";


function PaymentCompleted() {
    const navigate = useNavigate();
    useEffect(() => {
      const timer = setTimeout(() => {
        navigate('/session-details');
      }, 3000); 
    }, []);
  return (
    <div>
      {" "}
      <Navbar />
      <section className="Ccowner-account-activation">
        <img
          src={PaymentCompletedIcon}
          n
          alt="CheckCircle"
          className="check-circle"
        />
        <h3>Payment Completed</h3>
        <p>
          Welcome to our community. Now, it's time to start building your
          profile. If you{" "}
          <span className="disply-blk">
            need any assistance, click{" "}
            <span className="support">"Support"</span> and we will contact you
            as soon as possible{" "}
          </span>
          to keep you moving forward.
        </p>
      </section>
    </div>
  );
}

export default PaymentCompleted;
