import React, { useState, useEffect, useRef } from "react";
import "../Styles/EcoEnquiry.css";
import { Tabs, Tab, Row, Col } from "react-bootstrap";
import SearchIcon from "../Assets/Images/search-icon.svg";
import Person1 from "../Assets/Images/default-profile-img.png";
import TimeImg from "../Assets/Images/time.svg";
import StarredMsgImg from "../Assets/Images/starred-icon.svg";
import SendIcon from "../Assets/Images/send-icon.svg";
import AddMediaIcon from "../Assets/Images/add-media.svg";
import { useGetEcoenquirylistQuery, useSubmitStarredMutation, useGetStareedMessagesQuery } from "../Services/CcownerAPI";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import moment from "moment";
import { useLocation } from "react-router-dom";

function formatDate(dateString) {
  return moment(dateString).format("hh:mm A");
}

function EcoEnquiry() {
  const location = useLocation();
  const { sender_type, sender_id} = location.state || {};
  const CurrentBranch = localStorage.getItem("branchId_current_selected");
  const { data: conversations, refetch } = useGetEcoenquirylistQuery(
    CurrentBranch,
    {
      pollingInterval: 1000,
    }
  );

  const { data: starredMessages, refetch: refectStarredMsg } = useGetStareedMessagesQuery(
    CurrentBranch,
    {
      pollingInterval: 1000,
    }
  );

  const [key, setKey] = useState("allMessages");
  const [subKey, setSubKey] = useState("all");
  const [upgradeModal, setUpgradeModal] = useState(false);
  const [selectedConversation, setSelectedConversation] = useState(null);
  const socketRef = useRef(null);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");


  const [submitStarred, { isLoading, isError, isSuccess, error }] =
    useSubmitStarredMutation();

  // Ref for the message container
  const messagesEndRef = useRef(null);

  const subscription_type = localStorage.getItem(
    "subscription_type_current_selected"
  );
  const subscription_status = localStorage.getItem(
    "subscription_status_current_selected"
  );
  useEffect(() => {
    if ((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))) {
      setUpgradeModal(false);
    }
    else {
      setUpgradeModal(true);
    }
  }, [subscription_type, subscription_status]);

  const markAsRead = (messageId) => {
 
    const data = {
      action: "mark_as_seen",
      message_id: messageId,
      content_type: 'cafe_branch',
      object_id: CurrentBranch
    };
    if (socket && socket.readyState === WebSocket.OPEN) {
     
      socket.send(JSON.stringify(data));
    } else {
      console.error('WebSocket is not open');
    }
  };

  useEffect(() => {
    if (selectedConversation) {
      if (selectedConversation.display_participant.app_name == "food_service") {
        const iiidd = selectedConversation.display_participant.id;

        if (socketRef.current) {
          socketRef.current.close();
        }
        const token = localStorage.getItem('accessToken');
        // const url = `wss://primarybackend.dev.clevercafecompany.com.au/ws/chat/cafe_branch/${CurrentBranch}/food_service_branch/${iiidd}/`;
        const url = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/chat/cafe_branch/${CurrentBranch}/food_service_branch/${iiidd}/?token=${token}`;

        // `+process.env.REACT_APP_BASEURL_SERVER+`
        const ws = new WebSocket(url);

        ws.onopen = () => {
         
        };

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
         
  
          if (data.message) {
           
            const messageText = typeof data.message === 'object' ? data.message.text : data.message;
            const messageStatus = data.message.status || 'sent';
            const messageId = data.message.id;
            const createdAt = data.message.created_at ? new Date(data.message.created_at) : new Date();
            const appName = data.message.sender.app_name;
            const notification = data.message.notification
           
            if (!messageId) {
              console.error('Message ID is missing:', data);
              return;
            }
  
            setMessages((prevMessages) => {
              const existingIndex = prevMessages.findIndex(msg => msg.id === messageId);
  
              if (existingIndex !== -1) {
                const updatedMessages = [...prevMessages];
                updatedMessages[existingIndex] = {
                  id: messageId,
                  text: messageText,
                  status: messageStatus,
                  created_at: createdAt,
                  app_name: appName,
                  notification:notification,
                };
                return sortMessages(updatedMessages);
              } else {
                return sortMessages([
                  ...prevMessages,
                  { id: messageId, text: messageText, status: messageStatus, created_at: createdAt, app_name: appName ,notification:notification}
                ]);
              }
            });
          }
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
         
        };

        setSocket(ws);

        return () => {
          ws.close();
        };
      } 
     else
      {
        const iiidd = selectedConversation.display_participant.id;

        if (socketRef.current) {
          socketRef.current.close();
        }
        const token = localStorage.getItem('accessToken');
        const url = `wss://${process.env.REACT_APP_BASEURL_SERVER_ENQUIRY}ws/chat/cafe_branch/${CurrentBranch}/trade_service_branch/${iiidd}/?token=${token}`;
        const ws = new WebSocket(url);

        ws.onopen = () => {
         
        };

        ws.onmessage = (event) => {
          const data = JSON.parse(event.data);
         
  
          if (data.message) {
           
            const messageText = typeof data.message === 'object' ? data.message.text : data.message;
            const messageStatus = data.message.status || 'sent';
            const messageId = data.message.id;
            const createdAt = data.message.created_at ? new Date(data.message.created_at) : new Date();
            const appName = data.message.sender.app_name;
            const notification = data.message.notification
           
            if (!messageId) {
              console.error('Message ID is missing:', data);
              return;
            }
  
            setMessages((prevMessages) => {
              const existingIndex = prevMessages.findIndex(msg => msg.id === messageId);
  
              if (existingIndex !== -1) {
                const updatedMessages = [...prevMessages];
                updatedMessages[existingIndex] = {
                  id: messageId,
                  text: messageText,
                  status: messageStatus,
                  created_at: createdAt,
                  app_name: appName,
                  notification:notification,
                };
                return sortMessages(updatedMessages);
              } else {
                return sortMessages([
                  ...prevMessages,
                  { id: messageId, text: messageText, status: messageStatus, created_at: createdAt, app_name: appName ,notification:notification}
                ]);
              }
            });
          }
        };

        ws.onerror = (error) => {
          console.error("WebSocket error:", error);
        };

        ws.onclose = () => {
        
        };

        setSocket(ws);

        return () => {
          ws.close();
        };
      }
    }
  }, [selectedConversation]);

  useEffect(() => {
    messages.forEach((msg) => {
      if (msg.status == 'sent' || msg.status=='delivered') {
        markAsRead(msg.id);
      }
    });
  }, [messages]);

  // Scroll to the bottom whenever messages change
  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleCloseUpgradeModal = () => {
    setUpgradeModal(false);
  };

  const sendMessage = (e) => {
    e.preventDefault();
    if (socket && message.trim()) {
      const messageData = {
        message,
        content_type: "text",
      };
      socket.send(JSON.stringify(messageData));
      setMessage("");
    }
  };

  // const handleConversationSelect = (conversation) => {
   
  //   setSelectedConversation(conversation);
  //   setMessages([]);
  // };

  const handleConversationSelect = (conversation) => {
    if (selectedConversation?.conversation.id === conversation.conversation.id) {
        return; 
    }

    setSelectedConversation(conversation);
    setMessages([]); 
};


  const handleStarred = async (id) => {

    const postData = {
      conversation: id,
      content_type: "cafe_branch",
      object_id: Number(CurrentBranch),
    }
    try {
      const response = await submitStarred(postData).unwrap();
      if (response) {


      }

    } catch (err) {
      console.error("Failed to submit form:", err);
    }

  }

  const truncateText = (text, maxLength) => {
    return text?.length > maxLength
      ? `${text?.substring(0, maxLength)}...`
      : text;
  };
  

  // State for search input
  const [searchTerm, setSearchTerm] = useState('');

  // Function to handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Filter conversations based on search term
  const filteredConversations = conversations?.filter(conversation =>
    conversation?.display_participant.business_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const foodServiceConversations = conversations?.filter(
    (conversation) =>
      conversation?.display_participant?.app_name === "food_service"
  );

  const tradeServiceConversations = conversations?.filter(
    (conversation) =>
      conversation?.display_participant?.app_name === "trade_service"
  );


  const filteredFoodServiceConversations = foodServiceConversations?.filter(conversation =>
    conversation?.display_participant.business_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const filteredTradeServiceConversations = tradeServiceConversations?.filter(conversation =>
    conversation?.display_participant.business_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  //  stareed messages

  const starredfilteredConversations = starredMessages?.filter(conversation =>
    conversation?.display_participant.business_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const starredfoodServiceConversations = starredMessages?.filter(
    (conversation) =>
      conversation?.display_participant?.app_name === "food_service"
  );

  const starredtradeServiceConversations = starredMessages?.filter(
    (conversation) =>
      conversation?.display_participant?.app_name === "trade_service"
  );


  const starredfilteredFoodServiceConversations = starredfoodServiceConversations?.filter(conversation =>
    conversation?.display_participant.business_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const starredfilteredTradeServiceConversations = starredtradeServiceConversations?.filter(conversation =>
    conversation?.display_participant.business_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const sortMessages = (messages) => {
    return messages.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
  };
  
  useEffect(() => {
    if (sender_id && conversations?.length) {
     
      const filteredConversations = conversations.filter(convo => 
        convo.conversation.id === sender_id 
      );  
    }
  }, [sender_type, sender_id, conversations]);
  
  

  return (
    <div className="eco-enquiry">
      <h4>Eco Enquiry</h4>
      <div
        className={`enquiry-div ${subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))
          ? ""
          : "chat-disable-for-liteuser"
          }`}
      >
        <Row>
          <Col xs={12} sm={12} lg={4} md={4} className="p-0 m-0">
            <div className="left-sec">
              <div className="search-box">
                <form>
                  <div className="search-box-div">
                    <img
                      src={SearchIcon}
                      alt="SearchIcon"
                      className="SearchIcon"
                    />
                    <input
                      type="text"
                      placeholder="Search"
                      name="search"
                      className="serach-box"
                      value={searchTerm} // bind searchTerm state to the input
                      onChange={handleSearchChange} // update searchTerm on input change
                    />
                  </div>
                </form>
              </div>
              <div className="chat-tab">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => setKey(k)}
                  className="mb-3 main-tab"
                >
                  <Tab
                    eventKey="allMessages"
                    title="All messages"
                    className="sub-tabs-list"
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={subKey}
                      onSelect={(k) => setSubKey(k)}
                      className="mb-3 sub-tab"
                    >
                      <Tab eventKey="all" title="All">
                        {filteredConversations?.length > 0 ? (
                          filteredConversations.map((conversation) => (
                            <div
                              key={conversation.id}
                              className="message-lists"

                            >
                              <Row>
                                <Col xs={12} sm={12} md={10} lg={10} onClick={() => handleConversationSelect(conversation)}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={conversation?.display_participant?.image || Person1}
                                        width={40}
                                        height={40}
                                        className="enquiry-chat-profile-img"
                                        alt={conversation.display_participant.name}
                                      />
                                    </div>
                                    <div className="profile-data">
                                    <p className="name">{conversation.display_participant.business_name}</p>
                                    <p className="category">({conversation.display_participant.location})</p>
                                      <p className="category">{conversation.type}</p>
                                      <p className="message">
                                        {truncateText(conversation?.last_message.text, 25)}
                                      </p>
                                      <p className="date">
                                        <img src={TimeImg} alt="Time" />
                                        {new Date(conversation.last_message.created_at).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} className="text-end" onClick={() => handleStarred(conversation?.conversation?.id)}>
                                  <img src={StarredMsgImg} alt="Starred" className={conversation.favourite_status ? "starred-enquiry-icon" : "starred-img"} />
                                  <br />
                                  {conversation?.unseen_count>0 && <p className="unseen-count-cafe">{conversation?.unseen_count}</p>}
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <p className="no-enguiry-data">No data found</p>
                        )}
                      </Tab>


                      <Tab eventKey="foodservice" title="Foodservice">
                        {filteredFoodServiceConversations?.length > 0 ? (
                          filteredFoodServiceConversations.map((conversation) => (
                            <div
                              key={conversation.id}
                              className="message-lists"

                            >
                              <Row>
                                <Col xs={12} sm={12} md={10} lg={10} onClick={() => handleConversationSelect(conversation)}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={conversation?.display_participant?.image || Person1}
                                        width={40}
                                        height={40}
                                        className="enquiry-chat-profile-img"
                                        alt={conversation.display_participant.name}
                                      />
                                    </div>
                                    <div className="profile-data">
                                      <p className="name">{conversation.display_participant.business_name}</p>
                                      <p className="category">({conversation.display_participant.location})</p>
                                      <p className="category">{conversation.type}</p>
                                      <p className="message">
                                        {truncateText(conversation?.last_message.text, 25)}
                                      </p>
                                      <p className="date">
                                        <img src={TimeImg} alt="Time" />
                                        {new Date(conversation.last_message.created_at).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} className="text-end" onClick={() => handleStarred(conversation?.conversation?.id)}>
                                  <img src={StarredMsgImg} alt="Starred" className={conversation.favourite_status ? "starred-enquiry-icon" : "starred-img"} />
                                  <br />
                                  {conversation?.unseen_count>0 &&   <p className="unseen-count-cafe">{ conversation?.unseen_count}</p>}
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <p className="no-enguiry-data"> No data found </p>
                        )}
                      </Tab>


                      <Tab eventKey="tradeservice" title="Trade service">
                        {filteredTradeServiceConversations?.length > 0 ? (
                          filteredTradeServiceConversations.map((conversation) => (
                            <div
                              key={conversation.id}
                              className="message-lists"

                            >
                              <Row>
                                <Col xs={12} sm={12} md={10} lg={10} onClick={() => handleConversationSelect(conversation)}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={conversation?.display_participant?.image || Person1}
                                        width={40}
                                        height={40}
                                        className="enquiry-chat-profile-img"
                                        alt={conversation.display_participant.name}
                                      />
                                    </div>
                                    <div className="profile-data">
                                      <p className="name">{conversation.display_participant.business_name}</p>
                                      <p className="category">({conversation.display_participant.location})</p>
                                      <p className="category">{conversation.type}</p>
                                      <p className="message">
                                        {truncateText(conversation?.last_message.text, 25)}
                                      </p>
                                      <p className="date">
                                        <img src={TimeImg} alt="Time" />
                                        {new Date(conversation.last_message.created_at).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} className="text-end" onClick={() => handleStarred(conversation?.conversation?.id)}>
                                  <img src={StarredMsgImg} alt="Starred" className={conversation.favourite_status ? "starred-enquiry-icon" : "starred-img"} />
                                  <br />
                                 {conversation?.unseen_count>0 && <p className="unseen-count-cafe">{ conversation?.unseen_count}</p>}
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <p className="no-enguiry-data">No data found</p>
                        )}
                      </Tab>

                    </Tabs>
                  </Tab>

                  <Tab
                    eventKey="starred"
                    title="Starred"
                    className="sub-tabs-list"
                  >
                    <Tabs
                      id="controlled-tab-example"
                      activeKey={subKey}
                      onSelect={(k) => setSubKey(k)}
                      className="mb-3 sub-tab"
                    >
                      <Tab eventKey="all" title="All">
                        {starredfilteredConversations?.length > 0 ? (
                          starredfilteredConversations.map((conversation) => (
                            <div
                              key={conversation.id}
                              className="message-lists"

                            >
                              <Row>
                                <Col xs={12} sm={12} md={10} lg={10} onClick={() => handleConversationSelect(conversation)}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={conversation?.display_participant?.image || Person1}
                                        width={40}
                                        height={40}
                                        className="enquiry-chat-profile-img"
                                        alt={conversation.display_participant.name}
                                      />
                                    </div>
                                    <div className="profile-data">
                                      <p className="name">{conversation.display_participant.business_name}</p>
                                      <p className="category">({conversation.display_participant.location})</p>
                                      <p className="category">{conversation.type}</p>
                                      <p className="message">
                                        {truncateText(conversation?.last_message.text, 25)}
                                      </p>
                                      <p className="date">
                                        <img src={TimeImg} alt="Time" />
                                        {new Date(conversation.last_message.created_at).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} className="text-end" onClick={() => handleStarred(conversation?.conversation?.id)}>
                                  <img src={StarredMsgImg} alt="Starred" className={conversation.favourite_status ? "starred-enquiry-icon" : "starred-img"} />
                                  <br />
                                  {conversation?.unseen_count>0 && <p className="unseen-count-cafe">{conversation?.unseen_count}</p>}
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <p className="no-enguiry-data">No data found</p>
                        )}
                      </Tab>


                      <Tab eventKey="foodservice" title="Foodservice">
                        {starredfilteredFoodServiceConversations?.length > 0 ? (
                          starredfilteredFoodServiceConversations.map((conversation) => (
                            <div
                              key={conversation.id}
                              className="message-lists"

                            >
                              <Row>
                                <Col xs={12} sm={12} md={10} lg={10} onClick={() => handleConversationSelect(conversation)}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={conversation?.display_participant?.image || Person1}
                                        width={40}
                                        height={40}
                                        className="enquiry-chat-profile-img"
                                        alt={conversation.display_participant.name}
                                      />
                                    </div>
                                    <div className="profile-data">
                                      <p className="name">{conversation.display_participant.business_name}</p>
                                      <p className="category">({conversation.display_participant.location})</p>
                                      <p className="category">{conversation.type}</p>
                                      <p className="message">
                                        {truncateText(conversation?.last_message.text, 25)}
                                      </p>
                                      <p className="date">
                                        <img src={TimeImg} alt="Time" />
                                        {new Date(conversation.last_message.created_at).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} className="text-end" onClick={() => handleStarred(conversation?.conversation?.id)}>
                                  <img src={StarredMsgImg} alt="Starred" className={conversation.favourite_status ? "starred-enquiry-icon" : "starred-img"} />
                                  <br />
                                  {conversation?.unseen_count>0 &&  <p className="unseen-count-cafe">{conversation?.unseen_count}</p>}
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <p className="no-enguiry-data">No data found </p>
                        )}
                      </Tab>


                      <Tab eventKey="tradeservice" title="Trade service">
                        {starredfilteredTradeServiceConversations?.length > 0 ? (
                          starredfilteredTradeServiceConversations.map((conversation) => (
                            <div
                              key={conversation.id}
                              className="message-lists"

                            >
                              <Row>
                                <Col xs={12} sm={12} md={10} lg={10} onClick={() => handleConversationSelect(conversation)}>
                                  <div className="d-flex">
                                    <div>
                                      <img
                                        src={conversation?.display_participant?.image || Person1}
                                        width={40}
                                        height={40}
                                        className="enquiry-chat-profile-img"
                                        alt={conversation.display_participant.name}
                                      />
                                    </div>
                                    <div className="profile-data">
                                      <p className="name">{conversation.display_participant.business_name}</p>
                                      <p className="category">({conversation.display_participant.location})</p>
                                      <p className="category">{conversation.type}</p>
                                      <p className="message">
                                        {truncateText(conversation?.last_message.text, 25)}
                                      </p>
                                      <p className="date">
                                        <img src={TimeImg} alt="Time" />
                                        {new Date(conversation.last_message.created_at).toLocaleString()}
                                      </p>
                                    </div>
                                  </div>
                                </Col>
                                <Col xs={12} sm={12} md={2} lg={2} className="text-end" onClick={() => handleStarred(conversation?.conversation?.id)}>
                                  <img src={StarredMsgImg} alt="Starred" className={conversation.favourite_status ? "starred-enquiry-icon" : "starred-img"} />
                                  <br />
                                  {conversation?.unseen_count>0 && <p className="unseen-count-cafe">{conversation?.unseen_count}</p>}
                                </Col>
                              </Row>
                            </div>
                          ))
                        ) : (
                          <p className="no-enguiry-data">No data found</p>
                        )}
                      </Tab>
                    </Tabs>
                  </Tab>
                </Tabs>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8} className="p-0 m-0">
            <div className="right-sec">
              <div className="message-box">
                <div className="message-box-header">
                  <img src={selectedConversation?.display_participant.image || Person1} className="enquiry-chat-profile-img" alt="Profile" />
                  <p className="name">
                    {selectedConversation
                      ? selectedConversation?.display_participant.name
                      : "Select a conversation"}
                  </p>
                </div>
                {/* <div className="message-type-box">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`message-bubble ${msg.sender.app_name === "cafe_entrepreneurship"
                        ? "user-message"
                        : "bot-message"
                        }`}
                    >
                      <p>{msg.text}</p>
                      <p className="messsage-send-time">
                        {formatDate(msg.created_at)}
                      </p>
                    </div>
                  ))}
               
                  <div ref={messagesEndRef} />
                </div> */}
                <div className="message-type-box">
                  {messages.map((msg, index) => (
                    <div
                      key={index}
                      className={`message-bubble ${msg.app_name === "cafe_entrepreneurship"
                        ? "user-message"
                        : "bot-message"
                        } ${msg.notification ? "notification-message-chat" : ""}`}
                    >
                      <p>{msg.notification || msg.text}</p>
                      {!msg.notification && (
                        <p className="message-send-time">
                          {formatDate(msg.created_at)}
                          <br />
                          {msg.app_name === "cafe_entrepreneurship" && (<p>  {msg.status}</p>) }
                        </p>
                      )}
                    </div>
                  ))}
                  {/* Scroll target */}
                  <div ref={messagesEndRef} />
                </div>

                {selectedConversation && (
                  <div className="type-form">

                    <form onSubmit={sendMessage}>
                      <div className="form-control">
                        <input
                          type="text"
                          placeholder="Type your message here ..."
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                        <div className="action-btn">
                          {/* <img src={AddMediaIcon} alt="AddMediaIcon" /> */}
                          <button type="submit" className="send-btn">
                            <img src={SendIcon} alt="SendIcon" />
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradeModal} />
    </div>
  );
}

export default EcoEnquiry;
