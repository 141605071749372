import React, {useState, useEffect} from "react";
import "../Styles/Allmaterial.css";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CafestartImg from "../Assets/Images/all-matrial-cafe-startup.png";
import BusinessPlanningImg from "../Assets/Images/all-marials-business-planning.png";
import LeaseImg from "../Assets/Images/all-matrials-leasing.png";
import OperationImg from "../Assets/Images/all-material-operations.png";
import TeamImg from "../Assets/Images/all-material-team.png";
import LeadershipImg from "../Assets/Images/all-matrial-leadership.png";
import ProfitabilityImg from "../Assets/Images/all-material-profitabilitty.png";
import CreativityImg from "../Assets/Images/all-material-creativity.png";
import ExitStrategyImg from "../Assets/Images/all-material-exit-strategy.png";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import {useFetchBusinessMaterialwithSaveRecordsQuery} from "../Services/SecondaryAPI"
function AllMaterial() {

  const accountHolderId = localStorage.getItem("accountHolderId");
    const branchId = localStorage.getItem("branchId_current_selected");
    const subscription_type = localStorage.getItem("subscription_type_current_selected");
  const {data, isLoading}=useFetchBusinessMaterialwithSaveRecordsQuery({
user_id:accountHolderId,
directory_listing_id:branchId,
subscription_type:subscription_type
  });

  const [upgradeModal, setupgradeModal] = useState(false);


  const subscription_status  = localStorage.getItem(
    "subscription_status_current_selected"
  );
    useEffect(() => {
      if((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))){
        setupgradeModal(false);
      }
     else {
        setupgradeModal(true);
      }
    }, [subscription_type,subscription_status]);

  const handleCloseUpgradModal =() =>{
    setupgradeModal(false);
  }
  const showupgradeModal = () => {
    setupgradeModal(true);
  };
  return (
    <div className={`all-matrials ${ subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial")) 
      ? ""
      : "chat-disable-for-liteuser"}`}>
      {" "}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4>My Materials</h4>
        </Col>
      </Row>
      <Row>
      {isLoading && <div class="loader"></div>}
       {data?.data?.map((data)=>(
        <Col sm={12} xs={12} md={4} lg={4}>
          <Link to={`/my-materials-cafe-startup/${data.id}`}>
            <div className="card">
              <div className="card-img">
                <img src={data.image} alt="CafestartImg" />
              </div>
              <p>{data.title}</p>
            </div>
          </Link>
        </Col>
       ))} 
     
      </Row>
      <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} />
    </div>
  );
}

export default AllMaterial;
