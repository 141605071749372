import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Table, Form } from "react-bootstrap";
import SessionPaymentForm from "../Components/SessionPaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const focusAreas = [
  { id: 1, name: "Vision & Goals" },
  { id: 2, name: "Market Research" },
  { id: 3, name: "Finance & Funding" },
  { id: 4, name: "Core Team Members" },
  { id: 5, name: "Legal & Regulatory" },
  { id: 6, name: "Preliminary Budgeting" },
  { id: 7, name: "Initial Branding & Marketing" },
  { id: 8, name: "Preliminary Business Planning" },
  { id: 8, name: "Action Planning" },
];

function SessionPayments({ sessionData, price }) {
  const [checkedStates, setCheckedStates] = useState(
    focusAreas.reduce(
      (acc, area) => ({
        ...acc,
        [area.id]: { deepDive: false, broaderOverview: false },
      }),
      {}
    )
  );

  const handleCheckboxChange = (areaId, type) => {
    setCheckedStates((prevState) => ({
      ...prevState,
      [areaId]: {
        ...prevState[areaId],
        [type]: !prevState[areaId][type],
      },
    }));
  };

  return (
    <div className="session-payment">
      <Row>
        <Col xs={12} sm={12} lg={6} md={6} className="left-sec">
          <h4>Payment Location List</h4>
          <h5>{sessionData?.title}</h5>
          <p className="para">{sessionData?.description}</p>
          <div className="d-flex align-space-btwn">
            <p className="head">{sessionData?.title}</p>
            <p className="value">{price}</p>
          </div>
          <div className="d-flex align-space-btwn">
            <p className="head">Discount (60%)</p>
            <p className="value">$86.00</p>
          </div>
          <hr />
          <div className="d-flex align-space-btwn">
            <p className="head">Subtotal</p>
            <p className="grand-total">{price}</p>
          </div>
          <p className="para">
            Users should be able to select only one Deep dive session or a
            maximum of 3 broader overview sessions
          </p>
          <Table>
            <thead>
              <tr>
                <th>Focus Area</th>
                <th className="text-center">
                  Deep Dive
                  <br />
                  <span>01 Hour | 01 Topic</span>
                </th>
                <th className="text-center">
                  {" "}
                  Broader Overview
                  <br />
                  <span>01 Hour | 03 Topics</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {focusAreas.map((area) => (
                <tr key={area.id}>
                  <td>{area.name}</td>
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={checkedStates[area.id]?.deepDive || false}
                      onChange={() => handleCheckboxChange(area.id, "deepDive")}
                    />
                  </td>
                  <td className="text-center">
                    <Form.Check
                      type="checkbox"
                      checked={checkedStates[area.id]?.broaderOverview || false}
                      onChange={() =>
                        handleCheckboxChange(area.id, "broaderOverview")
                      }
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
        <Col xs={12} sm={12} lg={6} md={6} className="right-sec">
          <h4>Date & Time Selection11</h4>
          <iframe width='100%' height='750px' src='https://motivation.zohobookings.in/portal-embed#/243828000000035054' frameborder='0' allowfullscreen='' > </iframe>
          {/* <Elements stripe={stripePromise}>
            <SessionPaymentForm sessionData={sessionData} price={price} />
          </Elements> */}
        </Col>
      </Row>
    </div>
  );
}

export default SessionPayments;
