import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import "../Styles/Huddlesession.css";
import ActionIMg from "../Assets/Images/actions-img.svg";
import {
  useHuddlesessionInvitationQuery,
  useSubmitInvitationRespondMutation,
} from "../Services/SecondaryAPI";
import { useGetSessionQuery } from "../Services/SecondaryAPI";
import Swal from 'sweetalert2';

function HuddleSession() {
  const navigate = useNavigate();
  const accountHolderId = localStorage.getItem("accountHolderId");
  const { data: sessionDetails } = useGetSessionQuery();
  const subscription_type = localStorage.getItem(
    "subscription_type_current_selected"
  );
  const branchId = localStorage.getItem("branchId_current_selected");
  const [proPrice, setProprice] = useState("");

  useEffect(() => {
    if (sessionDetails && sessionDetails?.length > 0) {
      const oneOnOneSession = sessionDetails?.find(
        (session) => session?.session_type === "Group sessions"
      );
     if(oneOnOneSession){
      if (oneOnOneSession?.pro_price === null) {
        setProprice("Free");
      } else {
        setProprice(oneOnOneSession?.pro_price);
      }
    }
     
    }
  }, [sessionDetails]);

  const { data: invitations, isLoading, refetch } = useHuddlesessionInvitationQuery({
    userId: accountHolderId, branchId
  });

  const [submitForm, { isLoading: formSubmitting, isError, isSuccess, error }] =
    useSubmitInvitationRespondMutation();

useEffect(()=>{
  refetch();
},[])
  const initialSessions = [
    {
      id: 1,
      title: "How to patch KDE on FreeBSD?",
      duration: "60min",
      description:
        "Lorem ipsum dolor sit amet consectetur. Dignissim tincidunt sed vitae vehicula. Congue lacus magnis viverra risus aliquam enim. Sed nunc rhoncus nisl interdum augue mattis lacinia adipiscing mauris.",
      status: "pending",
    },
    // Add more session objects here
  ];

  const [sessions, setSessions] = useState(initialSessions);

  const handleDecline = async (id) => {
    const accountHolderId = parseInt(
      localStorage.getItem("accountHolderId"),
      10
    );
    let postData = {
      user_id: accountHolderId,
      invitation_id: id,
      status: "declined",
    };
    try {
      const response = await submitForm(postData).unwrap();
      if (response) {
        alert("Declined");
        refetch();
      }
    } catch (err) {
      alert(err?.data?.error);
    }
  };

  const handleAccept = async (id, invitations) => {
 
    if(subscription_type == "pro"){
      const ivitationId = id;
      const accountHolderId = parseInt(
        localStorage.getItem("accountHolderId"),
        10
      );
      let postData = {
        user_id: accountHolderId,
        invitation_id: ivitationId,
        status: "approved",
      };
      try {
        const response = await submitForm(postData).unwrap();
        if (response) {
          Swal.fire({
            icon: 'success',
            text: 'Accepeted',
            confirmButtonText: 'OK',
            allowOutsideClick: false,
            allowEscapeKey: false,
          });
          refetch();
        }
      } catch (err) {
        alert(err?.data?.error);
      }
    }
   else{
    //  navigate("/cafe-huddlesession-payment", {state: {invitations}})

     navigate("/cafe-huddlesession-payment", {
      state: {invitations},
    });
   }
  };
  return (
    <div className="huddle-session">
      {" "}
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Huddle session</h4>
          <p className="sub-head">
            We set these up and invite you. Keep an eye on your alerts for
            upcoming sessions and join whenever you like.
          </p>
        </Col>
      </Row>
      <div className="huddle-session-listing">
  {isLoading && <div className="loader"></div>}

  {/* Check if invitations data exists and filter out declined invitations */}
  {invitations?.data?.length > 0 ? (
    invitations?.data
      ?.filter((item) => item.status !== "declined")
      .map((invitation) => (
        <div className="card" key={invitation.id}>
          <div className="d-flex align-space-between">
            <div>
              <h4>{invitation?.huddle_session?.session_title}</h4>
              <p className="time">
                {invitation?.huddle_session?.time}, Huddle session
              </p>
              <p className="para">
                {invitation?.huddle_session?.description}
              </p>
            </div>
          </div>
          <hr />
          <a
            href={invitation?.huddle_session?.meeting_link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <p className="join-the-session">Join the session</p>
          </a>
          <div className="text-end">
            <button
              type="button"
              className="btn-decline"
              onClick={() => handleDecline(invitation.id)}
            >
              Decline
            </button>
            {invitation?.status !== "approved" && (
              <button
                type="button"
                className="btn-accept"
                onClick={() => handleAccept(invitation.id, invitation)}
              >
                Accept
              </button>
            )}
          </div>
        </div>
      ))
  ) : (
    <div>No data available</div> 
  )}
</div>

    </div>
  );
}

export default HuddleSession;
