import React, { useEffect, useState } from "react";
import ProStar from "../Assets/Images/crown-star-pro.svg";
import ManageListing from "../Assets/Images/manage-listing.svg";
import CreateListing from "../Assets/Images/create-listing.svg";
import ActionIMg from "../Assets/Images/actions-img.svg";
import LocationIcon from "../Assets/Images/location-icon.svg";
import { Row, Col } from "react-bootstrap";
import AddLocationMOdal from "../Components/AddAnotheLocation";
import "../Styles/DirectoryPayment.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DeleteIcon from "../Assets/Images/delete-icon.svg";
import EditIcon from "../Assets/Images/edit-2-yellow.png";
import AccountSuspended from "./AccountSuspended";
import ShowCreateLIst from "../Components/Showcreatelisting";

import {
  useGetCompletedBranchesQuery,
  useGetInCompletedBranchesQuery,
  useGetAccountHolderDataQuery,
  useBranchDeleteMutation,
} from "../Services/CcownerAPI";
import { useElements } from "@stripe/react-stripe-js";
import UpgradeModal from "../Components/UpgradeModal";

function DashboardDirectoryListing() {
  const navigate = useNavigate();
  const [showAddLocationpModal, setshowAddLocationpModal] = useState(false);
  const [showActionTypesPro, setShowActionTypePro] = useState(false);
  const [showActionTypesLite, setShowActionTypeLite] = useState(null);
  const [showActionTypesNoData, setshowActionTypesNoData] = useState(null);
  const [showActionTypesUnlist, setShowActionTypeUnlist] = useState(null);
  const [showAccountsuspededModal, setshowAccountsuspededModal] =
    useState(false);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [upgradeModal, setupgradeModal] = useState(false);
  const {
    data: unlistedLocations,
    isLoading,
    refetch: refetchinCompletedLOcationData,
  } = useGetInCompletedBranchesQuery();

  const {
    data: completedbranch,
    isLoading: isLoadingCompletedData,
    refetch: refetchCompletedLOcationData,
  } = useGetCompletedBranchesQuery();

  const { data: accouldHolderData, refetch } = useGetAccountHolderDataQuery();

  const handleshowAdLocationModal = () => {
    setshowAddLocationpModal(true);
  };
  const handleCloseModal = () => {
    setshowAddLocationpModal(false);
  };
  const handleCloseAccountsuspededModal = () => {
    setshowAccountsuspededModal(false);
  };

  const handleShowActionTypePro = (id) => {
    setShowActionTypePro((prevState) => (prevState === id ? null : id));
  };
  const handleShowActionTypeLite = (id) => {
    setShowActionTypeLite((prevState) => (prevState === id ? null : id));
  };
  const handleShowActionTypeUnlist = (id) => {
    setShowActionTypeUnlist((prevState) => (prevState === id ? null : id));
  };
  const handleShowActionTypeforNoData = () => {
    setshowActionTypesNoData((prevState) => (prevState ? null : true));
  };
  const handleNavigatetoCreateListing = (locationId) => {
    navigate("/dashboard", {
      state: { showcreateList: true, locationId },
    });
  };

  useEffect(() => {
    refetchCompletedLOcationData();
    refetchinCompletedLOcationData();
  }, []);


  const [deleteBranch] = useBranchDeleteMutation();

  const handleDeleteBranch = async (branchId) => {
    const isConfirmed = window.confirm("Are you sure you want to delete this branch?");
    if (!isConfirmed) return;

    try {
      const response = await deleteBranch(branchId).unwrap();
      alert("Branch deleted successfully");
      refetchinCompletedLOcationData();
      refetchCompletedLOcationData();
    } catch (err) {
      console.error("Failed to delete branch:", err);
      alert("Failed to delete branch");
    }
  };

  const proLocations = completedbranch?.filter(
    (loc) => loc?.subscription_type === "pro"
  );
  const liteLocations = completedbranch?.filter(
    (loc) => loc?.subscription_type === "lite"
  );
  const [showCreatelist, seShowCReateList] = useState(false);
  const [CurrentBranchId, setCurrentBranchIdId] = useState("");
  const handleshowCreatelist = (id) => {
    seShowCReateList(true);
    setCurrentBranchIdId(id);
  };

  const handlecloseShowCreatelist = () => {
    seShowCReateList(false);
  };

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };

  const showupgradeModal = () => {
    if (selectedLocations.length > 0) {
      setupgradeModal(true);
    }
  }
  const handleShowUpgradeModal = (location) => {
    setupgradeModal(true);
    setSelectedLocations(location)
  }

  return (
    <div
      className={`dashboard-listing ${showAccountsuspededModal ? "suspended" : ""
        }`}
    >
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Account & Directory Listing</h4>
          <p className="sub-head">
            Complete directory listings amplify engagement. Ensure your business
            shines by filling in all criteria.
          </p>
          <p className="required-message-impoertant">Cafe Listings will not appear on the public directory map until the business name and location address are correctly entered.</p>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button
            className="add-another-location"
            onClick={handleshowAdLocationModal}
          >
            Add another location
          </button>
        </Col>
      </Row>

      {/* Pro Locations */}
      {isLoading && <div class="loader"></div>}
      {isLoadingCompletedData && <div class="loader"></div>}
      {proLocations?.length > 0 && (
        <div className="list-card">
          <Row className="flagship-div">
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>
                <img src={ProStar} alt="Star" />
                <span>Pro</span>
              </p>
            </Col>
          </Row>
          {proLocations?.map((location) => (
            <div className="card" key={location.id}>
              <Row className="brdr-btm">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <p className="location-head m-0">
                    {location.flagship && <img src={LocationIcon} alt="LocationIcon" />}
                    {location.location ?? "Location"}
                    {location.operational_status.length > 0 ? (
                      location.operational_status.map((status) => (
                        <button
                          key={status.id}
                          className="open-for-business-btn"
                        >
                          {status.name}
                        </button>
                      ))
                    ) : (
                      <button className="open-for-business-btn">
                        Unknown status
                      </button>
                    )}
                  </p>
                  <p className="location_business_name">{location.business_name ?? "Business Name"}</p>
                  <p className="address">{location.address ?? "Address"}</p>
                  <Link to="/downgrade-to-lite">
                    {" "}
                    {
                      !(location?.ce_subscription_record?.subscription_cancel_at_period_end || location?.subscription_status === "free_trial") && (
                        <p className="upgrade-downdrade-btn">Downgrade to Lite</p>
                      )
                    }
                  </Link>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} className="text-end">
                  <div className="actipntypediv">
                    <img
                      src={ActionIMg}
                      alt="ActionIMg"
                      onClick={() => handleShowActionTypePro(location.id)}
                    />
                    {showActionTypesPro === location.id && (
                      <div className="actiontypes">
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          onClick={() => handleDeleteBranch(location.id)}
                        />
                        <img
                          src={EditIcon}
                          alt="EditIcon"
                          onClick={() =>
                            handleNavigatetoCreateListing(location.id)
                          }
                        />
                      </div>
                    )}
                  </div>

                  <div className="progressbar-div">
                    <p className="percentage-text">
                      {location.completion_percentage}% Completed
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${location.completion_percentage}%` }}
                        aria-valuenow={location.completion_percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div
                  className="manage-listing"
                  onClick={() => handleNavigatetoCreateListing(location.id)}
                >
                  <p>
                    <img src={ManageListing} alt="ManageListing" />
                    Manage listing
                  </p>
                </div>
              </Row>
            </div>
          ))}
        </div>
      )}

      {/* Lite Locations */}
      {liteLocations?.length > 0 && (
        <div className="list-card lite-listing">
          <Row className="flagship-div">
            <Col xs={12} sm={12} md={12} lg={12}>
              <p>
                <span>Lite</span>
              </p>
            </Col>
          </Row>
          {liteLocations?.map((location) => (
            <div className="card" key={location.id}>
              <Row className="brdr-btm">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <p className="location-head m-0">
                    {location.flagship && <img src={LocationIcon} alt="LocationIcon" />}
                    {location.location ?? "Location"}
                    {location.operational_status.length > 0 ? (
                      location.operational_status.map((status) => (
                        <button
                          key={status.id}
                          className="open-for-business-btn"
                        >
                          {status.name}
                        </button>
                      ))
                    ) : (
                      <button className="open-for-business-btn">
                        Unknown status
                      </button>
                    )}
                  </p>
                  <p className="location_business_name">{location.business_name ?? "Business Name"}</p>
                  <p className="address">{location.address ?? "Address"}</p>
                  <Link onClick={() => handleShowUpgradeModal(location)} className="show-upgrademodal-text">
                    <p className="upgrade-downgrade-btn">Upgrade to Pro</p>
                  </Link>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} className="text-end">
                  <div className="actipntypediv">
                    <img
                      src={ActionIMg}
                      alt="ActionIMg"
                      onClick={() => handleShowActionTypeLite(location.id)}
                    />
                    {showActionTypesLite === location.id && (
                      <div className="actiontypes">
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          onClick={() => handleDeleteBranch(location.id)}
                        />
                        <img
                          src={EditIcon}
                          alt="EditIcon"
                          onClick={() =>
                            handleNavigatetoCreateListing(location.id)
                          }
                        />
                      </div>
                    )}
                  </div>
                  <div className="progressbar-div">
                    <p className="percentage-text">
                      {location.completion_percentage}% Completed
                    </p>
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${location.completion_percentage}%` }}
                        aria-valuenow={location.completion_percentage}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <div
                  className="manage-listing"
                  onClick={() => handleNavigatetoCreateListing(location.id)}
                >
                  <p>
                    <img src={ManageListing} alt="ManageListing" />
                    Manage listing
                  </p>
                </div>
              </Row>
            </div>
          ))}
        </div>
      )}

      {/* Unlisted Locations */}
      {unlistedLocations?.length > 0 && (
        <div className="list-card">
          <Row>
            <div className="un-listing">
              <p>Unlisted</p>
            </div>
          </Row>
          {unlistedLocations?.map((location) => (
            <div className="card" key={location.id}>
              <Row className="brdr-btm">
                <Col xs={12} sm={12} md={8} lg={8}>
                  <p className="location-head m-0">
                    {location.location ?? "Location"}
                  </p>
                  <p className="location_business_name">{location.business_name ?? "Business Name"}</p>
                  <p className="address">{location.address}</p>
                </Col>
                <Col xs={12} sm={12} md={2} lg={2} className="text-end">
                  <div className="actipntypediv">
                    <img
                      src={ActionIMg}
                      alt="ActionIMg"
                      onClick={() => handleShowActionTypeUnlist(location.id)}
                    />

                    {showActionTypesUnlist === location.id && (
                      <div className="actiontypes">
                        <img
                          src={DeleteIcon}
                          alt="DeleteIcon"
                          onClick={() => handleDeleteBranch(location.id)}
                        />
                        <img
                          src={EditIcon}
                          alt="EditIcon"
                          onClick={() =>
                            handleNavigatetoCreateListing(location.id)
                          }
                        />
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <div
                  className="manage-listing"
                  onClick={() => handleshowCreatelist(location.id)}
                >
                  <p>
                    <img src={CreateListing} alt="CreateListing" />
                    Create listing
                  </p>
                </div>
              </Row>
            </div>
          ))}
        </div>
      )}
      <UpgradeModal
        show={upgradeModal}
        selectedLocations={selectedLocations}
        handleCloseUpgradeModal={handleCloseUpgradModal}
      />
      <AddLocationMOdal
        show={showAddLocationpModal}
        handleCloseLocationModal={handleCloseModal}
        refetchCompletedLOcationData={refetchCompletedLOcationData}
        refetchinCompletedLOcationData={refetchinCompletedLOcationData}
      />
      <ShowCreateLIst
        CurrentBranchId={CurrentBranchId}
        show={showCreatelist}
        refetch={refetchinCompletedLOcationData}
        handleCloseLocationModal={handlecloseShowCreatelist}
      />
      <AccountSuspended
        show={showAccountsuspededModal}
        handleCloseAccountsuspededModal={handleCloseAccountsuspededModal}
      />
    </div>
  );
}

export default DashboardDirectoryListing;
