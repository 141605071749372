import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseUrl = process.env.REACT_APP_SECONDARY_DEV_URL;


export const secondaryapi = createApi({
  reducerPath: 'secondaryapi',
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { endpoint }) => {
      const token = localStorage.getItem('accessToken');

      
      const noAuthEndpoints = ['submitForm', 'verifyRegisterOTP', 'resendRegisterOTP'];
      if (token && !noAuthEndpoints.includes(endpoint)) {
        headers.set('authorization', `Bearer ${token}`);
      }
      
      return headers;
    },
  }),
  endpoints: (builder) => ({
getBusinessMaterials: builder.query({
    query: () => `api/business-materials`,
  }),
  getSubCategorybyMaterialId: builder.query({
    query: ({id}) => `api/business-materials/${id}/subcategories`,
  }),
  getAllRecordsbyMaterialId: builder.query({
    query: ({businessmaterialid, subcategory_id}) => `api/business-materials/${businessmaterialid}/subcategories/${subcategory_id}/records`,
  }),
  getMaterialType: builder.query({
    query: () => 'api/all-material-types'
  }),
  getMaterialTypebyCategoryId: builder.query({
    query: ({businessmaterialid, subcategory_id}) => `api/material-types/${businessmaterialid}/${subcategory_id}`
  }),

  getRecordsById: builder.query({
    query: ({businessmaterialid, subcategory_id,materialtypeid}) => `api/business-materials/${businessmaterialid}/subcategories/${subcategory_id}/records/material-type/${materialtypeid}`
  }),

  saveRecords:builder.mutation({
    query: (postData) => ({
      url: `api/user-record/save`,
      method: 'POST',
      body: postData,
    }),
  }),

  fetchBusinessMaterialwithSaveRecords: builder.query({
    query: ({user_id,directory_listing_id, subscription_type}) => `api/business-materials/with-saved-records?user_id=${user_id}&directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`
  }),

 

  huddlesessionInvitation:builder.query({
    query: ({userId, branchId}) => `api/invitations/${userId}/${branchId}`
  }),

  submitInvitationRespond:builder.mutation({
    query: (postData) => ({
      url: `api/invitations/respond`,
      method: 'POST',
      body: postData,
    }),
  }),

  listSavedMaterialRecords: builder.query({
    query: ({userId,businessmaterialid, subcategory_id,directoryListingId,subcsriptionType,status,material_type_id}) => `api/records/${userId}/${businessmaterialid}/${subcategory_id}/${status}?directory_listing_id=${directoryListingId}&subscription_type=${subcsriptionType}&user_id=${userId}&material_type_id=${material_type_id}`
  }),
  listUnSavedMaterialRecords: builder.mutation({
    query: ({ userId, record_id, directory_listing_id, subscription_type }) => ({
      url: `api/unsave-record/${userId}/${record_id}?directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`,
      method: 'DELETE',
    }),
  }),

  submitSessionPayment:builder.mutation({
    query: (postData) => ({
      url: 'api/payments',
      method: 'POST',
      body: postData,
    }),
  }),
  getSession :builder.query({
    query: () => 'api/list-mentor-session'
  }),

  gethuddlesessionPayment : builder.query({
    query: () => `api/get-sessions`,
  }),

  gethuddlesessionPriceforWeb : builder.query({
    query: () => `api/get_huddle_price`,
  }),


  huddleSessionPayment:builder.mutation({
    query: (postData) => ({
      url: 'api/invitations/payment-lite',
      method: 'POST',
      body: postData,
    }),
  }),
  editRecords:builder.mutation({
    query: ({formData, recordId}) => ({
      url: `api/edit-records/${recordId}`,
      method: 'POST',
      body: formData,
    }),
  }),

  deleteUserbyRecordId: builder.mutation({
    query: (user_id) => ({
      url: `api/delete-saved-records-invitations/${user_id}`,
      method: 'DELETE',
    }),
  }),

  deleteBranchbyRecordId: builder.mutation({
    query: ({user_id, deleteBranchId}) => ({
      url: `api/delete-records_by_branch/${user_id}/${deleteBranchId}`,
      method: 'DELETE',
    }),
  }),

  filterMymaterial : builder.query({
    query: ({userId, record_id, directory_listing_id, subscription_type,status, businessmaterialid}) => `api/savedrecords/filter/${userId}/${businessmaterialid}/${status}?directory_listing_id=${directory_listing_id}&subscription_type=${subscription_type}`,
  }),

  }),
});

export const {
    useGetBusinessMaterialsQuery,
    useGetSubCategorybyMaterialIdQuery,
    useGetAllRecordsbyMaterialIdQuery,
    useGetMaterialTypeQuery,
    useGetMaterialTypebyCategoryIdQuery,
    useGetRecordsByIdQuery,
    useSaveRecordsMutation,
    useFetchBusinessMaterialwithSaveRecordsQuery,
   
   useHuddlesessionInvitationQuery,
   useSubmitInvitationRespondMutation,
   useListSavedMaterialRecordsQuery,
   useListUnSavedMaterialRecordsMutation,
   useSubmitSessionPaymentMutation,
   useGetSessionQuery,
   useGethuddlesessionPaymentQuery,
   useHuddleSessionPaymentMutation,
   useEditRecordsMutation,
   useGethuddlesessionPriceforWebQuery,
   useDeleteUserbyRecordIdMutation,
   useDeleteBranchbyRecordIdMutation
} = secondaryapi;
