import React, { useEffect, useState } from "react";
// import "../../Styles/EcosystemRecommendation.css"
import RecommendationModal from "../Components/EcosystemRecommendationModal"


function FoodServiceEcosystemRecommandation() {
    const [show, showModal] =useState(true)
    const handleCloseModal =()=>{
        showModal(false);
    }
  return (
    <div>
        <h4>Recommendation</h4>
    <RecommendationModal show={show} handleCloseModal={handleCloseModal}/>
    </div>
  )
}

export default FoodServiceEcosystemRecommandation
