import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { ccownerapi } from "../Services/CcownerAPI";
import { foodserviceapi } from "../Services/FoodserviceAPI";
import { tradeserviceapi } from "../Services/TradeserviceAPI";
import { communityapi } from "../Services/CommunityAPI";
import { secondaryapi } from "../Services/SecondaryAPI";
import { commonapi } from "../Services/CommonAPI";
import {websiteapi} from "../Services/WebsiteAPI"
import {supportapi} from "../Services/SupportAPI"
import {articleapi} from "../Services/ArticleAPI"

export const store = configureStore({
  reducer: {
    [ccownerapi.reducerPath]: ccownerapi.reducer,
    [foodserviceapi.reducerPath]: foodserviceapi.reducer,
    [tradeserviceapi.reducerPath]: tradeserviceapi.reducer,
    [communityapi.reducerPath]: communityapi.reducer,
    [secondaryapi.reducerPath]: secondaryapi.reducer,
    [commonapi.reducerPath]: commonapi.reducer,
    [websiteapi.reducerPath]: websiteapi.reducer,
    [supportapi.reducerPath]: supportapi.reducer,
    [articleapi.reducerPath]: articleapi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(ccownerapi.middleware, supportapi.middleware,foodserviceapi.middleware, 
      tradeserviceapi.middleware, communityapi.middleware,secondaryapi.middleware,commonapi.middleware,
      websiteapi.middleware,  articleapi.middleware),
});

setupListeners(store.dispatch);
