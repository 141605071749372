import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import UploadModal from "../Components/UploadModal";
import "../Styles/MediaGallery.css";
import img1 from "../../../Assets/Images/media-galleryimg1.png";
import img2 from "../../../Assets/Images/media-galleryimg2.svg";
import img3 from "../../../Assets/Images/gallery-img3.svg";
import Playbtn from "../../../Assets/Images/vido-play-btn.svg";
import AlertImg from "../../../Assets/Images/trade-open-info.svg";
import DropDownArrow from "../../../Assets/Images/trade-dropdown-arrow.png";
import TooltipImg from "../../../Assets/Images/tooltip-trade.svg";
import {
  useUploadMediaFilesMutation,
  useGetMediaGalleryQuery,
} from "../../../Services/TradeserviceAPI";
import QuickpitchGuide from "../Components/QuickPitchGuide"
import CloseImg from "../../../Assets/Images/close.svg"
import BlackBg from "../../../Assets/Images/default_video_img.png"
import DefaultPdfImg from "../../../Assets/Images/defaultpdf-img.png"
import DefaultpdfiMGColor from "../../../Assets/Images/pdf_default_img1.jpg"
import * as pdfjsLib from "pdfjs-dist";

function MediaGallery() {
  const [showUploadpModal, setshowUploadModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const branchId = localStorage.getItem("tdservicebranchId_current");
  const [showQuickpitchModal, setQuickPitchModal] = useState(false)
  const { data: mediagalleryData } = useGetMediaGalleryQuery(branchId);

  const approvedFiles = mediagalleryData?.filter(
    (file) => file.status === "approved"
  );
  const unapprovedFiles = mediagalleryData?.filter(
    (file) => file.status === "pending"
  );

  useEffect(() => {

  }, [mediagalleryData]);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const handleUploadClick = () => {
    setshowUploadModal(true);
  };
  const handleCloseUploadModal = () => {
    setshowUploadModal(false);
  };

  const handleHoverQuickPitch = () => {
    setQuickPitchModal(true);
  };

  const handleCloseQuickpitchMOdal = () => {
    setQuickPitchModal(false);
  };

  const handleCloseTooltip = () => {
    setIsOpen(false);
  }

  return (
    <div className="media-gallery media-gallery-trade">
      <Row>
        <Col xs={12} sm={12} md={8} lg={8}>
          <h4>Media Gallery</h4>
          <p className="sub-head">
            All submissions are reviewed and approved before being published.
          </p>
          <div className="mb-20">
            <img src={AlertImg} />
            <button
              onClick={toggleDropdown}
              className="open-info-toggle-button"
            >
              Open info <img src={DropDownArrow} alt="DropDownArrow" />
            </button>
          </div>
        </Col>
        <Col xs={12} sm={12} md={4} lg={4} className="text-end">
          <button className="upload-media-btn" onClick={handleUploadClick}>
            Upload
          </button>
        </Col>
      </Row>
      <div className="open-info-content">
        {isOpen && (
          <div className="dropdown-content" style={{ width: "290px" }}>
            <div className="media-gallery-openinfo-close">

              <img src={CloseImg} onClick={handleCloseTooltip} />
            </div>
            <h6><b>Essential Content for Maximising Engagement</b></h6>
            <p>
              We recommend completing your directory listing before adding media
              content. To make the most of the Media Gallery, upload content
              that enhances cafe members' understanding of your services. This
              helps you target the right audience based on their operational
              status and exactly how you can help.
            </p>
            <p>
              You can get your gallery going in a few minutes by using these key
              recommendations and then keep it updated. Consider uploading any
              combination of the following for the best engagement:
            </p>
            <h6>Media Upload Guidelines</h6>
            <ul>
              <li>
                <span>*</span>Price Lists / Menus
              </li>
              <li>
                <span>*</span>Catalogues / Brochures
              </li>
              <li>
                <span>*</span>Training Manuals / Recipe SheetsHealth & Safety
                Guidelines / Certification Documents
              </li>
              <li>
                <span>*</span>Promotions: Marketing Collateral
              </li>
              <li>
                <span>*</span>Webinars: Relevant Recorded Sessions
              </li>
              <li >
                <span>*</span>Quick Pitch Guide{" "}
                <div class="tooltip-common">
                  <img src={TooltipImg} alt="TooltipImg" onMouseEnter={handleHoverQuickPitch} />
                  {/* <span class="tooltip-commontext">
                  Quick Pitch video tutorial and template
                  </span> */}
                </div>
              </li>
              {/* <li>
                <span>*</span>No discounts on products and services
              </li> */}
            </ul>
            <div className="book-now">
              <p className="head"> 15-Minute Session & Business Interview</p>
              <p>
                Book a free 15-minute session with a Clever Cafe expert. We'll
                help you highlight the best aspects of your business. After the
                interview, you'll receive a polished 3-minute video to submit to
                your portal, showcasing your offerings at their best
              </p>
              <a href="https://ccc-clevercafecompany.zohobookings.com.au/#/17362000000471130" target="_blank"> <button type="button" className="book-now-btn">
                Book now
              </button></a>
            </div>
          </div>
        )}
      </div>

      {/* Unapproved Files */}
      <Row style={{ marginLeft: isOpen ? "290px" : "0" }}>
        {unapprovedFiles && unapprovedFiles.length > 0 ? (
          unapprovedFiles.map((file, index) => (
            <Col key={index} lg={isOpen ? 6 : 4} md={isOpen ? 6 : 4} sm={12}>
              <a href={file.file} target="_blank">
                {" "}
                <div className="card-container">
                  <div className="card">
                    <div className="card-image-overlay">
                      <p className="card-text">Waiting for admin approval</p>
                      {file.file_type === "pdf" ? (
                        <p className="gallery-title-waiting-approval">
                          {file.file_name}
                        </p>
                      ) : (
                        ""
                      )}
                      <div>
                        {file.file_type === "video" && (
                          <div>
                            <img
                              src={Playbtn}
                              alt="Play button"
                              className="play-btn-waiting-approval"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {file.file_type === "pdf" ? (
                      <img
                        src={DefaultPdfImg}
                        alt="PDF preview"
                        className="card-image"
                      />
                    ) : file.file_type === "video" ? (
                      <img
                        src={BlackBg}
                        alt="Video thumbnail"
                        className="card-image"
                      />
                    ) : null}
                  </div>
                </div>
              </a>
            </Col>
          ))
        ) : (
          <p>No unapproved files available</p>
        )}
      </Row>

      {/* Approved Files */}
      <Row
        className="approved-block"
        style={{ marginLeft: isOpen ? "290px" : "0" }}
      >
        <h5>Approved</h5>
        {approvedFiles && approvedFiles.length > 0 ? (
          approvedFiles.map((file, index) => (
            <Col key={index} lg={isOpen ? 6 : 4} md={isOpen ? 6 : 4} sm={12}>
              <a href={file.file} target="_blank">
                <div className="card-container">
                  <div className="card">


                    {file.file_type === "pdf" ? (
                      <>
                        <div className="card-image-overlay-approved">

                        </div>
                        <div className="media-gallery-card-text">

                          <p className="gallery-title-approved-pdf">{file.file_name}</p>

                        </div>
                        <div className="pdf-icon-div">

                          {/* <iframe
                       src={file.file}
                       frameBorder="0"
                       style={{
                         
                         height: "100%",
                         border: "none",
                       }}
                       scrolling="no"
                     ></iframe> */}
                          <img src={DefaultpdfiMGColor} className="pdf-icon-media-gallery" />

                        </div>
                      </>



                    ) : file.file_type === "video" ? (
                      <>
                        <div className="card-image-overlay-approved">

                        </div>
                        <div className="media-gallery-card-text">

                          <p className="gallery-title-approved">{file.file_name}</p>

                        </div>
                        <div style={{ overflow: "hidden", height: "500px", width: "100%" }}>
                          <img
                            src={BlackBg}
                            alt="Video thumbnail"
                            className="card-image"
                          />
                        </div>
                      </>
                    ) : null}
                  </div>
                </div>
              </a>
            </Col>

          ))
        ) : (
          <p>No approved files available</p>
        )}
      </Row>
      <UploadModal
        show={showUploadpModal}
        handleCloseModal={handleCloseUploadModal}
      />
      {showQuickpitchModal && <QuickpitchGuide handleCloseQuickPitchModal={handleCloseQuickpitchMOdal} />}
    </div>
  );
}

export default MediaGallery;
