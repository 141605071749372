import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../Styles/Cafelogin.css";
import { useSubmitLoginMutation } from "../../../Services/CcownerAPI";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/logo.svg";
import VectorImg from "../../../Assets/Images/login-cafe-bg-vector.svg";
import OtpInput from "react-otp-input";
import CheckCircle from "../../../Assets/Images/check-circle-green.svg"

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [formValid, setFormValid] = useState(false);
  const [error, setError] = useState("");
  // const maskedEmail = email ? maskEmail(email) : "";

  const handleChange = (otp) => {
    setCode(otp);
    setError("");
  };

  useEffect(() => {
    if (code.length == 4) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [code]);

  const [submitForm, { isLoading, isError, isSuccess }] =
    useSubmitLoginMutation();

  const handleSubmit = async (e) => {
    const un = "techteam";
    e.preventDefault();
  };
  const handleResenOTP = async (e) => {
    e.preventDefault();

    // try {
    //   const response = await submitResendOtp({ id }).unwrap(); // Await the response
    //   if (response) {
    //     alert("The New OTP is send to yor mail id");
    //   }
    // } catch (err) {
    //   console.error("Failed to submit form:", err);
    // }
  };
  useEffect(() => {
    const timer = setTimeout(() => {
   navigate("/food-login")
  }, 1000);
  }, []);

  return (
    <div className="login forgot-psw-otp food-login">
      <Row>
      <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg">
            <div className="logo-div">
            <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>  
            </div>
            <div className="login-bg-text">
              <h2>
              Foodservice
              </h2>
              <p>
              Discover the Breakthrough You've Been Looking For with Australia's Premier Cafe Support Platform
              </p>
            </div>
            <img src={VectorImg} alt="VectorImg" className="vector-img-login"/>
          </div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
        <img src={CheckCircle} alt="CheckCircle" />
        <p className="verification-complete-txt">Password changed successfully</p>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
