import React, { useState, useEffect, useRef } from "react";
import DirectoryListingNavbar from "../Components/DirectoryListingNavbar";
import { Row, Col } from "react-bootstrap";
import DReamCfeImg from "../Assets/Images/dream-cafe-img.png";
import MailIcon from "../Assets/Images/mail-icon.svg";
import LinkIcon from "../Assets/Images/link-icon.svg";
import PhoneIcon from "../Assets/Images/phone-icon.svg";
import Facebook from "../Assets/Images/connect-facebook.svg";
import Linkdin from "../Assets/Images/connect-linkdin.svg";
import Instagram from "../Assets/Images/connect-instagram.svg";
import RSS from "../Assets/Images/connect-rss.svg";
import Googleplus from "../Assets/Images/connect-googleplus.svg";
import Twitter from "../Assets/Images/connect-twitter.svg";
import Youtube from "../Assets/Images/connect-youtube.svg";
import CafeImg1 from "../Assets/Images/cafe-img1.png";
import CafeImg2 from "../Assets/Images/cafe-img2.png";
import CafeImg3 from "../Assets/Images/cafe-img3.png";
import CafeImg4 from "../Assets/Images/cafe-img4.png";
import CafeImg5 from "../Assets/Images/cafe-img5.png";
import defaultcoverImage from "../Assets/Images/default-cover-image.png";
import OtherLocationElipseImg from "../Assets/Images/other-location-elipse-img.svg";
import FeatureImg from "../Assets/Images/cafe-features.png";
import MenuImg from "../Assets/Images/cafe-menu.png";
import Ambience from "../Assets/Images/cafe-ambience.png";
import CafeDietry from "../Assets/Images/cafe-dietry.png";
import GoogleImg from "../Assets/Images/Google.svg";
import Trustpilot from "../Assets/Images/Trustpilot_ Reviews & Ratings.svg";
import FacebookImg from "../Assets/Images/facebook-color.svg";
import TechStack from "../Assets/Images/tech-stack.png";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import LikesImg from "../Assets/Images/likes-img.svg";
import Elipse1 from "../Assets/Images/tradesservice-elipse1.svg";
import Elipse2 from "../Assets/Images/tradesservice-elispe2.svg";
import Elipse3 from "../Assets/Images/tradesservice-elispe3.svg";
import Elipse4 from "../Assets/Images/tradesservice-elipse4.svg";
import ProImg from "../Assets/Images/public-directory-proimg.png";
import FlagImg from "../Assets/Images/flag-icon.svg";
import CloseIcon from "../Assets/Images/close.svg";
import EditIcon from "../Assets/Images/directory-edit.svg";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import {
  useGetCafeViewMoreFromTradeQuery,
  useGetCafeViewMoreFromFoodQuery,
  useGetAllBranchDetailsForallUsersQuery,
  useGetBranchListQuery,
  useGetpreviewbranchesQuery,
  useGetpreviewbranchesMatchesQuery,
  useFsacceptcafeMutation
} from "../Services/CcownerAPI";
import FlagTicket from "../Components/FlagTicket";
import DefaultLogo from "../Assets/Images/default-preview-logo.png"
import CafeInterestFood from "../Assets/Images/cafe-interest-food.png";
import CafeInterestTrade from "../Assets/Images/cafe-interest-trade.png"
import {
  useGetRefreshTokenMutation
} from "../Services/CommonAPI"
import Sendbtn from "../Assets/Images/send-btn.svg";
import InactiveBUtton from "../Assets/Images/Inactive Button.svg";
import ActiveButton from "../Assets/Images/cafe-match-active.svg";
import { skipToken } from '@reduxjs/toolkit/query';

import {
  useTsacceptcafeMutation
} from "../Services/TradeserviceAPI";



const mapContainerStyle = {
  height: "600px",
  width: "100%",
  borderRadius: "20px",
};

const center = {
  lat: 52.3676, // Latitude for Amsterdam, Netherlands
  lng: 4.9041, // Longitude for Amsterdam, Netherlands
};

function PublicDirectory() {
  const location = useLocation();
  const { id, senderType } = useParams();
  const branchId = id;
  const { data, isLoading } = useGetAllBranchDetailsForallUsersQuery({ branchId });
  const [showFlagTicket, setShowfalgTicket] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [decisionts, setDesicionts] = useState(false);
  const [decisionfs, setDesicionfs] = useState(false);

  const branchId_current_selected_ts = localStorage.getItem("tdservicebranchId_current");
const branchId_current_selected_fs = localStorage.getItem("fdservicebranchId_current");



const { data: decisionDatats } = useGetCafeViewMoreFromTradeQuery(
  branchId_current_selected_ts
    ? { tsbranchId: branchId_current_selected_ts, ceBranchid: id }
    : skipToken // Skip the query if branchId_current_selected_ts is null
);

const { data: decisionDatafs } = useGetCafeViewMoreFromFoodQuery(
  branchId_current_selected_fs
    ? { fsbranchId: branchId_current_selected_fs, ceBranchid: id }
    : skipToken // Skip the query if branchId_current_selected_fs is null
);



  // const branchId_current_selected_ts = localStorage.getItem("tdservicebranchId_current")
  // const { data: decisionDatats } = useGetCafeViewMoreFromTradeQuery({ tsbranchId: branchId_current_selected_ts, ceBranchid: id })

  // const branchId_current_selected_fs = localStorage.getItem("fdservicebranchId_current")
  // const { data: decisionDatafs } = useGetCafeViewMoreFromFoodQuery({ fsbranchId: branchId_current_selected_fs, ceBranchid: id })

  const [fstocsaccept, {}] = useFsacceptcafeMutation();

  const convertTo12HourFormat = (time) => {
    if (!time) return ""; // Handle null or empty time values

    let [hours, minutes] = time.split(":");
    let suffix = hours >= 12 ? "PM" : "AM";

    hours = hours % 12 || 12; // Convert 0 hours to 12 for 12 AM/PM
    return `${hours}:${minutes} ${suffix}`;
  };

  // Function to render the time or "Holiday"
  const renderTime = (isAvailable, openingTime, closingTime) => {
    if (isAvailable) {
      return `${convertTo12HourFormat(openingTime)} - ${convertTo12HourFormat(
        closingTime
      )}`;
    } else {
      return "Closed";
    }
  };
  const { data: branchListData } = useGetBranchListQuery();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const handleClose = () => {
    toggleMenu();
    navigate(-1);
  };
  const { data: locations, error } = useGetpreviewbranchesMatchesQuery(id);
  const mapRef = useRef(null);
  useEffect(() => {
    // Load the Google Maps script
    const script = document.createElement("script");
    script.src =
      `https://maps.googleapis.com/maps/api/js?key=` +
      process.env.REACT_APP_GOOGLEMAP_API;
    script.async = true;
    script.onload = () => {
      // Initialize the map
      const australia = { lat: -25.2744, lng: 133.7751 };
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 4,
        center: australia,
      });

      // Check if locations is defined and is an array
      if (locations && Array.isArray(locations)) {
        // Add markers
        locations.forEach((location) => {
          new window.google.maps.Marker({
            position: location.position,
            map: map,
            title: location.title,
          });
        });
      } else {
        console.error("Locations data is unavailable or not an array.");
      }
    };
    document.head.appendChild(script);
  }, [locations]);
  const { activeTab } = location.state || { activeTab: 1 };


  const goBackToTab = () => {
    navigate(-1);
  };
  const handleShowTicket = () => {
    setShowfalgTicket(true);
  };
  const handleclose = () => {
    setShowfalgTicket(false);
  };

  const [submitRefreshToken, { isSuccessRefreshToken, isError: isErrorRefreshToken }] =
    useGetRefreshTokenMutation();
  const [refreshToken, setRefreshToken] = useState("");
  const [refreshtokenNew, setRefreshTokenNew] = useState("")
  const [tstocsaccept, { }] = useTsacceptcafeMutation();

  const handleSubmitRefreshToken = async () => {
    const RefreshToken = localStorage.getItem("refreshToken")
    const postData = { refresh: RefreshToken };
    try {
      const response = await submitRefreshToken(postData).unwrap();
      if (response) {

        localStorage.setItem("accessToken", response.access);
        localStorage.setItem("refreshToken", response.refresh);
      }
    } catch (err) {
      console.error("Failed to refresh token:", err);
    }
  };

  useEffect(() => {

    if (isErrorRefreshToken) {
      navigate("/cafe-login");
      localStorage.clear();
    }
  }, [isErrorRefreshToken])

  useEffect(() => {
    const intervalId = setInterval(() => {
      handleSubmitRefreshToken();
    }, 30000); // 10 seconds

    return () => clearInterval(intervalId);
  }, [refreshToken, refreshtokenNew]);


  const handleRedirectoChat = () => {
    if (senderType == "foodService") {
      navigate("/food-service-ecosystem-enquiry", {
        state: {
          sender_id: branchId
        },
      });
    }

    else if (senderType == "tradeService") {
      navigate("/trade-eco-enquiry", {
        state: {
          sender_id: branchId
        },
      });
    }
  }

  useEffect(() => {
    setDesicionts(decisionDatats?.status);
  }, [decisionDatats])


  useEffect(() => {
    setDesicionfs(decisionDatafs?.status);
  }, [decisionDatafs])

  const handleActiveClickts = async () => {
    const postdata = {
      ce_branch_id: id,
      status: "accepted",
    };
    const currentbranchbranchId = localStorage.getItem("tdservicebranchId_current")
    try {
      const response = await tstocsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);

      }
    } catch (err) {
      alert("Failed to Accept");
    }

  };

  const handleInactiveClickts = async () => {
    const postdata = {
      ce_branch_id: id,
      status: "declined",
    };
    const currentbranchbranchId = localStorage.getItem("tdservicebranchId_current")
    try {
      const response = await tstocsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {

        alert("Listing removed from matches and added to declined list.");
      }
    } catch (err) {
      alert("Failed to decline the listing");
    }
  };



  const handleActiveClickfs = async () => {
    const postdata = {
      ce_branch_id: id,
      status: "accepted",
    };
    const currentbranchbranchId = localStorage.getItem("fdservicebranchId_current")
    try {
      const response = await fstocsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);

      }
    } catch (err) {
      alert("Failed to Accept");
    }

  };

  const handleInactiveClickfs = async () => {
    const postdata = {
      ce_branch_id: id,
      status: "declined",
    };
    const currentbranchbranchId = localStorage.getItem("fdservicebranchId_current")
    try {
      const response = await fstocsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {

        alert("Listing removed from matches and added to declined list.");
      }
    } catch (err) {
      alert("Failed to decline the listing");
    }
  };


  return (
    <>
      {/* <DirectoryListingNavbar /> */}
      <div>
        <nav className="navbar public-directory-nav">
          <div className={`nav-links ${isOpen ? "open" : ""}`}>
            <a onClick={goBackToTab}>
              <img src={CloseIcon} alt={CloseIcon} /> Close
            </a>
            {/* <a href="/directory-listing"><img src={CloseIcon} alt={CloseIcon} /> Close</a> */}
          </div>
          <div className="nav-toggle" onClick={toggleMenu}>
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
          </div>
        </nav>
      </div>
      <div className="public-directory">
        <section
          className="public-directory-banner"
          style={{
            backgroundImage: `url(${data?.additional_detail?.cover_image || defaultcoverImage
              })`,
          }}
        >
          {/* <div>
            <img src={LikesImg} alt="LikesImg" className="like-img" />
            <div className="like-count"> {data?.branch?.likes_count}</div>
          </div> */}
          <div className="ecosystem-recommendation-tooltip">


            <div class="tooltip-common">
              <img src={LikesImg} alt="LikesImg" className="like-img" />
              <div className="like-count"> {data?.branch?.likes_count}</div>
              <span class="tooltip-commontext">
                Ecosystem Recommendations
              </span>
            </div>
          </div>
          <div className="dream-cafe">
            <Row>
              <Col md={6} lg={6} sm={12} className="text-start left-sec">
                <div className="d-flex">
                  <div>
                    <img
                      src={data?.additional_detail?.logo || DefaultLogo}
                      alt="DReamCfeImg"
                      className="priview-logo"
                    />
                  </div>
                  <div>
                    {data?.branch?.operational_status.length > 0 ? (
                      data?.branch?.operational_status.map((status) => (
                        <button
                          key={status.id}
                          className="open-for-business-btn"
                        >
                          {status.name}
                        </button>
                      ))
                    ) : (
                      <button className="open-for-business-btn">
                        Unknown status
                      </button>
                    )}
                    <h3>{data?.branch?.business_name}</h3>
                    <p>{data?.branch.address}</p>
                    <p>{data?.branch.location}</p>
                    <p>ABN {data?.branch.abn}</p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={6} sm={12} className="right-sec">
                {data?.branch.email && (
                  <p>
                    <img src={MailIcon} alt="MailIcon" /> {data?.branch.email}
                  </p>
                )}
                {data?.branch.contact_number && (
                  <p className="phone-align-left">
                    <img src={PhoneIcon} alt="PhoneIcon" />{" "}
                    {data?.branch.contact_number}
                  </p>
                )}
                {data?.branch.weburl && (
                  <p>
                    <img src={LinkIcon} alt="LinkIcon" /> {data?.branch.weburl}
                  </p>
                )}
              </Col>
            </Row>
            <Row className="bottom-sec">
              <h5>About</h5>
              <p>{data?.additional_detail?.min_bio}</p>
              <Row className="align-flex-end">
                <Col sm={12} lg={9} md={9}>
                  <div className="social-media-icons">
                    <a href={data?.branch?.facebook} target="_blank">
                      {" "}
                      <img src={Facebook} alt="Facebook" />
                    </a>
                    <a href={data?.branch?.twitter} target="_blank">
                      {" "}
                      <img src={Twitter} alt="Twitter" />
                    </a>
                    <a href={data?.branch?.linkedin} target="_blank">
                      {" "}
                      <img src={Linkdin} alt="Linkdin" />
                    </a>
                    <a href={data?.branch?.youtube} target="_blank">
                      {" "}
                      <img src={Youtube} alt="Youtube" />
                    </a>
                    <a href={data?.branch?.instagram} target="_blank">
                      {" "}
                      <img src={Instagram} alt="Instagram" />
                    </a>
                  </div>
                </Col>
                <Col sm={12} lg={3} md={3} className="text-end p-0">

                  {/* <button className="msg-send-btn-match-cafe" onClick={handleRedirectoChat}>
                    <img src={Sendbtn} alt="Sendbtn" />
                    Message
                  </button> */}

                  {senderType === "tradeService" ? (
                    decisionts !== "accepted" ? (
                      <div className="d-flex justify-content-end">
                         {!isActive ? (
                        <>
                          <button onClick={handleInactiveClickts} className="accept-reject-btn">
                            <img src={InactiveBUtton} alt="InactiveButton" />
                          </button>
                          <button onClick={handleActiveClickts} className="accept-reject-btn">
                            <img src={ActiveButton} alt="ActiveButton" />
                          </button>
                        </>
                         ) : (
                          <Link to="/trade-eco-enquiry">
                      <button type="button" className="primary-btn">
                        Message
                      </button>
                    </Link>
                        )}
                      </div>
                    ) : (
                      <>
                       <Link to="/trade-eco-enquiry">
                      <button type="button" className="primary-btn">
                        Message
                      </button>
                    </Link>
                      </>
                    )
                  ) : senderType === "foodService" ? (
                    decisionfs !== "accepted" ? (
                      <div className="d-flex justify-content-end">
                        {!isActive ? (
                        <>
                          <button onClick={handleInactiveClickfs} className="accept-reject-btn">
                            <img src={InactiveBUtton} alt="InactiveButton" />
                          </button>
                          <button onClick={handleActiveClickfs} className="accept-reject-btn">
                            <img src={ActiveButton} alt="ActiveButton" />
                          </button>
                        </>

                        ):(
                          <Link to="/food-service-ecosystem-enquiry">
                          <button type="button" className="primary-btn">
                            Message
                          </button>
                        </Link>
                        )}
                      </div>
                    ) : (
                      <>
                        <Link to="/food-service-ecosystem-enquiry">
                      <button type="button" className="primary-btn">
                        Message
                      </button>
                    </Link>
                      </>
                    )
                  ) : (
                    <></>
                  )}



                </Col>
              </Row>
            </Row>
            <img src={Elipse1} alt="elipse" className="elipse1" />
            <img src={Elipse2} alt="elipse" className="elipse2" />
            <img src={Elipse3} alt="elipse" className="elipse3" />
            <img src={Elipse4} alt="elipse" className="elipse4" />
            <img src={ProImg} alt="ProImg" className="proImg" />
          </div>
        </section>
        <section className="cafe-gallery">
          <Row>
            {/* Display the first two images */}
            {data?.additional_detail?.images.slice(0, 2).map((img, index) => (
              <Col sm={12} md={6} lg={6} key={img.id}>
                <img
                  src={img.image}
                  alt={`Cafe Image ${index + 1}`}
                  className="cafe-images"
                />
              </Col>
            ))}
          </Row>

          <Row className="mt-24">
            {/* Display the next set of images */}
            {data?.additional_detail?.images.slice(2).map((img, index) => (
              <div className={`div${index + 3}`} key={img.id}>
                <img
                  src={img.image}
                  alt={`Cafe Image ${index + 3}`}
                  className="cafe-images"
                />
              </div>
            ))}
          </Row>
        </section>
        {data?.user_branches?.length > 0 && <section className="other-location">
          <h4>Other Locations</h4>
          <Row>
            {data?.user_branches?.map((branch) => (
              <Col sm={12} lg={3} md={3}>
                <div className="card">
                  <h5> {branch.location} </h5>
                  <p> {branch.address} </p>
                </div>
              </Col>
            ))}
          </Row>
          {/* <Row className="text-center">
            <button className="see-all-btn">See all</button>
          </Row> */}
          <img
            src={OtherLocationElipseImg}
            alt="OtherLocationElipseImg"
            className="other-location-elipseimg"
          />
        </section>}
        <section className="additional-details">
          <h4>Additional Details</h4>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={FeatureImg} alt={FeatureImg} />
            </div>
            <div className="right-sec">
              {data?.additional_detail?.features.map((item) => (
                <button key={item.id}>{item.name}</button>
              ))}
            </div>
          </div>

          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={MenuImg} alt={MenuImg} />
            </div>
            <div className="right-sec">
              {data?.additional_detail?.menu_highlights.map((item) => (
                <button key={item.id}>{item.name}</button>
              ))}
            </div>
          </div>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={Ambience} alt={Ambience} />
            </div>
            <div className="right-sec">
              {data?.additional_detail?.ambiences.map((item) => (
                <button key={item.id}>{item.name}</button>
              ))}
            </div>
          </div>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={CafeDietry} alt={CafeDietry} />
            </div>
            <div className="right-sec">
              {data?.additional_detail?.specialist_dietary_services.map(
                (item) => (
                  <button key={item.id}>{item.name}</button>
                )
              )}
            </div>
          </div>
        </section>


        <section className="additional-details">
          <h4>Interests</h4>
          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={CafeInterestFood} alt={CafeInterestFood} />
            </div>
            <div className="right-sec">
              {data?.ecosystem_criteria?.fs_service_categories?.map((item) => (
                <button key={item.id}>{item.name}</button>
              ))}
            </div>
          </div>

          <div className="d-flex additional-data">
            <div className="left-sec">
              <img src={CafeInterestTrade} alt={CafeInterestTrade} />
            </div>
            <div className="right-sec">
              {data?.ecosystem_criteria?.ts_service_categories?.map((item) => (
                <button key={item.id}>{item.name}</button>
              ))}
            </div>
          </div>

        </section>

        <section className="restaurant-details">
          <h4>Restaurant Details</h4>
          <h5>Timing</h5>
          <div className="table-div">
            <table class="table">
              <thead>
                <tr>
                  <th scope="col">Monday</th>
                  <th scope="col">Tuesday</th>
                  <th scope="col">Wednesday</th>
                  <th scope="col">Thursday</th>
                  <th scope="col">Friday</th>
                  <th scope="col">Saturday</th>
                  <th scope="col">Sunday</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {renderTime(
                      data?.additional_detail?.monday_availability,
                      data?.additional_detail?.monday_opening_time,
                      data?.additional_detail?.monday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.tuesday_availability,
                      data?.additional_detail?.tuesday_opening_time,
                      data?.additional_detail?.tuesday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.wednesday_availability,
                      data?.additional_detail?.wednesday_opening_time,
                      data?.additional_detail?.wednesday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.thursday_availability,
                      data?.additional_detail?.thursday_opening_time,
                      data?.additional_detail?.thursday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.friday_availability,
                      data?.additional_detail?.friday_opening_time,
                      data?.additional_detail?.friday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.saturday_availability,
                      data?.additional_detail?.saturday_opening_time,
                      data?.additional_detail?.saturday_closing_time
                    )}
                  </td>
                  <td>
                    {renderTime(
                      data?.additional_detail?.sunday_availability,
                      data?.additional_detail?.sunday_opening_time,
                      data?.additional_detail?.sunday_closing_time
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <hr></hr>
          <Row className="menu-download">
            {data?.additional_detail?.menu && (
              <Col sm={12} md={6} lg={6}>
                <h5>Menu</h5>
                <button className="pdf-name">Menu</button>
                <a href={data?.additional_detail?.menu} target="_blank">
                  <button className="download-btn"> Download</button>
                </a>
              </Col>
            )}
            <Col sm={12} md={6} lg={6}>
              <h5>Delivery details</h5>
              <p>
                {data?.additional_detail?.delivery_available ? (
                  <>
                    Delivery <span>Available</span> Range
                    <span>
                      0km to {data?.additional_detail?.delivery_range}km
                    </span>
                  </>
                ) : (
                  <>
                    Delivery <span>Not Available</span>
                  </>
                )}
              </p>
            </Col>
          </Row>
        </section>
        <section className="evets-reviews">
          <h4>Events & Reviews</h4>
          <Row>
            <Col md={6} lg={6} sm={12}>
              <h5>Upcoming events</h5>
              <hr />
              {data?.additional_detail?.events?.map((event, index) => (
                <div
                  key={event.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <a
                      href={event.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {event.title}
                    </a>
                  </p>
                </div>
              ))}
            </Col>

            <Col md={6} lg={6} sm={12}>
              <h5>Online reviews</h5>
              <hr />
              {data?.additional_detail?.reviews?.map((review, index) => (
                <div
                  key={review.id}
                  className={index % 2 === 0 ? "div1" : "div2"}
                >
                  <p>
                    <img
                      src={
                        review.platform_name === "Google Reviews"
                          ? GoogleImg
                          : review.platform_name === "Facebook Reviews"
                            ? FacebookImg
                            : Trustpilot
                      }
                      alt={review.platform_name}
                    />
                    <a
                      href={review.review_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {review.platform_name}
                    </a>
                  </p>
                </div>
              ))}
            </Col>
          </Row>
        </section>
        <FlagTicket
          show={showFlagTicket}
          handleCloseAddNewModal={handleclose}
        />
        <section className="techstack">
          <div className="techstack-container">
            <h4>Tech stack</h4>
            {data?.additional_detail?.techstacks?.map((techstack, index) => (<img src={techstack.image || DefaultLogo} alt="TechStack" width={20} className="techstack-image" />))}
          </div>
          <div className="coming-soon-overlay">
            <span>Tech stack Coming Soon...</span>
          </div>

        </section>
        <section className="googlemap-location">
          <h4>Location</h4>
          <div ref={mapRef} style={mapContainerStyle}></div>
        </section>
      </div>
    </>
  );
}

export default PublicDirectory;
