import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import "../Styles/Cafelogin.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import logo from "../Assets/Images/logo.svg";
import VectorImg from "../Assets/Images/login-cafe-bg-vector.svg";
import OtpInput from "react-otp-input";
import CheckCircle from "../Assets/Images/check-circle.svg";
import BackIcon from "../Assets/Images/Back-icon.svg";
import {
  useChangePasswordVerifyOTPMutation,
  useResendRegisterOTPMutation,
} from "../Services/CcownerAPI";


const maskEmail = (email) => {
  const [localPart, domain] = email.split("@");
  const maskedLocalPart =
    localPart.slice(0, 2) + "*".repeat(localPart.length - 2);
  return `${maskedLocalPart}@${domain}`;
};

function Login() {

  const location = useLocation();
  const { responseEmail } =
    location.state || {};
  const maskedEmail = responseEmail ? maskEmail(responseEmail) : "";

 const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [submitForm, { isLoading, isError, isSuccess, submiterror }] =
  useChangePasswordVerifyOTPMutation();
  const [submitResendOtp, {}] = useResendRegisterOTPMutation();
  const [formValid, setFormValid] = useState(false);

  const handleChange = (otp) => {
    setCode(otp);
    setError("");
  };

  useEffect(() => {
    if (code.length == 4) {
      setFormValid(true);
    } else {
      setFormValid(false);
    }
  }, [code]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (code.length < 4) {
      setError("Please enter a 4-digit OTP.");
      return;
    }

    let Postata = {
      otp: code,
      email: responseEmail,
    };

    try {
     
      const response = await submitForm(Postata ).unwrap();
      const email = response.email;
      const otp = response.otp
      const timer = setTimeout(() => {
        navigate("/forgot-otp-verification", {
          state: { email, otp},
        });
      }, 1000);
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  };

  const handleResenOTP = async (e) => {
    e.preventDefault();

    // try {
    //   const response = await submitResendOtp({ id }).unwrap(); 
    //   if (response) {
    //     alert("The New OTP is send to yor mail id");
    //   }
    // } catch (err) {
    //   console.error("Failed to submit form:", err);
    // }
  };


  return (
    <div className="login forgot-psw-otp">
      <Row>
        <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg">
            <div className="logo-div">
              <Link to="/">
                <img src={logo} alt="logo" className="img-fluid" />
              </Link>
            </div>
            <div className="login-bg-text">
              <h2>
                Cafe <br />
                Entrepreneurship
              </h2>
              <p>
                Discover the Breakthrough You've Been Looking For with
                Australia's Premier Cafe Support Platform
              </p>
            </div>
            <img src={VectorImg} alt="VectorImg" className="vector-img-login" />
          </div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
        <div className="login-form">
              <h5>Verify your email using OTP</h5>
              <p>
                We have sent an OTP to <span>{maskedEmail}</span>
              </p>
              <p>
                Didn't receive the email? Check your spam or promotions folder
                or{" "}
                <span onClick={handleResenOTP} className="resend-otp">
                  [Resend]
                </span>
              </p>
              <form onSubmit={handleSubmit}>
                <OtpInput
                  value={code}
                  onChange={handleChange}
                  numInputs={4}
                  separator={<span style={{ width: "8px" }}></span>}
                  isInputNum={true}
                  shouldAutoFocus={true}
                  renderInput={(props) => <input {...props} />}
                  className="otp-input"
                  inputStyle={{
                    border: "1px solid transparent",
                    borderRadius: "8px",
                    width: "54px",
                    height: "54px",
                    fontSize: "14px",
                    color: "#000",
                    fontWeight: "400",
                    caretColor: "blue",
                    marginRight: "10px",
                    backgroundColor: "#F1F1F1",
                  }}
                  focusStyle={{
                    border: "1px solid #CFD3DB",
                    outline: "none",
                  }}
                />
                {error && <p className="error">{error}</p>}
                <button
                  className={`login-submit-btn mt-40 ${
                    formValid ? "btn-primary-valid" : ""
                  }`}
                  type="submit"
                >
                  Verify
                </button>
                {isError && (
                  <p style={{ color: "red" }} className="error-message">
                    Error: {submiterror?.data?.error || "Failed to submit form"}
                  </p>
                )}
                {isSuccess && (
                  <p style={{ color: "green" }} className="isSuceesMsg">
                    Form submitted successfully!
                  </p>
                )}
                {isLoading && <div class="loader"></div>}
              </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
