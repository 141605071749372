import React, { useState, useEffect } from "react";
import "../Styles/ServiceCafe.css";
import { Row, Col } from "react-bootstrap";
import KeyPointImg from "../Assets/Images/key-points.svg";
import ElipseBlue from "../Assets/Images/service-item-blue.svg";
import ElipsePink from "../Assets/Images/service-item-pink.svg";
import ElipseYellow from "../Assets/Images/service-item-ylw.svg";
import CafeMarketing from "../Assets/Images/cafe-marketing.png";
import CafeStartup from "../Assets/Images/service-images/Cafe_Startups.png"
import CafeProfitability from "../Assets/Images/service-images/Profitability.png";
import CafeMentroship from "../Assets/Images/service-images/Business mentorship.png"
import CafeExpansion from "../Assets/Images/service-images/Expansion.png";
import CafeFoodservicePartner from "../Assets/Images/cafe-foodservice-partner.png";
import Caferesource from "../Assets/Images/cafe-resource.png";
import CafeMenuDevelopment from "../Assets/Images/service-images/Creativity & Menu.png"
import CafeDirectory from "../Assets/Images/service-images/Cafe Directory.png"
import FSMatches from "../Assets/Images/service-images/Foodservice Matches - Filter.png"
import TSMatches from "../Assets/Images/service-images/cafe-ts-match.png"
import CafeBusinessPlanning from "../Assets/Images/service-images/R&CA Logo White.png"
import Whatwedo from "../Assets/Images/ce-service-top-img.png";
import CafeResourceImg from "../Assets/Images/service-images/Cafe Resources.png"
import { Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import LandingPagePopup from './LandingPagePopup'


function Services() {
  const [fileName, setFileName] = useState("No file chosen");

  const [showLandingModal, setShowLandingModal] = useState(false);
  const handleCloseLandingModal = () => {
    setShowLandingModal(false);
  }

  useEffect(()=>{
    setTimeout(() => {
      setShowLandingModal(true);
    }, 4000);
  }, [])

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
    } else {
      setFileName("No file chosen");
    }
  };

  const location = useLocation();


  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const yOffset = -100; // Offset to leave 50px space above the element
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  }, [location]);

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black
    zIndex: 999, // Ensures the overlay is on top
  };

  <Helmet>
    <title>Clever Cafe | Maximise Cafe Profitability with Specialised Industry Experts</title>
    <meta name="description" content="Unlock your cafe’s full potential with expert insights from industry leaders. From menu strategy to financial management, we help cafes streamline operations, control costs, and thrive in an evolving market." />
    <meta name="keywords" content="cafe consulting services in australia, best cafe consulting services in australia, opening a cafe business plan, smart cafe business plans australia" />
  </Helmet>

  return (
    <div className="service-cafe">
      <section className="service-banner">
        <div className="service-banner-content">
          <h2>
            It took us 30 years to learn what we know, but with entrepreneurial thinking and a platform designed to support your success, you’ll get there in no time.
          </h2>
          <p>
            With our guidance, you’ll master the numbers, craft a profitable menu, grow a loyal customer base, motivate your team, and streamline reliable supply chain & operational systems – all in one place.
          </p>

          <p>
            Watch this <b><a href="https://youtu.be/7Ig_Nw5gCCU" target="_blank">quick video </a></b> to see how we help your cafe thrive from the moment you sign up for your free trial. Find more details below.

          </p>
        </div>
      </section>

      <section>
        <div className="home-what-we-do">
          <div className="what-we-do">
            <Row>
              <Col sm={12} lg={8} md={8}>
                {/* <h3>When you sign up for a Clever</h3>{" "}
                <h3>Cafe membership, you'll get a </h3>
                <h3>one-month free trial with </h3>
                <h3>instant benefits.</h3> */}
                <div className="head-desk">
                  <h3>When you sign up for a </h3>
                  <h3> Clever Cafe membership,</h3>
                  <h3> you'll get a one-month </h3>
                  <h3 className="mb-40"> free trial with instant benefits. </h3>
                </div>
                <div className="head-mob">
                  <h3>When you sign up for a Clever Cafe membership,  you'll get a one-month free trial with instant benefits. </h3>
                </div>
                <Row>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>01</h4>
                      <h4>More Value</h4>
                      <p>
                        Enjoy free onboarding, a menu review, and a directory
                        listing - whether you're just starting out or already
                        established.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>02</h4>
                      <h4> More Time</h4>
                      <p>
                        Easily connect with the right food & trade service
                        providers to streamline your business.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>03</h4>
                      <h4>Less Risk</h4>
                      <p className="mb-50">
                        Learn how to lower your expenses, improve your menu,
                        optimise your operations, and build a dedicated,
                        motivated team.
                      </p>
                    </div>
                  </Col>
                  <Col sm={12} lg={6} md={6}>
                    <div className="cotent-element">
                      <h4>04</h4>
                      <h4>More Money</h4>
                      <p>
                        Discover how to build and run a sustainable cafe using
                        the same strategies as industry veterans.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={12} lg={4} md={4}>
                <img src={Whatwedo} alt="Whatwedo" className="what-we-do-img" />
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section1">
        <Helmet>
          <title>Clever Cafe | Cafe Marketing & Exposure - Effective Branding & Marketing</title>
          <meta name="keywords" content="cafe marketing strategy, cafe marketing ideas, cafe marketing agency, how to promote a cafe" />
          <meta name="description" content="Discover proven strategies for branding, marketing, and customer engagement to help your cafe stand out in a competitive market." />
        </Helmet>
        <div className="card">
          <h3>Cafe Marketing & Exposure</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Gain access to a valuable free resource with a high-quality
                directory listing. We begin promoting your future or existing
                cafe through targeted social channels as soon as you sign up.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "Adding our cafe to the free directory was really easy, and it’s
                great to know we’re getting extra exposure without spending any
                additional money, time, or effort!"
              </p>
              <p className="testimonial-owner">Emily M | Owner</p>
              <p className="testimonial-owner">Mr Cuppa | Parramatta, NSW</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  free onboarding support
                </li>
                <li>
                  <img src={KeyPointImg} /> free directory listing
                </li>
                <li>
                  <img src={KeyPointImg} /> appear on top search platforms
                </li>
                <li>
                  <img src={KeyPointImg} /> reach the right customers
                </li>
                <li>
                  <img src={KeyPointImg} />
                  promote your business & menu
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid" />
          <img src={ElipseYellow} className="elipse-yellow img-fluid" />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeDirectory}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Add your startup or established cafe for more views.</h4>
                <Link to="/common-signup">
                  <button className="btn-boost-visibility">
                    Boost my visibility
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section2">
        <Helmet>
          <title>Clever Cafe | How to Start a Cafe Business: Concept, Location, Menu & Profit</title>
          <meta name="keywords" content="cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe" />
          <meta name="description" content="Your complete guide to starting a successful cafe, from concept and location scouting to creating a profitable menu that resonates with your target customers." />
        </Helmet>
        <div className="card">
          <h3>Cafe Startups</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                If you're just starting your cafe research, curious about the
                costs of opening a cafe, or need a clear roadmap to launch and
                grow your business, you're in the right place.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "This was the best thing I could have done for my business in
                its early stages! The perfect place to plan a cafe startup."
              </p>
              <p className="testimonial-owner">Ross B | Cafe Startup</p>
              <p className="testimonial-owner">Chocolate Cafe, Penrith, NSW</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> business planning & risk reduction
                </li>
                <li>
                  <img src={KeyPointImg} /> menu development & location analysis
                </li>
                <li>
                  <img src={KeyPointImg} /> supplier relationship development
                </li>
                <li>
                  <img src={KeyPointImg} />
                  marketing exposure & operational systems
                </li>
                <li>
                  <img src={KeyPointImg} />
                  group & 1:1 mentorship
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeStartup}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Grow your future cafe, create your support network & access
                  our community.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Join the Incubator!
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section3">
        <Helmet>
          <title>Clever Cafe | Profitability & Operational Experts: Over $1M Client Revenue Generated FY24
          </title>
          <meta name="keywords" content="how to start a cafe business, cafe marketing strategy, cafe marketing ideas, cafe marketing agency, how to promote a cafe" />
          <meta name="description" content="Work with operational experts who have helped Australian cafes generate over $1M in revenue through tailored profitability and growth strategies." />
        </Helmet>
        <div className="card">
          <h3>Cafe Profitability </h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                We've added millions of dollars in revenue to our clients'
                bottom lines. If your cafe has the potential for sustainable
                growth and increased profit, we're here to make it happen.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "With Clever Cafe, we achieved a 26% increase in kitchen
                efficiency and a 5% decrease in COGS."
              </p>
              <p className="testimonial-owner">Irene Z | Owner</p>
              <p className="testimonial-owner">
                Little Shop of Waffles, Manly, NSW
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} /> improve processes & systems
                </li>
                <li>
                  <img src={KeyPointImg} /> streamline supply chain management
                </li>
                <li>
                  <img src={KeyPointImg} />
                  tailor & optimise menu development
                </li>
                <li>
                  <img src={KeyPointImg} />
                  increase team efficiency & reduce labour
                </li>
                <li>
                  <img src={KeyPointImg} />
                  ensure quality control & consistency
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeProfitability}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Refine your operations & uncover powerful opportunities for
                  profit growth.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Get started now
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section4">
        <Helmet>
          <title> Clever Cafe | Simplify Scaling & Maximise Growth with Proven Multi-Site Experts
          </title>
          <meta name="keywords" content="cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe" />
          <meta name="description" content="Learn how Clever Cafe helps multi-site operators streamline processes, scale efficiently, and maximise profits with expert-driven solutions." />
        </Helmet>
        <div className="card">
          <h3>Cafe Expansion </h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                We provide top accredited business coaches and hospitality
                leaders with first-hand experience to help you expand
                sustainably into multiple sites. Our experts have a proven track
                record of working with large cafe groups.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "Tim’s impact on our business has been undeniably positive. He
                shows a genuine dedication to our success, displaying unwavering
                commitment to achieving our objectives. He excels in dealing
                with practical aspects and ensures that his guidance is grounded
                in reality rather than theory. His results-driven approach has
                allowed us to meticulously monitor the return on our
                investments, down to the last dollar."
              </p>
              <p className="testimonial-owner">Rouby S | Managing Director</p>
              <p className="testimonial-owner">
                LifeGrain Cafe Group, Sydney, NSW
              </p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  identify profitable locations
                </li>
                <li>
                  <img src={KeyPointImg} /> enhance infrastructure, technology &
                  logistics
                </li>
                <li>
                  <img src={KeyPointImg} />
                  boost operational efficiency & reduce risks
                </li>
                <li>
                  <img src={KeyPointImg} />
                  attract skilled talent for expansion
                </li>
                <li>
                  <img src={KeyPointImg} />
                  broaden menu options & customer offerings
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeExpansion}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Ready to build your cafe empire? Join us for expert guidance &
                  collaboration.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Start scaling
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section5">
        <Helmet>
          <title> Clever Cafe | Cafe Business Planning - Your Partner with Leading Menu Experts & Qualified Coaches
          </title>
          <meta name="keywords" content="cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe" />
          <meta name="description" content="Partner with Clever Cafe’s experienced coaches and menu developers to craft a business plan that supports both concept innovation and operational success." />
        </Helmet>
        <div className="card">
          <h3>Cafe Business Planning </h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Clever Cafe is your essential partner in building a sustainable,
                profitable cafe business. We help with financial projections,
                menu development, location guidance, and valuable insights to
                ensure your cafe's success, supporting you at every stage of
                your journey.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "I learned more about cafe business planning and the numbers
                involved in one day than I had in months of searching online.
                Great experience."
              </p>
              <p className="testimonial-owner">Fraser I | Cafe Startup</p>
              <p className="testimonial-owner">Rotorua, NZ</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  financial planning & startup support
                </li>
                <li>
                  <img src={KeyPointImg} /> location & menu strategy
                </li>
                <li>
                  <img src={KeyPointImg} />
                  staff hiring & retention guidance
                </li>
                <li>
                  <img src={KeyPointImg} />
                  operational efficiency tools
                </li>
                <li>
                  <img src={KeyPointImg} />
                  access to mentorship & community
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility mt-150">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeBusinessPlanning}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Ready to bring your cafe vision to life? Join Clever Cafe for
                  expert guidance & ongoing support.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Begin your journey
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section6">
        {/* <Helmet>
          <title> Clever Cafe | Learn from Top Chefs & Make Data-Driven Decisions for Menu Development
          </title>
          <meta name="keywords" content="cafe menu development, how to develop a restaurant menu, cafe menu ideas australia, cafe menu ideas in australia, small cafe menu ideas" />
          <meta name="description" content="Access expert insights from top chefs and learn how to make data-driven menu decisions that align with customer demand and increase profitability." />
        </Helmet> */}
        <div className="card">
          <h3>Cafe Mentorship</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                We’ve facilitated over 7,000 hours of coaching, offering
                accredited business coaches with hands-on hospitality expertise.
                Our accreditation ensures success through proven methods,
                real-world experience, and deep industry knowledge. Trusted by
                major cafe groups, we deliver better results - faster.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "Top-notch coaching, excellent value, and service. THANK YOU!"
              </p>
              <p className="testimonial-owner">Luca & Jenni</p>
              <p className="testimonial-owner">Cafe Startup, Toowoomba, QLD</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  monthly specialised mentoring programs
                </li>
                <li>
                  <img src={KeyPointImg} /> regular collaborative group huddles
                </li>
                <li>
                  <img src={KeyPointImg} />
                  optional 1:1 tailored roadmapping
                </li>
                <li>
                  <img src={KeyPointImg} />
                  10 business pillars to success
                </li>
                <li>
                  <img src={KeyPointImg} />
                  expert advice, easy to apply
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeMentroship}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Discover proven strategies & gain the knowledge and skills
                  needed to thrive.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Book a session
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section7">
        <Helmet>
          <title>Clever Cafe | Learn from Top Chefs & Make Data-Driven Decisions for Menu Development
          </title>
          <meta name="keywords" content="cafe menu development, how to develop a restaurant menu, cafe menu ideas australia, cafe menu ideas in australia, small cafe menu ideas" />
          <meta name="description" content="Access expert insights from top chefs and learn how to make data-driven menu decisions that align with customer demand and increase profitability." />
        </Helmet>
        <div className="card">
          <h3>Cafe Menu Development</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Having designed and crafted hundreds of our own profitable cafe
                menus, we ensure your offerings align with your location and
                keep people coming back for more.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                “Working with this team has significantly streamlined our menu
                and business model, leading to better growth and sustainability.
                Their insights and tailored strategies have made a noticeable
                impact on our efficiency and overall success.”
              </p>
              <p className="testimonial-owner">Aniko K | Owner</p>
              <p className="testimonial-owner">Wicked Nutri Cookie | NSW</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  free initial menu review
                </li>
                <li>
                  <img src={KeyPointImg} />
                  location & customer analysis
                </li>
                <li>
                  <img src={KeyPointImg} />
                  seasonality, simplification, & streamlining
                </li>
                <li>
                  <img src={KeyPointImg} />
                  operational feasibility
                </li>
                <li>
                  <img src={KeyPointImg} />
                  profitability & consistency
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeMenuDevelopment}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Learn from experts who have written thousands of menus, guided
                  by an executive chef.
                </h4>
                <Link to="/common-signup">
                  {" "}
                  <button className="btn-boost-visibility">
                    Refine my menu
                  </button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section8">

        <Helmet>
          <title>Clever Cafe | Find Top Foodservice Providers for Your Cafe</title>
          <meta name="keywords" content="top food service providers for your cafe in australia, australia food service top companies, top food service providers for your cafe" />
          <meta name="description" content="Easily discover trusted foodservice providers through Clever Cafe’s curated list, ensuring your cafe’s needs are met by reliable partners." />
        </Helmet>
        <div className="card">
          <h3>Cafe Foodservice Partners </h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Our network seamlessly connects both startup and established
                cafe owners with the right foodservice solutions tailored to
                their offerings and location. If you can't find what you need,
                we’ll source it for you and put you in touch.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "My daughter has been running cafes for years, and as her
                father, I'm grateful that this level of support is available to
                help her succeed."
              </p>
              <p className="testimonial-owner">Pete U | Proud Dad</p>
              <p className="testimonial-owner">Palm Beach, NSW</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  build your customised foodservice network
                </li>
                <li>
                  <img src={KeyPointImg} />
                  check trusted reviews & recommendations
                </li>
                <li>
                  <img src={KeyPointImg} />
                  tailored matchmaking for specific needs
                </li>
                <li>
                  <img src={KeyPointImg} />
                  explore profiles, videos, & insights
                </li>
                <li>
                  <img src={KeyPointImg} />
                  fast & convenient direct communication
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={FSMatches}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>Build your foodservice & supply chain network.</h4>
                <Link to="/common-signup"> <button className="btn-boost-visibility">
                  Connect anytime
                </button></Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section9">
        <Helmet>
          <title>Clever Cafe | Connect with Trusted Trades & Services Partners for Your Cafe
          </title>
          <meta name="keywords" content="cafe business planning in australia, starting a cafe business in australia, australian cafe entrepreneurship, australian cafe entrepreneurship ideas, how to run a successful cafe" />
          <meta name="description" content="Access a network of vetted trades and service providers who specialise in supporting cafes with everything from maintenance to renovations." />
        </Helmet>
        <div className="card">
          <h3>Cafe Trades & Services Partners </h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                We connect members with a network of cafe trades and service
                professionals across Australia, offering support in over 30
                areas. If a solution isn’t available, we’ll source it and
                connect you.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "Clever Cafe supports new and emerging cafes to get off the
                ground and thrive. I've seen the positive impact on business
                owners in achieving their growth goals."
              </p>
              <p className="testimonial-owner">Ella B | Founding Director</p>
              <p className="testimonial-owner">Employii, Perth, WA</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  build your own operational network
                </li>
                <li>
                  <img src={KeyPointImg} />
                  customise trades & services connections
                </li>
                <li>
                  <img src={KeyPointImg} />
                  view reviews & recommendations
                </li>
                <li>
                  <img src={KeyPointImg} />
                  explore profiles, videos, & insights
                </li>
                <li>
                  <img src={KeyPointImg} />
                  enjoy fast, direct communication
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={TSMatches}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Create your trades and service ecosystem for better
                  operational support.
                </h4>
                <Link to="/common-signup">   <button className="btn-boost-visibility">
                  Access 24/7 support
                </button></Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>

      <section className="service-items" id="section10">
        <Helmet>
          <title> Clever Cafe | Cafe Resources: Visuals, Templates, Workbooks & Interactive Checklists

          </title>
          <meta name="keywords" content="Daily Restaurant Checklist Template, cafe menu checklist, coffee shop checklist, cafe business planning in australia" />
          <meta name="description" content="Gain access to a comprehensive suite of cafe-specific resources, including templates, workbooks, and checklists that simplify daily operations." />
        </Helmet>
        <div className="card">
          <h3>Cafe Resources</h3>

          <Row>
            <Col lg={8} md={8} sm={12}>
              <p>
                Our team collaborates regularly to create cafe resources
                covering 10 key management areas. These materials feature
                templates, checklists, workbooks, and visual examples, along
                with supplementary learning to bridge knowledge gaps.
              </p>
              <h5 className="testimonial-head">Testimonial</h5>
              <p className="testimonial-para">
                "We are always looking to improve and innovate, and Clever Cafe
                has been an invaluable resource."
              </p>
              <p className="testimonial-owner">Rick H | Manager</p>
              <p className="testimonial-owner">Monkey Bean, Toorak, VIC</p>
            </Col>
            <Col lg={4} md={4} sm={12}>
              <h4>Key benefits</h4>
              <ul>
                <li>
                  <img src={KeyPointImg} />
                  create a tailored resource library
                </li>
                <li>
                  <img src={KeyPointImg} />
                  develop time & cost-saving systems
                </li>
                <li>
                  <img src={KeyPointImg} />
                  streamline operations for efficiency
                </li>
                <li>
                  <img src={KeyPointImg} />
                  practical tools for immediate use
                </li>
                <li>
                  <img src={KeyPointImg} />
                  strengthen cafe business knowledge & skills
                </li>
              </ul>
            </Col>
          </Row>
          <img src={ElipseBlue} className="elipse-blue img-fluid img-fluid" />
          <img src={ElipsePink} className="elipse-pink img-fluid img-fluid" />
          <img
            src={ElipseYellow}
            className="elipse-yellow img-fluid img-fluid"
          />
          <div className="boost-visibility">
            <Row>
              <Col sm={12} md={6} lg={6}>
                <img
                  src={CafeResourceImg}
                  className="cafe-marketing-curved-img img-fluid"
                />
              </Col>
              <Col sm={12} md={6} lg={6}>
                <h4>
                  Access valuable resources for additional insights and growth.
                </h4>
                <Link to="/common-signup">   <button className="btn-boost-visibility">
                  Explore resources
                </button></Link>
              </Col>
            </Row>
          </div>
        </div>
      </section>
      {showLandingModal && (
        <div style={overlayStyle}></div>
      )}
      <LandingPagePopup showLandingModal={showLandingModal} handleCloseLandingModal={handleCloseLandingModal} />
    </div>
  );
}

export default Services;
