import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import Home from "../Components/Home";
import Layout from "../Components/Layout";
import LayoutforRegistration from "../Components/LayoutforRegistration";
import AboutuS from "../Components/AboutUs";
import ContactUs from "../Components/Contact";
import Article from "../Components/Article";
import ArticleDetail from "../Components/ArticleDetail";
import ArticleEffectiveCafeBusiness from "../Components/ArticleEffectiveCafeBusiness";
import ArticlehowtofindClentsforTradesandServices from "../Components/ArticleHowtofindClientforTradesandServices";
import ArticleHowSupportFoodServices from "../Components/ArticleHowSupportFoodServices";
import ArticleCreateWinningCafeMenu from "../Components/ArticleCreatingWinnigCafeMenu";
import CcOwnerSignUp from "../Components/CcOwnerSignUp";
import CcOwnerSignUpPro from "../Components/CcOwnerSignupPro";
import CcOwnerVerifyOtp from "../Components/CcOwnerVerifyOtp";
import CcOwnerVerificationCompleted from "../Components/CcOwnerVerificationComplete";
import CcOwnerAccountActivation from "../Components/CcOwnerAccountActivation";
import CcOwnerPayment from "../Components/CcOwnerPayment";
import DashboardLayout from "../Components/DashboardLayout";
import DirectoryListing from "../Components/DashboardDirectoryListing";
import AddAccountDetails from "../Components/AddAccountDetails";
import DirectoryPayment from "../Components/DirectoryPayment";
import DirectoryPaymentList from "../Components/DirectoryPaymentList";
import UpgradetoPro from "../Components/UpgradetoPro"
import DowngradetoLite from "../Components/DowngradetoLite"
import PaymentCancelled from "../Components/PaymentCancelled"
import HuddleSession from "../Components/HuddleSession"
import PublicDirectory from "../Components/PublicDirectory";
import IndustryDirectory from "../Components/IndustryDirectory"
import TradesService from "../Components/TradesService"
import Foodservice from "../Components/Foodsevice"
import PaymentCompleted from "../Components/PaymenCompleted"
import DowngradeLIteSuccess from "../Components/DowngradeLiteSuccess"
import UpgradePaymentList from "../Components/UpgradePamnetList"
import Services from "../Components/services"
import CafeStartupService from "../Components/ServiceCafeStartup"
import FoodService from "../Components/Servicefood"
import TradeService from "../Components/ServiceTrade"
import PublicCafeDirectory from "../Components/PublicCafeDirectory"
import CommonSignUp from "../Components/CommonSignUp";
import EcosystemRecommandation from "../Components/EcosystemRecommandation";
import Preference from "../Components/Preference"
import Cafelogin from "../Components/Cafelogin"
import FreetialCommonSignup from "../Components/FreeTrialCommonSignup"
import CafeDeclineList from "../Components/DeclineList"
import Ecosystemnotification from "../Components/Ecosystemnotification.jsx";
import HuddleSessionPayment from "../Components/HuddleSessionPayment.jsx"
import PrivacyPolicy from "../Components/PrivacyPolicy.jsx"
// community
import NoticeBoard from "../Components/NoticeBoard"
import ChatList from "../Components/ChatList"
import ChatListView from "../Components/ChatListView"
import ChatListReply from "../Components/ChatListReply"
import ChatListViewMyTopic from "../Components/ChatListVieMyTopic"
import ChatListReplyMyTopic from "../Components/ChatListReplyMyTopic"

// Ecosystem
import EcoEnquiry from "../Components/EcoEnquiry"
import EcoSystemMatches from "../Components/EcoSystemMatches"

// BusinessMentorship
import SessionDetails from "../Components/SessionDetails"
import SessionDetailsBookNow from "../Components/SessionDetailsBookNow";
import AllMatrial from "../Components/AllMaterial"
import AllMaterialCafeStartup from "../Components/AllMaterialCafeStratup"
import MyMaterial from "../Components/MyMaterial"
import MyMaterialCafeStartup from "../Components/MyMaterialCafeStartup"
import MyMaterialEdit from "../Components/MyMaterialEdit"
import PdfEdit from "../Components/PdfEdit"

// subscription
import CcOwnerSubscription from "../Components/CcOwnerSubscription";

// Support
import Support from "../Components/Support"
import SupportViweTicket from "../Components/SupportViweTicket";

import FoodServicesSupport from "../Components/FoodServices/Components/Support"

// FoodServices
import FoodServiceHomeLayout from "../Components/FoodServices/Components/Layout"
import FoodServiceLayout from "../Components/FoodServices/Components/DashboardLayout"
import FoodServiceSignup from "../Components/FoodServices/Components/SignUp.jsx"
import FoodServiceSignupVerificationComplete from "../Components/FoodServices/Components/SignupVerificatonComplete"
import FoodServiceSignupPayment from "../Components/FoodServices/Components/SignUpPayement"
import FoodServiceSignupAccountActicvation from "../Components/FoodServices/Components/SignUpAccountActivation"
import FoodServiceDirectoryPageListing from "../Components/FoodServices/Components/DirectoryPageListing"
import FoodServiceAddAccountDetails from "../Components/FoodServices/Components/AddAccountDetails"
import FoodServiceDirectoryPayment from "../Components/FoodServices/Components/DirectoryPayment"
import FoddServiceDirectoryPaymentList from "../Components/FoodServices/Components/DirectoryPaymentList"
import FoodServiceSignUpVerifyOtp from "../Components/FoodServices/Components/fdwnerVerifyOtp"
import FoodServiceEcosystemMatches from "../Components/FoodServices/Components/EcosystemMatches"
import FoodServiceEcoEnquiries from "../Components/FoodServices/Components/EcoEnquiry"
import FoodserviceMediaGallery from "../Components/FoodServices/Components/MediaGallery"
// import FoodserviceMediaGallery from "../Components/FoodServices/Components/MediaGallery"
import FoodserviceEcosystemRecommandation from "../Components/FoodServices/Components/EcosystemRecommandation";
import FoodservicePreference from "../Components/FoodServices/Components/Preference"
import FoodLogin from "../Components/FoodServices/Components/FoodLogin"
import FoodSubscription from "../Components/FoodServices/Components/Subscription"
import FoodNotification from "../Components/FoodServices/Components/EcosystemNotification.jsx"
import FoodDeclineList from "../Components/FoodServices/Components/DeclineList.jsx"
import FoodTicketView from "../Components/FoodServices/Components/SupportViewTicket.jsx"

import FoodServiceSignupfree from "../Components/FoodServices/Components/FreeTrailSignUp.jsx"
import FoodServiceSignupfreeOTP from "../Components/FoodServices/Components/FreeTrailOtp.jsx"
import FoodServiceSignupfreeVerification from "../Components/FoodServices/Components/FreeTrialVerificationComplete.jsx"

// tradeservice
import TradeServiceHomeLayout from "../Components/TradesServices/Components/Layout"
import TradeServiceLayout from "../Components/TradesServices/Components/DashboardLayout"
import TradeServiceSignup from "../Components/TradesServices/Components/SignUp"
import TradeServiceSignupVerificationComplete from "../Components/TradesServices/Components/SignupVerificatonComplete"
import TradeServiceSignupPayment from "../Components/TradesServices/Components/SignUpPayement"
import TradeServiceSignupAccountActicvation from "../Components/TradesServices/Components/SignUpAccountActivation"
import TradeServiceDirectoryPageListing from "../Components/TradesServices/Components/DirectoryPageListing"
import TradeServiceAddAccountDetails from "../Components/TradesServices/Components/AddAccountDetails"
import TradeServiceDirectoryPayment from "../Components/TradesServices/Components/DirectoryPayment"
import TradeServiceDirectoryPaymentList from "../Components/TradesServices/Components/DirectoryPaymentList"
import TradeServiceSignUpVerifyOtp from "../Components/TradesServices/Components/fdwnerVerifyOtp"
import TradeServiceEcoEnquiry from "../Components/TradesServices/Components/EcoEnquiry"
import TradeServiceEcoSystemMatches from "../Components/TradesServices/Components/EcosystemMatches"
import TradeMediaGallery from "../Components/TradesServices/Components/MediaGallery"
import TradeSubscription from "../Components/TradesServices/Components/Subscription"
import TradePreference from "../Components/TradesServices/Components/Preference"
import TradeRecommendation from "../Components/TradesServices/Components/Recommendation"
import TradeSupport from "../Components/TradesServices/Components/Support"
import TradeSupportViewTicket from "../Components/TradesServices/Components/SupportViweTicket"
import TradeLogin from "../Components/TradesServices/Components/TradeLogin"
import CcownersignupFreeTrial from "../Components/CcownerSignupfreetrial"
import CcownerotpFreeTrial from "../Components/CcownerfreetrialOtp.jsx"
import CcownerFreeTrialOtpVerification from "../Components/CCownerfreetrialAccountVerificationComplete.jsx"
import TradeServiceFreeTrial from "../Components/TradesServices/Components/FreeTrialSignup.jsx"
import TradeNotification from "../Components/TradesServices/Components/EcosystemNotification.jsx"
import TradeDeclineList from "../Components/TradesServices/Components/DeclineList.jsx"
import TradeFreeTrialOtp from "../Components/TradesServices/Components/FreeTrialOTP.jsx"
import TradeFreeTrialVerification from "../Components/TradesServices/Components/FreetrialVerificationComplete.jsx"

import CafeMatchPreview from "../Components/PreviewCafeMatches.jsx"
import CafeMatchPreviewBeforelogin from "../Components/PreviewCafeMatchesBeforelogin.jsx"
import TradeMatchPreview from "../Components/PreviewTradeMatches.jsx"
import FoodMatchPreview from "../Components/PreviewFoodMatches.jsx"
import ForgotPasswordEmail from "../Components/ForgotPasswordEmail.jsx"
import ForgotPasswordOTP from "../Components/ForgotPasswordOtp.jsx"
import ForgotOTPVerification from "../Components/forgotpswVerificationComplete.jsx"
import ChangePassword from "../Components/ChangePasswoed.jsx"
import PasswordChangeSuccess from "../Components/PsasswordChangedSuccess.jsx"


import TradeForgotPasswordEmail from "../Components/TradesServices/Components/ForgotPasswordEmail.jsx"
import TradeForgotPasswordOTP from "../Components/TradesServices/Components/ForgotPasswordOtp.jsx"
import TradeForgotOTPVerification from "../Components/TradesServices/Components/forgotpswVerificationComplete.jsx"
import TradeChangePassword from "../Components/TradesServices/Components/ChangePasswoed.jsx"
import TradePasswordChangeSuccess from "../Components/TradesServices/Components/PsasswordChangedSuccess.jsx"


import FoodForgotPasswordEmail from "../Components/FoodServices/Components/ForgotPasswordEmail.jsx"
import FoodForgotPasswordOTP from "../Components/FoodServices/Components/ForgotPasswordOtp.jsx"
import FoodForgotOTPVerification from "../Components/FoodServices/Components/forgotpswVerificationComplete.jsx"
import FoodChangePassword from "../Components/FoodServices/Components/ChangePasswoed.jsx"
import FoodPasswordChangeSuccess from "../Components/FoodServices/Components/PsasswordChangedSuccess.jsx"
import CommonSignUpFood from "../Components/Common-signup-food.jsx"
import CommonSignUpTrade from "../Components/Common-signup-trade.jsx"
import FreeTrialSignupFood from "../Components/FreeTrialSignupFood"
import FreeTrialSignupTrade from "../Components/FreeTrialSignupTrade.jsx"

import UpgardePayment from "../Components/UpgradePayment.jsx"
function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return !!localStorage.getItem('accessToken');
  });

  useEffect(() => {

    if (localStorage.getItem('accessToken')) {
      setIsLoggedIn(true);
    }


  }, []);

  return (
    <Routes>
      <Route path="/" element={<Layout><Home /></Layout>} />
      <Route path="/about-us" element={<Layout><AboutuS /></Layout>} />
      <Route path="/contact-us" element={<Layout><ContactUs /></Layout>} />
      <Route path="/articles" element={<Layout><Article /></Layout>} />
      <Route path="/privacy-policy" element={<Layout><PrivacyPolicy /></Layout>} />

      <Route path="/article-detail/:id" element={<Layout><ArticleDetail /></Layout>} />
      <Route path="/article-effective-cafe-business/" element={<Layout><ArticleEffectiveCafeBusiness /></Layout>} />
      <Route path="/article-find-clentsfor-trades-and-services" element={<Layout><ArticlehowtofindClentsforTradesandServices /></Layout>} />
      <Route path="/article-how-support-food-services" element={<Layout><ArticleHowSupportFoodServices /></Layout>} />
      <Route path="/article-create-winning-cafe-menu" element={<Layout><ArticleCreateWinningCafeMenu /></Layout>} />
      <Route path="/preview-cafe" element={<PublicDirectory />} />
      <Route path="/industry-directory" element={<IndustryDirectory />} />
      <Route path="/trades-service" element={<TradesService />} />
      <Route path="/food-service" element={<Foodservice />} />
      <Route path="/downdrade-lite-success" element={<DowngradeLIteSuccess />} />
      <Route path="/services" element={<Layout><Services /></Layout>} />
      <Route path="/service-cafe-startup" element={<Layout><CafeStartupService /></Layout>} />
      <Route path="/service-food" element={<Layout><FoodService /></Layout>} />
      <Route path="/service-trade" element={<Layout><TradeService /></Layout>} />
      <Route path="/public-cafe-directory" element={<Layout><PublicCafeDirectory /></Layout>} />
      <Route path="/common-signup" element={<Layout><CommonSignUp /></Layout>} />
      <Route path="/free-trial-common-signup" element={<Layout><FreetialCommonSignup /></Layout>} />


      <Route path="/common-signup-food" element={<Layout><CommonSignUpFood /></Layout>} />
      <Route path="/common-signup-trade" element={<Layout><CommonSignUpTrade /></Layout>} />
      <Route path="/free-trial-signup-food" element={<Layout><FreeTrialSignupFood /></Layout>} />
      <Route path="/free-trial-signup-trade" element={<Layout><FreeTrialSignupTrade /></Layout>} />
      {/* CADashboard */}
      <Route
        path="/ccowner-signup"
        element={
         
            <LayoutforRegistration>
              <CcOwnerSignUp />
            </LayoutforRegistration>
          
        }
      />
      {/* <Route path="/ccowner-signup" element={<LayoutforRegistration><CcOwnerSignUp /></LayoutforRegistration>} /> */}
      <Route path="/ccowner-signup-pro" element={<LayoutforRegistration><CcOwnerSignUpPro /></LayoutforRegistration>} />
      <Route path="/ccowner-verify-otp" element={<LayoutforRegistration><CcOwnerVerifyOtp /></LayoutforRegistration>} />
      <Route path="/ccowner-verification-complete" element={<LayoutforRegistration><CcOwnerVerificationCompleted /></LayoutforRegistration>} />
      <Route path="/ccowner-account-activation" element={<LayoutforRegistration><CcOwnerAccountActivation /></LayoutforRegistration>} />
      <Route path="/ccowner-payment" element={<LayoutforRegistration><CcOwnerPayment /></LayoutforRegistration>} />
      <Route path="/directory-listing" element={isLoggedIn ? <DashboardLayout><DirectoryListing /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/dashboard" element={<DashboardLayout><AddAccountDetails /></DashboardLayout>} />
      <Route path="/directory-payment" element={isLoggedIn ? <DashboardLayout><DirectoryPayment /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/directory-payment-list" element={isLoggedIn ? <DashboardLayout><DirectoryPaymentList /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/upgrade-to-pro" element={isLoggedIn ? <DashboardLayout><UpgradetoPro /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/downgrade-to-lite" element={isLoggedIn ? <DashboardLayout><DowngradetoLite /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/upgrade-payment-list" element={isLoggedIn ? <DashboardLayout><UpgradePaymentList /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/payment-cancelled" element={<PaymentCancelled />} />
      <Route path="/payment-completed" element={<PaymentCompleted />} />
      <Route path="/ecosystem-recommendation" element={isLoggedIn ? <DashboardLayout><EcosystemRecommandation /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/preference" element={isLoggedIn ? <DashboardLayout><Preference /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/ecosystem-notification" element={isLoggedIn ? <DashboardLayout><Ecosystemnotification /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/cafe-login" element={<Cafelogin />} />
      <Route path="/ccowner-signup-free-trial" element={<LayoutforRegistration><CcownersignupFreeTrial /></LayoutforRegistration>} />
      <Route path="/ccowner-OTP-free-trial" element={<LayoutforRegistration><CcownerotpFreeTrial /></LayoutforRegistration>} />

      <Route path="/ccowner-free-trail-verification-complete" element={<LayoutforRegistration><CcownerFreeTrialOtpVerification /></LayoutforRegistration>} />

      {/* Community */}
      <Route path="/notice-board" element={isLoggedIn ? <DashboardLayout><NoticeBoard /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/chat-list" element={isLoggedIn ? <DashboardLayout><ChatList /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/chat-list-view/:id" element={isLoggedIn ? <DashboardLayout><ChatListView /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/chat-list-view-my-topic/:id" element={isLoggedIn ? <DashboardLayout><ChatListViewMyTopic /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/chat-list-reply/:id" element={isLoggedIn ? <DashboardLayout><ChatListReply /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/chat-list-reply-my-topic/:id" element={isLoggedIn ? <DashboardLayout><ChatListReplyMyTopic /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />

      {/* Ecosystem */}
      <Route path="/eco-enquiry" element={isLoggedIn ? <DashboardLayout><EcoEnquiry /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/ecosystem-matches" element={isLoggedIn ? <DashboardLayout><EcoSystemMatches /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />

      {/* BusinessMentorship */}
      <Route path="/session-details-booknow" element={isLoggedIn ? <DashboardLayout><SessionDetailsBookNow /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/session-details" element={isLoggedIn ? <DashboardLayout><SessionDetails /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/huddlesession" element={isLoggedIn ? <DashboardLayout><HuddleSession /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/all-materials" element={isLoggedIn ? <DashboardLayout><AllMatrial /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/all-materials-cafe-startup/:id" element={isLoggedIn ? <DashboardLayout><AllMaterialCafeStartup /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/my-materials" element={isLoggedIn ? <DashboardLayout><MyMaterial /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/my-materials-cafe-startup/:id" element={isLoggedIn ? <DashboardLayout><MyMaterialCafeStartup /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/my-materials-edit" element={isLoggedIn ? <DashboardLayout><MyMaterialEdit /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/pdf-edit" element={isLoggedIn ? <DashboardLayout><PdfEdit /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/cafe-decline-list" element={isLoggedIn ? <DashboardLayout><CafeDeclineList /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/cafe-huddlesession-payment" element={isLoggedIn ? <DashboardLayout><HuddleSessionPayment /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/upgrade-payment" element={isLoggedIn ? <DashboardLayout><UpgardePayment /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />

      {/* subscription */}
      <Route path="/ccowner-subscription" element={isLoggedIn ? <DashboardLayout><CcOwnerSubscription /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      {/* support */}
      <Route path="/support-listing" element={isLoggedIn ? <DashboardLayout><Support /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/support-view-ticket/:id" element={isLoggedIn ? <DashboardLayout><SupportViweTicket /></DashboardLayout> : <Navigate to="/cafe-login" replace />} />
      <Route path="/food-service-support-listing" element={isLoggedIn ? <FoodServiceLayout><FoodServicesSupport /></FoodServiceLayout> : <Navigate to="/cafe-login" replace />} />
      {/* foodservices */}
      <Route path="/food-service-signup" element={<FoodServiceHomeLayout><FoodServiceSignup /></FoodServiceHomeLayout>} />


      <Route path="/food-service-signupotp" element={<FoodServiceHomeLayout><FoodServiceSignUpVerifyOtp /></FoodServiceHomeLayout>} />
      <Route path="/food-service-verificationcomplete" element={<FoodServiceHomeLayout><FoodServiceSignupVerificationComplete /></FoodServiceHomeLayout>} />
      <Route path="/food-service-signuppayment" element={<FoodServiceHomeLayout><FoodServiceSignupPayment /></FoodServiceHomeLayout>} />
      <Route path="/food-service-signupaccountactivation" element={<FoodServiceHomeLayout><FoodServiceSignupAccountActicvation /></FoodServiceHomeLayout>} />
      <Route path="/food-service-directorypagelisting" element={isLoggedIn ? <FoodServiceLayout><FoodServiceDirectoryPageListing /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-Accountdetails" element={<FoodServiceLayout><FoodServiceAddAccountDetails /></FoodServiceLayout>} />
      <Route path="/food-service-directory-payment" element={isLoggedIn ? <FoodServiceLayout><FoodServiceDirectoryPayment /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-directory-payment-list" element={isLoggedIn ? <FoodServiceLayout><FoddServiceDirectoryPaymentList /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-ecosystem-matches" element={isLoggedIn ? <FoodServiceLayout><FoodServiceEcosystemMatches /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-decline-list" element={isLoggedIn ? <FoodServiceLayout><FoodDeclineList /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />


      <Route path="/food-service-ecosystem-enquiry" element={isLoggedIn ? <FoodServiceLayout><FoodServiceEcoEnquiries /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-media-gallery" element={isLoggedIn ? <FoodServiceLayout><FoodserviceMediaGallery /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-ecosystem-recommendation" element={isLoggedIn ? <FoodServiceLayout><FoodserviceEcosystemRecommandation /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-preference" element={isLoggedIn ? <FoodServiceLayout><FoodservicePreference /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-subscription" element={isLoggedIn ? <FoodServiceLayout><FoodSubscription /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-service-notification" element={isLoggedIn ? <FoodServiceLayout><FoodNotification /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      <Route path="/food-login" element={<FoodLogin />} />

      <Route path="/food-service-signup-free" element={<FoodServiceHomeLayout><FoodServiceSignupfree /></FoodServiceHomeLayout>} />
      <Route path="/food-service-otp-free" element={<FoodServiceHomeLayout><FoodServiceSignupfreeOTP /></FoodServiceHomeLayout>} />
      <Route path="/food-service-verification-free" element={<FoodServiceHomeLayout><FoodServiceSignupfreeVerification /></FoodServiceHomeLayout>} />

      <Route path="/food-support-viewticket/:id" element={isLoggedIn ? <FoodServiceLayout><FoodTicketView /></FoodServiceLayout> : <Navigate to="/food-login" replace />} />
      {/* tradesservice */}
      <Route path="/trade-service-signup" element={<TradeServiceHomeLayout><TradeServiceSignup /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-signupotp" element={<TradeServiceHomeLayout><TradeServiceSignUpVerifyOtp /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-verificationcomplete" element={<TradeServiceHomeLayout><TradeServiceSignupVerificationComplete /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-signuppayment" element={<TradeServiceHomeLayout><TradeServiceSignupPayment /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-signupaccountactivation" element={<TradeServiceHomeLayout><TradeServiceSignupAccountActicvation /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-directorypagelisting" element={isLoggedIn ? <TradeServiceLayout><TradeServiceDirectoryPageListing /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-service-Accountdetails" element={<TradeServiceLayout><TradeServiceAddAccountDetails /></TradeServiceLayout>} />
      <Route path="/trade-service-directory-payment" element={isLoggedIn ? <TradeServiceLayout><TradeServiceDirectoryPayment /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-service-directory-payment-list" element={isLoggedIn ? <TradeServiceLayout><TradeServiceDirectoryPaymentList /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-eco-enquiry" element={isLoggedIn ? <TradeServiceLayout><TradeServiceEcoEnquiry /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-eco-matches" element={isLoggedIn ? <TradeServiceLayout><TradeServiceEcoSystemMatches /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-decline-list" element={isLoggedIn ? <TradeServiceLayout><TradeDeclineList /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />

      <Route path="/trade-media-gallery" element={isLoggedIn ? <TradeServiceLayout><TradeMediaGallery /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-subscription" element={isLoggedIn ? <TradeServiceLayout><TradeSubscription /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-preference" element={isLoggedIn ? <TradeServiceLayout><TradePreference /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-recommendation" element={isLoggedIn ? <TradeServiceLayout><TradeRecommendation /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-support" element={isLoggedIn ? <TradeServiceLayout><TradeSupport /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-notification" element={isLoggedIn ? <TradeServiceLayout><TradeNotification /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-support-viewticket/:id" element={isLoggedIn ? <TradeServiceLayout><TradeSupportViewTicket /></TradeServiceLayout> : <Navigate to="/trade-login" replace />} />
      <Route path="/trade-login" element={<TradeLogin />} />

      <Route path="/trade-service-signup-free" element={<TradeServiceHomeLayout><TradeServiceFreeTrial /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-otp-free" element={<TradeServiceHomeLayout><TradeFreeTrialOtp /></TradeServiceHomeLayout>} />
      <Route path="/trade-service-verification-free" element={<TradeServiceHomeLayout><TradeFreeTrialVerification /></TradeServiceHomeLayout>} />


      <Route path="/cafe-preview-matches/:senderType/:id" element={<CafeMatchPreview />} />
      <Route path="/cafe-view/:id" element={<CafeMatchPreviewBeforelogin />} />
      <Route path="/trade-preview-matches/:id" element={<TradeMatchPreview />} />
      <Route path="/food-preview-matches/:id" element={<FoodMatchPreview />} />
      <Route path="/forgot-password-email" element={<ForgotPasswordEmail />} />
      <Route path="/forgot-password-otp" element={<ForgotPasswordOTP />} />
      <Route path="/forgot-otp-verification" element={<ForgotOTPVerification />} />
      <Route path="/change-password" element={<ChangePassword />} />
      <Route path="/change-password-success" element={<PasswordChangeSuccess />} />


      <Route path="/trade-forgot-password-email" element={<TradeForgotPasswordEmail />} />
      <Route path="/trade-forgot-password-otp" element={<TradeForgotPasswordOTP />} />
      <Route path="/trade-forgot-otp-verification" element={<TradeForgotOTPVerification />} />
      <Route path="/trade-change-password" element={<TradeChangePassword />} />
      <Route path="/trade-change-password-success" element={<TradePasswordChangeSuccess />} />

      <Route path="/food-forgot-password-email" element={<FoodForgotPasswordEmail />} />
      <Route path="/food-forgot-password-otp" element={<FoodForgotPasswordOTP />} />
      <Route path="/food-forgot-otp-verification" element={<FoodForgotOTPVerification />} />
      <Route path="/food-change-password" element={<FoodChangePassword />} />
      <Route path="/food-change-password-success" element={<FoodPasswordChangeSuccess />} />

  

    </Routes>
  );
}

export default function Routers() {
  return (
    <ScrollToTop />
  );
}
