import React, {useState, useEffect} from "react";
import "../Styles/Allmaterial.css";
import { Row, Col } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import CafestartImg from "../Assets/Images/all-matrial-cafe-startup.png";
import BusinessPlanningImg from "../Assets/Images/all-marials-business-planning.png";
import LeaseImg from "../Assets/Images/all-matrials-leasing.png";
import OperationImg from "../Assets/Images/all-material-operations.png";
import TeamImg from "../Assets/Images/all-material-team.png";
import LeadershipImg from "../Assets/Images/all-matrial-leadership.png";
import ProfitabilityImg from "../Assets/Images/all-material-profitabilitty.png";
import CreativityImg from "../Assets/Images/all-material-creativity.png";
import ExitStrategyImg from "../Assets/Images/all-material-exit-strategy.png";
import { useGetBusinessMaterialsQuery } from "../Services/SecondaryAPI";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import DefaultPdfImg from "../Assets/Images/defaultpdf-img.png"

function AllMaterial() {
  const { data: materials ,isLoading} = useGetBusinessMaterialsQuery();

  const [upgradeModal, setupgradeModal] = useState(false);

  const subscription_type = localStorage.getItem("subscription_type_current_selected");
  const subscription_status  = localStorage.getItem(
    "subscription_status_current_selected"
  );
  useEffect(() => {
    if((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))){
      setupgradeModal(false);
    }
   else {
      setupgradeModal(true);
    }
  }, [subscription_type,subscription_status]);

  const handleCloseUpgradModal =() =>{
    setupgradeModal(false);
  }
  const showupgradeModal = () => {
    setupgradeModal(true);
  };

  return (
    <div className={`all-matrials ${subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial")) 
      ? ""
      : "chat-disable-for-liteuser"}`}>
      {" "}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4>Business Development Materials</h4>
          <p className="sub-head">
          Our business materials are supplementary tools designed to support your 
          development in key areas of cafe management. These materials include visual examples,
           resources, templates, workbooks, and checklists.
          </p>
        </Col>
      </Row>
      <Row>
      {materials?.data?.length === 0 ? (
  <p>No data available</p>
) : (
  materials?.data?.map((materials) => (
    <Col sm={12} xs={12} md={4} lg={4} key={materials.id}>
      <Link to={`/all-materials-cafe-startup/${materials.id}`}>
        <div className="card">
          <div className="card-img">
            <img src={materials.image} alt="CafestartImg" />
          </div>
          <p>{materials.title}</p>
        </div>
      </Link>
    </Col>
  ))
)}
         {isLoading && <div class="loader"></div>}
         <UpgradeModal show={upgradeModal} handleCloseUpgradeModal={handleCloseUpgradModal} />
      </Row>
    </div>
  );
}

export default AllMaterial;
