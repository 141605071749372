import React from "react";
import Navbar from "../Components/DashboardNavbar";
import SideBar from "../Components/DashboardSidebar";
import Footer from "../Components/DashboardFooter";
import "../Styles/DashboardNavbar.css";
import "../Styles/DashboardSidebar.css";
import "../Styles/DashboardFooter.css";
import "../Styles/Dashboard.css";
import { useLocation } from "react-router-dom";



const Layout = ({ children }) => {
  const location = useLocation();
  const lastSegment = location.pathname.split("/").filter(Boolean).pop();
  const isDashboard = lastSegment === "dashboard" || lastSegment === "session-details-booknow" || lastSegment==="directory-listing" || lastSegment==="directory-payment" || lastSegment==="ccowner-subscription" || lastSegment==="preference" || lastSegment==="support-listing" ;
  return (
    // <div>
    //   <Navbar />
    //   <div className="container-fluid">
    //     <div className="row">
    //       <SideBar />
    //       <div className="dashboard-main-content">
    //           <div className="dashboard-content-layout">{children}</div>
    //         </div>
    //     </div>
    //   </div>
    //   <Footer />
    // </div>
    <div className="dashboard-container">
    <Navbar />
    <div className="dashboard-content">
      <SideBar />
      <div  className={`dashboard-main-content ${
            isDashboard ? "remove-location-margin" : ""
          }`}>
        <div className="dashboard-content-layout">{children}</div>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default Layout;
