import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import "../../../Styles/Cafelogin.css";
import { useResetPasswordMutation } from "../../../Services/CcownerAPI";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../Assets/Images/logo-white.svg";
import VectorImg from "../../../Assets/Images/login-cafe-bg-vector.svg";

function Login() {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const validateForm = () => {
    const formErrors = {};
    if (!formData.email) formErrors.email = "Email is required.";

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   if (validateForm) {
  //   }
  // };

  const [submitForm, { isLoading, isError, isSuccess, error }] =
  useResetPasswordMutation();

//   const handleSubmit = async (e) => {
//     const un = "techteam";
//     e.preventDefault();
//  navigate("/forgot-password-otp")
//   };

const handleSubmit = async (e) => {
  e.preventDefault();
  if (validateForm()) {
    let postData = {
      email: formData.email,
      user_type : "trade_service"
    };
    try {
      const response = await submitForm(postData).unwrap();
      setFormData({
        email: "",
        password: "",
      });

    const responseEmail = response.email;
 

      const timer = setTimeout(() => {
        navigate("/trade-forgot-password-otp", {
          state: { responseEmail},
        });
        // navigate("/forgot-password-otp"), {state:{responseEmail}};
      }, 1000);
    } catch (err) {
      console.error("Failed to submit form:", err);
    }
  }
};

  return (
    <div className="login  trade-login">
      <Row>
      <Col xs={12} md={5} sm={12} lg={5}>
          <div className="login-bg">
            <div className="logo-div">
            <Link to="/"><img src={logo} alt="logo" className="img-fluid" /></Link>  
            </div>
            <div className="login-bg-text">
              <h2>
              Trades & services
              </h2>
              <p>
              Discover the Breakthrough You've Been Looking For with Australia's Premier Cafe Support Platform
              </p>
            </div>
            <img src={VectorImg} alt="VectorImg" className="vector-img-login"/>
          </div>
        </Col>
        <Col xs={12} md={7} sm={12} lg={7} className="left-div">
          <p className="go-to-register">
            Don't have an account?{" "}
            <Link to="/trade-service-signup">
              <span>Register</span>
            </Link>
          </p>
          <div className="login-form">
            <h3>
            Forgot Password
            </h3>
            <p className="login-para">
            Enter your Email address and we’ll  send you an <span className="disply-blk"> OTP to reset your password</span>
            </p>
        
            <form onSubmit={handleSubmit}>
              <div className="form-control">
                <label>Email</label>
                <br />

                <input
                  type="text"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                />
                {errors.email && <p className="error">{errors.email}</p>}
              </div>
         
              
              <div className="form-control">
                <button type="submit" className="login-submit-btn">
                 Submit
                </button>
              </div>
              {isError && (
                <p style={{ color: "red" }}>
                  Error: {error?.data?.message || error?.data?.error || "Login Failed"}
                </p>
              )}
              {isSuccess && (
                <p style={{ color: "green" }} className="isSuceesMsg">
                 Form submitted successfully
                </p>
              )}
              {isLoading && <div class="loader-trade"></div>}
            </form>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
