import React from "react";
import "../Styles/ProductTypes.css";
import CloseImg from "../../../Assets/Images/close.svg";
import { useGetServiceTypeFoodQuery,useGetProductTypeFoodQuery} from "../../../Services/FoodserviceAPI";

function ProductTypes({ handlecloseProductTypeModal }) {
  const handleClose = () => {
    handlecloseProductTypeModal();
  };
  const { data: servicetypes } = useGetServiceTypeFoodQuery();
  const { data: producttypes } = useGetProductTypeFoodQuery();

  return (
    <div className="trades-product-types food-product-types ">
      <img
        src={CloseImg}
        alt="CloseImg"
        className="close-img"
        onClick={handleClose}
      />
         <div>
        <h4>Service Types Available
        </h4>
        <div className="d-flex">
        {servicetypes?.map((product) => (
    <button>{product.name.split(":")[0]}</button>
  ))}
        </div>
      </div>
      <div>
        <h4>Product Types Available 
        </h4>
        <div className="d-flex">
          {producttypes?.map((product) => (
            <button>{product.name}</button>
          ))}
        </div>
      </div>
   

    </div>
  );
}

export default ProductTypes;
