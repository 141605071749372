import { useEffect } from "react";
import "../Styles/ArticleDetail.css";
import ArticleDetailImg from "../Assets/Images/article-detail.png";
import CafeStartup from "../Assets/Images/cafe-starup.png";
import BusinessStrategy from "../Assets/Images/business-planning-strategy.png";
import Operations from "../Assets/Images/operations.png";
import Team from "../Assets/Images/team.png";
import Leadeship from "../Assets/Images/leadership.png";
import Profilibilty from "../Assets/Images/profitibility.png";
import Creativity from "../Assets/Images/creativity.png";
import ExitStrategy from "../Assets/Images/exit-strategy.png";
import WingPNG from "../Assets/Images/pngwing.png";
import { Row, Col } from "react-bootstrap";
import ArticleReadMore1 from "../Assets/Images/ariticle-read-more-1.png";
import ArticleReadMore2 from "../Assets/Images/article-read-more-2.png";
import ArticleReadMore3 from "../Assets/Images/article-read-more-3.png";
import RightArrow from "../Assets/Images/right-arrow.svg";
import { Link } from "react-router-dom";
import ReadMoreArticleBg from "../Assets/Images/readmore-article-bg.png";
import Rocket from "../Assets/Images/rocket_svgrepo.com.svg";
import Lock from "../Assets/Images/lock_svgrepo.com.svg";
import MessageBox from "../Assets/Images/message-text-1_svgrepo.com.svg";
import Linkdin from "../Assets/Images/linkedin-black.svg";
import Facebook from "../Assets/Images/facebook-black.svg";
import Instagram from "../Assets/Images/instagram-black.svg";
import Youtube from "../Assets/Images/youtube-black.svg";
import GoogleMyBusiness from "../Assets/Images/google-my-business.svg";
import EllispeYellow from "../Assets/Images/Ellipse-ylw.svg";
import EllipseRed from "../Assets/Images/Ellipse-red.svg";
import EllipseBlue from "../Assets/Images/elipse-blue.svg";

function ArticleDetail() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="article-detail-page">
      <div className="article-detail">
        <section className="article-detail-banner">
          <h3>
            How to Find New Clients for Your Trades &
            <span className="disply-blk">
              Services Business in the Cafe Industry
            </span>
          </h3>
          {/* <p className="banner-subtext">
            Clear imagery of how the trades and services portal looks and works,
            including matching, directory, and ecosystem.
          </p> */}
        </section>
        <section className="cafe-startup">
          <h4>Empowering Trades & Services to Thrive</h4>
          <p className="mt-27 paragraph">
            Are you a trades & services provider looking for fresh and
            innovative opportunities within the cafe industry? Clever Cafe
            offers a dynamic platform that fosters strategic partnerships,
            attracts new clients, and simplifies your business operations
            through automated and relevant introductions.
          </p>
          <p className="paragraph">
            Whether you are an electrician, plumber, commercial cleaner, IT
            support specialist, eco-friendly packaging supplier, or a specialist
            in cafe fitouts and refurbishments, Clever Cafe is your gateway to
            growth. Join our ecosystem and connect with cafe entrepreneurs,
            saving time, reducing stress, and cutting costs while creating a
            continuous flow of opportunities tailored to your business needs.
            From skilled carpenters to painters, marketers, event planners,
            brokers, and accountants, our platform is designed to support a
            diverse range of trades & services.
          </p>
        </section>

        <section className="cafe-startup">
          <h4>Why Choose Clever Cafe?</h4>
          <p className="mt-27 paragraph">
            Clever Cafe is dedicated to enhancing sustainability, revenue, and
            operational efficiency for cafe entrepreneurs. We provide highly
            relevant, targeted, accessible, and cost-effective support. Our
            platform allows trades & services providers to gain visibility and
            understand how they can contribute to the cafe industry at the right
            time, creating strong and impactful collaborations.
          </p>
        </section>
        <section className="cafe-startup">
          <h4>Comprehensive Support for Cafe Entrepreneurs</h4>
          <h5>Transforming Connections in the Cafe Industry</h5>
          <p className="paragraph">
            Our platform is revolutionising how startups and established cafe
            entrepreneurs connect with the trades & services sector. We make it
            easier than ever for cafes to operate by ensuring they have the
            necessary support readily available. For those looking to exit, we
            provide tailored assistance through cafe business brokers and exit
            experts, ensuring a smooth transition. By presenting your business
            beautifully and inviting over 30 areas to participate, we ensure
            continuous growth based on demand from our cafe members.
          </p>
          <h5>Tailored Trades & Services Solutions</h5>
          <p className="paragraph">
            Equipping cafes with essential trades & services enables them to
            operate more efficiently and effectively. This collaboration
            benefits trades & services businesses by creating strategic and
            productive partnerships. Our platform and training resources
            transform how cafes manage their operations, providing practical
            strategies for both new and established cafe owners.
          </p>
        </section>
        <section className="cafe-startup">
          <h4>Operational Optimisation & Financial Literacy</h4>
          <p className="paragraph">
            We specialise in operational optimisation, helping cafes manage
            costs and ensure profitability. Our mentorship has proven results,
            supported by testimonials from well-known cafe groups, including
            multi-site operations. Our interactive business materials, such as
            visual examples, workbooks, checklists, templates, and resources,
            support learning and growth through our cafe incubators and
            accelerators. These tools optimise financial literacy and business
            management skills, empowering our members to make informed decisions
            and maintain sustainable operations.
          </p>
        </section>
        <section className="cafe-startup">
          <h4>How Clever Cafe Works for Trades & Services Members</h4>
          <h5>Cost-Effective Growth</h5>
          <p className="paragraph">
            Clever Cafe makes it cost-effective for trades & services businesses
            of all sizes to expand and refine their networks. By connecting you
            with relevant cafe entrepreneurs, we help you present your offerings
            in a targeted ecosystem designed to enhance your efficiencies and
            support your growth.
          </p>
          <h5>Getting Started</h5>
          <p className="paragraph">
            The sign-up process is quick and easy, taking just a few minutes.
            Once your profile is complete, you become discoverable in our
            industry directory and ecosystem.
          </p>
          <h5>Professional Listings</h5>
          <p className="paragraph">
            Create detailed listings that showcase your brand and tell your
            story. Include images, videos, and other media to make your business
            stand out.
          </p>
          <h5>Setting Criteria</h5>
          <p className="paragraph">
            Customise your account settings based on your business model,
            service category, and products offered. This ensures you receive
            relevant opportunities tailored to your needs.
          </p>
          <h5>Automated Networking</h5>
          <p className="paragraph">
            Once your profile is set up, our platform starts providing
            automated, criteria-based introductions. Engage with cafe
            entrepreneurs to grow your network and unlock new opportunities.
          </p>
          <h5>Continuous Engagement</h5>
          <p className="paragraph">
            Our ecosystem operates 24/7, continuously providing relevant
            introductions and opportunities. Update your criteria as needed, and
            our system will adjust matches accordingly, ensuring you always have
            access to the best potential partners and customers.
          </p>
          <h5>Event Promotion</h5>
          <p className="paragraph">
            Share upcoming events, whether online or in-person, to build
            relationships and create a buzz. Add event details to your profile,
            and our platform will promote them to a targeted audience, boosting
            engagement and turnout.
          </p>
          <h5>Boost Reviews</h5>
          <p className="paragraph">
            Leverage our ecosystem's recommendation feature and direct links to
            review platforms to enhance your online presence and credibility.
            Encourage cafe members to share their experiences, attracting more
            startups and established cafe operators to engage with your business
            and products.
          </p>
          <h5>Educate and Inform</h5>
          <p className="paragraph">
            Use our Media Gallery to share videos, audio files, webinars, and
            case studies, educating cafe members about your business. This
            enriches their understanding of your offerings.
          </p>
          <h5>Simplified Account Applications</h5>
          <p className="paragraph">
            Within your account, you can add direct links for cafe members to
            apply for service accounts with you. This streamlines the onboarding
            process and ensures you connect with well-prepared, knowledgeable
            cafe owners.
          </p>
          <h5>Affordable Monthly Fees</h5>
          <p className="paragraph">
            Each branch you register costs $15 per month. There is no limit to
            the number of branches you can add. Each branch must be assigned to
            a specific state for accurate matching within our ecosystem. If you
            have branches in multiple states, you can designate one as the
            "Headquarters" for clear identification and management within the
            platform.
          </p>
          <h5>Affordable Monthly Fees</h5>
          <p className="paragraph">
            Each branch you register costs $15 per month. There is no limit to
            the number of branches you can add. Each branch must be assigned to
            a specific state for accurate matching within our ecosystem. If you
            have branches in multiple states, you can designate one as the
            "Headquarters" for clear identification and management within the
            platform.
          </p>
        </section>
        <section className="cafe-startup">
          <h4>Leading Mentorship & Facilitation</h4>
          <p className="paragraph">
            Clever Cafe is not just a platform; it’s a catalyst for
            transformation in the cafe industry. Our leading mentorship and
            facilitation services are designed to revolutionise how cafe
            entrepreneurs do business. We save valuable time and create more
            opportunities for your business without the high costs usually
            associated with extensive support.
          </p>
          <p>
            Clever Cafe is your gateway to connecting with the cafe sector in
            Australia. Join our platform to enhance your business relationships,
            streamline your operations, and boost your visibility. With our
            comprehensive support, practical solutions, and cost-effective
            growth opportunities, Clever Cafe can transform your business.
          </p>
        </section>
      </div>
      <section className="our-news-letter">
        <div className="news-letter-cnt">
          <h3>Our Newsletter</h3>
          <p>
            Subscribe to our monthly newsletter, We'll serve up all the latest
            from{" "}
            <span className="disply-blk">Clever Cafe and the industry.</span>
          </p>
          <form>
            <input type="email" placeholder="Email" name="email" />
            <button type="submit">Submit</button>
          </form>
        </div>
        <img src={EllipseBlue} alt="elipse" className="ellipse-blue" />
        <img src={EllipseRed} alt="elipse" className="ellipse-red" />
        <img src={EllispeYellow} alt="elipse" className="ellipse-ylw" />
      </section>
      <section className="article-readmore">
        <h3>Read More Articles</h3>
        <img src={WingPNG} alt="pngwing" className="png-wing" />
        <Row className="read-more-article-card">
          <Col xs={12} sm={12} lg={4} md={4}>
            <div className="card-image">
              <img src={ArticleReadMore1} alt="card-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8}>
            <div className="card-body">
              <p className="publish-date">Alec Whitten • 17 Jan 2022</p>
              <Link to="/article-detail">
                {" "}
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h3>
                Effective Cafe Business Coaching & Mentorship by Hospitality
                Experts
              </h3>
              <p>
                At Clever Cafe, we specialise in providing comprehensive cafe
                business coaching and mentorship across 70 areas covering 10
                vital aspects of cafe management.
              </p>
              <div className="d-flex">
                {" "}
                <button type="button" className="researchbtn-yellow">
                  Research
                </button>{" "}
                <button type="button" className="researchbtn-blue">
                  Research
                </button>{" "}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="read-more-article-card">
          <Col xs={12} sm={12} lg={4} md={4}>
            <div className="card-image">
              <img src={ArticleReadMore2} alt="card-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8}>
            <div className="card-body">
              <p className="publish-date">Alec Whitten • 17 Jan 2022</p>
              <Link to="/article-detail">
                {" "}
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h3>
                Effective Cafe Business Coaching & Mentorship by Hospitality
                Experts
              </h3>
              <p>
                At Clever Cafe, we specialise in providing comprehensive cafe
                business coaching and mentorship across 70 areas covering 10
                vital aspects of cafe management.
              </p>
              <div className="d-flex">
                {" "}
                <button type="button" className="researchbtn-yellow">
                  Research
                </button>{" "}
                <button type="button" className="researchbtn-blue">
                  Research
                </button>{" "}
              </div>
            </div>
          </Col>
        </Row>
        <Row className="read-more-article-card">
          <Col xs={12} sm={12} lg={4} md={4}>
            <div className="card-image">
              <img src={ArticleReadMore3} alt="card-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} lg={8} md={8}>
            <div className="card-body">
              <p className="publish-date">Alec Whitten • 17 Jan 2022</p>
              <Link to="/article-detail">
                {" "}
                <img src={RightArrow} alt="arrow" className="right-arrow" />
              </Link>
              <h3>
                Effective Cafe Business Coaching & Mentorship by Hospitality
                Experts
              </h3>
              <p>
                At Clever Cafe, we specialise in providing comprehensive cafe
                business coaching and mentorship across 70 areas covering 10
                vital aspects of cafe management.
              </p>
              <div className="d-flex">
                {" "}
                <button type="button" className="researchbtn-yellow">
                  Research
                </button>{" "}
                <button type="button" className="researchbtn-blue">
                  Research
                </button>{" "}
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="ready-to-start">
        <h4>Ready to get started?</h4>
        <h3>Join Australia's Premier Cafe</h3>
        <h3>Ecosystem</h3>
        <button className="btn get-ready-outline-btn" type="button">
          Foodservice
        </button>
        <button className="btn get-ready-btn" type="button">
          Get a membership
        </button>
        <button className="btn get-ready-outline-btn" type="button">
          Trades & Services
        </button>
        <Row className="mt-80 border-top">
          <Col xs={12} sm={12} lg={4} md={4}>
            <p>
              <img src={Rocket} alt="help-img" className="help-img" />
              99% uptime in last 12 months
            </p>
          </Col>
          <Col xs={12} sm={12} lg={4} md={4}>
            <p>
              <img src={Lock} alt="help-img" className="help-img" />
              Safe and secure always backed up
            </p>
          </Col>
          <Col xs={12} sm={12} lg={4} md={4}>
            <p>
              <img src={MessageBox} alt="help-img" className="help-img" />
              Dedicated support here for you at any time
            </p>
          </Col>
        </Row>
      </section>
      <div className="share-icon-div">
        <ul>
          <li>
            <img src={Linkdin} alt="share-icon" />
          </li>
          <li>
            <img src={Facebook} alt="share-icon" />
          </li>
          <li>
            <img src={Instagram} alt="share-icon" />
          </li>
          <li>
            <img src={Youtube} alt="share-icon" />
          </li>
          <li>
            <img src={Linkdin} alt="share-icon" />
          </li>
          <li>
            <img src={GoogleMyBusiness} alt="share-icon" />
          </li>
        </ul>
      </div>
      <img
        src={ReadMoreArticleBg}
        alt="ReadMoreArticleBg"
        className="read-more-articlebg"
      />
    </div>
  );
}

export default ArticleDetail;
