import React, { useState, useCallback, useRef, useEffect } from "react";
import "../Styles/EcoSystemMatches.css";
import { Row, Col } from "react-bootstrap";
import FilterIcon from "../Assets/Images/filter-icon.svg";
import ActionIcon from "../Assets/Images/actions-img.svg";
import ComapnyLogo from "../Assets/Images/company-logo.png";
import InactiveBUtton from "../Assets/Images/Inactive Button.svg";
import ActiveButton from "../Assets/Images/cafe-match-active.svg";
import heartImg from "../Assets/Images/heart-cafe-match.svg";
import heartImgFill from "../Assets/Images/heart-cafe-fill.svg";
import { Link, useLocation } from "react-router-dom";
import UpgradeModal from "../Components/UpgradetoProMOdalforliteUse";
import LockIcon from "../Assets/Images/lock-page.svg";
import ArrowdownIcon from "../Assets/Images/Dropdown.svg";
import {
  useGetEcosystemFoodSericeQuery,
  useGetEcosystemTradeSericeQuery,
  useGetStatesQuery,
  useGetServiceTypeQuery,
  useGetProductTypeQuery,
  useGetfoodDeietryServiceQuery,
  useGetTradeServiceCategoryQuery,
  useEcosystematchFoodserviceLikeMutation,
  useFoodserviceLikeMutation,
  useTradeserviceLikeMutation,
  useCafeacceptfdMutation,
  useCaferejectfdMutation,
  useTdacceptcafeMutation,
  useTdrejectcafeMutation,
} from "../Services/CcownerAPI";

const SortDropdown = ({ handleClickOrderingFood }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const onSelectFoodService = (item) => {
   
    setActiveItem(item);
   
    setIsOpen(false);
    handleClickOrderingFood(item);
  };

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();
  const { data: tradeServicesType } = useGetTradeServiceCategoryQuery();
  return (
    <div className="custom-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Sort
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button
            className={`dropdown-item ${
              activeItem === "latest" ? "active" : ""
            }`}
            onClick={() => onSelectFoodService("latest")}
          >
            Latest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "oldest" ? "active" : ""
            }`}
            onClick={() => onSelectFoodService("oldest")}
          >
            Oldest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "recommendation" ? "active" : ""
            }`}
            onClick={() => onSelectFoodService("recommendation")}
          >
            Recommendation
          </button>
          <button
            className={`dropdown-item ${activeItem === "" ? "active" : ""}`}
            onClick={() => onSelectFoodService("bestmatches")}
          >
            Best matches
          </button>
        </div>
      )}
    </div>
  );
};

const SortDropdownTrade = ({ handleClickOrderingTrade }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const onSelectTradeService = (item) => {
  
    setActiveItem(item);
   
    setIsOpen(false);
    handleClickOrderingTrade(item);
  };

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();
  const { data: tradeServicesType } = useGetTradeServiceCategoryQuery();
  return (
    <div className="custom-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Sort
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <button
            className={`dropdown-item ${
              activeItem === "latest" ? "active" : ""
            }`}
            onClick={() => onSelectTradeService("latest")}
          >
            Latest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "oldest" ? "active" : ""
            }`}
            onClick={() => onSelectTradeService("oldest")}
          >
            Oldest
          </button>
          <button
            className={`dropdown-item ${
              activeItem === "recommendation" ? "active" : ""
            }`}
            onClick={() => onSelectTradeService("recommendation")}
          >
            Recommendation
          </button>
          <button
            className={`dropdown-item ${activeItem === "" ? "active" : ""}`}
            onClick={() => onSelectTradeService("bestmatches")}
          >
            Best matches
          </button>
        </div>
      )}
    </div>
  );
};

const FilterDropdownFoodservice = ({
  handleStateButtonClick,
  handleProductTypeButtonclick,
  handleFoodCategoryButtonclick,
  serviceTypes,
  handleFoodServiceApplyClick,
  handleDietraryTypeButtonclick,
  states,
  productTypes,
  dietryServices,
  foodserviceCategories,
  productType,
  dietaryType,
  stateData,
  handleResetFoodData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);
  const handleApplyClick = () => {
    handleFoodServiceApplyClick(); // Call the apply function
    setIsOpen(false); // Close the dropdown after applying
  };



  return (
    <div className="custom-dropdown filter-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Filter
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="apply-btns">
            <button className="reset-button" onClick={handleResetFoodData}>Reset</button>
            <button className="apply-btn" onClick={handleApplyClick}>
              Apply
            </button>
          </div>
          <div>
            <div className="d-flex align-space-between category-head">
              <p>Service Category</p>
              <img src={ArrowdownIcon} alt="ArrowdownIcon" />
            </div>
            <div className="menu-items">
              {serviceTypes &&
                serviceTypes.map((service) => (
                  <button
                    key={service.id}
                    onClick={() => handleFoodCategoryButtonclick(service.id)}
                    className={
                      foodserviceCategories?.includes(service.id)
                        ? "active"
                        : ""
                    }
                  >
                    {service.name.split(":")[0]}
                  </button>
                ))}
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>Product type</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {productTypes &&
                  productTypes.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleProductTypeButtonclick(service.id)}
                      className={
                        productType?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>Specialist dietary services</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {dietryServices &&
                  dietryServices.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleDietraryTypeButtonclick(service.id)}
                      className={
                        dietaryType?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
            <div>
              <div className="d-flex align-space-between category-head">
                <p>States</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {states &&
                  states.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleStateButtonClick(service.id)}
                      className={
                        stateData?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const FilterDropdownTradeservice = ({
  handleTradeStateButtonClick,
  states,
  handleTradeCategoryButtonclick,
  tradeServiceTypes,
  handleTradeServiceApplyClick,
  tradestateData,
  tradeserviceCategories,
  handleResetTradeData
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const [activeItem, setActiveItem] = useState(null);

  const handleApplyClick = () => {
    handleTradeServiceApplyClick(); // Call the apply function
    setIsOpen(false); // Close the dropdown after applying
  };

  
  return (
    <div className="custom-dropdown filter-dropdown" ref={useRef(null)}>
      <button className="dropdown-toggle" onClick={toggleDropdown}>
        <img src={FilterIcon} alt="FilterIcon" />
        Filter
      </button>
      {isOpen && (
        <div className="dropdown-menu">
          <div className="apply-btns">
            <button className="reset-button" onClick={handleResetTradeData}>Reset</button>
            <button
              className="apply-btn"
              onClick={handleApplyClick}
            >
              Apply
            </button>
          </div>
          <div>
            <div className="d-flex align-space-between category-head">
              <p>Service Category</p>
              <img src={ArrowdownIcon} alt="ArrowdownIcon" />
            </div>
            <div className="menu-items">
              {tradeServiceTypes &&
                tradeServiceTypes.map((service) => (
                  <button
                    key={service.id}
                    onClick={() => handleTradeCategoryButtonclick(service.id)}
                    className={
                      tradeserviceCategories?.includes(service.id)
                        ? "active"
                        : ""
                    }
                  >
                    {service.name}
                  </button>
                ))}
            </div>

            <div>
              <div className="d-flex align-space-between category-head">
                <p>States</p>
                <img src={ArrowdownIcon} alt="ArrowdownIcon" />
              </div>
              <div className="menu-items">
                {states &&
                  states.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleTradeStateButtonClick(service.id)}
                      className={
                        tradestateData?.includes(service.id) ? "active" : ""
                      }
                    >
                      {service.name}
                    </button>
                  ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Card = ({
  companyLogo,
  businessName,
  location,
  address,
  dietrayTypes,
  productTypes,
  serviceTypes,
  onActionClick,
  handleClikFoodserviceLike,
  fsbranchid,
  liked,
  decision,
  refetchfoodserviceData,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const [cstofsaccept, {}] = useCafeacceptfdMutation();
  const [cstofsdeclined, {}] = useCaferejectfdMutation();
  const currentbranchbranchId = localStorage.getItem(
    "branchId_current_selected"
  );

  const handleActiveClick = async () => {
    const postdata = {
      fs_branch_id: fsbranchid,
      status: "accepted",
    };
    try {
      const response = await cstofsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);
        refetchfoodserviceData();
      }
    } catch (err) {
      alert("Failed to Accept");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  const handleInactiveClick = async () => {
    const postdata = {
      fs_branch_id: fsbranchid,
      status: "declined",
    };
    try {
      const response = await cstofsdeclined({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        // setIsActive(true);
        // setIsHidden(false);
        alert("Listing removed from matches and added to declined list.");
        refetchfoodserviceData();
      }
    } catch (err) {
      alert("Failed to decline the listing");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  if (isHidden) {
    return null;
  }
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
   <>
      <div className="card">
        <div className="d-flex justify-space-between">
          <div className="d-flex">
            <div>
              <img src={companyLogo || ComapnyLogo} alt="CompanyLogo" className="matches-company-logo"/>
            </div>
            <Link to={`/food-preview-matches/${fsbranchid}`}> <div className="mlr-20">
              <h4>{businessName}</h4>
              
              <p>{truncateText(location, 25)}</p>
            </div></Link>
          </div>
          <div className="d-flex align-items-start">
            <img
              src={liked ? heartImgFill : heartImg}
              alt="heart-img"
              className="img-fluid"
              width={24}
              onClick={handleClikFoodserviceLike}
            />
            {/* <img
              src={ActionIcon}
              alt="ActionIcon"
              className="img-fluid"
              width={30}
              onClick={onActionClick}
            /> */}
          </div>
        </div>
        <div className="d-flex justify-space-between flex-end">
          <div>
            <p className="address">{address}</p>
          </div>
          {decision !== "accepted" && (
            <div className="d-flex">
              {/* <button><img src={InactiveBUtton} alt='InactiveButton' /></button>
            <button><img src={ActiveButton} alt='ActiveButton' /></button> */}
              {!isActive ? (
                <>
                  <button onClick={handleInactiveClick}>
                    <img src={InactiveBUtton} alt="InactiveButton" />
                  </button>
                  <button onClick={handleActiveClick}>
                    <img src={ActiveButton} alt="ActiveButton" />
                  </button>
                </>
              ) : (
                <Link to="/eco-enquiry">
                  <button type="button" className="primary-btn">
                    Message
                  </button>
                </Link>
              )}
            </div>
          )}

          {decision === "accepted" && (
            <div className="d-flex">
              {/* <button><img src={InactiveBUtton} alt='InactiveButton' /></button>
            <button><img src={ActiveButton} alt='ActiveButton' /></button> */}

              <Link to="/eco-enquiry">
                <button type="button" className="primary-btn">
                  Message
                </button>
              </Link>
            </div>
          )}
        </div>
        <hr />
        <Link to={`/food-preview-matches/${fsbranchid}`}>
        <div className="services-detail-data">
        <div className="service-type types">
          <h5>Service Type</h5>
          {serviceTypes &&
            serviceTypes?.map((type, index) => (
              <button key={index}>{type.name.split(":")[0]}</button>
            ))}
        </div>
        <div className="dietary-type types">
          <h5>Dietary services</h5>
          {dietrayTypes &&
            dietrayTypes?.map((type, index) => (
              <button key={index}>{type.name}</button>
            ))} 
        </div>
        <div className="product-type types">
          <h5>Product type</h5>
          {/* {productTypes && productTypes?.map((type, index) => (
          <button key={index}>{type}</button>
        ))} */}
        </div>
        </div>
        <div className="see-more-div">
        <button type="button" className="see-more-btn">See more</button>
        </div>
       
        </Link>
      </div>
    </>
  );
};

const TradeCard = ({
  companyLogo,
  businessName,
  location,
  address,
  serviceTypes,
  onActionClick,
  tsbranchid,
  handleClikTradeserviceLike,
  liked,
  decision,
  refetchTradeserviceData,
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isHidden, setIsHidden] = useState(false);

  const [cstotsaccept, {}] = useTdacceptcafeMutation();
  const [cstotsdeclined, {}] = useTdrejectcafeMutation();
  const currentbranchbranchId = localStorage.getItem(
    "branchId_current_selected"
  );

  const handleActiveClick = async () => {
    const postdata = {
      ts_branch_id: tsbranchid,
      status: "accepted",
    };
    try {
      const response = await cstotsaccept({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        setIsActive(true);
        setIsHidden(false);
        refetchTradeserviceData();
      }
    } catch (err) {
      alert("Failed to Accept");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };

  const handleInactiveClick = async () => {
    const postdata = {
      ts_branch_id: tsbranchid,
      status: "declined",
    };
    try {
      const response = await cstotsdeclined({
        postdata,
        currentbranchbranchId,
      }).unwrap();
      if (response) {
        // setIsActive(true);
        // setIsHidden(false);
        alert("Listing removed from matches and added to declined list.");
        refetchTradeserviceData();
      }
    } catch (err) {
      alert("Failed to decline the listing");
    }

    // setIsActive(true);
    // setIsHidden(false);
  };
  if (isHidden) {
    return null;
  }
  const truncateText = (text, maxLength) => {
    return text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;
  };

  return (
   <>
      {" "}
      <div className="card">
        <div className="d-flex justify-space-between">
          <div className="d-flex">
            <div>
            <img src={companyLogo || ComapnyLogo} alt="CompanyLogo" className="matches-company-logo"/>
            </div>
            <Link to={`/trade-preview-matches/${tsbranchid}`}> <div className="mlr-20">
              <h4>{businessName}</h4>
              <p>{truncateText(location, 25)}</p>
            </div></Link>
          </div>
          <div className="d-flex align-items-start">
            <img
              src={liked ? heartImgFill : heartImg}
              alt="heart-img"
              className="img-fluid"
              width={24}
              onClick={handleClikTradeserviceLike}
            />

            {/* <img
              src={ActionIcon}
              alt="ActionIcon"
              className="img-fluid"
              width={30}
              onClick={onActionClick}
            /> */}
          </div>
        </div>
        <div className="d-flex justify-space-between flex-end">
          <div>
            <p className="address">{address}</p>
          </div>
          {decision !== "accepted" && (
            <div className="d-flex">
              {/* <button><img src={InactiveBUtton} alt='InactiveButton' /></button>
            <button><img src={ActiveButton} alt='ActiveButton' /></button> */}
              {!isActive ? (
                <>
                  <button onClick={handleInactiveClick}>
                    <img src={InactiveBUtton} alt="InactiveButton" />
                  </button>
                  <button onClick={handleActiveClick}>
                    <img src={ActiveButton} alt="ActiveButton" />
                  </button>
                </>
              ) : (
                <Link to="/eco-enquiry">
                  <button type="button" className="primary-btn">
                    Message
                  </button>
                </Link>
              )}
            </div>
          )}

          {decision === "accepted" && (
            <div className="d-flex">
              {/* <button><img src={InactiveBUtton} alt='InactiveButton' /></button>
            <button><img src={ActiveButton} alt='ActiveButton' /></button> */}

              <Link to="/eco-enquiry">
                <button type="button" className="primary-btn">
                  Message
                </button>
              </Link>
            </div>
          )}
        </div>
        <hr />
        <Link to={`/trade-preview-matches/${tsbranchid}`}>
        <div className="services-detail-data">
        <div className="service-type types">
          <h5>Service Type</h5>
          {serviceTypes &&
            serviceTypes?.map((type, index) => (
              <button key={index}>{type.name}</button>
            ))}
        </div>
        </div>
        <div className="see-more-div">
        <button type="button" className="see-more-btn">See more</button>
        </div>

        </Link>
        
      </div>
   </>
  );
};

function EcoSystemMatches() {
  const [activeTab, setActiveTab] = useState("tab1");
  const branchId = localStorage.getItem("branchId_current_selected");
 
  const [foodServiceParams, setfoodServiceParams] = useState({
    id: branchId,
    service_categories: [],
    states: [],
    product_type: [],
    specialist_dietary_services: [],
    recommendation: "",
    ordering: "",
    delivery_range: "",
  });

  const [tradeServiceParams, settradeServiceParams] = useState({
    id: branchId,
    service_categories: [],
    states: [],
    recommendation: "",
    ordering: "",
    delivery_range: "",
  });

  const [foodserviceCategories, setfoodserviceCategories] = useState([]);
  const [productType, setproductTypes] = useState([]);
  const [dietaryType, setdietaryType] = useState([]);
  const [stateData, setstates] = useState([]);
  const [tradestateData, setstatesTrade] = useState([]);
  const [tradeserviceCategories, settradeserviceCategories] = useState([]);
  const [orderingValue, setOrdering] = useState("");

  const { data: serviceTypes } = useGetServiceTypeQuery();

  const { data: states } = useGetStatesQuery();

  const { data: productTypes } = useGetProductTypeQuery();

  const { data: dietryServices } = useGetfoodDeietryServiceQuery();
  const { data: tradeServicesType } = useGetTradeServiceCategoryQuery();
  const {
    data: foodServiceData,
    isLoading: ecosystemfoodServiceIsloding,
    refetch: refetchfoodserviceData,
  } = useGetEcosystemFoodSericeQuery(foodServiceParams);

  const {
    data: tradeServiceData,
    isLoading: ecosystemTradeServiceIsloding,
    refetch: refetchTradeserviceData,
  } = useGetEcosystemTradeSericeQuery(tradeServiceParams);

  useEffect(() => {
   
  }, [foodServiceData]);

  useEffect(() => {
   
  }, [tradeServiceData]);

  const handleFoodCategoryButtonclick = (serviceTypeId) => {
    setfoodserviceCategories((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };
  const handleStateButtonClick = (serviceTypeId) => {
    setstates((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleProductTypeButtonclick = (serviceTypeId) => {
    setproductTypes((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleDietraryTypeButtonclick = (serviceTypeId) => {
    setdietaryType((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const handleTradeCategoryButtonclick = (serviceTypeId) => {
    settradeserviceCategories((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };
  const handleTradeStateButtonClick = (serviceTypeId) => {
    setstatesTrade((prevCategories) => {
      if (prevCategories.includes(serviceTypeId)) {
        return prevCategories.filter((id) => id !== serviceTypeId);
      } else {
        return [...prevCategories, serviceTypeId];
      }
    });
  };

  const [progress, setProgress] = useState(0);
  const [deliveryRange, setDeliveryRange] = useState("100km");
  const barRef = useRef(null);

  useEffect(() => {
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      delivery_range: 100,
    }));
    setProgress(100);
    setDeliveryRange("100km");
  }, []); // Empty dependency array to run only once on mount

  const updateProgress = useCallback((clientX) => {
    if (!barRef.current) return;
    const barRect = barRef.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100);
    const roundedvalue = Math.round(
      Math.min(Math.max((offsetX / barWidth) * 100, 0), 100)
    );
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      delivery_range: roundedvalue,
    }));

    setProgress(newProgress);
    setDeliveryRange(`${Math.round(newProgress)}km`);
  }, []);

  const handleMouseDown = (e) => {
    updateProgress(e.clientX);
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMove);
      },
      { once: true }
    );
  };

  const handleMouseMove = useCallback(
    (e) => {
      if (e.buttons > 0) {
        updateProgress(e.clientX);
      }
    },
    [updateProgress]
  );

  const [progresstrade, setProgresstrade] = useState(0);
  const [deliveryRangetrade, setDeliveryRangetrade] = useState("100km");
  const barReftrade = useRef(null);

  useEffect(() => {
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      deliveryRangetrade: 100,
    }));
    setProgress(100);
    setDeliveryRange("100km");
  }, []); // Empty dependency array to run only once on mount

  const updateProgressTrade = useCallback((clientX) => {
    if (!barReftrade.current) return;
    const barRect = barReftrade.current.getBoundingClientRect();
    const barWidth = barRect.width;
    const offsetX = clientX - barRect.left;
    const newProgress = Math.min(Math.max((offsetX / barWidth) * 100, 0), 100);
    const roundedvalue = Math.round(
      Math.min(Math.max((offsetX / barWidth) * 100, 0), 100)
    );
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      delivery_range: roundedvalue,
    }));

    setProgresstrade(newProgress);
    setDeliveryRangetrade(`${Math.round(newProgress)}km`);
  }, []);

  const handleMouseDownTrade = (e) => {
    updateProgressTrade(e.clientX);
    document.addEventListener("mousemove", handleMouseMoveTrade);
    document.addEventListener(
      "mouseup",
      () => {
        document.removeEventListener("mousemove", handleMouseMoveTrade);
      },
      { once: true }
    );
  };

  const handleMouseMoveTrade = useCallback(
    (e) => {
      if (e.buttons > 0) {
        updateProgressTrade(e.clientX);
      }
    },
    [updateProgressTrade]
  );

  const subscription_type = localStorage.getItem(
    "subscription_type_current_selected"
  );

  const subscription_status  = localStorage.getItem(
    "subscription_status_current_selected"
  );
  const [upgradeModal, setupgradeModal] = useState(false);

  const handleCloseUpgradModal = () => {
    setupgradeModal(false);
  };
  const showupgradeModal = () => {
    setupgradeModal(true);
  };


  useEffect(() => {
    if((subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial"))){
      setupgradeModal(false);
    }
   else {
    setupgradeModal(true);
    }
  }, [subscription_type,subscription_status]);

  const handleFoodServiceApplyClick = () => {
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      service_categories: foodserviceCategories,
      product_type: productType,
      specialist_dietary_services: dietaryType,
      states: stateData,
    }));
  
  };

  const handleResetFoodData = () => {
    // Reset all the selected filters
    setfoodserviceCategories([]); // Reset to an empty array
    setproductTypes([]); // Reset to an empty array
    setdietaryType([]); // Reset to an empty array
    
    setfoodServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      service_categories: [],
      product_type: [],
      specialist_dietary_services: [],
      states: [],
    }));
  
    
  };
  const handleClickOrderingFood = (orderingValue) => {
    if (orderingValue !== "bestmatches") {
      if (orderingValue !== "recommendation") {
        // Pass only the ordering value and clear recommendation
        setfoodServiceParams((prevfoodServiceParams) => ({
          ...prevfoodServiceParams,
          ordering: orderingValue,
          recommendation: null, // Clear the recommendation if ordering is set
        }));
      } else {
        // Pass only recommendation and clear ordering
        setfoodServiceParams((prevfoodServiceParams) => ({
          ...prevfoodServiceParams,
          recommendation: "most-liked",
          ordering: null, // Clear the ordering if recommendation is set
        }));
      }
    } else {
      // Clear both ordering and recommendation, pass only the id
      setfoodServiceParams({ id: branchId });
    }
  };

  const handleClickOrderingTrade = (orderingValue) => {
    if (orderingValue !== "bestmatches") {
      if (orderingValue !== "recommendation") {
        // Pass only the ordering value and clear recommendation
        settradeServiceParams((prevtradeServiceParams) => ({
          ...prevtradeServiceParams,
          ordering: orderingValue,
          recommendation: null, // Clear the recommendation if ordering is set
        }));
      } else {
        // Pass only recommendation and clear ordering
        settradeServiceParams((prevtradeServiceParams) => ({
          ...prevtradeServiceParams,
          recommendation: "most-liked",
          ordering: null, // Clear the ordering if recommendation is set
        }));
      }
    } else {
      settradeServiceParams({ id: branchId });
    }
  };

  const handleTradeServiceApplyClick = () => {
    settradeServiceParams((prevtradeServiceParams) => ({
      ...prevtradeServiceParams,
      service_categories: tradeserviceCategories,
      states: tradestateData,
    }));
  };

  const handleResetTradeData = () => {
    // Reset all the selected filters
    settradeserviceCategories([]); // Reset to an empty array
   
    
    settradeServiceParams((prevfoodServiceParams) => ({
      ...prevfoodServiceParams,
      service_categories: [],
      states: [],
    }));
  
 
  };

  const username = localStorage.getItem("name");

  const [submitFoodLike, {}] = useFoodserviceLikeMutation();

  const [submitTradeLike, {}] = useTradeserviceLikeMutation();

  const handleClikFoodserviceLike = async (id) => {
   
    const postdata = {
      fs_branch_id: id,
    };
    try {
      const response = await submitFoodLike({ postdata, branchId }).unwrap();
      if (response) {
        refetchfoodserviceData();
        // alert(response.status);
      }
    } catch (err) {
      alert("Failed to like");
    }
  };

  const handleClikTradeserviceLike = async (id) => {
    const postdata = {
      ts_branch_id: id,
    };
    try {
      const response = await submitTradeLike({ postdata, branchId }).unwrap();
      if (response) {
        refetchTradeserviceData();
        // alert(response.status);
      }
    } catch (err) {
      alert("Failed to like");
    }
  };

  return (
    // <div
    // className={`ecosystem-matches ${
    //   subscription_type === "pro" && (subscription_type === "pro" && (subscription_status === "active" || subscription_status === "free_trial")) 
    //     ? ""
    //     : "chat-disable-for-liteuser"
    // }`}
    // >
    <div
    className="ecosystem-matches"
    >
      {" "}
      <Row>
        <Col xs={12} sm={12} md={12} lg={12}>
          <h4>Welcome, {username}</h4>
          <p className="sub-head">
            Here are the best current matches in the ecosystem.
          </p>
        </Col>
      </Row>
      <div className="match-tabs-container">
        <div className="match-tabs-header">
          <button
            className={`match-tab-button ${
              activeTab === "tab1" ? "active" : ""
            }`}
            onClick={() => setActiveTab("tab1")}
          >
            Foodservice
          </button>
          <button
            className={`match-tab-button ${
              activeTab === "tab2" ? "active" : ""
            }`}
            onClick={() => setActiveTab("tab2")}
          >
            Trades & Services
          </button>
        </div>
        <div className="match-tabs-content">
          {activeTab === "tab1" && (
            <div className="match-tab-content">
              <Row>
                <Col sm={12} xs={12} lg={6} md={6}>
                  <p className="para">
                    {/* Over {foodServiceData?.length} top matches now available. */}
                    If you don’t see the services you’re looking for, 
                    simply contact support, and we’ll assist you. We’re here to 
                    help you find the right solutions.
                  </p>
                </Col>
                {/* <Col
                  sm={12}
                  xs={12}
                  lg={3}
                  md={3}
                  className="progress-bar-div-matches"
                >
                  <div className="d-flex align-space-between">
                    <span className="near-by-text">Near By</span>
                    <span className="near-by-text">{deliveryRange}</span>
                  </div>
                  <div
                    className="progress-bar-container"
                    ref={barRef}
                    onMouseDown={handleMouseDown}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: `${(progress / 100) * 100}%` }}
                    ></div>
                  </div>
                </Col> */}
                <Col sm={12} xs={12} lg={6} md={6} className="d-flex align-end">
                  <div className="d-flex gap-20">
                    {" "}
                    <SortDropdown
                      handleClickOrderingFood={handleClickOrderingFood}
                    />
                    <FilterDropdownFoodservice
                      handleStateButtonClick={handleStateButtonClick}
                      handleProductTypeButtonclick={
                        handleProductTypeButtonclick
                      }
                      handleFoodCategoryButtonclick={
                        handleFoodCategoryButtonclick
                      }
                      handleDietraryTypeButtonclick={
                        handleDietraryTypeButtonclick
                      }
                      serviceTypes={serviceTypes}
                      handleFoodServiceApplyClick={handleFoodServiceApplyClick}
                      states={states}
                      productTypes={productTypes}
                      dietryServices={dietryServices}
                      foodserviceCategories={foodserviceCategories}
                      productType={productType}
                      dietaryType={dietaryType}
                      stateData={stateData}
                      handleResetFoodData={handleResetFoodData}
                     
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {/* {foodServiceData && foodServiceData.length > 0 ? (
                  foodServiceData.map((data, index) => (
                    <Col sm={12} xs={12} md={4} lg={4} key={index}>
                      <Card
                        companyLogo={data.companyLogo}
                        businessName={data.business_name}
                        location={data.location}
                        address={data.address}
                        status={data.status}
                        productTypes={data.productTypes}
                        dietrayTypes={data.dietrayTypes}
                        serviceTypes={data.serviceTypes}
                        onActionClick={data.onActionClick}
                      />
                    </Col>
                  ))
                ) : (
                  <div>No data available</div>
                )} */}

                {foodServiceData && foodServiceData?.results ? (
                  foodServiceData.results.length > 0 ? (
                    foodServiceData.results.map((data, index) => (
                      <Col sm={12} xs={12} md={4} lg={4} key={index}>
                        <Card
                          companyLogo={data.additional_detail.logo}
                          fsbranchid={data.id}
                          businessName={data.business_name || "N/A"}
                          location={data.location || "N/A"}
                          address={data.address || "N/A"}
                          status={data.status || "N/A"}
                          liked={data.liked}
                          decision={data.decision}
                          productTypes={data.productTypes}
                          dietrayTypes={data.dietrayTypes}
                          serviceTypes={
                            data.additional_detail?.service_categories || []
                          }
                          onActionClick={data?.onActionClick}
                          handleClikFoodserviceLike={() =>
                            handleClikFoodserviceLike(data.id)
                          }
                          refetchfoodserviceData={refetchfoodserviceData}
                        />
                      </Col>
                    ))
                  ) : (
                    <div>No data available</div>
                  )
                ) : (
                  <div>No data available</div>
                )}

                {ecosystemfoodServiceIsloding && <div class="loader"></div>}
              </Row>
            </div>
          )}
          {activeTab === "tab2" && (
            <div className="match-tab-content">
              <Row>
                <Col sm={12} xs={12} lg={6} md={6}>
                  <p className="para">
                    {/* Over {tradeServiceData?.length} top matches now available. */}
                    If you don’t see the services you’re looking for, simply contact support, 
                    and we’ll assist you. We’re here to help you find the right solutions.
                  </p>
                </Col>
                {/* <Col
                  sm={12}
                  xs={12}
                  lg={3}
                  md={3}
                  className="progress-bar-div-matches"
                >
                  <div className="d-flex align-space-between">
                    <span className="near-by-text">Near By</span>
                    <span className="near-by-text">{deliveryRangetrade}</span>
                  </div>
                  <div
                    className="progress-bar-container"
                    ref={barReftrade}
                    onMouseDown={handleMouseDownTrade}
                  >
                    <div
                      className="progress-bar"
                      style={{ width: `${progresstrade}%` }}
                    ></div>
                  </div>
                </Col> */}
                <Col sm={12} xs={12} lg={6} md={6} className="d-flex align-end">
                  <div className="d-flex gap-20">
                    {" "}
                    <SortDropdownTrade
                      handleClickOrderingTrade={handleClickOrderingTrade}
                    />
                    <FilterDropdownTradeservice
                      handleTradeStateButtonClick={handleTradeStateButtonClick}
                      tradeServiceTypes={tradeServicesType}
                      handleTradeServiceApplyClick={
                        handleTradeServiceApplyClick
                      }
                      states={states}
                      handleTradeCategoryButtonclick={
                        handleTradeCategoryButtonclick
                      }
                      tradestateData={tradestateData}
                      tradeserviceCategories={tradeserviceCategories}
                      handleResetTradeData={handleResetTradeData}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                {tradeServiceData && tradeServiceData.results ? (
                  tradeServiceData.results.length > 0 ? (
                    tradeServiceData.results.map((data, index) => (
                      <Col sm={12} xs={12} md={4} lg={4} key={index}>
                        <TradeCard
                          companyLogo={data.additional_detail.logo}
                          tsbranchid={data.id}
                          decision={data.decision}
                          businessName={data.business_name || "N/A"}
                          location={data.location || "N/A"}
                          address={data.address || "N/A"}
                          status={data.status || "N/A"}
                          liked={data.liked}
                          serviceTypes={
                            data.additional_detail?.service_categories || []
                          }
                          onActionClick={data?.onActionClick}
                          handleClikTradeserviceLike={() =>
                            handleClikTradeserviceLike(data.id)
                          }
                          refetchTradeserviceData={refetchTradeserviceData}
                        />
                      </Col>
                    ))
                  ) : (
                    <div>No data available</div>
                  )
                ) : (
                  <div>No data available</div>
                )}
                {ecosystemTradeServiceIsloding && <div class="loader"></div>}
              </Row>
            </div>
          )}
        </div>
        {/* <UpgradeModal
          show={upgradeModal}
          handleCloseUpgradeModal={handleCloseUpgradModal}
        /> */}
      </div>
      {/* <div className="lock-page">
        <img src={LockIcon} alt={LockIcon} />
        <br />
        <Link  onClick={showupgradeModal}>
          <button type="button" className="upgrade-btn">Upgrade to pro</button>
        </Link>
      </div> */}
    </div>
  );
}

export default EcoSystemMatches;
