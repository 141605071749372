import React from "react";
import "../Styles/ProductTypes.css";
import CloseImg from "../Assets/Images/close.svg";
import { useGetProductTypeFoodQuery} from "../Services/FoodserviceAPI";

function ProductTypes({ handlecloseProductTypeModal }) {
  const handleClose = () => {
    handlecloseProductTypeModal();
  };
  const { data: producttypes } = useGetProductTypeFoodQuery();

  return (
    <div className="trades-product-types">
      <img
        src={CloseImg}
        alt="CloseImg"
        className="close-img"
        onClick={handleClose}
      />
      <div>
        {/* <h4>Product type</h4>
        <div className="d-flex">
          {producttypes?.map((product) => (
            <button>{product.name}</button>
          ))}
        </div> */}
      </div>
    </div>
  );
}

export default ProductTypes;
