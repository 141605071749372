import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "../Styles/UpgradeModal.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSubcsriptionCancleMutation } from "../Services/CcownerAPI"

function UpgradeModal({ show, handleCloseUpgradeModal, selectedBranchIds }) {
  const navigate = useNavigate();
  const handleCloseModal = () => {
    handleCloseUpgradeModal();
  }
  const [submitForm, { isLoading, isError, isSuccess, error }] =
    useSubcsriptionCancleMutation();

  const handleSubmit = async (e) => {
    let postData = {
      branch_ids: selectedBranchIds
    }
    e.preventDefault();
    try {
      const response = await submitForm(postData).unwrap();
      if (response) {
        handleCloseUpgradeModal();
        navigate("/downdrade-lite-success")
      }

    } catch (err) {
      console.error("Failed to submit form:", err);
      alert("Failed");
      handleCloseUpgradeModal();
    }
  }

  return (
    <Modal
      show={show}
      onHide={handleCloseUpgradeModal}
      backdrop="true"
      dialogClassName="modal-dialog-centered upgrade-modal"
    >
      <Modal.Body>
        <Row>
          <Col xs={12} sm={12} md={5} lg={5}>
            <div className="left-sec">
              <h4>All Lite Member Features Plus:</h4>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Reduced networking opportunities</span>
              </h5>

              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Limited ecosystem communication and no matching support</span>
              </h5>

              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Standard industry rates for 1:1 mentorship & road mapping</span>
              </h5>

              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Paid access to specialist course frameworks</span>
              </h5>

              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Paid access to regular group huddle sessions</span>
              </h5>

              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>No access to development materials</span>
              </h5>

              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>Basic support only</span>
              </h5>
              <h5>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="8"
                  viewBox="0 0 9 8"
                  fill="none"
                >
                  <path
                    d="M5.21921 0L4.87683 3.07307L7.99165 2.20459L8.26722 4.30898L5.42797 4.50939L7.29019 6.98956L5.39457 8L4.09186 5.38622L2.94781 7.9833L0.977035 6.98956L2.82255 4.50939L0 4.29228L0.325678 2.20459L3.3737 3.07307L3.03132 0H5.21921Z"
                    fill="#FD9C00"
                  />
                </svg>
                <span>No discounts on products and services</span>
              </h5>

            </div>
          </Col>
          <Col xs={12} sm={12} md={7} lg={7}>
            <div className="right-sec">
              <h4>Considering Downgrading?</h4>
              <p>
                We get that things can change. Just make sure this decision fits your goals and those of your cafe.
              </p>
              <Link onClick={handleSubmit}><div className="upgrade-to-pro" aria-disabled={isLoading}>
                <h4>Continue with Downgrade to Lite </h4>
                <p>Stick with the basic plan and access limited features.  </p>
              </div></Link>
              {isLoading && <div class="loader downgrade-to-lite-loader"></div>}
              <div className="upgrade-to-pro" onClick={handleCloseModal}>
                <h4>Stick with Pro Plan</h4>
                <p>Get full access to all features and exclusive benefits.  </p>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default UpgradeModal;
